import {iFrameConnection} from "./IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {
    GATE_TYPE,
    PANEL_FRAME,
    PANEL_TYPE
} from "../../blink-sdk/Constants";
import {
    ensureArray,
    ensureInEnum,
    ensureNonRecursivePlainObject,
    ensurePlainObject,
    isHTTPSUrl,
    isValidFontFace
} from "../panel/Security";
import {BlinkGlobal, cleanObject, toArray} from "../UtilsLib";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {ProductStore} from "../../client/state/ProductStore.js";
import {filterStoreByMerchantAndLooseIdArray} from "../../client/state/misc/Filter.js";


// TODO @cleanup remove all getters
class IFrameState extends Dispatchable {
    iframeParams = {
        forceHideWallet: false,
        gateType: null,
        screen: null,
        allowUserToCoverFees: null,
        merchantPageUrl: "",
        merchantPageReferrerUrl: "",
        preferredEmail: null,
        activePanel: false,
    };

    init(iframeParams) {
        this.iframeParams = iframeParams;

        BlinkGlobal.iFrameState = this;

        iFrameConnection.addListener(IFrameMessages.UPDATE_APP_STATE, ({iframeParams}) => {
            this.iframeParams = iframeParams;
            this.dispatchChange(iframeParams);
        });

        iFrameConnection.addListener(IFrameMessages.UPDATE_PANEL_CONTENT, ({contentAST}) => {
            this.iframeParams.contentAST = contentAST;
            this.dispatch("customPanelContentUpdate");
        });
    }

    get activePanel() {
        return this.iframeParams.activePanel;
    }

    get forceHideWallet() {
        return this.iframeParams.forceHideWallet;
    }

    get preferredEmail() {
        return this.iframeParams.preferredEmail;
    }

    // These panel options get transferred from panel to modal when the flow switches iframe.
    getPanelToFlowOptions() {
        // TODO: Probably transfer unevaluated context? Not sure.
        // TODO @flow @cleanup there need to be a consistent rule on what gets passed from panel to flow.
        const copiedFields = ["subscriptionOffer", "donationOffer", "newsletter", "messages", "subscriptionOfferConfigs"];
        let inheritedOptions = {};
        for (const key of copiedFields) {
            if (this.iframeParams[key]) {
                inheritedOptions[key] = this.iframeParams[key];
            }
        }

        return {
            ...inheritedOptions,
            metadata: this.getMetadata(),
            onFlowCheckpoint: this.iframeParams.onFlowCheckpoint,
        };
    }

    getMetadata() {
        return cleanObject({
            ...this.iframeParams.metadata,
            // Make sure to only overwrite what we explicitly have
            ...cleanObject({
                journeyId: this.iframeParams.journeyId,
                journeyAlias: this.iframeParams.journeyAlias,
            })
        });
    }

    get donationOverrides() {
        return this.iframeParams.donationOverrides || {};
    }

    get panelType() {
        return ensureInEnum(this.iframeParams.type, PANEL_TYPE);
    }

    get skipCTA() {
        return !!this.iframeParams.skipCTA;
    }

    get skipAuth() {
        return !!this.iframeParams.skipAuth;
    }

    get editRecurring() {
        return !!this.iframeParams.editRecurring;
    }

    get frame() {
        return ensureInEnum(this.iframeParams.frame, PANEL_FRAME);
    }

    get gateType() {
        return ensureInEnum(this.iframeParams.gateType, GATE_TYPE);
    }

    get subscriptionOffer() {
        const {subscriptionOffer} = this.iframeParams;
        return subscriptionOffer ? (Array.isArray(subscriptionOffer) ? subscriptionOffer : [subscriptionOffer]) : null;
    }

    get newsletter() {
        const {newsletter} = this.iframeParams;
        return newsletter && toArray(newsletter);
    }

    get authSocialProviders() {
        return ensureArray(this.iframeParams.authSocialProviders).map(value => value.toLowerCase());
    }

    get theme() {
        return ensurePlainObject(this.iframeParams.theme);
    }

    get messages() {
        return ensureNonRecursivePlainObject(this.iframeParams.messages);
    }

    get title() {
        return this.iframeParams.title;
    }

    get textStyle() {
        // TODO @cleanup What the fuck is this?
        return ensurePlainObject(this.iframeParams.textStyle);
    }

    get footerSeparatorStyle() {
        return ensurePlainObject(this.iframeParams.footerSeparatorStyle);
    }

    get footerTextStyle() {
        return ensurePlainObject(this.iframeParams.footerTextStyle);
    }

    get allowUserToCoverFees() {
        // TODO: Make sure you support the fees checkbox while editing after some model update.
        return this.iframeParams.allowUserToCoverFees;
    }

    get actionButtonStyle() {
        return ensurePlainObject(this.iframeParams.actionButtonStyle);
    }

    get logoSrc() {
        return isHTTPSUrl(this.iframeParams.logoSrc) ? this.iframeParams.logoSrc : null;
    }

    get logoAlt() {
        return this.iframeParams.logoAlt;
    }

    get fonts() {
        return ensureArray(this.iframeParams.fonts).filter(isValidFontFace);
    }

    get merchantPageUrl() {
        return this.iframeParams.merchantPageUrl;
    }

    get widgetVisible() {
        return this.iframeParams.visible;
    }

    get screen() {
        return this.iframeParams.screen;
    }

    get positionBottom() {
        return this.iframeParams.positionBottom;
    }

    // TODO @branch these should be deprecated
    isInlineDonationFlow() {
        return this.gateType === GATE_TYPE.popup && this.panelType === PANEL_TYPE.donation;
    }

    isInlineSubscriptionFlow() {
        return this.gateType === GATE_TYPE.popup && this.panelType === PANEL_TYPE.subscribe;
    }

    handleFlowInline() {
        return this.gateType === GATE_TYPE.popup || this.iframeParams.inlineFlow;
    }
}

export const iFrameState = new IFrameState();
