import {UI} from "../../../stem-core/src/ui/UIBase.js";

import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {getQueryParam, isSmallScreen} from "../../Utils.js";
import {ErrorPanel} from "../components/ErrorPanel.jsx";
import {Messages} from "../../Messages.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {Image} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {
    apiGetNewsletterSubscriptions, apiUserUpdateNewsletterSubscriptions,
    MerchantAudienceMemberStore, NewsletterSubscriptionUpdateType
} from "../../../client/state/merchant/MerchantAudienceMemberStore.js";
import {Errors} from "../../../client/connection/services/Errors.js";
import {MerchantStore} from "../../../client/state/MerchantStore.js";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore.js";
import {UserProfileStore} from "../../../client/state/UserProfileStore.js";
import {CheckboxInput} from "../../../stem-core/src/ui/input/checkbox/CheckboxInput.jsx";
import {Toast} from "../../ui/Toast.jsx";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore.js";
import {MessageElement} from "../../widget/ui/MessageElement.jsx";
import {TNR_ALIAS} from "../../../blink-sdk/Constants.js";

class EmailPreferencesPageStyle extends StyleSheet {
    @styleRule
    pageContent = {
        minHeight: 144,
        padding: 20,
        width: 416,
        boxShadow: this.themeProps.LOGO_CARD_SHADOW,
        borderRadius: this.borderRadius,
        height: () => (isSmallScreen() ? "100vh" : "fit-content"),
        display: () => (isSmallScreen() ? "flex" : ""),
        flexDirection: "column",
        transform: "translate(-50%,-50%)",
        position: "fixed",
        top: "50%",
        left: "50%",
        bottom: "50%",
        right: "50%",
    };

    @styleRule
    textContent = {
        margin: "8px 0",
        ":>last-child": {
            marginBottom: 0,
        }
    };

    @styleRule
    checkboxesList = {
        padding: "16px 0",
    };

    @styleRule
    separatorLine = {
        width: "100%",
        height: 0.5,
        background: "#E5E5E5",
    };
}

// TODO: Some of this code is duplicated from UserDashboardNewslettersTab. De-duplicate.
@registerStyle(EmailPreferencesPageStyle)
export class EmailPreferencesPage extends UI.Element {
    editKey = getQueryParam("edit-key");
    emailMessageInstanceKey = getQueryParam("email-message-instance-key");
    loaded = false;
    error = null;
    merchant = null;
    user = null;
    emailMessageInstanceAudienceId = null;

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.pageContent);
    }

    async updateAudienceMemberStatus(audience, isSubscribe) {
        try {
            await apiUserUpdateNewsletterSubscriptions({
                key: this.editKey,
                merchantId: this.merchant.id,
                updateType: isSubscribe
                    ? NewsletterSubscriptionUpdateType.SUBSCRIBE
                    : NewsletterSubscriptionUpdateType.UNSUBSCRIBE,
                newsletterIds: [audience.id],
            }, true);
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error);
        }
    }

    async updateCanReceivePromotionalEmails(canReceivePromotionalEmails) {
        try {
            await apiUserUpdateNewsletterSubscriptions({
                key: this.editKey,
                merchantId: this.merchant.id,
                updateType: NewsletterSubscriptionUpdateType.SUBSCRIBE,
                newsletterIds: [],
                canReceivePromotionalEmails,
            }, true);
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error);
        }
    }

    isTNR() {
        return this.merchant.alias === TNR_ALIAS;
    }

    render() {
        const {
            error,
            loaded,
            editKey,
            merchant,
            user,
            emailMessageInstanceAudienceId,
            styleSheet
        } = this;
        if (!editKey) {
            return <ErrorPanel errorMessage={Messages.invalidEmailUnsubscribeLink}/>;
        }
        if (error) {
            return <ErrorPanel errorMessage={error.message}/>;
        }
        if (!loaded) {
            return <LoadingSpinner/>;
        }
        const emailMessageInstanceAudience = MerchantAudienceStore.get(emailMessageInstanceAudienceId);
        const merchantAudiences = MerchantAudienceStore.filterBy({merchant});
        const merchantUser = MerchantUserStore.getByUserAndMerchant(user.id, merchant.id);
        return [
            <Image src={merchant.wideLogoUrl} style={{width: 376}}/>,
            this.isTNR() ? [
                <h4>Sign up for a TNR newsletter!</h4>,
                <div className={styleSheet.textContent}>Check the boxes below.</div>,
            ] : [
                <h4>{merchant.name}</h4>,
                <div className={styleSheet.textContent}>
                    <MessageElement message={Messages.manageNewsletterPreferences(user.email)}/>
                </div>,
                <div className={styleSheet.textContent}>
                    <MessageElement message={emailMessageInstanceAudience
                        ? Messages.manageNewsletterPreferencesNewsletter(emailMessageInstanceAudience.name)
                        : Messages.manageNewsletterPreferencesPromotional}/>
                </div>
            ],
            <div className={styleSheet.checkboxesList}>
                {merchantAudiences.map(audience => {
                    const audienceMember = MerchantAudienceMemberStore.findBy({audience, user, merchant});
                    return <CheckboxInput className={styleSheet.textContent}
                                            label={audience.name}
                                            onChange={enabled => this.updateAudienceMemberStatus(audience, enabled)}
                                            initialValue={audience === emailMessageInstanceAudience || (audienceMember ? audienceMember.isSubscribed() : false)}/>
                })}
                <div className={styleSheet.separatorLine}/>
                <CheckboxInput className={styleSheet.textContent}
                                 label={
                                     this.isTNR() ? "Promotional emails from <i>The New Republic</i> and its partners" :
                                     Messages.allowPromotionalEmailsFromMerchant(merchant.name)}
                                 initialValue={merchantUser ? merchantUser.canReceivePromotionalEmails : false}
                                 onChange={enabled => this.updateCanReceivePromotionalEmails(enabled)} />
            </div>,
            this.isTNR() ? <div className={styleSheet.textContent}>
                <MessageElement message={`You will be subscribed to the newsletters checked using the email address <b>${user.email}</b>.`}/>
            </div> : null,
        ];
    }

    async onMount() {
        if (this.editKey) {
            try {
                const response = await apiGetNewsletterSubscriptions({
                    editKey: this.editKey,
                    emailMessageInstanceKey: this.emailMessageInstanceKey,
                }, true);
                this.emailMessageInstanceAudienceId = response.emailMessageInstanceAudienceId;
                this.merchant = MerchantStore.loadObject(response);
                this.user = UserProfileStore.loadObject(response);
            } catch (error) {
                if (error.code === Errors.errorCodes.NOT_FOUND || error.code === Errors.errorCodes.VALIDATION_ERROR) {
                    this.error = {
                        message: Messages.invalidEmailUnsubscribeLink,
                    };
                } else {
                    this.error = error;
                }
            } finally {
                this.loaded = true;
                this.redraw();
            }
        }
    }
}
