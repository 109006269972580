import {Dispatchable} from "stem-core/src/base/Dispatcher.js";
import {authService} from "../../client/connection/services/AuthService.js";
import {AuthHelper} from "../../blinkpay/AuthHelper.js";
import {isIframe} from "../../blinkpay/Utils.js";
import {iFrameMerchantService} from "../../blinkpay/services/IframeMerchantService.js";


class OAuth1Client extends Dispatchable {
    constructor(clientConfig) {
        super();
        Object.assign(this, clientConfig);
    }

    setLoginCallback(onLoginCallback) {
        this.onLoginCallback = () => onLoginCallback();
    }

    onRequestTokenCallback(response) {
        const urlParams = new URLSearchParams();
        urlParams.set("oauth_token", response.oauthToken);
        // If we're in an iframe, we send the referral merchant's ID in the OAuth state.
        if (isIframe()) {
            urlParams.set("state", JSON.stringify({merchantId: iFrameMerchantService.merchantId}));
        }

        const url = this.authServerUrl + "?" + urlParams.toString();

        AuthHelper.oauth1TokenReceivedCallback(url);
    }

    async login() {
        AuthHelper.oauth1Login();
        // Request a token from Blink API first
        const response = await authService.requestOAuthToken(this.requestTokenEndpoint);
        this.onRequestTokenCallback(response);
    }

    async handleLoginResponse(urlParams) {
        const oauthToken = urlParams.get("oauth_token");
        const oauthVerifier = urlParams.get("oauth_verifier");
        await authService.loginAtEndpoint(this.loginEndpoint, {
            oauthToken,
            oauthVerifier,
            termsAgreed: true,
        });
        this.onLoginCallback();
    }
}

export {OAuth1Client};
