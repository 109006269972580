import {UI, styleRule, styleRuleInherit, registerStyle} from "../../stem-core/src/ui/UI";
import {Section, SectionStyle} from "../primitives/Section";
import {TeamCard, TeamCardStyle} from "./primitives/TeamCard";
import {isDesktopSmall} from "../Utils";
import {HorizontalLine} from "../sections-common/HorizontalLine";
import {TeamData} from "./TeamData";
import {COMPONENT_MAX_WIDTH} from "../Constants";


class TeamStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        color: this.themeProps.LANDING_PAGE_DARK_FONT_COLOR,
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        paddingTop: 0,
    };

    @styleRule
    title = {
        fontSize: () => isDesktopSmall() ? 24 : 32,
        paddingTop: () => isDesktopSmall() ? 36 : "",
    };

    @styleRule
    teamMembersContainer = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    };

    @styleRule
    invisibleTeamCard = {
        opacity: 0,
        pointerEvents: "none",
    }
}

@registerStyle(TeamStyle)
export class Team extends Section {
    render() {
        const {styleSheet} = this;

        let teamCards = TeamData.map(item => <TeamCard {...item} />);

        if (!isDesktopSmall()) {
            const rowWidth = Math.min(window.innerWidth - 2 * styleSheet.lateralPadding, COMPONENT_MAX_WIDTH);
            const cardsPerRow = parseInt(rowWidth / TeamCardStyle.getInstance().teamCardLargeScreenWidth, 10);

            while (teamCards.length % cardsPerRow) {
                teamCards.push(<TeamCard className={styleSheet.invisibleTeamCard} {...TeamData[0]}/>);
            }
        }

        return [
            <div className={styleSheet.title}>
                Meet the team
            </div>,
            <div className={styleSheet.teamMembersContainer}>
                {teamCards}
            </div>,
            <HorizontalLine marginTop={isDesktopSmall() ? 36 : 60} />,
        ];
    }
}
