export const NavbarConstants = {
    HEIGHT: 75,
    PADDING: 64,
    MOBILE_HEIGHT: 32,
};

export const MOBILE_MARGIN = 24;

export const COMPONENT_MAX_WIDTH = 1080;

export const CDN_LINK = "https://cdn.blink.net/";

export const IMAGES_FOLDER = CDN_LINK + "images/";

export const ILLUSTRATIONS_FOLDER = IMAGES_FOLDER + "illustrations-jonathan/";

export const SCREENSHOTS_FOLDER = IMAGES_FOLDER + "screenshots/";

export const MEDIA_FOLDER = CDN_LINK + "media/";

export const PUBLISHER_URL = "publisher";

export const READER_URL = "reader";

export const COMPANY_URL = "company";

export const NAVBAR_HOVER_CUT_OFF = 10;
