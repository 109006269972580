import {registerStyle, styleRule, styleRuleInherit, StyleSheet, UI} from "../../../../stem-core/src/ui/UI.js";
import {BlinkButton, BlinkButtonStyle} from "../../Button.jsx";
import {BaseInputElement} from "../../../../stem-core/src/ui/input/BaseInputElement.js";
import {BUTTON_TYPE} from "../../../../blinkpay/Constants.js";


class RadioButtonStyle extends BlinkButtonStyle {
    @styleRuleInherit
    container = {
        borderRadius: 0,
        padding: 0,
        marginLeft: () => -this.themeProps.BUTTON_PRIMARY_BORDER_WIDTH,
    };

    @styleRuleInherit
    secondaryButton = {
        opacity: this.themeProps.BASE_DISABLED_OPACITY,
        ":hover:not([disabled])": {
            opacity: 1, // Disable the opacity and remove the parent's properties
            color: null,
            background: null,
            boxShadow: null,
            borderColor: null,
        },
    }

    @styleRule
    invalid = {
        borderColor: this.themeProps.ERROR_COLOR,
        ":hover": {
            borderColor: this.themeProps.ERROR_COLOR,
        }
    };
}

@registerStyle(RadioButtonStyle)
export class RadioButton extends BlinkButton {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            label: "",
            active: false,
        }
    }
}


export class RadioButtonsInputStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        width: "100%",
        // Don't hardcode the height of inline elements, it leads to nasty CSS bugs
        // height: this.themeProps.INPUT_DEFAULT_HEIGHT
        ">:first-child": {
            marginLeft: 0,
            borderRadius: `${this.themeProps.RADIO_BUTTON_BORDER_RADIUS}px 0 0 ${this.themeProps.RADIO_BUTTON_BORDER_RADIUS}px`,
        },
        ">:last-child": {
            marginRight: 0,
            borderRadius: `0 ${this.themeProps.RADIO_BUTTON_BORDER_RADIUS}px ${this.themeProps.RADIO_BUTTON_BORDER_RADIUS}px 0`,
        },
    };

    @styleRule
    button = {
        padding: this.themeProps.RADIO_BUTTON_PADDING,
        flex: this.themeProps.RADIO_BUTTON_FLEX,
    };
}

@registerStyle(RadioButtonsInputStyle)
export class RadioButtonsInput extends BaseInputElement {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            formatter: value => value,
            values: [],
            initialValue: null,
            label: null,
        };
    }

    render() {
        const {styleSheet} = this;
        const {values, formatter} = this.options;
        const currentValue = this.getValue();

        return values.map(value => (
            <RadioButton
                label={formatter(value)}
                active={value === currentValue}
                type={value === currentValue ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
                onClick={() => this.setValue(value)}
                className={styleSheet.button}
            />
        ));
    }

    // TODO deprecate
    getActive() {
        return this.getValue();
    }
}