import {UI, registerStyle} from "../stem-core/src/ui/UI";

import {NavBar} from "./nav/NavBar";
import {Header} from "./sections-common/Header";
import {MobileNavbar} from "./nav/MobileNavbar";
import {NavManager} from "./nav/NavManager";
import {Footer} from "./sections-common/Footer";
import {OurCompany} from "./sections-common/OurCompany";
import {ReadyToGetStarted} from "./sections-common/ReadyToGetStarted";
import {PUBLISHER_URL, READER_URL} from "./Constants";
import {Messages} from "../blinkpay/Messages";
import {AuthenticationComponent} from "./components/AuthenticationComponent";
import {BlinkWallet} from "./sections-user/BlinkWallet";
import {Mission} from "./sections-user/Mission";
import {Features} from "./sections-user/Features";
import {LandingPageIndexStyle, LandingPageIndex} from "./LandingPageIndex";
import {EmptySection} from "./sections-user/EmptySection";

@registerStyle(LandingPageIndexStyle)
export class ReaderIndex extends LandingPageIndex {
    // This ids are tied to the Messages so be careful when changing them.
    navManager = new NavManager(
        [
            {
                id: "navPublisher",
                href: PUBLISHER_URL,
                label: Messages.navPublisher,
            },
            {
                id: "navReader",
                href: READER_URL,
                label: Messages.navReader,
            },
        ],
        [
            {
                element: Header,
                id: "header",
                label: "",
            },
            {
                element: BlinkWallet,
                id: "wallet",
                label: Messages.wallet,
            },
            {
                element: Features,
                id: "features",
                label: Messages.features,
            },
            {
                element: Mission,
                id: "mission",
                label: Messages.mission,
            },
        ]
    );

    onPageEnter() {
        super.onPageEnter();
        this.navBar.refreshFloatingStatus();
        this.features.redraw();
    }

    render() {
        const {styleSheet, navManager} = this;

        const headerOptions = {
            title: Messages.readerHeaderTitle,
            description: Messages.readerHeaderDescription,
            ctaSection: [<AuthenticationComponent colorTheme={AuthenticationComponent.themes.DARK} />],
        };

        const readyToGetStartedOptions = {
            underTitle: Messages.readyToGetStartedSubtitleReader,
            ctaSection: <AuthenticationComponent colorTheme={AuthenticationComponent.themes.LIGHT} />,
        };

        return [
            <MobileNavbar className={styleSheet.navMobile} selectedSection="navReader" navManager={navManager} />,
            <NavBar
                ref="navBar"
                className={styleSheet.nav}
                selectedSection="navReader"
                pageInstance={this}
                navManager={navManager}
            />,
            <Header id="header" {...headerOptions} />,
            <EmptySection />,
            <BlinkWallet id="wallet" containerId="product-content" />,
            <Features id="features" ref="features" containerId="features-content" />,
            <Mission id="mission" containerId="mission-content" />,
            <ReadyToGetStarted {...readyToGetStartedOptions} containerId="ready-content" />,
            <OurCompany containerId="company-content" />,
            <Footer id="contact" containerId="footer-content" />,
        ];
    }
}
