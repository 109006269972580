import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";

import {ConfirmationModal} from "../../../../../ui/ConfirmationModal.jsx";
import {Messages} from "../../../../../Messages.js";
import {wrapInSpinner} from "../../../../../../core/ui/LoadingSpinner.jsx";
import {SubscriptionStore} from "../../../../../../client/state/SubscriptionStore.js";
import {apiDeleteAddress, UserAddressStore} from "../../../../../../client/state/UserAddressStore.js";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw.js";


@autoredraw(SubscriptionStore, UserAddressStore)
export class DeleteAddressModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.deleteAddressTitle,
            cancelLabel: Messages.close,
            confirmLabel: Messages.removeAddress,
            description: Messages.deleteAddressDescription(this.options.address.toString()),
            confirmAction: () => this.deleteAddress(),
            address: null,
        };
    }

    @wrapInSpinner
    async deleteAddress() {
        this.hide();
        await apiDeleteAddress(this.options.address.id);
    }
}
