import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {INLINE_USER_DASHBOARD_URLS} from "../../PanelConstants.js";
import {Flow} from "../Flow.js";

export class EditSubscriptionConfirmationStep extends BaseFlowStep {
    async showPanel() {
        await Flow.subscriptionPlan.fetchPrices(Flow.address.selectedValue); // TODO @flow this is here only as a temp solution, should happen automatically after fetching the address
        Router.changeURL(INLINE_USER_DASHBOARD_URLS.subscriptionChangeConfirm);
    }
}