import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils.js";
import {ShortCodeInput} from "./ShortCodeInput.jsx";
import {Messages} from "../../Messages.js";
import {Errors} from "../../../client/connection/services/Errors.js";
import {isNetworkError} from "../../Utils.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {BlinkButton} from "../../../core/ui/Button.jsx";


export class DefaultCodeConfirmationFormStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        flexDirection: "column",
    }

    // TODO @cleanup we just want a global WIDE button
    @styleRule
    button = {
        width: "100%",
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
        padding: "12px !important",
        textAlign: "center",
        marginTop: 30,
    };
}

@registerStyle(DefaultCodeConfirmationFormStyle)
export class DefaultCodeConfirmationForm extends UI.Element {
    error = null;
    attemptedKeyUpAutoConfirmation = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSubmit: NOOP_FUNCTION,
        }
    }

    render() {
        const {styleSheet} = this;

        return [
            <ShortCodeInput
                ref={"codeInput"}
                onClearError={() => {
                    this.error = null;
                    this.redraw();
                }}
                error={this.error}
                onChange={() => this.redraw()}
                onCodeInserted={() => this.handleCodeInserted()}
                onEnter={() => this.handleEnterKeyPress()}/>,

            <BlinkButton
                label={Messages.confirm}
                className={styleSheet.button}
                disabled={!this.canConfirmInsertedCode()}
                onClick={() => this.confirm()}
            />,
        ];
    }

    handleEnterKeyPress() {
        if (this.canConfirmInsertedCode()) {
            this.confirm();
        }
    }

    handleCodeInserted() {
        if (!this.attemptedKeyUpAutoConfirmation) {
            this.confirm();
            this.attemptedKeyUpAutoConfirmation = true;
        }
    }

    async confirm() {
        try {
            // Careful with the order, the onSubmit might delete the element.
            this.handleConfirmationSuccess();
            await this.options.onSubmit(this.codeInput.getCode());
        } catch (error) {
            if (this.node) {
                this.handleConfirmationError(error);
            }
        }
    }

    getErrorMessageByCode(errorCode) {
        if (errorCode === Errors.confirmEmailCodeErrors.EMAIL_ADDRESS_ALREADY_IN_USE) {
            return Messages.emailAddressAlreadyInUse;
        }

        return Messages.incorrectConfirmationCode;
    }

    handleConfirmationError(error) {
        this.codeInput.blur();
        this.error = isNetworkError(error) ? error.message : this.getErrorMessageByCode(error.code);
        this.redraw();
    }

    handleConfirmationSuccess() {
        this.error = null;
        this.codeInput.blur();
        this.codeInput.updateOptions({success: true});
        this.redraw();
    }

    canConfirmInsertedCode() {
        return this.codeInput && this.codeInput.hasValidCode();
    }

    onMount() {
        super.onMount();

        this.attachListener(Router.Global, "change", () => {
            this.attemptedKeyUpAutoConfirmation = false;
        });
    }
}
