import {UI} from "../../stem-core/src/ui/UIBase.js";
import {StyleSheet} from "../../stem-core/src/ui/Style.js";

import {PaymentMethodIcon} from "../panel/pages/panels/payment-method/components/PaymentMethodIcon.jsx";
import {registerStyle} from "../../stem-core/src/ui/style/Theme.js";
import {styleRule} from "../../stem-core/src/decorators/Style.js";
import {Messages} from "../Messages.js";


export function FormatPaymentMethodDescription(paymentMethod) {
    if (paymentMethod == null) {
        return Messages.walletFunds; // TODO This should still be a payment method
    }
    const lastDigits = paymentMethod.getLastDigits();
    if (lastDigits) {
        return Messages.usingCard(paymentMethod.getBrand(), lastDigits);
    }
    return paymentMethod.getBrand();
}


class PaymentMethodDetailsStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between"
    };

    @styleRule
    primaryDetails = {
        display: "flex",
    }

    @styleRule
    secondaryDetails = {
        display: "flex"
    };

    @styleRule
    icon = {
        marginLeft: 8,
        marginRight: 12,
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    message = {
        paddingLeft: 2,
    }

    @styleRule
    defaultLabel = {
        color: this.themeProps.SUCCESS_COLOR,
        paddingLeft: 8,
        paddingRight: 8,

    };

    @styleRule
    expiryDate = {
        paddingLeft: 8,
        paddingRight: 8,
    }
}

@registerStyle(PaymentMethodDetailsStyle)
export class PaymentMethodDescription extends UI.Element {
    getDefaultOptions() {
        return {
            paymentMethod: null,
            showExpiryDate: false,
            showIfDefault: false,
        };
    }

    render() {
        const {paymentMethod, showExpiryDate, showIfDefault} = this.options;
        const {styleSheet} = this;

        const expiryDate = paymentMethod.getExpiryDate()?.format("MM/YY");

        return [
            <div className={styleSheet.primaryDetails}>
                <PaymentMethodIcon
                    brand={paymentMethod.getBrand()}
                    className={styleSheet.icon}
                    size="1.1em"
                />
                <div className={styleSheet.message}>
                    {FormatPaymentMethodDescription(paymentMethod)}
                </div>
            </div>,
            <div className={styleSheet.secondaryDetails}>
                {showIfDefault && paymentMethod.isPrimary && <div className={styleSheet.defaultLabel}>{Messages.default}</div>}
                {showExpiryDate && expiryDate && <div className={styleSheet.expiryDate}>{expiryDate}</div>}
            </div>
        ];
    }
}
