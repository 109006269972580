import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {DraggableElement} from "../../stem-core/src/ui/Draggable";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {Device} from "../../stem-core/src/base/Device";
import {Direction} from "../../stem-core/src/ui/Constants";


class RangeSliderStyle extends StyleSheet {
    barHeight = 2;
    invisibleBarHeight = 8;
    buttonSize = 12;
    invisibleButtonSize = 30;
    borderRadius = 1;

    @styleRule
    rangeSlider = {
        display: "flex",
        alignItems: "center",
        position: "relative",
    };

    @styleRule
    slider = {
        height: this.barHeight,
        position: "relative",
        background: this.themeProps.WALLET_4,
        flex: 1,
        margin: "0 10px",
        borderRadius: this.borderRadius,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    };

    @styleRule
    bar = {
        height: this.barHeight,
        background: this.themeProps.WALLET_11,
        left: 0,
        top: 0,
        position: "absolute",
        borderRadius: this.borderRadius,
    };

    buttonCommon = {
        transform: "translateX(-50%)",
        position: "absolute",
        borderRadius: "100%",
    };

    @styleRule
    visibleButton = {
        ...this.buttonCommon,
        background: this.themeProps.WALLET_11,
        height: this.buttonSize,
        width: this.buttonSize,
    };

    @styleRule
    button = {
        ...this.buttonCommon,
        cursor: "grab",
        zIndex: 3,
        background: "transparent",
        height: this.invisibleButtonSize,
        width: this.invisibleButtonSize,
    };

    @styleRule
    extreme = {
        color: this.themeProps.MERCHANT_1,
    };

    @styleRule
    value = {
        color: this.themeProps.WALLET_11,
        position: "absolute",
        transform: "translateX(-50%)"
    };

    @styleRule
    valueBelow = {
        top: 12 + this.barHeight,
    };

    @styleRule
    valueAbove = {
        bottom: 12 + this.barHeight,
    };

    @styleRule
    highlighted = {
        color: () => this.themeProps.WALLET_11 + "!important",
    };

    @styleRule
    hidden = {
        display: "none",
    };

    @styleRule
    clickableBar = {
        height: this.invisibleBarHeight,
        cursor: "pointer",
        width: "100%",
        position: "absolute",
        left: 0,
        zIndex: 1,
    };
}

// RangeSlider needs to have a converter to handle mapping values from [0, 1] to the desired interval (and back)
// See the default converter that doesn't change anything as an example for the interface
const NOOP_CONVERTER = {
    getMin() { return 0.0; },
    getMax() { return 1.0; },
    getValue(value) { return value; },
    reverseValue(value) { return value; }
};

// An example converter to transform the range [min, max] into [0, 1] through linear scaling.
export class LinearSizeConverter {
    min = 0;
    max = 0;

    constructor(min, max) {
        this.min = min;
        this.max = max;
    }

    // From a value between 0 and 1.0, return a value between Min and Max
    getValue(value) {
        return this.min + Math.floor(value * (this.max - this.min));
    }

    // For a value between Min and Max, return a value between 0 and 1.0
    reverseValue(value) {
        return (value - this.min) / (this.max - this.min);
    }

    getMin() {
        return this.min;
    }

    getMax() {
        return this.max;
    }
}

class RangeSliderWindowPan extends UI.Element {
    static instance = null;

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.setStyle({
            top: 0,
            left: 0,
            position: "fixed",
            zIndex: 9999,
            width: "100vw",
            height: "100vh",
            cursor: "grabbing",
        });
    }

    static show() {
        this.instance = this.create(document.body);
    }

    static hide() {
        this.instance.node.remove();
        delete this.instance;
    }
}

@registerStyle(RangeSliderStyle)
export class RangeSlider extends UI.Element {
    dragging = false;
    value = null;
    initialValue = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            converter: NOOP_CONVERTER,
            formatFunction: value => value,
            initialValue: 0,
            direction: Direction.UP, // TODO There is a lot of padding at the bottom
            onChange: NOOP_FUNCTION,
            onRelease: NOOP_FUNCTION,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.rangeSlider);
    }

    getConverter() {
        return this.options.converter;
    }

    // Returns a float between 0.0 and 1.0
    getRawValue() {
        return this.rawValue || 0.0;
    }

    setRawValue(rawValue, propagateToValue = true) {
        rawValue = Math.min(rawValue, 1.0);
        rawValue = Math.max(rawValue, 0.0);
        this.rawValue = rawValue;
        if (propagateToValue) {
            this.value = this.getConverter().getValue(rawValue);
        }
        this.options.onChange(this.getValue());
    }

    getValue() {
        if (this.value) {
            // We have two values to always return the exact value if that was intended by a previous setValue()
            // For instance to return the exact initialValue (to not lose precision)
            return this.value;
        }
        const rawValue = this.getRawValue();
        const converter = this.getConverter();
        return converter.getValue(rawValue);
    }

    setValue(value) {
        this.value = value;
        const converter = this.getConverter();
        const rawValue = converter.reverseValue(value);
        this.setRawValue(rawValue, false);
    }

    getPercentageString() {
        return (100.0 * this.getRawValue()) + "%";
    }

    render() {
        const {styleSheet} = this;
        const converter = this.getConverter();
        const min = converter.getMin();
        const max = converter.getMax();
        const {formatFunction, initialValue, direction} = this.options;
        let minClass = "";
        let maxClass = "";
        let valueClass = "";

        // The behaviour is the following: when the class' initial value is changed from options, it means it must be
        // set as the input's value; otherwise, don't modify the current input's value.
        if (this.initialValue !== initialValue) {
            this.initialValue = initialValue;
            this.setValue(initialValue);
        }

        const valueInPercent = this.getPercentageString();

        if (this.getRawValue() === 0.0) {
            minClass = styleSheet.highlighted;
            valueClass = styleSheet.hidden;
        }

        if (this.getRawValue() === 1.0) {
            maxClass = styleSheet.highlighted;
            valueClass = styleSheet.hidden;
        }

        const valuePositionClass = direction === Direction.UP ? styleSheet.valueAbove : styleSheet.valueBelow;

        return [
            <div className={styleSheet.extreme + minClass}>{formatFunction(min)}</div>,
            <div className={styleSheet.slider} ref="slider">
                <div className={styleSheet.bar} style={{width: valueInPercent}}/>
                <div className={styleSheet.clickableBar} ref="clickableBar"/>
                <div className={styleSheet.visibleButton} style={{left: valueInPercent}}/>
                <DraggableElement className={styleSheet.button} ref="button" style={{left: valueInPercent}}/>
                <div className={styleSheet.value + valuePositionClass + valueClass} style={{left: valueInPercent}}>
                    {formatFunction(this.getValue())}
                </div>
            </div>,
            <div className={styleSheet.extreme + maxClass}>{formatFunction(max)}</div>,
        ];
    }

    addSliderListeners() {
        let currentLeft = 0;

        this.button.addDragListener({
            onStart: () => {
                currentLeft = this.getRawValue() * this.slider.getWidth();
                this.dragging = true;
                this.redraw();
                Object.assign(document.body.style, {
                    userSelect: "none",
                    overflow: "hidden",
                });
                RangeSliderWindowPan.show();
            },
            onDrag: (deltaX) => {
                currentLeft += deltaX;
                this.setRawValue(currentLeft / this.slider.getWidth());
                this.redraw();
            },
            onEnd: () => {
                this.dragging = false;
                this.redraw();
                this.options.onRelease(this.getValue());
                Object.assign(document.body.style, {
                    userSelect: "",
                    overflow: "",
                });
                RangeSliderWindowPan.hide();
            }
        });

        this.clickableBar.addClickListener((event) => {
            const eventX = Device.getEventX(event);
            const {left, width} = this.clickableBar.node.getBoundingClientRect();
            this.setRawValue((eventX - left) / width);
            this.redraw();
            this.options.onRelease(this.getValue());
        });
    }

    onMount() {
        super.onMount();
        setTimeout(() => this.addSliderListeners());
    }
}
