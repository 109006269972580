import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {authService} from "../../../../client/connection/services/AuthService.js";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {LOGIN_URL} from "../../../Constants.js";
import {AuthHelper} from "../../../AuthHelper.js";
import {userService} from "../../../../client/connection/services/UserService.js";


// Also has to match the prototype of a flow handler
export class AuthFlowStep extends BaseFlowStep {
    panelType = "auth";
    shouldPrompt = () => !authService.isAuthenticated();
    noHistory = true; // By default, the authentication step can't be accessed through back

    // TODO @flow2 maybe remove this
    async promptIfNeeded(options) {
        if (!userService.getUser()) {
            await this.prompt(options);
        }
    }

    showPanel() {
        if (authService.isAuthenticated()) {
            this.noHistory = false; // TODO @flow2 temp fix to not pop router
            this.onSelectSuccess();
            return;
        }

        Router.changeURL(LOGIN_URL);

        AuthHelper.addAuthenticationCallback(() => {
            this.onSelectSuccess();
        });
    }

    // ----- Methods for compatibility with BaseFlowHandler
    getFlowPlan() {
        return [this];
    }

    finalize() {}

    saveInitialOptions() {
        // Empty on purpose
    }

    sameAsNewOptions(panelType) {
        return this.panelType === panelType;
    }
}
