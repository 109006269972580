import {UI} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {DonationStore} from "../../../../State.js";
import {BaseRecurringPaymentList} from "../../../components/BaseRecurringPaymentList.jsx";


export class PastRecurringDonations extends BaseRecurringPaymentList {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.pastRecurringDonations,
        };
    }

    getEntries() {
        return DonationStore.getPastRecurring();
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            value: entry => entry.getMerchantTitle(),
            headerStyle: {
                textAlign: "left",
                width: "55%",
            }
        }, {
            headerName: Messages.amount,
            value: entry => entry.formatPriceShortened(),
            headerStyle: {
                textAlign: "left",
                width: "20%"
            }
        }, {
            headerName: Messages.lastPayment,
            value: entry => this.renderLastPayment(entry),
            headerStyle: {
                textAlign: "right",
                width: "25%",
            },
            cellStyle: {
                color: this.themeProps.MERCHANT_2
            }
        }];
    }
}
