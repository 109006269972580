import {UI} from "../stem-core/src/ui/UIBase";
// This defines "UI.TranslationTextElement"
import "../stem-core/src/ui/Translation";

// TODO @Mihai When changing any of these keys (like paywall -> panel), update the merchant sdk settings for panel options using that key.
const Messages = {
    networkError: "Internet connection error",
    unexpectedErrorWithCode: "Unexpected error: %1",
    subscribeShareUserName: "We will occasionally recognize members by name on %1. Do we have permission to share your name publicly?",
    donate: "Donate",
    editDonation: "Edit donation",
    editDonationDescription: "%1 - %2 donation.",
    activeDonationDescription1: "Your next payment will occur on %1.",
    activeDonationDescription2: "Thank you for supporting <b>%1!</b>",
    donationDashboardUpdateDescription: "The amount will update at your scheduled payment date.",
    donationErrorAmountNotChanged: "Donation amount not changed",
    donationSuccessAmountChanged: "Donation amount changed",
    cancelDonationTitle: "Confirm donation cancellation",
    cancelDonationDescription: "Are you sure you want to cancel your donation to %1?",
    cancelDonationSuccess: "Successfully canceled your recurring donation.",
    recurringDonor: "Recurring donor — %1",
    editRecurringDonation: "Edit recurring donation",
    recurringDonations: "Recurring donations",
    recurring: "Recurring",
    pastRecurringDonations: "Past recurring donations",
    makeAnotherDonation: "Make another donation",
    recurringDonationPaymentDescription: "Recurring donation to %1",
    oneTimeDonationPaymentDescription: "Donation to %1",
    usingCard: "%1 ending in %2",
    walletFunds: "Blink Wallet Funds",
    update: "Update",
    dashboard: "Dashboard",
    manageAutopay: "Manage Autopay",
    enableAutopay: "Enable Autopay",
    disableAutopay: "Disable Autopay",
    autopayInfo1: "Automatically pay for articles on <i>%1</i> that are at most <b>%2</b>.",
    autopayInfo2: "You can customize autopay settings in your ",
    disabledAutopayInfo1: "Your autopay settings are <b>disabled</b>.",
    disabledAutopayInfo2: "To enable autopay, open your ",
    blinkDashboard: "Blink Dashboard",
    autopayDefaultDescription: "Automatically pay for an article when you open it, instead of manually confirming each article purchase. Your autopay default setting applies to all publications, unless individually overridden.",
    articlePayments: "Purchases", // TODO @branch rename
    articlePaymentsDescription: "Your list of purchases made through Blink.",
    emptyArticlePayments: "So far you haven’t purchased anything through Blink.",
    change: "Change",
    changesSaved: "Changes saved",
    cardAdded: "Card added",
    cardRemoved: "Card removed",
    addressRemoved: "Address removed",
    addNewPaymentMethod: "Add new card", // TODO @cleanup merge "Add card" and "Add new card" instances
    choosePaymentMethod: "Choose a payment method",
    failedAddFunds: "Failed to add funds",
    makeDefault: "Make default",
    readForAmount: "Read for %1",
    addFundsAmount: "Add %1",
    back: "Back",
    subscriptionPaymentDescription: "Subscription to %1",
    chargeback: "Chargeback",
    walletTopUp: "Wallet top-up",
    walletTopUpAutomatic: "Wallet top-up (automatic)",
    subscriptionPayments: "Subscription payments",
    walletInsufficientFunds: "<b>Insufficient balance</b>. Add funds to continue reading.",
    autoReloadWallet: "Your card will be charged %1 when wallet balance drops below %2.",
    autoReload: "Auto reload",
    paymentMethods: "Payment methods",
    addCard: "Add card",
    removeCard: "Remove card",
    saveNewCard: "Save new card",
    personalDetails: "Personal details",
    confirmEmailChangeModalTitle: "Please confirm your current email",
    confirmEmailChangeLine1: "We sent a confirmation code to <b>%1</b> to verify it's you requesting this.",
    confirmEmailChangeLine2: "Once confirmed, your new email address will be <b>%1</b> and we'll send a confirmation to your old email (<b>%2</b>).",
    emailAddressAlreadyInUse: "Email address is already in use",
    emailSuccessfullyChanged: "Email successfully changed.",
    emailChangeUndoSuccessModalTitle: "Email changed back",
    emailChangeUndoSuccessText: "Your email was changed back to <b>%1</b>",
    settings: "Settings",
    alwaysShowBalance: "Always show balance",
    alwaysShowBalanceDescription: "Control if you want to always see your Blink balance while browsing.",
    showPaymentAnimation: "Payment animation",
    showPaymentAnimationDescription: "Show the pulsating animation every time a payment is made.",
    security: "Security",
    closeAccountLabel: "Close account",
    closeAccountText: "Closing your account will delete all your data from our servers and we will refund any outstanding balance to your original payment method. Once initiated, you'll have a 7 days grace period to reconsider, after which we will process the deletion request.",
    closeAccountReasonModalTitle: "We're sorry to see you leaving",
    closeAccountReasonModalDescription: "Tell us why you're closing your account, to help improve our service:",
    closeAccountFeedbackText: "Your feedback is important for us, is there anything else you would like to add?",
    closeAccountModalTitle: "We sent you a confirmation email",
    closeAccountConfirmationLine1: "Please enter the code we sent at <b>%1</b> to finish closing your account.",
    accountClosedModalTitle: "Account suspended and enqueue for deletion",
    accountClosedModalText: "After this grace period, your account will be processed for deletion. We will delete all your personal data from our servers and we will refund any outstanding balance to your original payment method.",
    name: "Name",
    email: "Email",
    save: "Save",
    logOut: "Log out",
    logIn: "Log in",
    other: "Other",
    paymentMethod: "Payment method",
    paymentHistory: "Payment history",
    paymentHistoryDescription: "A list of your credit card charges. These may differ from the payments you made using your Blink wallet balance.",
    autoPayAndBelow1: "Automatically pay for articles ",
    autoPayAndBelow2: " and below.",
    autopayDisabled: "Automatically paying on access is disabled",
    controlAutopayPerMerchant: "You can also enable for individual publications:",
    emailAddress: "Email address",
    enterEmailAddress: "Enter your email address",
    total: "Total",
    cancel: "Cancel",
    cancelSubscription: "Cancel subscription",
    cancelSubscriptionTitle: "Confirm subscription cancellation",
    cancelSubscriptionDescription: "Are you sure you want to cancel your %1 subscription? Your service will be available only until the end of paid period.",
    cancelSubscriptionSuccess: "Successfully canceled",
    reactivateSubscriptionDescription: "Are you sure you want to reactivate your %1 subscription?",
    reactivateSubscriptionTitle: "Confirm subscription reactivation",
    reactivateSubscriptionSuccess: "Successfully reactivated",
    updateEntryPaymentMethodSuccess: "Payment method successfully updated",
    expires: "Expires",
    reactivate: "Reactivate",
    resubscribe: "Re-subscribe",
    canceled: "canceled",
    publicationName: "Publication",
    planDetails: "Plan details",
    lastPayment: "Last payment",
    nextPayment: "Next payment",
    yes: "Yes",
    no: "No",
    edit: "Edit",
    manageSubscription: "Manage subscription",
    activeSubscriptions: "Active subscriptions",
    pastSubscriptions: "Past subscriptions",
    nextBillingDate: "Next billing date %1",
    expiresOnDate: "Expires on %1",
    walletPage: "Wallet",
    subscriptionsPage: "Subscriptions",
    accountPage: "Account",
    donationsPage: "Donations",
    articlesPage: "Purchases", // TODO rename field to articlesPage
    autopay: "Autopay",
    continue: "Continue",
    or: "or",
    unexpectedError: "Unexpected error!",
    continueWithExternalAuth: "Continue with a third-party account", // TODO @cleanup rename to continueWithExternalAuth
    orContinueWithEmailAddress: "Or continue with email",
    continueWithEmail: "Continue with email",
    lookUpPrintMagazineTitle: "Look up your account",
    lookUpPrintMagazineDescription: "Look up your account using your account number or email address and zip code.",
    lookUpPrintMagazineButton: "Look up your print subscription",
    lookupPrintMagazineZipCode: "Zip code",
    createAccount: "Create account",
    loggingInByEmailKey: "Authenticating....",
    redirectingToInitialPage: "Redirecting to initial page...",
    confirm: "Confirm",
    choose: "Choose",
    terms: "Terms of Service",
    policy: "Privacy Policy",
    termsAndConditions: "By continuing, you agree to our ",
    refund: "Refund",
    selectRefundReason: "Select a reason",
    refundTransactionSuccess: "Successfully refunded",
    chooseYourMerchant: "Choose your merchant",
    donations: "Donations",
    from: "From",
    to: "To",
    reports: "Reports",
    download: "Download",
    payouts: "Payouts",
    subscribe: "Subscribe",
    errorWhileSaving: "Error while saving",
    chooseAddress: "Choose address",
    addNewAddress: "Add new address",
    addressStreet: "Street address",
    addressCountry: "Country",
    addressCity: "City",
    addressState: "State",
    addressStateRegion: "State/Region",
    addressZipCode: "Zip code",
    addressZipCodeInvalid: "Invalid format.",
    zipCodeDoesNotMatchState: "Zip code does not match state.",
    invalidStateName: "Invalid state name.",
    addressStateNotRequired: "Not required",
    shippingAddress: "Shipping address",
    wrongEmail: "Use another login?",
    enterConfirmationCodeShort: "To continue, please enter the 6-digit verification code sent to %1.",
    enterConfirmationCodeLong: "To continue, please enter the verification code sent to %1.",
    resendCode: "Resend code",
    priceIncludingShippingAndTax: "(%1 including shipping and tax)",
    activeSubscriptionText: "You have an active subscription on this website.",
    activeSubscription: "Active subscription",
    canceledSubscription: "You have a canceled subscription on this website.",
    incorrectConfirmationCode: "Wrong code, try again.",
    confirmationCodeResent: "New verification code sent.",
    description: "Description",
    amount: "amount",
    date: "date",
    footerSupportEmailText: "Need help? Email us at ",
    promptSetCardAsDefault: "Set this card as default?",
    emptySubscriptionPayments: "You haven’t made any subscription payments through Blink yet.",
    emptyActiveSubscriptions: "You don’t have any active subscriptions.",
    emptyRecurringDonations: "You don’t have any recurring donations.",
    donationPayments: "Donation payments",
    emptyDonationPayments: "You haven’t made any donation payments through Blink yet.",
    termsPreMessage: "Blink makes it easy and secure to log in and pay anywhere on the Internet. ",
    termsAuthenticated: "See our ",
    termsLandingPage: "By signing up, you agree to our ", // TODO @cleanup should be define from Merchant specific terms, with Blink.net being a merchant
    default: "Default",
    pickStartDate: "Pick a <b>start date</b>",
    pickEndDate: "Pick an <b>end date</b>",
    reportsPageDescription: "Download the previously generated reports in CSV format here.",
    discounted: "Discounted",
    discountInfo: "Discount code %1 (%2). More information can be seen by exporting the data as CSV.",
    subscribeInlineAuth1: "<b>%1</b> uses <b>Blink</b> to process subscriptions.", // TODO @flow rename (also in TNR dashboard)
    merchantUsesBlinkForDonations: "<b>%1</b> uses <b>Blink</b> to accept donations.",
    authEmailConfirmationTitle: "Please confirm your email address",
    saveCardDetailsCheckbox: "Securely save my card details for future payments.",
    addCardNameInput: "Name on card",
    donationDetails: "Donation details",
    donorCoversFeesCheckbox: "I'll add %1 to cover the transaction fees so you can keep 100% of my donation.",
    donateInlineFlowButton: "Donate %1%2",
    editDonationInlineFlowButton: "Update to %1/%2",
    donateThankYouTitleRecurring: "Thank you for donating %1!",
    donateThankYouTitleOneTime: "Thank you for donating %1!",
    donateThankYouMessageRecurring: "You are now a recurring donor for <b>%1</b>. We’ve sent you a confirmation email at <b>%2</b>.",
    donateThankYouMessageOneTime: "You have successfully donated to <b>%1</b>. We’ve sent you a confirmation email at <b>%2</b>.",
    donateThankYouBottomMessage: "In the future, you have the possibility to:",
    donateThankYouRecurringGuideline1: "Edit your recurring donation or make another one-time donation from your Blink Wallet.",
    donateThankYouRecurringGuideline2: "Access your Blink Wallet by clicking on the widget in the lower right corner of the screen.",
    donateThankYouOneTimeGuideline1: "Make another donation or become a recurring donor from your Blink Wallet.",
    donateThankYouOneTimeGuideline2: "Access your Blink Wallet by clicking on the widget in the lower right corner of the screen.",
    ok: "Ok",
    finalizePayment: "Finalize payment",
    manageMyAccount: "Manage my account",
    myAccount: "My Account",
    close: "Close",
    supportByDonating: "Please consider making a financial contribution to maintain, expand and improve %1.",
    paymentFailed: "Sorry, payment failed!",
    subscribeFailedMessage: " Alternatively, please try a different payment method.",
    editPaymentDetails: "Edit payment details",
    retryPayment: "Retry payment",
    genericCardError: "There was an error while processing this card.",
    blinkWallet: "Blink Wallet",
    amountInvalidError: "Enter an amount between %1 and %2",
    alreadyHaveAnAccount: "Already have an account?",
    updateAddressSuccess: "Successfully updated shipping address",
    updateAddressTitle: "Choose shipping address",
    discountCode: "Discount code",
    invalidDiscountCode: "Invalid discount code.",
    removeCardEndingIn: "Are you sure you want to remove %1 ending in %2?",
    remove: "Remove",
    cancelDonation: "Cancel donation",
    updatePaymentMethod: "Update payment method",
    subscribePaymentFailed: "Your previous payment failed. Change your payment method or retry your current one to not lose your subscription.",
    donatePaymentFailedRetry: "Your previous payment failed. Change your payment method or retry your current one to continue supporting %1.",
    entrySuccessfullyPaid: "Successfully paid",
    updateAndRetryPayment: "Update & retry payment",
    retry: "Retry",
    updateShippingAddress: "Update shipping address",
    updateShippingAddressDescriptionLine1: "All of your shipments for this subscription will go to this address starting from the next billing cycle.",
    updateShippingAddressDescriptionLine2: "Your new subscription cost will change from <b>%1</b> to <b>%2</b>.",
    emptyIndexExpandedDescription: "This publication doesn't have the functionality you are looking for enabled. (subscriptions/donations)",
    functionalityNotEnabledNewsletters: "<b>%1</b> doesn't offer any newsletters at this time",
    functionalityNotEnabledSubscriptions: "<b>%1</b> doesn't accept subscriptions at this time",
    functionalityNotEnabledDonations: "<b>%1</b> doesn't accept donations at this time",
    functionalityNotEnabledShop: "<b>%1</b> doesn't accept individual purchases at this time",
    functionalityNotEnabled: "<b>%1</b> doesn't have the functionality you are looking for (%2)",
    unauthorizedUserAccountTitle: "This user account isn't associated with a Blink merchant account.",
    unauthorizedUserAccountDescriptionLine1: "<b>%1</b> user account isn't associated with a Blink merchant account.",
    unauthorizedUserAccountDescriptionLine2: "Try logging in with a different email address.",
    deleteMessage: "Delete",
    switchAccount: "Switch account",
    deleteAddressTitle: "Confirm address deletion",
    deleteAddressDescription: "Are you sure you want to remove <b>%1</b> from your address list?",
    removeAddress: "Remove address",
    editAddress: "Edit address",
    addressInUseError: "This address is currently in use, it cannot be modified.",
    paymentMethodInUseError: "Cannot remove this payment method. It is being used for an active recurring payment.",
    changeDetails: "Change details",
    myAddresses: "My Addresses",
    emptyAddressListDescription: "You don't have any physical addresses associated with your Blink account",
    subscribeInlineFlowButton: "Subscribe for %1",
    discountCodeInputLabel: "Apply discount code",
    paymentDetails: "Payment details",
    reviewOrder: "Review order",
    address: "address",
    payment: "payment",
    emptyPaymentMethodsListDescription: "You don't have any cards associated with your Blink account.",
    continueToPayment: "Continue to payment",
    returnToHomepage: "Return to %1 homepage",
    donatePageRecurringDonationMessage: "You are a recurring donor, currently contributing <b>%1</b>. Edit your recurring donation below or cancel it ",
    here: "here",
    donatePageCancelDonationTitle: "Cancel recurring donation?",
    donatePageCancelDonationText1: "Thank you for contributing to <b>%1</b> on a recurring basis. We appreciate all the support we’ve been getting from all our community members.",
    donatePageCancelDonationText2: "We are sorry to see you go and hope you will continue using %1.",
    donatePageCancelDonationButton: "Yes, cancel my donation",
    donatePageDonationCanceledTitle: "Donation canceled",
    noPaymentMethodForThisSubscription: "No payment method associated",
    noPaymentMethodForThisSubscriptionGifted: "Your subscription is a gift. Changing the payment method will grant you full control over the subscription, including upgrading it or preventing a cancellation from the gift buyer.",
    considerSubscribing: "If you love our content and want to help support us, become a subscriber.",
    subscribeToNewsletter: "Sign up to newsletter",
    newslettersEmailDescription: "You will be subscribed to the newsletters using the email address <b>%1</b>.",
    newsletterEmailDescription: "You will be subscribed to the newsletter using the email address <b>%1</b>.",
    newsletterDashboardEmailDescription: "You will be subscribed to the newsletter using the email address <b>%1</b>.",
    newsletterSubscribeSuccessTitle: "Thank you for subscribing to %1 newsletter(s)!",
    newsletterSuccessMessage: "You can review your newsletter subscriptions in ",
    chooseCardDescription: "Use one of the payment methods securely saved in your account or add a new one.",
    creditCardsListHeader: "Your payment methods",
    donationCanceledText1: "Your donation of <b>%1</b> has been canceled!",
    donationCanceledText2: "If you change your mind in the future, you have the possibility to:",
    donationCanceledGuideline1: "Make another donation or become a recurring donor from your Blink Wallet.",
    donationCanceledGuideline2: "Access your Blink Wallet by clicking on the widget in the lower right corner of the screen.",
    editDonationSuccessTitle: "Congratulations!",
    editDonationSuccessText1: "You’ve updated your recurring donation to <b>%1</b>!",
    editDonationSuccessText2: "In the future, you have the possibility to:",
    editDonationSuccessGuideline1: "Edit your recurring donation or make another one-time donation from your Blink Wallet.",
    editDonationSuccessGuideline2: "Access your Blink Wallet by clicking on the widget in the lower right corner of the screen.",
    subscriptionPlan: "Subscription plan",
    subscriptionPlanDescription: "Your current subscription plan is <b>%1</b> at <b>%2/%3</b>.",
    discountCodeApplied: "Discount code <b>%1</b> successfully applied.",
    subscribePaymentDescription: "Your payment method will be automatically charged <b>%1</b> every %2 starting today.",
    giftSubscriptionPaymentDescription: "Your payment method will be charged <b>%1</b>.",
    subscribeThankYouTitle: "Congratulations, you are now a subscriber!",
    subscribeThankYouText: "Your subscription plan is %1. In the future, you have the possibility to:",
    subscribeThankYouGuideline1: "Edit or cancel your subscription from your Blink Wallet.",
    subscribeThankYouGuideline2: "Access your Blink Wallet by clicking on the widget in the lower right corner of the screen.",
    changePlan: "Change plan",
    currentSubscriptionPlan: "You current subscrption plan is %1, paid every %2.",
    changePlanButtonLabel: "Change to %1",
    subscribedButtonLabel: "Subscribed for %1",
    reviewChange: "Review change",
    editSubscriptionCancelTitle: "Are you sure you want to cancel?",
    editSubscriptionCancelText1: "Your current subscriptions plan is <b>%1</b> at <b>%2</b> and will remain active until %3.",
    editSubscriptionCancelText2: "Your subscription plan won't automatically renew.",
    editSubscriptionCancelButton: "Yes, cancel my subscription",
    subscriptionCanceled: "Subscription canceled",
    updateAtEndCycleCheckbox: "Make the change at the end of the current billing period (%1)",
    updateAtEndCyclePaymentInfo: "Your payment method will be automatically charged <b>%1</b> every %2 starting on %3.",
    updateAtEndCycleChangeOffer: " You’ll keep your current plan’s benefits until then.",
    updateInstantlyPaymentInfo: "You will be charged <b>%1</b> now, then <b>%2</b> every %3 starting on %4.",
    newsletterDescription: "%1",
    newsletterSubscribeSingleNewsletterAuthenticatedTitle: "Sign up below",
    newsletterSubscribeSingleNewsletterUnauthenticatedTitle: "Enter your email address and sign up below",
    newsletterSubscribeMultipleNewsletterAuthenticatedTitle: "Check the boxes below to sign up for each newsletter",
    newsletterSubscribeMultipleNewsletterUnauthenticatedTitle: "Enter your email address and check the boxes below to sign up.",
    inlineUserDashboardSubscription: "Subscription",
    notSubscribedToMerchant: "You are not currently subscribed to <b>%1</b>.",
    subscribedCurrentPlan: "Your current subscription plan is <b>%1</b> at <b>%2</b>.",
    paymentMethodCharge: "Your payment method is automatically charged <b>%1</b> every %2.",
    inlineAddressSectionTitle: "Address",
    emptyPayments: "You haven’t made any payments yet.",
    recurringDonation: "Recurring Donation",
    notRecurringDonorToMerchant: "Please consider making a financial contribution to maintain, expand and improve <b>%1</b>.",
    recurringDonorContributing: "You are a recurring donor, currently contributing <b>%1</b>.",
    makeAnotherDonationText: "You can still make another one-time donation to show your support.",
    pastDonations: "Past Donations",
    subscriptionPlanTitle: "Subscription Plan",
    paymentMethodTitle: "Payment Method",
    makeAnotherDonationTitle: "Make Another Donation",
    signUpForMerchantNewsletter: "Sign up for a %1 newsletter!",
    newslettersEditDescription: "Check the boxes below to sign up. You will be subscribed to the newsletters using the email address <b>%1</b>.",
    allowPromotionalEmailsFromMerchant: "Receive promotional emails from <b>%1</b>",
    newslettersTitle: "Newsletters",
    personalDetailsTitle: "Personal Details",
    myAddressesTitle: "My Addresses",
    paymentMethodsTitle: "Payment Methods",
    myDashboard: "My Dashboard",
    dashboardExternalLinkText: "For more control over your account access your ",
    addNew: "Add new",
    accountNumber: "Account number",
    nameAndZip: "Name and zip code",
    invalidEmailUnsubscribeLink: "Invalid unsubscribe link",
    manageNewsletterPreferences: "Manage your newsletter preferences for <b>%1</b>.",
    manageNewsletterPreferencesNewsletter: "You received this email because you are subscribed to newsletter <b>%1</b>. Toggle the checkbox below to unsubscribe.",
    manageNewsletterPreferencesPromotional: "This is a promotional email. Toggle the last checkbox below to unsubscribe from such emails in the future.",
    updateSubscriptionPlanDescription: "You will update your subscription plan to <b>%1</b> at <b>%2</b>.",
    updateSubscriptionPlanAddress: "Your shipping address will be <b>%1</b>.",
    international: "International",
    subscriptionPerksList: "This subscription includes:",
    cardNumberInputPlaceholder: "Card number",
    cardDateInputPlaceholder: "MM/YY",
    cardCVCInputPlaceholder: "CVC",
    bestValue: "BEST VALUE",
    bestValueBelowPrice: "",
    adFree: "AD-FREE",
    merchantUserResourcesAccessLink: "Load %1 from Blink",
    usingBlinkDefault: "Using Blink default",
    authenticationPanelTitle: " ",
    changeSubscription: "Change subscription",
    tnrSubscribeSuccess: "Your subscription continues at the best rate available until you decide to cancel it. We always send a reminder email, including the rate, prior to the charge.",
    subscribeAndEditSuccess: "Thank you for supporting <i>%1</i>. Please check your email inbox for important info related to your subscription.",
    subscribeChangeSuccess: "You have successfully updated your subscription.",
    finalizeDonation: "Finalize donation",
    donationExplanationMessage: "",
    tnrDashboardPlanMessage: "Your subscription continues at the best rate available until you decide to cancel it (if account is set to auto-renew and we have your card details). We always send a reminder email, including the rate, prior to charging your card.",
    newsletterTitle: "Sign up for a %1 newsletter",
    tnrContactCustomerSupport: " For assistance, please reach out to <a href='https://newrepublic.com/faq'>customer support</a>.",
    donation: "Donation",
    giftSubscriptionButtonLabel: "Gift for %1",
    giftDetails: "Gift details",
    giftEmail: "Recipient email address",
    giftName: "Your name (leave blank for anonymous gifts)",
    giftMessage: "Gift message",
    recipientAddress: "Recipient address",
    giftSubscription: "Gift subscription",
    giftSubscriptionSuccessTitle: "Congratulations, your gift has been sent!",
    giftSubscriptionSuccessText: "In the future, you have the possibility to:",
    giftSubscriptionSuccessGuideline1: "Cancel or edit your gift from your Blink Wallet.",
    giftSubscriptionSuccessGuideline2: "Access your Blink Wallet by clicking on the widget in the lower right corner of the screen.",
    giftSubscriptionText: "You have bought a gift subscription for <b>%1</b>. The subscription plan is <b>%2</b> for <b>%3</b>.",
    shopBuyNow: "Buy Now",

    // ----- LANDING PAGE START -----
    mission: "Mission",
    features: "Features",
    wallet: "Wallet",
    missionTitle: "It’s more than just convenience though",
    missionSubtitle: "Our goal is to make the Internet a better place for both readers and publishers.",
    loginMessage: "Already using Blink?",
    publisherHeaderTitle: "Shared Identity and Payment Solutions",
    readerHeaderTitle: "Change the way you use the Internet",
    publisherHeaderDescription: "Blink is a set of tools built to help publishers and creators of digital content. Choose the services you need. Integrate seamlessly. Become part of an amazing network.",
    readerHeaderDescription: "Blink offers an easy way to log in to different publications and make digital news purchases. Access the content you love. Support those who create it.",
    supportJournalistsTitle: "Support serious journalism directly",
    supportJournalistsDescription1: "The only way the situation can be improved is by paying for quality content directly. We need technology that makes this easy, seamless and fair.",
    supportJournalistsDescription2: "Let’s reward the publishers that are keeping our communities well informed. Their work is vital for a healthy society.",
    valuePrivacyTitle: "We value your privacy",
    valuePrivacyDescription1: "We live in a world where big tech companies feed on data, at times often invading our privacy without our explicit consent. Blink’s business model aligns with your best interests.",
    valuePrivacyDescription2: "We want readers to pay with their wallets and not with their data.",
    clickBaitTitle: "We’re drowning in clickbait",
    clickBaitDescription: "The current model of online advertising is intrusive to your reading experience. It also incentives publications to flood us with clickbait, to grab our attention. We need to change this.",
    company: "Company",
    ourCompanyTitle: "Our company",
    ourCompanyDescription1: "<b>Blink</b> is a technology company founded in 2018 and headquartered in the heart of Silicon Valley, in Palo Alto, CA, with additional offices in London and Bucharest.",
    ourCompanyDescription2: "Our goal is to create new identity and payments solutions designed for the Internet, from the ground up. We are providing our current suite of tools as the first step towards building software to help readers and publishers alike.",
    findOutMore: "Find out more",
    companyHeaderTitle: "What drives us",
    companyHeaderDescription1: "The Internet has revolutionzed the way we see the world. Global supply and demand combined with access to information have reshaped the established conventions.",
    companyHeaderDescription2: "But the transition to digital hasn't been perfect. Fundamentally, the Internet provides the best way for transmitting data, but it lacks support for user identification and a native payment mechanism.",
    companyHeaderDescription3: "The Internet's initial promise of rich content accesibile for everyone is still far away. Content creators are struggling to monetize their work, and users find it difficult to navigate through low quality content. Blink's mission is to build a better Internet - to empower content creators and readers alike.",
    blinkWalletDescription1: "Create one account and access all partner publications.",
    blinkWalletDescription2: "You can access the Blink Wallet from the lower right corner of your screen.",
    identity: "Identity",
    pay: "Pay",
    funnel: "Funnel",
    requestDemo: "Request a demo",
    headerSubtext: "Schedule a meeting to see how Blink best suits your needs.",
    ourProducts: "Our products",
    blinkIdentity: "Blink Identity",
    blinkPay: "Blink Pay",
    blinkFunnel: "Blink Funnel",
    identityShortText: "Get to know your readers with our one-click login.",
    payShortText: "Remove payment friction and convert your audience to paying readers.",
    funnelShortText: "Optimize the conversion process and maximize your revenue.",
    howItWorks: "How it works",
    identityLongSummary: "<b>Blink Identity</b> helps to reduce anonymous traffic and get readers registered.",
    identityLongText: "Readers sign up with Blink only once — instead of registering with every publication they visit. They can then use their Blink account to read on other sites, being able to log in with a single click. For subsequent visits, they stay logged-in no matter their device or platform.",
    payLongSummary: "<b>Blink Pay</b> removes the friction from online payments.",
    payLongText: "Readers have only to enter their payment information the first time they make an online purchase. For subsequent purchases with any Blink partner, readers can subscribe, donate or purchase other type of content with just one click.",
    funnelLongSummary: "<b>Blink Funnel</b> helps you optimize the conversion process and maximize your revenue.",
    funnelLongText: "Our dashboard helps you identify user trends and opportunities for partnership with other publications. We also provide machine learning tools for you to discover new insights, as well as test and refine them with A/B testing.",
    readerRelationshipTitle: "Reader Relationship",
    readerRelationshipText: "We don't change your website experience, preserving your reader relationship",
    networkEffectsTitle: "Network Effects",
    networkEffectsText: "As more publishers join the network, everyone benefits from the increased user base",
    frictionlessUXTitle: "Frictionless UX",
    frictionlessUXText: "Removing login and payment friction increases conversion rates",
    easyIntegrationTitle: "Easy Integration",
    easyIntegrationText: "Integrating with Blink requires minimum development efforts on your side",
    modularityTitle: "Modularity",
    modularityText: "You can choose the Blink modules that best suit your needs",
    supportTitle: "Support",
    supportText: "We'll work with you so you can get the most from our software",
    valuePropositionTitle: "Why Blink?",
    readyToGetStartedTitle: "Ready to get started?",
    readyToGetStartedSubtitlePublisher: "Schedule a meeting to find out more.",
    readyToGetStartedSubtitleReader: "Create a Blink account now.",
    companyStats1: "Blink founded",
    companyStats2: "global offices",
    companyStats3: "employees",
    footerCopyright: "© %1 Blink Ledger Systems Inc. All rights reserved",
    navPublisher: "PUBLISHER",
    navReader: "READER",
    walletProductTitle1: "Start reading",
    walletProductDescription1: "Blink integrates directly with publishers, so you don't have to do a thing.",
    walletProductTitle2: "Works on any device",
    walletProductDescription2: "It doesn’t matter how you access your news—smartphone or laptop, search engine or messaging app.",
    walletProductTitle3: "Enjoy the experience",
    walletProductDescription3: "We have designed Blink to save you time, and protect your privacy.",
    walletTextImageSectionTitle1: "Universal Log in",
    walletTextImageSectionDescription1a: "Accessing the news is a hassle. The more you want to find out about the world, the more paywalls you hit — Blink fixes this.",
    walletTextImageSectionDescription1b: "A Blink account works across publishers. So you'll no longer have to ask yourself — do I have an account? Which email did I use? What's the password?",
    walletTextImageSectionTitle2: "Frictionless Payments",
    walletTextImageSectionDescription2a: "Paying on the Internet is more cumbersome than it should be.",
    walletTextImageSectionDescription2b: "We make payments as easy as accessing information. Subscribe, donate, or access a podcast or article with one click, or enable autopay for small, frequent purchases.",
    walletTextImageSectionDescription2c: "Manage all your subscriptions and your reading history in one place.",
    // ----- LANDING PAGE END -----
};

const englishTranslationMap = new Map();

for (const key of Object.keys(Messages)) {
    englishTranslationMap.set(key, Messages[key]);
}

export class MessageTextElement extends UI.TranslationTextElement {
    canOverwrite() {
        return false;
    }
}

for (const key of Object.keys(Messages)) {
    if (Messages[key].indexOf("%1") !== -1) {
        Messages[key] = (...args) => {
            args = args.map(value => String(value))
            return new MessageTextElement([key, ...args]);
        }
    } else {
        Object.defineProperty(Messages, key, {
            get: function() {
                return new MessageTextElement(key);
            },
        });
    }
}

export {Messages, englishTranslationMap};
