import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {StyleSheet} from "../../../../stem-core/src/ui/Style.js";
import {styleRule} from "../../../../stem-core/src/decorators/Style.js";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {CheckmarkIcon} from "../../SVGElements.jsx";


export class DropdownItemStyle extends StyleSheet {
    @styleRule
    container = {
        cursor: "pointer",
        transition: this.themeProps.DEFAULT_TRANSITION,
        ":hover": {
            background: this.themeProps.INPUT_BACKGROUND_HOVER,
        },
        display: "flex",
        flexDirection: "row",
        ">:first-child": {
            flex: 1,
        },
        ">:nth-child(2)": {
            width: 40,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        ">:nth-child(2)>*": {
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
        },
    };
}

@registerStyle(DropdownItemStyle)
export class DropdownItem extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            active: false,
            list: null,
            item: null,
        };
    }

    render() {
        return [
            <div>{super.render()}</div>,
            <div>
                <div>{this.options.active ? <CheckmarkIcon size={14} testId="dropdownItemMark" /> : null}</div>
            </div>,
        ];
    }

    setActive() {
        this.options.list.dispatch("changeActive", this.options.item);
    }

    onMount() {
        super.onMount();
        this.addClickListener(() => this.setActive());
    }
}
