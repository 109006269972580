import {styleRule, UI} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {
    MerchantStore,
    UserPreferencesStore,
    WalletStore
} from "../../../../State.js";
import {AutopayLimit} from "../components/AutopayLimit.jsx";
import {AutopaySetting} from "../components/AutopaySetting.jsx";
import {Toast} from "../../../../ui/Toast.jsx";
import {List, DividerPositions} from "../../../../ui/List.jsx";
import {IS_PRODUCTION} from "../../../../Constants.js";
import {BaseCard, BaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";
import {autoredraw} from "../../../../../stem-core/src/decorators/AutoRedraw.js";
import {apiUpdatePreferences} from "../../../../../client/state/UserPreferencesStore.js";
import {ToggleInput} from "../../../../../stem-core/src/ui/input/checkbox/ToggleInput.jsx";


class AutopayCardStyle extends BaseCardStyle {
    @styleRule
    list = {
        ">:first-child": {
            border: "none !important",
        }
    };
}

@registerStyle(AutopayCardStyle)
@autoredraw(UserPreferencesStore, WalletStore, WalletStore)
export class AutopayCard extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.autopay,
            description: Messages.autopayDefaultDescription,
            action: () => this.getActionButton(),
        };
    }

    getActionButton() {
        return <ToggleInput
            testId="autopayGlobalToggle"
            initialValue={this.autopayIsEnabled()}
            onChange={(value) => this.changeAutoPayEnabled(value)}
        />
    }

    render() {
        if (!this.autopayIsEnabled()) {
            return [];
        }

        let merchants = MerchantStore.getAutopayCompatibleMerchants();
        if (IS_PRODUCTION) {
            merchants = merchants.filter(merchant => !merchant.isFake);
        }

        return [
            <AutopayLimit testId="autopayLimit"/>,
            <List testId="autopayMerchantsList" dividerOptions={{position: DividerPositions.TOP}} className={this.styleSheet.list}>
                {merchants.map(merchant => <AutopaySetting merchant={merchant}/>)}
            </List>,
        ];
    }

    async changeAutoPayEnabled(autoPayEnabled) {
        try {
            await apiUpdatePreferences({autoPayEnabled});
            Toast.show(Messages.changesSaved); // TODO probably when we want to do this wrapper is should be a decorator @wrapInSpinner
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        }
    }

    autopayIsEnabled() {
        const preferences = UserPreferencesStore.getPreferences();
        return preferences?.autoPayEnabled;
    }
}
