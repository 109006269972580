import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {INLINE_USER_DASHBOARD_URLS} from "../PanelConstants.js";
import {BaseFlowHandler} from "./BaseFlowHandler.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {Flow} from "./Flow.js";
import {iFrameState} from "../../services/IFrameState.js";
import {toArray, unwrapArray} from "../../../stem-core/src/base/Utils.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";


export class DashboardFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.dashboard;
    card = null; // TODO what are these? Comment or remove.
    entries = null;

    getFlowPlan() {
        return [Flow.auth];
    }

    getDashboardTabTypes() {
        const {dashboardTabs} = iFrameState.iframeParams;

        if (dashboardTabs) {
            return toArray(dashboardTabs);
        }

        const merchant = iFrameMerchantService.getMerchant();

        return unwrapArray([
            merchant.allowSubscriptions && PANEL_TYPE.subscribe,
            merchant.allowDonations && PANEL_TYPE.donation,
            merchant.allowEmailing && PANEL_TYPE.newsletter,
            PANEL_TYPE.auth,
        ]);
    }

    finalize() {
        Router.changeURL(INLINE_USER_DASHBOARD_URLS.home);
    }
}
