import {registerStyle, styleRule, styleRuleInherit, UI} from "../../../../stem-core/src/ui/UI.js";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {getQueryParam} from "../../../UtilsLib.js";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {authService} from "../../../../client/connection/services/AuthService.js";
import {apiUndoEmailChange, UserProfileStore} from "../../../../client/state/UserProfileStore.js";
import {Modal, ModalStyle} from "../../../ui/Modal.jsx";
import {Messages} from "../../../Messages.js";
import {MessageElement} from "../../../widget/ui/MessageElement.jsx";
import {BlinkButton} from "../../../../core/ui/Button.jsx";


class EmailChangeUndoSuccessModalStyle extends ModalStyle {
    @styleRule
    container = {
        display: "flex",
        flexDirection: "column",
    };

    @styleRuleInherit
    title = {
        marginBottom: 12,
    };

    @styleRule
    confirmationButton = {
        marginTop: 36,
    };
}

// TODO pretty dumb as fuck to create a new class for this
@registerStyle(EmailChangeUndoSuccessModalStyle)
export class EmailChangeUndoSuccessModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.emailChangeUndoSuccessModalTitle,
            hasCloseIcon: false,
            hideOnScrimClick: false,
        };
    }

    render() {
        const {styleSheet} = this;
        const userProfile = UserProfileStore.getUserProfile();

        return <div className={styleSheet.content}>
            <MessageElement message={Messages.emailChangeUndoSuccessText(userProfile.getEmail())} />
            <BlinkButton
                label={Messages.ok}
                className={styleSheet.confirmationButton}
                onClick={() => this.hide()}
            />
        </div>
    }
}


// TODO fucking Cristi, how is this a Page??
export class UndoChangeEmailPage extends UI.Element {
    @wrapInSpinner
    async undoEmailChange(undoToken) {
        const response = await apiUndoEmailChange(undoToken);
        authService.setAuthToken(response);
        Router.changeURL("/account")
        EmailChangeUndoSuccessModal.show();
    }

    onMount() {
        super.onMount();

        const undoToken = getQueryParam("undoToken");
        if (!undoToken) {
            Router.changeURL("/");
            return;
        }

        this.undoEmailChange(undoToken);
    }
}
