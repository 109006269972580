import {UI} from "../../../../stem-core/src/ui/UI.js";
import {ActiveSubscriptions} from "./sections/ActiveSubscriptions.jsx";
import {SubscriptionPayments} from "./sections/SubscriptionPayments.jsx";
import {PastSubscriptions} from "./sections/PastSubscriptions.jsx";
import {SubscriptionStore} from "../../../State.js";
import {Dispatcher} from "../../../../stem-core/src/base/Dispatcher.js";
import {getQueryParam} from "../../../UtilsLib.js";
import {userService} from "../../../../client/connection/services/UserService.js";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";


@autoredraw(SubscriptionStore)
export class SubscriptionsPage extends UI.Element {
    render() {
        return [
            <ActiveSubscriptions testId="activeSubscriptionsCard"/>,
            (SubscriptionStore.getPast().length > 0) && <PastSubscriptions testId="pastSubscriptionsCard"/>,
            <SubscriptionPayments testId="subscriptionPaymentsCard"/>,
        ];
    }

    onMount() {
        // TODO @cleanup what is this crap?
        const dispatchEntryFocus = () => {
            userService.ensureUserDataRequested(() => {
                const subscriptionFocusId = getQueryParam("focus");
                if (subscriptionFocusId) {
                    const subscription = SubscriptionStore.get(subscriptionFocusId);
                    Dispatcher.Global.dispatch("highlightEntry", subscription);
                }
            })
        };
        this.attachTimeout(dispatchEntryFocus);
        this.addListener("urlEnter", dispatchEntryFocus);
    }
}
