import {UI} from "../../../../../stem-core/src/ui/UI.js";

import {SubscriptionStore, SubscriptionOfferStore} from "../../../../State.js";
import {Messages} from "../../../../Messages.js";
import {BaseRecurringPaymentList} from "../../../components/BaseRecurringPaymentList.jsx";
import {autoredraw} from "../../../../../stem-core/src/decorators/AutoRedraw.js";


export class PastSubscriptions extends BaseRecurringPaymentList {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.pastSubscriptions,
        };
    }

    getEntries() {
        return SubscriptionStore.getPast();
    }

    getExpandedRowContent(entry) {
        const contentRows = [
            this.makeRowEntry(
                Messages.planDetails,
                entry.coverage.name
            )
        ];

        const shippingAddress = entry.getShippingAddress();
        if (shippingAddress) {
            contentRows.push(this.makeRowEntry(
                Messages.shippingAddress,
                shippingAddress
            ));
        }

        contentRows.push(this.makeRowEntry(
            Messages.lastPayment,
            this.renderLastPayment(entry)
        ));

        return <div style={{paddingLeft: 30}}>{contentRows}</div>;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            value: entry => `${entry.getMerchantTitle()} - ${entry.coverage}`,
            headerStyle: {
                textAlign: "left",
                width: "50%"
            },
            cellStyle: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }
        }, {
            headerName: Messages.amount,
            value: entry => entry.formatPriceShortened(),
            headerStyle: {
                textAlign: "right",
                width: "20%",
            },
            cellStyle: {
                textAlign: "right"
            }
        }, {
            headerName: Messages.lastPayment,
            value: entry => this.renderLastPayment(entry),
            headerStyle: {
                textAlign: "right",
                width: "30%",
            },
            cellStyle: {
                textAlign: "right",
                color: this.themeProps.MERCHANT_2,
            }
        }];
    }
}
