import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {INLINE_SHOP_URLS} from "../../PanelConstants.js";
import {ProductStore} from "../../../../client/state/ProductStore.js";
import {filterStoreByMerchantAndLooseIdArray} from "../../../../client/state/misc/Filter.js";

export class ProductListFlowStep extends BaseFlowStep {
    getProducts() {
        const {products} = this.iframeParams;
        const productObjects = ProductStore.all();
        if (!products) {
            return productObjects;
        }
        return filterStoreByMerchantAndLooseIdArray(productObjects, products, (obj, idOrAlias) => obj.id == idOrAlias || obj.alias == idOrAlias);
    }

    showPanel() {
        Router.changeURL(INLINE_SHOP_URLS.products);
    }
}
