import {
    apiCalculateSubscriptionEditOffersPrice,
    apiCalculateSubscriptionOffersPrice,
    SubscriptionOfferStore
} from "../../../../client/state/SubscriptionOfferStore.js";
import {iFrameMerchantService} from "../../../services/IframeMerchantService.js";
import {iFrameUserDataService} from "../../../services/IFrameUserDataService.js";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {SUBSCRIPTION_PLAN_PANEL_URL} from "../../PanelConstants.js";
import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Flow} from "../Flow.js";
import {SubscriptionApplyPolicy} from "../../../../client/state/SubscriptionStore.js";
import {CoverageChangeType} from "../../../../client/state/SubscriptionCoverageStore.js";


export const SubscriptionFlowType = {
    SUBSCRIBE: "subscribe",
    EDIT_SUBSCRIPTION: "editSubscription",
    GIFT_SUBSCRIPTION: "giftSubscription",
};


// TODO actually consider Typescript?
export class SubscriptionPlanFlowStep extends BaseFlowStep {
    subscriptionFlowType = SubscriptionFlowType.SUBSCRIBE; // TODO @flow2 this is not part of this class

    discountCode = null;
    applyPolicy = SubscriptionApplyPolicy.INSTANTLY;

    shareName = true; // TODO @flow2 handle as generic metadata

    // Internally used fields
    discountCodeMap = new Map();
    instantChangeDiscountCodeMap = new Map();

    get selectedOffer() {
        return this.selectedValue;
    }

    userHasEquivalentSubscriptionToOffer(subscriptionOffer) {
        const subscription = iFrameUserDataService.getActiveSubscription();
        if (!subscription) {
            return false;
        }
        if (!subscriptionOffer) {
            return true;
        }
        return subscription.billingPlan === subscriptionOffer.billingPlan && subscription.coverage === subscriptionOffer.coverage;
    }

    userHasEquivalentSubscriptionToSelected() {
        return this.selectedValue == null || this.userHasEquivalentSubscriptionToOffer(this.selectedValue);
    }

    async fetchSubscriptionEditPrices(address) {
        try {
            const {recurringOffers} = await apiCalculateSubscriptionEditOffersPrice({
                discountCode: this.discountCode,
                deliveryAddressId: address?.id,
                subscriptionId: iFrameUserDataService.getActiveSubscription().id,
            });
            this.instantChangeDiscountCodeMap.clear();
            this.discountCodeMap.clear();
            for (const info of recurringOffers) {
                const subscriptionOffer = info.recurringOfferId == null ? null : SubscriptionOfferStore.get(info.recurringOfferId);
                if (info.applyPolicy === SubscriptionApplyPolicy.INSTANTLY) {
                    this.instantChangeDiscountCodeMap.set(subscriptionOffer, info);
                } else {
                    this.discountCodeMap.set(subscriptionOffer, info);
                }
            }
        } catch (error) {
            this.update({discountCode:  null});
        } finally {
            this.dispatch();
        }
    }

    // TODO @cleanup debounce this
    async checkDiscountCode(address) {
        this.discountCodeMap.clear();
        try {
            const {recurringOffers} = await apiCalculateSubscriptionOffersPrice({
                discountCode: this.discountCode,
                merchantId: iFrameMerchantService.merchantId,
                deliveryAddressId: address?.id
            });
            for (const info of recurringOffers) {
                this.discountCodeMap.set(SubscriptionOfferStore.get(info.recurringOfferId), info);
            }
        } catch (error) {
            // TODO: Handle API error.
        } finally {
            this.dispatch();
        }
    }

    // Get price maybe with discount
    getSubscriptionOfferPrice(offer) {
        const discountInfo = this.getDiscountInfo(offer);
        if (discountInfo) {
            const {discountedBeforeTaxesPrice, discountValue} = discountInfo;
            return discountValue ? offer.formatPrice(discountedBeforeTaxesPrice) : offer.getPrice();
        }

         return offer.getPrice();
    }

    // TODO @cleanup This is also use for subscription edit prices
    getDiscountInfo(offer) {
        return this.discountCodeMap.get(offer);
    }

    getDiscountInfoOnInstantChangePolicy(offer) {
        return this.instantChangeDiscountCodeMap.get(offer);
    }

    // This calculated the new price change for both options (instant and on the next cycle)
    // It also can change the currently selected apply policy, should it not be possible
    ensureAndGetApplyPolicyOptions(activeSubscription) {
        const offer = this.selectedOffer;
        const applyPolicyPaymentInfo = {
            [SubscriptionApplyPolicy.INSTANTLY]: null,
            [SubscriptionApplyPolicy.NEXT_BILLING_CYCLE]: null,

        }
        if (activeSubscription.recurringOfferId !== offer.id) {
            applyPolicyPaymentInfo[SubscriptionApplyPolicy.NEXT_BILLING_CYCLE] = this.getDiscountInfo(offer);
        }
        const applyInstancePaymentInfo = this.getDiscountInfoOnInstantChangePolicy(offer);
        if (applyInstancePaymentInfo) {
            const coverageChangeType = CoverageChangeType.fromValue(applyInstancePaymentInfo.coverageChangeType);
            if (coverageChangeType?.canAllowInstantConversion()) {
                applyPolicyPaymentInfo[SubscriptionApplyPolicy.INSTANTLY] = applyInstancePaymentInfo
            }
        }

        // Now we'll also update the selected value if it's not possible
        if (!applyPolicyPaymentInfo[this.applyPolicy]) {
            this.applyPolicy = (this.applyPolicy === SubscriptionApplyPolicy.INSTANTLY) ? SubscriptionApplyPolicy.NEXT_BILLING_CYCLE : SubscriptionApplyPolicy.INSTANTLY;
        }

        return applyPolicyPaymentInfo;
    }

    @wrapInSpinner
    async fetchPrices(address = Flow.address.selectedValue) {
        // TODO @flow2 why are these two methods so different?
        if (this.subscriptionFlowType === SubscriptionFlowType.EDIT_SUBSCRIPTION) {
            await this.fetchSubscriptionEditPrices(address);
        } else {
            await this.checkDiscountCode(address);
        }
    }

    showPanel() {
        Router.changeURL(SUBSCRIPTION_PLAN_PANEL_URL);
    }
}
