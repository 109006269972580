import {StoreObject, Store} from "../../stem-core/src/state/Store";
import {apiClient} from "../connection/BlinkApiClient.js";
import {CurrencyStore} from "../../stem-core/src/localization/CurrencyStore.js";

export class UserPreferences extends StoreObject {
    getDefaultCurrency() {
        return CurrencyStore.get(this.defaultCurrencyId);
    }

    shouldShowBalanceInline() {
        return this.alwaysShowBalance;
    }

    shouldShowPaymentAnimation() {
        return this.showPaymentAnimation;
    }
}

class UserPreferencesStoreClass extends Store("UserPreferences", UserPreferences) {
    getPreferences() {
        return this.all()[0];
    }
}

export const UserPreferencesStore = new UserPreferencesStoreClass();

export async function apiUpdatePreferences(preferences = {}) {
    const response = await apiClient.post("/preferences/update_user_preferences", preferences);
    return UserPreferencesStore.loadObject(response);
}
