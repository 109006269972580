import {UI} from "../stem-core/src/ui/UIBase.js";
import {AuthHelper} from "./AuthHelper";
import {authService} from "../client/connection/services/AuthService.js";
import {DonationOfferStore, PaymentMethodStore} from "./State";
import {apiClient} from "../client/connection/BlinkApiClient.js";
import {apiCancelDonation} from "../client/state/DonationStore";
import {apiCancelSubscription, apiSubscribe} from "../client/state/SubscriptionStore";
import {PaymentProcessorStore} from "../client/state/PaymentProcessorStore.js";
import {IS_PRODUCTION, TEST_MODE} from "../blink-sdk/Constants.js";
import {Flow} from "./panel/flows/Flow.js";


if (!IS_PRODUCTION || TEST_MODE) {
    self.STEM_DEBUG = true;
    // For non-production builds we insert the testId attribute directly into the DOM
    UI.Element.domAttributesMap.setAttribute("testId", {domName: "test-id"});
}


// TODO This needs to be only in debug/test builds
class AutomationHelperClass {
    async authenticate(userDetails = {email: "", firstName: "", lastName: ""}) {
        const automationEmailCode = "434343";
        const payload = {
            ...userDetails,
            withSignup: true,
            secure: false,
        };
        const response = await AuthHelper.requestLoginCode(payload);
        if (response.token) {
            return response;
        }
        return AuthHelper.loginWithEmailCode(automationEmailCode);
    }

    async addCard(cardDetails) {
        const request = {
            paymentProcessorId: PaymentProcessorStore.findBy({isPublic: true}).id,
            ...cardDetails,
        }
        return apiClient.post("/payment_methods/dev_add_payment_method", request);
    }

    async donate(payload) {
        const donationOffer = DonationOfferStore.all().find(offer => offer.id === payload.recurringOfferId);
        if (payload.preferredPaymentMethodId != null) {
            Flow.paymentMethod.update({
                selectedValue: PaymentMethodStore.get(payload.preferredPaymentMethodId),
            });
        }
        if (!donationOffer) {
            throw new Error(`Donation offer with id ${payload.recurringOfferId} not found.`);
        }
        return Flow.donation.callDonateEndpoint(payload);
    }

    async cancelDonation(donationId) {
        return apiCancelDonation({id: donationId});
    }

    async subscribe(subscriptionDetails) {
        return apiSubscribe(subscriptionDetails);
    }

    async cancelSubscription(subscriptionId) {
        return apiCancelSubscription(subscriptionId);
    }

    async addAddress(addressDetails) {
        return apiClient.post("/user_addresses/create", addressDetails);
    }

    async logout() {
        return authService.logout();
    }
}

export const AutomationHelper = new AutomationHelperClass();
window.AutomationHelper = AutomationHelper;
