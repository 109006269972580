import {UI, styleRule, registerStyle, StyleSheet, styleRuleInherit} from "../../../stem-core/src/ui/UI.js";
import {BaseCard, BaseCardStyle} from "../../ui/BaseCard.jsx";
import {MISSING_INFO, MOBILE_NAV_HEIGHT} from "../../Constants.js";
import {Messages} from "../../Messages.js";
import {
    DonationOfferStore,
    DonationStore,
    PaymentMethodStore,
    SubscriptionOfferStore,
    SubscriptionStore,
    UserAddressStore
} from "../../State.js";
import {Dispatcher} from "../../../stem-core/src/base/Dispatcher.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Toast} from "../../ui/Toast.jsx";
import {CollapsibleTable} from "../../../stem-core/src/ui/table/CollapsibleTable.jsx";
import {TableStyle} from "../../../stem-core/src/ui/table/Style.js";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {Table} from "../../../stem-core/src/ui/table/Table.jsx";


class RowExpansionPanelStyle extends StyleSheet {
    @styleRule
    label = {
        fontSize: 11,
        color: this.themeProps.MERCHANT_2,
        paddingBottom: 8,
    };

    @styleRule
    content = {
        fontSize: 14,
        color: this.themeProps.MERCHANT_4,
        lineHeight: 18,
    };

    @styleRule
    panelContainer = {
        paddingTop: 6,
        paddingLeft: 20,
    };

    @styleRule
    contentRow = {
        marginBottom: 18,
    };
}

export class RealTableStyle extends TableStyle {
    cellStyle = {
        padding: "8px",
        lineHeight: "1.42857143",
        borderTop: 0,
    };

    @styleRuleInherit
    container = {
        tableLayout: "fixed",
        ">tbody": {
            fontSize: 14
        }
    };

    @styleRuleInherit
    thead = {
        color: this.themeProps.TABLE_HEADER_TEXT_COLOR,
        background: this.themeProps.TABLE_HEADER_BACKGROUND_COLOR,
        fontSize: 12,
        textTransform: "uppercase",
        borderBottom: null,
    }

    @styleRule
    tableRow = {
        padding: "0 8px",
        ":not(:last-child)": {
            borderBottom: `1px solid ${this.themeProps.WALLET_2}`,
        }
    }
}


export class BaseRecurringPaymentStyle extends BaseCardStyle {
    @styleRule
    button = {
        fontSize: 14,
        marginTop: 24,
        marginBottom: 24,
        cursor: "pointer",
        display: "table",
    };

    @styleRule
    buttonCancel = {
        color: this.themeProps.ERROR_COLOR,
    };

    @styleRule
    buttonRenew = {
        color: this.themeProps.LINK_COLOR,
    };

    @styleRule
    rowHighlight = {
        background: this.themeProps.WALLET_2,
    };

    @styleRule
    rowRemoveHighlight = {
        transition: this.themeProps.DEFAULT_TRANSITION,
        background: "transparent !important",
        userSelect: "none",
    };

    @styleRule
    failedPayment = {
        display: "flex",
        color: this.themeProps.ERROR_COLOR,
    };

    @styleRule
    exclamationIcon = {
        marginRight: 8,
    };

    @styleRule
    retryPaymentButton = {
        margin: "16px auto 0",
        width: 214,
    };

    @styleRule
    center = {
        display: "flex",
        alignItems: "center",
    };
}

@registerStyle(BaseRecurringPaymentStyle)
@autoredraw(
    DonationStore,
    DonationOfferStore,
    UserAddressStore,
    PaymentMethodStore,
    SubscriptionStore,
    SubscriptionOfferStore
)
export class BaseRecurringPaymentList extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            emptyMessage: "",
            expandedRows: false,
        };
    }

    makeRowEntry(label, value) {
        const rowStyleSheet = RowExpansionPanelStyle.getInstance();
        return <div className={rowStyleSheet.contentRow}>
            {label && <div className={rowStyleSheet.label}>{label}</div>}
            {value && <div className={rowStyleSheet.content}>{value}</div>}
        </div>
    }

    @wrapInSpinner
    async retryPayment(entry) {
        await entry.reactivate();
        Toast.show(Messages.entrySuccessfullyPaid);
    }

    renderLastPayment(entry) {
        return entry.getLastPaymentDate() || MISSING_INFO;
    }

    renderNextPayment(entry) {
        return entry.getNextBillingDate() || Messages.canceled;
    }

    getEntries() {
        return [];
    }

    render() {
        if (!this.getEntries().length) {
            return [];
        }

        const tableOptions = {
            ref: "table",
            styleSheet: RealTableStyle,
            columns: this.getColumns(),
            entries: this.getEntries(),
            renderCollapsible: this.getExpandedRowContent && ((entry) => this.getExpandedRowContent(entry)),
        }

        const TableClass = tableOptions.renderCollapsible ? CollapsibleTable : Table;

        return <TableClass {...tableOptions} />;
    }

    highlightEntry(entry) {
        const tableRow = this.table?.getRowByEntry(entry);
        if (!tableRow) {
            return;
        }

        const {styleSheet} = this;

        const {top} = tableRow.node.getBoundingClientRect();
        window.scrollTo({
            top: window.pageYOffset + (top - MOBILE_NAV_HEIGHT - 16),
            behavior: "smooth"
        });

        if (tableRow.expand) {
            // Only if the table is collapsible-like
            tableRow.expand();
        }

        tableRow.addClass(styleSheet.rowHighlight);
        setTimeout(() => tableRow.addClass(styleSheet.rowRemoveHighlight));
        setTimeout(() => {
            tableRow.removeClass(styleSheet.rowHighlight);
            tableRow.removeClass(styleSheet.rowRemoveHighlight);
        }, this.themeProps.DEFAULT_TRANSITION_DURATION_MS);
    }

    onMount() {
        super.onMount();

        this.attachListener(Dispatcher.Global, "highlightEntry", entry => this.highlightEntry(entry));
    }
}
