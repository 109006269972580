import {MakeStore} from "../../stem-core/src/state/Store.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {apiClient} from "../connection/BlinkApiClient.js";
import {TopUpStatus} from "./TopUpPaymentStore.js";
import {MoneyObject} from "../../stem-core/src/localization/Money.js"; // Import for dependency
import {MerchantConversionStore} from "./merchant/MerchantConversionStore.js";  // For order

class Purchase extends MoneyObject {
    @field(Date) createdAt;
    @field(Date) finalizedAt;
    @field(TopUpStatus) status;
    @field("Merchant") merchant;
    @field("User") user;
    @field("MerchantUser") merchantUser;
    @field("MerchantConversion") conversion;
    @field("Payment") payment;

    getTitle() {
        return this.title || `Order ${this.id}`;
    }

    isRefundable() {
        return this.payment?.isAutomatic;
    }
}

export const PurchaseStore = MakeStore("Purchase", Purchase, {dependencies: ["MerchantConversion"]});

export async function apiUserPurchaseProduct(request) {
    const response = await apiClient.post("/payments/purchase_product", request);
    return PurchaseStore.loadObject(response);
}

export async function apiUserRequestPurchaseRefund(request) {
    return apiClient.post("/payments/request_refund", request);
}
