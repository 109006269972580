import {UI} from "../../../stem-core/src/ui/UIBase";

import {IdentitySVG} from "../../SVGElements";
import {ProductPage} from "../primitives/ProductPage";
import {Messages} from "../../../blinkpay/Messages";
import {MEDIA_FOLDER} from "../../Constants";
import {Device} from "../../../stem-core/src/base/Device";
import {getLandingPageMediaUrls} from "./PayPage";

export class IdentityPage extends ProductPage {
    getDefaultOptions() {
        const mediaBaseUrl = `${MEDIA_FOLDER}identity${Device.isMobileDevice() ? "-mobile" : ""}`;

        return {
            ...super.getDefaultOptions(),
            icon: <IdentitySVG size="100%"/>,
            summary: Messages.identityLongSummary,
            description: Messages.identityLongText,
            mediaUrl: getLandingPageMediaUrls(mediaBaseUrl),
            isVideo: true,
        };
    }
}
