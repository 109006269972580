import {styleRule, UI} from "../../../../../stem-core/src/ui/UI.js";
import {BaseCard, BaseCardStyle, UIPaginationManager} from "../../../../ui/BaseCard.jsx";
import {Messages} from "../../../../Messages.js";
import {TopUpPaymentStore, DonationStore, SubscriptionStore, PaymentStore} from "../../../../State.js";
import {DONATIONS_URL, SUBSCRIPTIONS_URL} from "../../../../Constants.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";
import {PaymentDescription} from "../../../../common/PaymentDescription.jsx";
import {Link} from "../../../../../stem-core/src/ui/UIPrimitives.jsx";
import {EndpointPaginator} from "../../../../../client/state/EndpointPaginator.js";
import {TopUpStatus} from "../../../../../client/state/TopUpPaymentStore.js";
import {Table} from "../../../../../stem-core/src/ui/table/Table.jsx";
import {RealTableStyle} from "../../../components/BaseRecurringPaymentList.jsx";


export class TopUpHistoryStyle extends BaseCardStyle {
    @styleRule
    paymentTitle = {
        marginBottom: 4,
        fontSize: 14,
        fontWeight: "initial",
    };
}

// List of all the credit card charges of this user
@registerStyle(TopUpHistoryStyle)
export class TopUpHistory extends BaseCard {
    paginator = new EndpointPaginator(
        TopUpPaymentStore,
        "/top_up_payments/list",
        {source: TopUpPaymentStore.Source.PAYMENT_METHOD}, // TODO: nicer enum lookup
        {status: [TopUpStatus.REVERTED, TopUpStatus.SUCCEEDED]}
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.paymentHistory,
            description: Messages.paymentHistoryDescription,
        };
    }

    getTransactions() {
        return this.paginator.all().sort((a, b) => b.createdAt - a.createdAt);
    }

    getTransactionDescription(transaction) {
        const defaultDescription = "";
        if (!transaction) {
            return {title: defaultDescription};
        }

        if (transaction.status === TopUpStatus.REVERTED) {
            return {title: Messages.chargeback};
        }

        if (transaction.resourceType == null) {
            // Top up payment.
            return {
                title: transaction.isAutomatic ? Messages.walletTopUpAutomatic : Messages.walletTopUp
            };
        }

        if (transaction.resourceType === PaymentStore.ResourceType.SUBSCRIPTION) {
            const subscription = SubscriptionStore.get(transaction.resourceId);
            return {
                title: Messages.subscriptionPaymentDescription(subscription && subscription.getMerchantTitle()),
                url: `/${SUBSCRIPTIONS_URL}?focus="${transaction.resourceId}"`
            }
        }
        if (transaction.resourceType === PaymentStore.ResourceType.DONATION) {
            const donation = DonationStore.get(transaction.resourceId);
            if (!donation) {
                return {title: defaultDescription};
            }

            if (donation.isRecurring()) {
                return {
                    title: Messages.recurringDonationPaymentDescription(donation && donation.getMerchantTitle()),
                    url: `/${DONATIONS_URL}?focus="${transaction.resourceId}"`
                }
            }

            return {
                title: Messages.oneTimeDonationPaymentDescription(donation && donation.getMerchantTitle()),
                url: DONATIONS_URL,
            }
        }

        return {title: defaultDescription};
    }

    getDescriptionColumnValue(payment) {
        const {styleSheet} = this;
        const {title, url} = this.getTransactionDescription(payment);

        return <PaymentDescription payment={payment}>
            {url ? <Link className={styleSheet.paymentTitle} href={url}>
                        {title}
                    </Link>
                : <div className={styleSheet.paymentTitle}>{title}</div>
            }
        </PaymentDescription>
    }

    render() {
        const transactions = this.getTransactions();

        if (!transactions.length) {
            return [];
        }

        return <Table
            styleSheet={RealTableStyle}
            entries={transactions}
            columns={[
                    {
                        headerName: Messages.description,
                        value: transaction => this.getDescriptionColumnValue(transaction),
                        headerStyle: {
                            textAlign: "left",
                            width: "60%",
                        },
                    },
                    {
                        headerName: Messages.amount,
                        value: transaction => `${transaction.status === TopUpStatus.REVERTED ? "-" : ""}${transaction.getAmount()}`,
                        headerStyle: {
                            textAlign: "right",
                            width: "15%",
                        },
                        cellStyle: {
                            textAlign: "right",
                        }
                    },
                    {
                        headerName: Messages.date,
                        value: transaction => transaction.createdAt,
                        headerStyle: {
                            textAlign: "right",
                            width: "25%",
                        },
                        cellStyle: {
                            textAlign: "right",
                            color: this.themeProps.MERCHANT_2,
                        }
                    },
                ]}
        />;
    }

    onMount() {
        super.onMount();
        this.paginationHandler.attach();
    }
}
