import {Dispatchable} from "stem-core/src/base/Dispatcher.js";
import {AuthHelper} from "../../blinkpay/AuthHelper.js";
import {isIframe} from "../../blinkpay/Utils.js";
import {iFrameMerchantService} from "../../blinkpay/services/IframeMerchantService.js";
import {authService} from "../../client/connection/services/AuthService.js";


export class OAuth2Client extends Dispatchable {
    constructor(clientConfig) {
        super();
        Object.assign(this, clientConfig);
        this.redirectUri = location.origin + this.redirectUri;
    }

    setLoginCallback(onLoginCallback) {
        this.onLoginCallback = onLoginCallback;
    }

    login() {
        const urlParams = new URLSearchParams();
        urlParams.set("client_id", this.clientId);
        urlParams.set("redirect_uri", this.redirectUri);
        // If we're in an iframe, we send the referral merchant's ID in the OAuth state.
        if (isIframe()) {
            urlParams.set("state", JSON.stringify({merchantId: iFrameMerchantService.merchantId}));
        }

        if (this.responseType) {
            urlParams.set("response_type", this.responseType);
        }
        if (this.scope) {
            urlParams.set("scope", this.scope);
        }

        const url = this.authServerUrl + "?" + urlParams.toString();

        AuthHelper.oauth2Login(url);
    }

    async handleLoginResponse(urlParams) {
        const authCode = urlParams.get("code");

        const response = await authService.loginAtEndpoint(this.loginEndpoint, {
            authCode,
            termsAgreed: true,
        });

        this.onLoginCallback(response);
    }
}
