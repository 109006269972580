import {UI} from "../../../stem-core/src/ui/UIBase";

import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Link} from "../../../stem-core/src/ui/UIPrimitives";
import {ExternalLinkIcon} from "../../../core/ui/SVGElements.jsx";


class ExternalLinkStyle extends StyleSheet {
    @styleRule
    container = {
        fontWeight: "initial",
    };

    @styleRule
    icon = {
        marginLeft: 5,
        display: "inline-flex",
    };
}

@registerStyle(ExternalLinkStyle)
export class ExternalLink extends Link {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            newTab: true,
            noIcon: false,
            label: "",
        };
    }

    getChildrenToRender() {
        return [
            this.render(),
            !this.options.noIcon && <ExternalLinkIcon className={this.styleSheet.icon}/>,
        ];
    }
}
