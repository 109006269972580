import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";
import {Modal, ModalStyle} from "../../../../../ui/Modal.jsx";
import {Messages} from "../../../../../Messages.js";
import {wrapInSpinner} from "../../../../../../core/ui/LoadingSpinner.jsx";
import {DefaultCodeConfirmationForm} from "../../../../../common/auth/DefaultCodeConfirmationForm.jsx";
import {NOOP_FUNCTION} from "../../../../../../stem-core/src/base/Utils.js";
import {apiConfirmCloseAccount} from "../../../../../../client/state/UserProfileStore.js";
import {MessageElement} from "../../../../../widget/ui/MessageElement.jsx";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme.js";
import {styleRule} from "../../../../../../stem-core/src/decorators/Style.js";


export class CloseAccountConfirmationModalStyle extends ModalStyle {
    @styleRule
    cancel = {
        cursor: "pointer",
        padding: 6,
        width: "fit-content",
        color: this.themeProps.MERCHANT_2,
        margin: "6px auto 0",
    };
}

@registerStyle(CloseAccountConfirmationModalStyle)
export class CloseAccountConfirmationModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.closeAccountModalTitle,
            userProfile: null,
            closeTestId: "closeAccountConfirmationModal",
            hideOnScrimClick: false,
            onConfirmSuccess: NOOP_FUNCTION,
        };
    }

    render() {
        const {styleSheet} = this;
        const {userProfile} = this.options;

        return [
            <MessageElement className={styleSheet.textLine} message={Messages.closeAccountConfirmationLine1(userProfile.getEmail())}/>,
            <DefaultCodeConfirmationForm onSubmit={(code, callback, errorCallback) =>
                this.handleCloseConfirmationCodeSubmit(code, callback, errorCallback)}
            />,
            <div className={styleSheet.cancel} onClick={() => this.hide()}>{Messages.cancel}</div>
        ]
    }

    @wrapInSpinner
    async handleCloseConfirmationCodeSubmit(code, successCallback = NOOP_FUNCTION, errorCallback = NOOP_FUNCTION) {
        const {onConfirmSuccess} = this.options;

        try {
            const response = await apiConfirmCloseAccount({code});
            successCallback(response);
            onConfirmSuccess();
            this.hide();
        } catch (error) {
            errorCallback(error);
            throw error;
        }
    }
}
