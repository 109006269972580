import {UI} from "../../../../../stem-core/src/ui/UIBase.js";
import {styleRuleInherit} from "../../../../../stem-core/src/decorators/Style.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";

import {BaseCard, BaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {AddFundsButton} from "../components/AddFundsButton.jsx";
import {isSmallScreen} from "../../../../Utils.js";
import {PaymentMethodStore, WalletStore} from "../../../../State.js";
import {DefaultPaymentMethodElement} from "../components/cards/DefaultPaymentMethodElement.jsx";
import {AutoReloadToggle} from "../../../../common/AutoReloadToggle.jsx";
import {RollAnimatedBalance} from "../../../../common/RollAnimatedBalance.jsx";
import {Money} from "../../../../../stem-core/src/localization/Money.js";
import {TOP_UP_VALUES} from "../../../../Constants.js";
import {styleRule} from "../../../../../stem-core/src/decorators/Style.js";
import {RadioButtonsInput} from "../../../../../core/ui/input/radio/RadioButtonsInput.jsx";
import {AddCardModal} from "../components/cards/AddCardModal.jsx";
import {autoredraw} from "../../../../../stem-core/src/decorators/AutoRedraw.js";


class AddFundsClassStyle extends BaseCardStyle {
    @styleRuleInherit
    container = {
        padding: () => isSmallScreen() ? "35px 18px 18px" : "60px 84px 66px",
        ">*": {
            maxWidth: 312,
            alignSelf: "center",
        }
    };

    @styleRule
    balance = {
        transform: "initial",
        fontWeight: "lighter",
        display: "block",
        color: this.themeProps.WALLET_11,
        fontSize: 60,
        marginBottom: 24,
        height: "1em",
    };

    @styleRule
    line = {
        margin: "24px 0",
    };

    @styleRule
    horizontalPadding = {
        padding: () => isSmallScreen() ? "" : "0 24px",
    };

    @styleRule
    button = {
        width: "100%",
    };
}

@autoredraw(PaymentMethodStore, WalletStore)
@registerStyle(AddFundsClassStyle)
export class AddFundsCard extends BaseCard {
    getCurrency() {
        return WalletStore.getWallet().getCurrency();
    }

    render() {
        console.assert(this.getCurrency());
        const {styleSheet} = this;

        const reloadOptions = TOP_UP_VALUES.map(value => this.mainUnitsToOption(value));
        this.activeValue = this.activeValue || reloadOptions[1];

        const onRadioSelect = value => {
            this.activeValue = value;
            this.redraw();
        };

        return [
            <RollAnimatedBalance ref="balance" className={styleSheet.balance}/>,
            <div className={styleSheet.horizontalPadding}>
                <RadioButtonsInput
                    ref="radioButtons"
                    testId="addFundsAmountButtons"
                    initialValue={this.activeValue}
                    values={reloadOptions}
                    onChange={onRadioSelect}
                    style={{
                        display: "flex",
                        width: "100%",
                        margin: 0
                    }}
                />
                <DefaultPaymentMethodElement className={styleSheet.line}/>
                <AutoReloadToggle className={styleSheet.line}/>
            </div>,
            <AddFundsButton
                testId="addFundsButton"
                amount={this.getAmountByOption(this.activeValue)}
                className={styleSheet.button}
                onNoPaymentMethod={() => AddCardModal.show()}
            />
        ];
    }

    mainUnitsToOption(amount) {
        return (new Money(this.getCurrency().mainUnitsToAmount(amount), this.getCurrency())).toMainUnitString({decimalsDisplayed: 0});
    }

    getAmountByOption(option) {
        return this.getCurrency().mainUnitsToAmount(
            TOP_UP_VALUES.find(value => this.mainUnitsToOption(value) === option));
    }
}
