import {UI} from "../../../stem-core/src/ui/UIBase";

import {styleRule} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Image} from "../../../stem-core/src/ui/primitives/Image.jsx";
import {RawHTML} from "../../../stem-core/src/ui/RawHTML.jsx";
import {Section, SectionStyle} from "../../primitives/Section";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {isScrolledInViewport} from "../../../blinkpay/Utils";
import {isDesktopSmall} from "../../Utils";
import {Device} from "../../../stem-core/src/base/Device";
import {IpadFrame, IphoneFrame} from "../../SVGElements";


export class ProductPageStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: () => (Device.isMobileDevice() ? "18px 0" : "48px 0"),
    };

    @styleRuleInherit
    content = {
        padding: isDesktopSmall() ? "0" : "0 24px",
    };

    hidden = {
        opacity: 0,
        transition: ".3s ease",
        transform: "translateY(40px)",
        ">*": {
            opacity: 0,
            transition: ".3s ease",
            transform: "translateY(20px)",
        },
    };

    bottomSectionTransition = {
        transitionDelay: ".2s",
        transitionDuration: ".7s",
    };

    @styleRule
    page = {
        background: this.themeProps.LANDING_PAGE_CARD_BACKGROUND,
        color: this.themeProps.LANDING_PAGE_DARK_FONT_COLOR,
        boxShadow: this.themeProps.LANDING_PAGE_CARD_SHADOW,
        width: "100%",
        padding: () => (Device.isMobileDevice() ? "24px 24px 20px" : "72px 90px"),
        textAlign: () => (Device.isMobileDevice() ? "left" : "center"),
        ...this.hidden,
    };

    @styleRule
    visible = {
        opacity: 1,
        transform: "translateY(0)",
        ">*": {
            opacity: 1,
            transform: "translateY(0)",
        },
    };

    text = {
        padding: "0 62px",
        fontSize: () => (Device.isMobileDevice() ? 16 : 21),
        fontWeight: "lighter",
        lineHeight: () => (Device.isMobileDevice() ? 24 : 33),
    };

    @styleRule
    summary = {
        ...this.text,
        marginBottom: () => (Device.isMobileDevice() ? "" : 20),
        " b": {
            fontWeight: "normal",
        },
        transitionDelay: 0,
        flex: () => (Device.isMobileDevice() ? 1 : ""),
        ...this.bottomSectionTransition,
        padding: () => (Device.isMobileDevice() ? "0 10px 0 14px" : "64px 62px 0"),
    };

    @styleRule
    description = {
        ...this.text,
        ...this.bottomSectionTransition,
        padding: () => (Device.isMobileDevice() ? "16px 0 20px" : "0 0 64px"),
    };

    @styleRule
    icon = {
        width: () => (Device.isMobileDevice() ? 73 : ""),
        transform: () => (Device.isMobileDevice() ? "scale(.73)" : ""),
        height: () => (Device.isMobileDevice() ? "" : 80),
        display: "flex",
        alignItems: "center",
        ">:first-child": {
            margin: "auto",
        },
        transitionDelay: 0,
    };

    @styleRule
    media = {
        ...this.bottomSectionTransition,
        width: () => (Device.isMobileDevice() ? "calc(100% - 7%)" : "100%"),
        bottom: () => (Device.isMobileDevice() ? "2%" : ""),
        borderRadius: () => (Device.isMobileDevice() ? "0 0 20px 20px" : ""),
        position: () => (Device.isMobileDevice() ? "absolute" : ""),
        zIndex: () => (Device.isMobileDevice() ? -1 : ""),
        left: () => (Device.isMobileDevice() ? "50%" : ""),
        transform: () => (Device.isMobileDevice() ? "translateX(-50%)" : ""),
    };

    @styleRule
    flexContainer = {
        display: "flex",
        flexDirection: () => (Device.isMobileDevice() ? "row" : "column"),
        marginTop: () => (Device.isMobileDevice() ? 20 : ""),
    };

    @styleRule
    mediaContainer = {
        position: "relative",
        maxWidth: () => (Device.isMobileDevice() ? 364 : ""),
        margin: () => (Device.isMobileDevice() ? "auto" : ""),
    };

    @styleRule
    borderCover = {
        display: () => (Device.isMobileDevice() ? "none" : ""),
        position: "absolute",
        zIndex: 1,
        width: "calc(100% + 18px)",
        height: "calc(100% + 24px)",
        top: -13,
        left: -9,
        boxShadow: "inset 0 0 0 10px white",
        borderRadius: 21,
    };

    @styleRule
    iphoneFrame = {
        display: () => Device.isMobileDevice() ? "" : "none",
    };

    @styleRule
    ipadFrame = {
        display: () => Device.isMobileDevice() ? "none" : "",
        position: "absolute",
        width: "100%",
        zIndex: 1,
        top: -2,
    };
}

@registerStyle(ProductPageStyle)
export class ProductPage extends Section {
    visible = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            icon: null,
            summary: null,
            description: null,
            mediaUrl: null,
            isVideo: false,
            mediaStyle: {}
        };
    }

    getVideo() {
        const {mediaUrl} = this.options;
        const mediaUrls = Array.isArray(mediaUrl) ? mediaUrl : [mediaUrl];
        const sourceHtml = mediaUrls.map(({url, type}) => `<source src="${url}" type="${type}">`).join("");

        return <RawHTML innerHTML={`<video autoplay="" loop="" muted="" playsinline="" data-object-fit="cover" 
            class="${this.styleSheet.media}">${sourceHtml}</video>`}/>;
    }

    render() {
        const {styleSheet, visible} = this;
        const {icon, summary, description, mediaUrl, mediaStyle, isVideo} = this.options;

        return [
            <div className={styleSheet.page + (visible ? styleSheet.visible : "")} ref="page">
                <div className={styleSheet.flexContainer}>
                    <div className={styleSheet.icon}>{icon}</div>
                    <RawHTML className={styleSheet.summary} innerHTML={summary} />
                </div>
                <div className={styleSheet.description}>{description}</div>
                <div className={styleSheet.mediaContainer}>
                    <IphoneFrame className={styleSheet.iphoneFrame} />
                    <IpadFrame className={styleSheet.ipadFrame} />
                    {isVideo ? this.getVideo() :
                        <Image className={styleSheet.media} src={mediaUrl} style={mediaStyle}/>}
                    <div className={styleSheet.borderCover} />
                </div>
            </div>,
        ];
    }

    onMount() {
        super.onMount();

        const showIfVisibleInViewport = percent => {
            if (isScrolledInViewport(this.page.node, Device.isMobileDevice() ? 0 : percent) && !this.visible) {
                this.visible = true;
                this.redraw();
            }
        };

        this.attachEventListener(window, "scroll", () => showIfVisibleInViewport(.2));
        setTimeout(() => showIfVisibleInViewport(.01));
    }
}
