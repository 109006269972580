import {authService} from "../../client/connection/services/AuthService.js";
import {WebsiteIndex} from "./WebsiteIndex.jsx";

const options = {replaceHistory: true};

// TODO @cleanup these should just be plain functions
export class AppGuards {
    static checkNotAuthenticated() {
        if (!authService.isAuthenticated()) {
            return null;
        }
        return ["/wallet", options];
    }

    static checkAuthenticated(routeSnapshot) {
        if (authService.isAuthenticated()) {
            return null;
        }
        WebsiteIndex.setRedirectURL(routeSnapshot.path);
        return ["/", options];
    }
}
