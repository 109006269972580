import {Flow, FlowPlan} from "./Flow.js";
import {isFunction} from "../../../stem-core/src/base/Utils.js";
import {iFrameState} from "../../services/IFrameState.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {DonationFlowHandler} from "./DonationFlowHandler.js";
import {SubscriptionFlowHandler} from "./SubscriptionFlowHandler.js";
import {GiftSubscriptionFlowHandler} from "./GiftSubscriptionFlowHandler.js";
import {ShopFlowHandler} from "./ShopFlowHandler.js";
import {DashboardFlowHandler} from "./DashboardFlowHandler.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {NewsletterFlowHandler} from "./NewsletterFlowHandler.js";
import {FUNCTIONALITY_NOT_ENABLED_URL} from "../PanelConstants.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";
import {BaseFlowHandler} from "./BaseFlowHandler.js";
import {PaywallPurchaseFlowHandler} from "./PaywallPurchaseFlowHandler.js";
import {PaymentMethodFlowStep} from "./steps/PaymentMethodFlowStep.js";
import {SubscriptionPlanFlowStep} from "./steps/SubscriptionPlanFlowStep.js";
import {AddressFlowStep} from "./steps/AddressFlowStep.js";
import {AuthFlowStep} from "./steps/AuthFlowStep";
import {GiftSubscriptionDetailsFlowStep} from "./steps/GiftSubscriptionDetailsFlowStep.js";
import {SubscriptionReviewFlowStep} from "./steps/SubscriptionReviewFlowStep.js";
import {DonationDetailsFlowStep} from "./steps/DonationDetailsFlowStep.js";
import {PaymentAndDonateFlowStep} from "./steps/PaymentAndDonateFlowStep.js";
import {ProductListFlowStep} from "./steps/ProductListFlowStep.js";
import {PurchaseReviewFlowStep} from "./steps/PurchaseReviewFlowStep.js";
import {EditSubscriptionFlowHandler} from "./EditSubscriptionFlowHandler.js";
import {EditSubscriptionConfirmationStep} from "./steps/EditSubscriptionConfirmationStep.js";
import {CustomPanelFlowHandler} from "./CustomPanelFlowHandler.js";


// We're overwriting the object here to prevent circular imports
Object.assign(Flow, {
    // Used as inputs from other main flows
    auth: new AuthFlowStep(),
    address: new AddressFlowStep(),
    paymentMethod: new PaymentMethodFlowStep(),
    subscriptionPlan: new SubscriptionPlanFlowStep(),
    giftSubscriptionDetails: new GiftSubscriptionDetailsFlowStep(),
    subscriptionReview: new SubscriptionReviewFlowStep(),
    editSubscriptionConfirmation: new EditSubscriptionConfirmationStep(),
    donationDetails: new DonationDetailsFlowStep(),
    paymentAndDonationDetails: new PaymentAndDonateFlowStep(),
    productList: new ProductListFlowStep(),
    purchaseReview: new PurchaseReviewFlowStep(),

    // Linked directly to panels
    donation: new DonationFlowHandler(),
    subscribe: new SubscriptionFlowHandler(),
    giftSubscription: new GiftSubscriptionFlowHandler(),
    editSubscription: new EditSubscriptionFlowHandler(),
    payment: new PaywallPurchaseFlowHandler(),
    shop: new ShopFlowHandler(),
    dashboard: new DashboardFlowHandler(),
    newsletter: new NewsletterFlowHandler(),
    custom: new CustomPanelFlowHandler(),

    startPlan(flowPlan) {
        this.flowPlan = flowPlan;
        this.flowPlan.prompt();
    },

    getCurrentStep() {
        return this.flowPlan.getCurrentStep();
    },

    // Move to that step in the flow. Will force show, even if the step wants to hide itself.
    promptAtStep(desiredStep) {
        this.flowPlan.goToStep(desiredStep);
        this.flowPlan.prompt(true);
    },

    back() {
        Router.back();
        this.flowPlan?.promptPreviousStep().then(() => {
            if (this.flowPlan?.isBeforeFirstStep()) {
                this.activeFlowHandler = null; // We want back and exited the flow
            }
        });
        // TODO @flow2 Send a message that we might need to recalculate the panel content
    },

    renderBackButton() {
        return this.getCurrentStep().renderBackButton();
    },

    startPlanFromActiveHandler() {
        let flowPlan = this.activeFlowHandler.getFlowPlan();

        if (!flowPlan) {
            // We've probably changed to another panel
            // TODO @flow2 this is here only to support very simple unmigrated stuff
            return;
        }

        if (!(flowPlan instanceof FlowPlan)) {
            flowPlan = new FlowPlan(flowPlan);
        }

        for (const step of flowPlan.steps) {
            // Just here so we don't need to manually init all steps manually when making the plan
            if (step.flowHandler !== this.activeFlowHandler) {
                step.init({});
            }
        }

        this.startPlan(flowPlan);
    },

    clearActiveFlow() {
        this.activeFlowHandler = null;
        delete this.flowPlan;

        // TODO @flow2 temp cludge to ensure we have all flows
        for (const [key, value] of Object.entries(this)) {
            if (value === Flow.paymentMethod || value === Flow.address) {
                continue;
            }
            
            if (!value || isFunction(value)) {
                // Just a regular method
                continue;
            }

            const Cls = value.constructor;
            this[key] = new Cls();
        }
    },

    isFlowTypeEnabled(panelType) {
        // TODO @flow move inside merchant?
        const merchant = iFrameMerchantService.getMerchant();
        const FLOW_TYPE_ENABLED = {
            [PANEL_TYPE.auth]: true,
            [PANEL_TYPE.custom]: true,
            [PANEL_TYPE.dashboard]: true,
            [PANEL_TYPE.payment]: merchant.allowPurchases,
            [PANEL_TYPE.shop]: merchant.allowPurchases,
            [PANEL_TYPE.donation]: merchant.allowDonations,
            [PANEL_TYPE.subscribe]: merchant.allowSubscriptions,
            [PANEL_TYPE.giftSubscription]: merchant.allowSubscriptions,
            [PANEL_TYPE.newsletter]: merchant.allowEmailing,
        };
        return FLOW_TYPE_ENABLED[panelType];
    },

    updateActiveFlow(panelType = iFrameState.panelType) {
        if (panelType === PANEL_TYPE.empty || this.activeFlowHandler?.sameAsNewOptions(panelType)) {
            return;
        }

        this.clearActiveFlow();

        if (!this.isFlowTypeEnabled(panelType)) {
            Router.changeURL(FUNCTIONALITY_NOT_ENABLED_URL);
            return;
        }

        if (!iFrameState.handleFlowInline()) {
            Router.changeToCustomPanel();
            return;
        }

        // TODO @flow2 It might make more sense to consider a stack of flows?
        this.activeFlowHandler = this[panelType];

        if (!(this.activeFlowHandler instanceof BaseFlowHandler) && this.activeFlowHandler !== this.auth) {
            // TODO @flow2 We'll just show the custom panel for now
            this.activeFlowHandler = this.custom;
        }

        this.activeFlowHandler.saveInitialOptions();

        this.startPlanFromActiveHandler();
    },
});
