import {apiClient} from "../connection/BlinkApiClient.js";
import {PaymentStatusDependentStore} from "./misc/StoreUtils";
import {CycleUnit, RecurringPaymentStoreObject} from "./RecurringPaymentStoreObject";
import {field} from "../../stem-core/src/state/StoreField.js";
import {cleanObject} from "../../stem-core/src/base/Utils.js";
import {getFeeRawPercentage} from "./DonationOfferStore.js";


export class Donation extends RecurringPaymentStoreObject {
    @field(Boolean) coversFees; // TODO rename from the backend to donorCoversFees
    @field(CycleUnit) frequency;

    isRecurring() {
        return !this.frequency.isOneTime();
    }

    serializeForSDK() {
        // TODO @branch check if this works
        const {id, blinkSignature, userId, amount, createdAt, canceledAt, frequency, activeUntil} = this;
        let obj = {id, blinkSignature, userId, amount, createdAt, canceledAt, frequency, activeUntil};

        obj.currencyCode = this.getCurrency().getIsoCode();

        return obj;
    }

    updatePaymentMethod(paymentMethodId) {
        return apiUpdateRecurringDonation(this, {preferredPaymentMethodId: paymentMethodId});
    }

    reactivate() {
        return apiUpdateRecurringDonation(this, {canceled: false});
    }

    getShortenedFrequency() {
        return this.frequency.getAbrev();
    }

    // TODO @branch this should be shared with Subscription Offer
    formatPriceShortened() {
        if (this.isRecurring()) {
            return `${this.getPrice()}/${this.getShortenedFrequency()}`;
        }
        return this.getPrice();
    }

    formatPrice() {
        if (this.isRecurring()) {
            return `${this.getPrice()}/${this.frequency.name.toLowerCase()}`;
        }
        return this.getPrice();
    }

    // TODO @cleanup WAT? This does the reverse fee calc rule. The original amount should be stored.
    computeAmount() {
        return this.coversFees ? Math.floor((this.amount - 3e5) / (1 + getFeeRawPercentage(this.getMerchant().id)) / 1e6) * 1e6 : this.amount;
    }

    isActive() {
        return super.isActive() && this.status === RecurringPaymentStoreObject.Status.ACTIVE;
    }
}

class DonationStoreClass extends PaymentStatusDependentStore("donation", Donation) {
    Status = Donation.Status;

    getRecurring() {
        return this.all().filter(entry => entry.isRecurring());
    }

    getPresentRecurring() {
        return [...this.getActiveRecurring(), ...this.getRecurringUnpaid()];
    }

    getActiveRecurring() {
        return this.getRecurring().filter(donation => donation.isActive());
    }

    getRecurringUnpaid() {
        return this.getRecurring().filter(donation => donation.isUnpaid());
    }

    getPastRecurring() {
        return this.getRecurring().filter(donation => !donation.isActive() && !donation.isUnpaid());
    }

    getOneTime() {
        return this.all().filter(entry => !entry.isRecurring());
    }

    computeFeeFromAmount(amount, merchantId) {
        if (!amount) {
            return 0;
        }
        return Math.ceil((getFeeRawPercentage(merchantId) * amount / 1e6 + 0.3) * 2) * 5e5;
    }
}

export const DonationStore = new DonationStoreClass();

export async function apiDonate(donationDetails) {
    const response = await apiClient.post("/donations/donate", donationDetails);
    return DonationStore.loadObject(response);
}

/*
Example usage:
    await apiDonateWithCds({
        merchantId: 1,
        recurringOfferId: 1,
        amount: 10 * 1e6,
        frequency: CycleUnit.ONE_TIME, // or CycleUnit.MONTH
        userAddressId: 1,
        paymentInfo: {
            card_number: "4111",
            card_expiry: "MMYY",
            card_cvv: "123,"
        }
    })
* */
export async function apiDonateWithCds(donationDetails = {}) {
    const response = await apiClient.post("/external_providers/donate_with_cds", donationDetails);
    return DonationStore.loadObject(response);
}

export async function apiEditDonationWithCds(donationDetails = {}) {
    const response = await apiClient.post("/external_providers/edit_donation_with_cds", donationDetails);
    return DonationStore.loadObject(response);
}

export async function apiUpdateRecurringDonation(donation, fields) {
    fields = cleanObject(fields);
    return apiClient.post("/donations/update", {
        donationId: donation.id,
        ...fields,
    })
}


export async function apiUpdateDonationAmount(donation, amount, coversFees = false) {
    return apiUpdateRecurringDonation(donation, {amount, coversFees});
}

export async function apiCancelDonation(donation) {
    return apiUpdateRecurringDonation(donation, {canceled: true});
}
