import {UI} from "../../../../stem-core/src/ui/UI.js";

import {TopUpHistory} from "./sections/TopUpHistory.jsx";
import {AddFundsCard} from "./sections/AddFundsCard.jsx";

export class WalletPage extends UI.Element {
    render() {
        return [
            <AddFundsCard testId="walletAddFunds" />,
            <TopUpHistory testId="walletCardCharges" />,
        ];
    }
}
