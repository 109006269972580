import {Dispatcher} from "../../../stem-core/src/base/Dispatcher";
import {iFrameConnection} from "../../services/IFrameConnection";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages";
import {Router} from "../../../stem-core/src/ui/Router";
import {WidgetRoutes} from "../WidgetRoutes";
import {AuthHelper} from "../../AuthHelper";
import {BlinkGlobal, isFacebookBrowser} from "../../Utils";
import {BLINK_PAY_URL, IFRAME_APP_TYPE, DEFAULT_TRANSITION_DURATION_MS} from "../../../blink-sdk/Constants";
import {iFrameState} from "../../services/IFrameState.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";


export function redirectToMerchantHomepage() {
    iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url: "/"});
}

// This should only be called for user events.
export function accessDashboardURLFromIFrame(event, url=BLINK_PAY_URL) {
    if (isFacebookBrowser()) {
        iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url});
        event.preventDefault();
        event.stopPropagation();
    }
}

export function collapseIframe(expandWallet=false) {
    AuthHelper.clearAuthenticationCallbacks();
    if (BlinkGlobal.appType === IFRAME_APP_TYPE.panel) {
        iFrameConnection.sendToSDK(IFrameMessages.DESTROY_PANEL, {expandWallet});
        Dispatcher.Global.dispatch("collapseIframe");
        return;
    }
    Router.changeURL(WidgetRoutes.INDEX_COLLAPSED);
    // We want to dispatch the event after the modal / widget has been collapsed because only then the correct page is rendered.
    setTimeout(() => {
        Dispatcher.Global.dispatch("collapseIframe");
        if (expandWallet) {
            Router.changeURL(WidgetRoutes.INDEX_EXPANDED);
        }
        // TODO Fucking do this when it actually closes
    }, DEFAULT_TRANSITION_DURATION_MS);
}

// Called when the user clicks "Ok" on a flow success panel
export function dismissFlowSuccess() {
    const {iframeParams} = iFrameState;
    const merchant = iFrameMerchantService.getMerchant();

    const urlToRedirect = iframeParams.urlToRedirectOnSuccess || (iframeParams.inlineFlow && merchant.url);
    if (urlToRedirect) {
        iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url: urlToRedirect});
    } else {
        collapseIframe();
    }
}
