import {BaseFlowHandler} from "./BaseFlowHandler.js";
import {iFrameState} from "../../services/IFrameState";
import {isThirdPartyStorageBlocked} from "../../Utils";
import {iFrameConnection} from "../../services/IFrameConnection";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages";
import {Messages} from "../../Messages.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {Flow} from "./Flow.js";
import {iFramePurchaseHandler} from "../../services/IFramePurchaseHandler.js";


export class PaywallPurchaseFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.payment;

    // TODO @flow probably broken
    async enqueueShittyOldPlan() {
        await Flow.auth.promptIfNeeded();

        const {paymentDetails} = iFrameState.iframeParams;

        // TODO @flow fuck this
        // Users with third party storage blocked will have a slightly different user experience, meaning that
        // they will be asked to manually make the content payment when they authenticate from the wall iframe.
        if (isThirdPartyStorageBlocked()) {
            iFrameConnection.sendToIFrames(IFrameMessages.MAKE_NEXT_PAYMENT_MANUALLY, {
                offerId: paymentDetails.offerId,
            });
        }

        iFrameConnection.sendToSDK(IFrameMessages.MAKE_PAYMENT_VIA_SDK, {
            request: paymentDetails,
        });
    }

    getFlowPlan() {
        this.enqueueShittyOldPlan().then();
        return null;
    }

    static getActionButtonLabel() {
        const {paymentDetails} = iFrameState.iframeParams;

        if (!paymentDetails) {
            return "Read article";
        }

        const {amount} = iFramePurchaseHandler.normalizeRequest(paymentDetails);

        return Messages.readForAmount(amount);
    }
}
