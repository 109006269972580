import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {
    INLINE_SHOP_URLS,
    PAYMENT_FAILED_PANEL_URL
} from "../PanelConstants.js";
import {Messages} from "../../Messages.js";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";
import {apiUserPurchaseProduct} from "../../../client/state/PurchaseStore.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Flow} from "./Flow.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {CalculateShippingPrice} from "../../../client/state/misc/ShippingPrice.js";
import {BaseFlowHandler} from "./BaseFlowHandler.js";


export class ShopFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.shop;

    getFlowPlan() {
        return [
            Flow.productList,

            Flow.auth,

            Flow.address.init({
                shouldPrompt: () => Flow.productList.selectedValue.requiresAddress,
                continueLabel: Messages.reviewOrder,
            }),

            Flow.purchaseReview,

            Flow.paymentMethod.init({
                savePaymentMethod: true,
                scope: PaymentMethodStore.Scope.PRIVATE
            }),
        ];
    }

    @wrapInSpinner
    async finalize() {
        const product = Flow.productList.selectedValue;

        const request = {
            merchantId: iFrameMerchantService.getMerchant().id,
            paymentMethodId: Flow.paymentMethod.selectedValue.id,
            products: {
                entries: [{
                    productId: product.id,
                }]
            }
        }

        const selectedAddress = Flow.address.selectedValue;
        if (selectedAddress) {
            request.deliveryAddressId = selectedAddress.id;
        }

        request.currencyId = product.currencyId;
        request.amount = product.amount + CalculateShippingPrice(selectedAddress, product.getShippingPrices()).amount;

        try {
            await apiUserPurchaseProduct(request);
        } catch (error) {
            Flow.paymentMethod.update({
                paymentError: error,
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
            return;
        }

        Router.changeURL(INLINE_SHOP_URLS.orderSuccess);
    }
}
