import {UI} from "../../stem-core/src/ui/UIBase.js";
import {styleRule, styleRuleInherit} from "../../stem-core/src/decorators/Style.js";
import {registerStyle} from "../../stem-core/src/ui/style/Theme.js";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils.js";
import {normalize} from "../Utils.js";
import {KeyEvent} from "../KeyEvent.js";
import {BlinkInput, BlinkInputStyle} from "./Input.jsx";


const InputAutocompleteStyle = InputStyle => class InputAutocompleteStyleClass extends InputStyle {
    @styleRuleInherit
    container = {
        position: "relative",
    };

    @styleRule
    suggestionContainer = {
        position: "absolute",
        width: "100%",
        zIndex: "9999",
        background: this.themeProps.INPUT_BACKGROUND,
        border: () => "1px solid " + this.themeProps.INPUT_BORDER_COLOR, // TODO: To add in merchant input.
    };

    @styleRule
    suggestionItem = {
        height: 40,
        display: "flex",
        alignItems: "center",
        paddingLeft: 12,
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
        cursor: "pointer",
        ":hover": {
            background: this.themeProps.INPUT_BACKGROUND_HOVER,
        },
    };
};

@registerStyle(InputAutocompleteStyle(BlinkInputStyle))
export class InputAutocomplete extends BlinkInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            autocompleteList: [],
            maxSuggestionCount: 3,
            minAutocompleteStringLength: 2,
            onOptionSelect: NOOP_FUNCTION,
        };
    }

    constructor(...args) {
        super(...args);
        this.shouldShowAutocompleteOptions = false;
        this.selectedOption = null;
    }

    getChildrenToRender() {
        return [
            ...super.getChildrenToRender(),
            this.renderAfterInput(),
        ]
    }

    isAutocompleteOption(option) {
        const formattedOption = normalize(option);
        const inputValue = normalize(this.getValue());

        if (inputValue.length < this.options.minAutocompleteStringLength) {
            return false;
        }
        if (formattedOption.length < inputValue.length) {
            return false;
        }
        for (let i = 0; i < inputValue.length; i += 1) {
            if (formattedOption[i].toLowerCase() !== inputValue[i].toLowerCase()) {
                return false;
            }
        }
        return true;
    }

    getAutocompleteItemsToShow() {
        return this.options.autocompleteList
            .filter(option => this.isAutocompleteOption(option.name))
            .slice(0, this.options.maxSuggestionCount);
    }

    selectOption(option) {
        if (!option) {
            return;
        }
        this.setValue(option.display);
        this.selectedOption = option;
        this.dispatch("optionSelected", option);
    }

    // TODO @input this seems slow for some reason
    renderAfterInput() {
        const {styleSheet} = this;
        const autocompleteItems = this.getAutocompleteItemsToShow();

        return [
            this.shouldShowAutocompleteOptions && (autocompleteItems.length > 0) && (
                <div className={styleSheet.suggestionContainer}>
                    {autocompleteItems.map(option => (
                        <div className={styleSheet.suggestionItem} onClick={() => this.selectOption(option)}>
                            {option.name}
                        </div>
                    ))}
                </div>
            ),
        ];
    }

    setValue(value) {
        super.setValue(value);
        this.selectedOption = value.trim().length ? this.getExactlyMatchedOption() : null;
    }

    hideAutocompleteOptions() {
        this.shouldShowAutocompleteOptions = false;
        this.redraw();
    }

    showAutocompleteOptions() {
        this.shouldShowAutocompleteOptions = true;
        this.redraw();
    }

    getExactlyMatchedOption() {
        const inputValue = this.getValue().trim();
        return this.options.autocompleteList.find(({name}) => {
            return inputValue.toLowerCase() === name.toLowerCase();
        });
    }

    initFocusListeners() {
        super.initFocusListeners();

        this.input.addNodeListener("blur", () => {
            if (!this.selectedOption) {
                const matchedOption = this.getExactlyMatchedOption() || this.selectedOption;
                this.selectOption(matchedOption);
            }
            this.options.onBlur();
        });

        this.input.addNodeListener("focus", (event) => {
            if (!this.selectedOption || this.selectedOption.name !== this.getValue().trim()) {
                this.showAutocompleteOptions();
            }
            this.options.onFocus(event);
        });

        document.body.addEventListener("click", event => {
            if (this.input && event.target !== this.input.node) {
                this.hideAutocompleteOptions();
            }
        });

        this.addListener("optionSelected", option => {
            this.options.onOptionSelect(option);
        });
    }

    initKeyListeners() {
        super.initKeyListeners();

        this.input.addNodeListener("keydown", event => {
            const key = new KeyEvent(event);
            if (key.isTab()) {
                this.hideAutocompleteOptions();
            }
        });

        this.input.addNodeListener("keyup", event => {
            this.clearError();
            this.selectedOption = null;
            this.showAutocompleteOptions();
            const key = new KeyEvent(event);

            if (key.isEnter()) {
                this.options.onEnter();
            }

            if (key.isEscape()) {
                this.hideAutocompleteOptions();
            }
        });
    }

    onMount() {
        super.onMount();
        this.showAutocompleteOptions();
    }
}
