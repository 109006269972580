import {UI} from "../../../../stem-core/src/ui/UIBase";
import {registerStyle, styleRule, StyleSheet} from "../../../../stem-core/src/ui/UI";
import {NOOP_FUNCTION} from "../../../../stem-core/src/base/Utils";
import {ChevronDownIcon} from "../../SVGElements.jsx";
import {isSmallScreen} from "../../../../blinkpay/Utils";
import {Messages} from "../../../../blinkpay/Messages";
import {DropdownList} from "./DropdownList";
import {InputLabel} from "../InputLabel.jsx";
import {BaseInputElement} from "../../../../stem-core/src/ui/input/BaseInputElement.js";


export class DropdownInputStyle extends StyleSheet {
    @styleRule
    dropdown = {
        display: "flex",
        alignItems: "center",
        transition: this.themeProps.DEFAULT_TRANSITION,
        border: "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
        borderRadius: this.themeProps.BASE_BORDER_RADIUS,
        background: this.themeProps.INPUT_BACKGROUND,
        cursor: "pointer",
        fontSize: () => (isSmallScreen() ? this.themeProps.FONT_SIZE_SMALL : this.themeProps.FONT_SIZE_NORMAL),
        whiteSpace: "nowrap",
        color: this.themeProps.INPUT_TEXT_COLOR,
        ":hover": {
            background: this.themeProps.INPUT_BACKGROUND,
            border: "1px solid " + this.themeProps.INPUT_BORDER_ACTIVE_COLOR,
        },
         ...this.themeProps.MERCHANT_INPUT_STYLE,
    };

    @styleRule
    placeholder = {
        color: this.themeProps.INPUT_PLACEHOLDER_COLOR,
    };

    @styleRule
    dropdownText = {
        flex: 1,
        paddingLeft: 15,
    }

    @styleRule
    chevronDownIcon = {
        padding: "9px 18px 9px 9px",
    };
}

@registerStyle(DropdownInputStyle)
export class DropdownInput extends BaseInputElement {
    setOptions(options) {
        const oldInitialValue = this.options.initialValue;
        super.setOptions(options);
        const {initialValue} = this.options;
        if (this.value === undefined || !this.node || !this.isEqual(initialValue, oldInitialValue)) {
            this.setValue(initialValue);
        }
        if (this.expandedList) {
            this.expandedList.updateOptions(this.getDropdownListOptions());
        }
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            label: null,
            options: [],
            formatter: x => x,
            onExpand: NOOP_FUNCTION,
            onCollapse: NOOP_FUNCTION,
            testId: "dropdownComponent",
        };
    }

    handleDropdownSelectedClick(event) {
        event.stopPropagation();
        event.preventDefault();
        // the default behavior we don't want to get rid of:
        // blur the active element to hide keyboard on mobile
        if (document.activeElement && document.activeElement.blur) {
            document.activeElement.blur();
        }
        this.expand();
    }

    render() {
        const {styleSheet} = this;
        const {placeholder, label, formatter} = this.options;
        const usePlaceholderAsLabel = this.themeProps.INPUT_USE_PLACEHOLDER_AS_LABEL;
        const dropdownClassName = styleSheet.dropdown + (this.getValue() ? "" : styleSheet.placeholder);
        const placeholderText = (usePlaceholderAsLabel ? placeholder : label) || Messages.choose + "...";

        return [
            label && !usePlaceholderAsLabel && <InputLabel label={label}/>,
            
            <div className={dropdownClassName} ref="dropdown">
                <div className={styleSheet.dropdownText}>
                    {this.getValue() ? formatter(this.getValue()) : placeholderText}
                </div>
                <ChevronDownIcon className={styleSheet.chevronDownIcon}/>
            </div>
        ];
    }

    getDropdownListOptions() {
        return {
            dropdown: this,
            anchor: this.dropdown,
            options: this.options.options,
            active: this.getValue(),
            formatter: this.options.formatter,
        };
    }

    expand() {
        if (!this.expandedList?.node && this.isInDocument()) {
            this.expandedList = DropdownList.expand(this.getDropdownListOptions());
            this.options.onExpand();
        }
    }

    destroyNode() {
        if (this.expandedList) {
            this.expandedList.destroyNode();
        }
        return super.destroyNode();
    }

    onMount() {
        super.onMount();

        this.addListener("changeActive", item => this.setValue(item));

        this.addListener("collapse", () => {
            this.expandedList = null;
            this.options.onCollapse();
        });

        this.dropdown.addClickListener(event => this.handleDropdownSelectedClick(event));
    }
}
