import {registerStyle, styleRule, UI, StyleSheet} from "../../../stem-core/src/ui/UI.js";
import {AuthHelper} from "../../AuthHelper.js";
import {LinkButton} from "../../../core/ui/LinkButton.jsx";
import {Messages} from "../../Messages.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {CONFIRMATION_CODE_TYPES, LOGIN_URL} from "../../Constants.js";
import {ShortCodeEmailConfirmationForm} from "./ShortCodeEmailConfirmationForm.jsx";
import {LongCodeEmailConfirmationForm} from "./LongCodeEmailConfirmationForm.jsx";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient.js";
import {authFormService} from "../../services/AuthFormService.js";
import {MessageElement} from "../../widget/ui/MessageElement.jsx";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Toast} from "../../ui/Toast.jsx";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";


class LoginWithEmailCodeStyle extends StyleSheet {
    @styleRule
    container = {
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
    };

    @styleRule
    message = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        marginBottom: 14,
    };

    @styleRule
    text = {
        display: "inline",
    };

    @styleRule
    resendCodeButton = {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        paddingTop: 6,
    };
}

@autoredraw(authFormService)
@registerStyle(LoginWithEmailCodeStyle)
export class LoginWithEmailCode extends UI.Element {
    canDisplayResendCodeOption = true;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onWrongEmailButtonClick: () => Router.changeURL(LOGIN_URL), // TODO @auth @cleanup this won't be necessary once we merge for TNR
        }
    }

    getBeforeInput() {
        const {styleSheet} = this;
        const messageFormatter = (authFormService.confirmationCodeType === CONFIRMATION_CODE_TYPES.SHORT) ?
            Messages.enterConfirmationCodeShort : Messages.enterConfirmationCodeLong;

        return [
            <MessageElement className={styleSheet.message} message={messageFormatter(authFormService.registrationMail)}/>,
            // TODO maybe keep disabled for like 10 seconds
            <LinkButton
                onClick={() => this.handleWrongEmailLinkClick()}
                label={Messages.wrongEmail}
            />
        ];
    }

    getEmailConfirmationFormClass() {
        return authFormService.confirmationCodeType === CONFIRMATION_CODE_TYPES.LONG
            ? LongCodeEmailConfirmationForm : ShortCodeEmailConfirmationForm;
    }

    render() {
        const {styleSheet} = this;
        const EmailConfirmationFormClass = this.getEmailConfirmationFormClass();

        return [
            <div className={styleSheet.message}>
                {this.getBeforeInput()}
            </div>,
            <EmailConfirmationFormClass ref="emailConfirmationForm"
                                        onCodeAutomaticallyVerified={() => {
                                            this.canDisplayResendCodeOption = false;
                                            this.redraw();
                                        }}
                                        onSubmit={(code) => this.handleSubmit(code)}/>,
            this.getAfterInput(),
        ];
    }

    getAfterInput() {
        const {canDisplayResendCodeOption, styleSheet} = this;

        if (!canDisplayResendCodeOption) {
            return null;
        }

        return <LinkButton
            className={styleSheet.resendCodeButton}
            label={Messages.resendCode}
            onClick={() => this.resendCode()}
        />
    }

    handleWrongEmailLinkClick() {
        if (this.emailConfirmationForm) {
            this.options.onWrongEmailButtonClick();
        }
    }

    @wrapInSpinner
    async handleSubmit(code) {
        try {
            dispatchAnalyticsEvent(AnalyticsEventType.ATTEMPT_CONFIRM_EMAIL_CODE);
            await this.confirmEmailCode(code);
            this.onConfirmSuccess();
        } finally {
            this.redraw(); // TODO @cleanup handle this
        }
    }

    onConfirmSuccess() {
        if (this.options.onSuccess) {
            this.options.onSuccess();
        }
        AuthHelper.confirmedAuthenticationAction();
    }

    async confirmEmailCode(code) {
        await AuthHelper.loginWithEmailCode(code);

        dispatchAnalyticsEvent(AnalyticsEventType.USER_LOGIN, {registration: authFormService.isRegistration});
    }

    async resendCode() {
        try {
            await AuthHelper.requestLoginCode();
            Toast.showSuccess(Messages.confirmationCodeResent);
        } catch (error) {
            Toast.showError(error, Messages.unexpectedError);
        }
    }
}
