import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {AnchoredPopup, AnchoredPopupStyle} from "../../../stem-core/src/ui/AnchoredPopup.jsx";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";

// TODO have an invisible scrim, that also passes through all click events (so another element can be toggled)
// TODO this should probably be merged with the Modal class in the future (at least in a base class)
export class DashboardAnchoredPopupStyle extends AnchoredPopupStyle {
    @styleRuleInherit
    popup = {
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
        padding: 8,
    };
}

@registerStyle(DashboardAnchoredPopupStyle)
export class DashboardAnchoredPopup extends AnchoredPopup {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            offset: 10,
        };
    }
}


export class PopupInput extends DashboardAnchoredPopup {
    hide(response=null) {
        super.hide();
        if (this.resolvePromise) {
            this.resolvePromise(response);
        }
    }

    handleInputChange(value) {
        this.hide(value);
    }

    // Will show an input, and on a second call with the same anchor will hide the previous one (
    static async prompt(InputClass, inputOptions={}, modalOptions={}) {
        let modal;
        const handleInput = inputOptions?.onChange ?? ((value) => modal?.handleInputChange(value));
        const inputElement = <InputClass {...inputOptions} onChange={handleInput} onSave={handleInput} />;
        modal = this.show({children: [inputElement], ...modalOptions});

        if (!modal) {
            return null;
        }

        return new Promise(resolve => {
            modal.resolvePromise = (result) => {
                delete modal.resolvePromise;
                resolve(result);
            };
        });
    }
}
