import {UI} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {Toast} from "../../../../ui/Toast.jsx";
import {CancelSubscriptionModal} from "./CancelSubscriptionModal.jsx";
import {wrapInSpinner} from "../../../../../core/ui/LoadingSpinner.jsx";
import {apiReactivateSubscription} from "../../../../../client/state/SubscriptionStore.js";


export class ReactivateSubscriptionModal extends CancelSubscriptionModal {
    getDefaultOptions(options) {
        const {subscription} = options;
        return {
            ...super.getDefaultOptions(),
            title: Messages.reactivateSubscriptionTitle,
            description: Messages.reactivateSubscriptionDescription(`${subscription.getMerchantTitle()} - ${subscription.coverage.name}`),
            confirmAction: () => this.reactivate(),
            confirmLabel: "",
        };
    }

    @wrapInSpinner
    async reactivate() {
        await apiReactivateSubscription(this.options.subscription.id);
        Toast.show(Messages.reactivateSubscriptionSuccess);
        this.hide();
    }
}
