import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";
import {registerStyle, styleRule, styleRuleInherit, StyleSheet} from "../../../../../../stem-core/src/ui/UI.js";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../ui/ConfirmationModal.jsx";
import {Messages} from "../../../../../Messages.js";
import {TextArea} from "../../../../../../stem-core/src/ui/input/Input.jsx";
import {NOOP_FUNCTION} from "../../../../../UtilsLib.js";
import {wrapInSpinner} from "../../../../../../core/ui/LoadingSpinner.jsx";
import {apiRequestCloseAccount} from "../../../../../../client/state/UserProfileStore.js";
import {RadioListInput} from "../../../../../../core/ui/input/radio/RadioListInput.jsx";


class ReasonTextAreaStyle extends StyleSheet {
    @styleRule
    container = {
        background: "rgba(232, 240, 242, 0.72)",
        color: this.themeProps.MERCHANT_4,
        fontSize: 16,
        lineHeight: 20,
        resize: "none",
        border: "none",
        borderRadius: 4,
        padding: "12px 10px",
        outline: "none",
        cursor: "not-allowed",
    };

    @styleRule
    editable = {
        cursor: "text",
        ":focus": {
            border: "solid 0.75px #0080FF",
            boxShadow: "0px 3px 6px 0 rgba(50, 50, 93, 0.08)",
        }
    }
}

@registerStyle(ReasonTextAreaStyle)
class ReasonTextArea extends TextArea {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        if (!this.options.readOnly) {
            attr.addClass(this.styleSheet.editable);
        }
    }
}

class CloseAccountReasonModalStyle extends ConfirmationModalStyle {
    @styleRuleInherit
    buttons = {
        marginTop: 0,
    };

    @styleRule
    secondaryTextLine = {
        fontSize: 16,
        color: this.themeProps.CLOSE_ACCOUNT_REASON_SECONDARY_TEXT_COLOR,
    };

    @styleRule
    radioList = {
        margin: "18px 0px 24px",
    };

    @styleRule
    textArea = {
        margin: "18px 0px 24px",
        width: "100%",
        height: 126,
    };
}

@registerStyle(CloseAccountReasonModalStyle)
export class CloseAccountReasonModal extends ConfirmationModal {
    selectedReason = null;
    confirmButtonDisabled = true;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "closeAccountReasonModal",
            title: Messages.closeAccountReasonModalTitle,
            confirmLabel: Messages.continue,
            cancelLabel: Messages.close,
            userProfile: null,
            onConfirmSuccess: NOOP_FUNCTION,
            confirmAction: () => this.submitCloseAccountRequest(),
        };
    }

    render() {
        const {styleSheet} = this;
        const closeAccountReasons = [
            "I accidentally signed up for this service.",
            "I have a privacy concern.",
            "I already have an account.",
            "I'm getting unwanted emails.",
            "Other",
        ]

        return [
            <div className={styleSheet.secondaryTextLine}>{Messages.closeAccountReasonModalDescription}</div>,

            <RadioListInput
                onChange={(value) => this.handleListItemSelection(value)}
                entries={closeAccountReasons}
                initialValue={this.selectedReason}
            />,

            <div className={styleSheet.secondaryTextLine}>{Messages.closeAccountFeedbackText}</div>,
            <ReasonTextArea ref="reasonTextArea" className={styleSheet.textArea} readOnly={!this.allowUserWriteReason()}
                            onInput={() => this.updateConfirmButtonDisabled()}/>
        ];
    }

    allowUserWriteReason() {
        return this.selectedReason === "Other";
    }

    getReasonText() {
        if (this.allowUserWriteReason()) {
            return this.reasonTextArea.getValue();
        }
        return this.selectedReason;
    }

    updateConfirmButtonDisabled() {
        this.confirmButtonDisabled = this.getReasonText().trim() === "";
        this.redraw();
    }

    handleListItemSelection(reason) {
        this.selectedReason = reason;
        this.confirmButtonDisabled = !this.selectedReason || this.getReasonText().trim() === "";
        if (!this.allowUserWriteReason()) {
            this.reasonTextArea.setValue("");
        }
        this.redraw();
    }

    @wrapInSpinner
    async submitCloseAccountRequest() {
        const {onConfirmSuccess} = this.options;

        this.hide();
        const response = await apiRequestCloseAccount({reason: this.getReasonText()});
        onConfirmSuccess(response);

    }

    onMount() {
        super.onMount();
        this.reasonTextArea.addNodeListener("keyup", () => this.updateConfirmButtonDisabled());
    }
}
