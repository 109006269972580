import {UI} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {PaymentStore} from "../../../../State.js";
import {SubscriptionPayments} from "../../subscriptions/sections/SubscriptionPayments.jsx";
import {Dispatcher} from "../../../../../stem-core/src/base/Dispatcher.js";
import {PaymentDescription} from "../../../../common/PaymentDescription.jsx";
import {Link} from "../../../../../stem-core/src/ui/UIPrimitives.jsx";
import {EndpointPaginator} from "../../../../../client/state/EndpointPaginator.js";
import {UIPaginationManager} from "../../../../ui/BaseCard.jsx";


export class DonationPayments extends SubscriptionPayments {
    paginator = new EndpointPaginator(
        PaymentStore,
        PaymentStore.API_LIST_ENDPOINT,
        {resourceTypes: [PaymentStore.ResourceType.DONATION]},
        {resourceType: PaymentStore.ResourceType.DONATION},
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.donationPayments,
            description: () => (this.getPayments().length || !this.paginator.lastPageLoaded) ? "" : Messages.emptyDonationPayments,
        };
    }

    getPaymentDescription(payment) {
        const donation = payment.getResource();
        return {
            title: `${donation.getMerchantTitle()}${donation.isRecurring() ? (" (" + Messages.recurring.toString().toLowerCase() + ")") : ""}`,
            highlighted: donation.isRecurring(),
        };
    }

    getDescriptionColumnValue(payment) {
        const {styleSheet} = this;
        const {title, highlighted} = this.getPaymentDescription(payment);
        return <PaymentDescription payment={payment}>
            {highlighted ? <Link
                    className={styleSheet.paymentTitle}
                    style={{color: styleSheet.themeProps.LINK_COLOR}}
                    onClick={() => Dispatcher.Global.dispatch("highlightEntry", payment.getResource())}
                >
                    {title}
                </Link>
                : <div className={styleSheet.paymentTitle}>{title}</div>
            }
        </PaymentDescription>;
    }

    onMount() {
        super.onMount();
        this.paginationHandler.attach();
    }
}
