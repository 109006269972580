import {Theme} from "../stem-core/src/ui/style/Theme";
import {BlinkLinkStyle} from "../core/ui/LinkButton.jsx";
import {styleRuleInherit} from "../stem-core/src/decorators/Style.js";
import {Link} from "../stem-core/src/ui/primitives/Link.jsx";
import {UI} from "../stem-core/src/ui/UIBase.js";
import {BlinkButton} from "../core/ui/Button.jsx";

export const LandingPageTheme = Theme.Global.fork("LandingPage", {
    LANDING_1: "#1D1F1F",
    NAV_MANAGER_NAVBAR_HEIGHT: 0,
    COLOR_BACKGROUND_BODY: "#f5f7f8",
    LIGHT_BACKGROUND: "#fff",
    CARD_SHADOW_1: "0 8px 16px -8px rgba(0, 0, 0, 0.15)",
    CARD_SHADOW_2: "0 13px 27px -5px rgba(50, 50, 93, 0.12)",
    MODAL_BACKGROUND_COLOR: "rgba(255,255,255,.84)",
    NAVBAR_ITEM_COLOR: "#fff",

    LANDING_PAGE_HEADER_BACKGROUND: "linear-gradient(52deg, #033840, #005a65 35%,  #005a65 40%, #033840 80%, #033840)",
    LANDING_PAGE_MOBILE_HEADER_BACKGROUND: props => props.WALLET_15,
    LANDING_PAGE_INPUT_COLOR: props => props.WALLET_14,
    LANDING_PAGE_INPUT_DARK_THEME_PLACEHOLDER_COLOR: props => props.WALLET_9,
    LANDING_PAGE_INPUT_LIGHT_THEME_PLACEHOLDER_COLOR: "rgba(29,29,31,.66)",
    LANDING_PAGE_LIGHT_BUTTON_SHADOW_ON_DARK_BACKGROUND: "0 8px 18px  -3px rgba(0,0,0,1)",
    LANDING_PAGE_LIGHT_BUTTON_SHADOW_ON_LIGHT_BACKGROUND: "0 8px 18px  -3px rgba(0,0,0,.5)",
    LANDING_PAGE_DARK_BUTTON_SHADOW_ON_DARK_BACKGROUND: "0 10px 20px  -6px rgba(0,0,0,1)",
    LANDING_PAGE_DARK_BUTTON_SHADOW_ON_LIGHT_BACKGROUND: "0 7px 12px  -3px rgba(0,0,0,.9)",
    LANDING_PAGE_LOGIN_BUTTON_SHADOW_ON_LIGHT_BACKGROUND: "0 .65rem 12px  -4px rgba(0,0,0,1)",
    LANDING_PAGE_BUTTON_SHADOW: "0 8px 16px -11px rgba(0,0,0,.8)",
    LANDING_PAGE_INPUT_SHADOW: "0 8px 16px -11px rgba(0,0,0,.6)",
    LANDING_PAGE_BUTTON_DARK_THEME_BACKGROUND: "#1A8289",
    LANDING_PAGE_BUTTON_LIGHT_THEME_BACKGROUND: props => props.WALLET_13,
    LANDING_PAGE_MISSION_COLOR: props => props.WHITE,
    LANDING_PAGE_CHAT_BUTTON_BACKGROUND: "#00717d",
    LANDING_PAGE_CHAT_BUTTON_HOVER_SHADOW: "0 2px 14px 2px rgba(0,0,0,.4)",
    LANDING_PAGE_DEMO_BUTTON_BACKGROUND: props => props.WHITE,
    LANDING_PAGE_DEMO_BUTTON_COLOR: props => props.LANDING_1,
    LANDING_PAGE_LIGHT_BACKGROUND: "#F5F7F7",
    LANDING_PAGE_DARK_FONT_COLOR: props => props.LANDING_1,
    LANDING_PAGE_LIGHT_FONT_COLOR: props => props.WHITE,
    LANDING_PAGE_CARD_BACKGROUND: props => props.WHITE,
    LANDING_PAGE_CARD_SHADOW: "0 8px 16px -11px rgba(0,0,0,0.6)",
    LANDING_PAGE_CARD_HOVER_SHADOW: "0 3px 20px  -1px rgba(0,0,0,0.3)",
    LANDING_PAGE_DARK_CARD_BACKGROUND: "rgba(0,0,0,0.22)",
    LANDING_PAGE_DARK_CARD_SHADOW: "inset 50px 50px 170px -70px #007a8a4a, 0 16px 24px -13px rgb(2, 38, 43)",
    LANDING_PAGE_VERTICAL_LINE_COLOR: "#C4C4C4",
    LANDING_PAGE_HORIZONTAL_LINE_COLOR: "#D7D7D7",
    LANDING_PAGE_LOGIN_BUTTON_BACKGROUND: "#1A8289",
    LANDING_PAGE_NAV_FLOATING_BACKGROUND: props => props.WHITE,
    LANDING_PAGE_NAV_FLOATING_COLOR: props => props.LANDING_1,
    LANDING_PAGE_NAV_FLOATING_BUTTON_BACKGROUND: props => props.WALLET_13,
    LANDING_PAGE_TEAM_NAME_FONT_COLOR: props => props.LANDING_1,
    VALUE_PROPOSITION_BACKGROUND: "linear-gradient(52deg, #033840, #005a65 35%,  #005a65 40%, #033840 80%, #033840)",
    VALUE_PROPOSITION_MOBILE_BACKGROUND: props => props.WALLET_15,
    FOOTER_ITEM_COLOR: props => props.MERCHANT_4,
    FOOTER_ITEM_HOVER_COLOR: "#000000",
});

class LandingPageLinkStyle extends BlinkLinkStyle {
    @styleRuleInherit
    container = {
        ":hover": {
            color: null,
            textDecoration: null,
        }
    }
}

LandingPageTheme.register(Link, LandingPageLinkStyle);

export class LandingPageLinkButton extends UI.Primitive(BlinkButton, "a") {}
