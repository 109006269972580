import {UI, registerStyle, styleRule} from "../../../../../stem-core/src/ui/UI.js";
import {BaseCard, BaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {Messages} from "../../../../Messages.js";
import {DividerPositions, List} from "../../../../ui/List.jsx";
import {BlinkButton} from "../../../../../core/ui/Button.jsx";
import {CloseAccountConfirmationModal} from "../components/close-account/CloseAccountConfirmationModal.jsx";
import {CloseAccountReasonModal} from "../components/close-account/CloseAccountReasonModal.jsx";
import {AccountSuspendedModal} from "../components/close-account/AccountSuspendedModal.jsx";


class SecurityCardStyle extends BaseCardStyle {
    @styleRule
    closeAccountButton = {
        width: "100%",
    }

    @styleRule
    setting = {
        marginTop: 18,
    };

    @styleRule
    settingLabel = {
        fontSize: this.themeProps.FONT_SIZE_LARGE,
        color: this.themeProps.SETTINGS_LABEL_COLOR,
        marginBottom: 8,
    };

    @styleRule
    cardFooter = {
        marginTop: 0,
    }
}

@registerStyle(SecurityCardStyle)
export class SecurityCard extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.security,
            userProfile: null,
        }
    }

    render() {
        const {styleSheet} = this;
        const {userProfile} = this.options;

        return <List dividerOptions={{position: DividerPositions.TOP}}>
            <div className={styleSheet.setting}>
                <div className={styleSheet.settingLabel}>
                    {Messages.closeAccountLabel}
                </div>
                <div className={styleSheet.settingDescription}>
                    {Messages.closeAccountText}
                </div>
                <BlinkButton
                    testId="closeAccountButton"
                    className={this.styleSheet.closeAccountButton}
                    onClick={() => CloseAccountReasonModal.show({
                        userProfile,
                        onConfirmSuccess: () => this.handleAccountCloseRequestSuccess(),
                    })}>
                    {Messages.closeAccountLabel}
                </BlinkButton>
            </div>
        </List>
    }

    handleAccountCloseRequestSuccess() {
        CloseAccountConfirmationModal.show({
            userProfile: this.options.userProfile,
            onConfirmSuccess: () => AccountSuspendedModal.show()
        });
    }
}
