import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";


class HorizontalLineStyle extends StyleSheet {
    @styleRule
    horizontalLine = {
        width: "100%",
        height: 2,
        background: this.themeProps.LANDING_PAGE_HORIZONTAL_LINE_COLOR,
    };
}

@registerStyle(HorizontalLineStyle)
export class HorizontalLine extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            marginTop: 0,
            marginBottom: 0,
        };
    }

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.horizontalLine);
        attr.setStyle({
            marginTop: this.options.marginTop,
            marginBottom: this.options.marginBottom,
        });
    }
}
