import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI";
import {Image} from "../../../stem-core/src/ui/UIPrimitives";
import {isDesktopSmall} from "../../Utils";
import {LocationIcon} from "../../../core/ui/SVGElements.jsx";


export class TeamCardStyle extends StyleSheet {
	teamCardLargeScreenWidth = 225;

	@styleRule
	teamCard = {
		width: () => isDesktopSmall() ? "100%" : this.teamCardLargeScreenWidth,
		height: () => isDesktopSmall() ? 120 : "",
		background: this.themeProps.LANDING_PAGE_CARD_BACKGROUND,
		borderRadius: 8,
		boxShadow: this.themeProps.LANDING_PAGE_CARD_SHADOW,
		marginTop: () => isDesktopSmall() ? 36 : 90,
		display: "flex",
		flexDirection: () => isDesktopSmall() ? "row" : "column",
	};

	@styleRule
	imageContainer = {
		height: () => isDesktopSmall() ? 120 : 225,
		width: () => isDesktopSmall() ? 120 : 225,
		border: 0,
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: () => isDesktopSmall() ? 8 : 0,
		borderTopRightRadius: () => isDesktopSmall() ? 0 : 8,
		objectFit: "cover",
	};

	@styleRule
	bodyContainer = {
		padding: () => isDesktopSmall() ? "12px 18px" : "20px 18px",
		flex: 1,
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "column",
	};

	@styleRule
	name = {
		fontSize: () => isDesktopSmall() ? 16 : 20,
		color: this.themeProps.LANDING_PAGE_TEAM_NAME_FONT_COLOR,
	};

	@styleRule
	position = {
		paddingTop: 6,
		fontSize: () => isDesktopSmall() ? 12 : 16,
		lineHeight: () => isDesktopSmall() ? 15 : 23,
		color: this.themeProps.LANDING_PAGE_TEAM_NAME_FONT_COLOR,
		fontWeight: "lighter",
	};

	@styleRule
	locationContainer = {
		paddingTop: 16,
		fontSize: 14,
		display: "flex",
		fontWeight: "lighter",
		alignItems: () => isDesktopSmall() ? "flex-end" : "",
	};

	@styleRule
	locationIcon = {
		paddingRight: 10,
		marginTop: () => isDesktopSmall() ? 4 : 2,
		alignSelf: "flex-start",
	};

	@styleRule
	extraPadding = {
		paddingTop: 4,
	};
}

@registerStyle(TeamCardStyle)
export class TeamCard extends UI.Element {
	extraNodeAttributes(attr) {
		super.extraNodeAttributes(attr);
		attr.addClass(this.styleSheet.teamCard);
	}

	render() {
		const {styleSheet} = this;
		const {imageURL, name, position, locations} = this.options;

		return [
			<Image src={imageURL} className={styleSheet.imageContainer}>
			</Image>,
			<div className={styleSheet.bodyContainer}>
				<div>
					<div className={styleSheet.name}>
						{name}
					</div>
					<div className={styleSheet.position}>
						{position}
					</div>
				</div>
				<div className={styleSheet.locationContainer}>
					<LocationIcon size={10} className={styleSheet.locationIcon} />
					<div>
						{
							locations.map((location, index) => {
								const extraArgs = {
									className: index !== 0 ? styleSheet.extraPadding : null
								};
								return <div {...extraArgs}>
									{location}
								</div>;
							})
						}
					</div>
				</div>
			</div>,
		];
	}
}
