import {UI} from "../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../stem-core/src/ui/Style";
import {styleRule} from "../../../stem-core/src/decorators/Style";
import {Device} from "../../../stem-core/src/base/Device";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Image} from "../../../stem-core/src/ui/primitives/Image.jsx";
import {RawHTML} from "../../../stem-core/src/ui/RawHTML.jsx";
import {isDesktopSmall} from "../../Utils";
import {IphoneFrame} from "../../SVGElements";

class WalletTextImageSectionStyle extends StyleSheet {
    @styleRule
    section = {
        display: "flex",
        paddingBottom: () => (isDesktopSmall() ? 0 : 90),
        flexDirection: () => (isDesktopSmall() ? "column-reverse" : ""),
        alignItems: "center",
    };

    @styleRule
    imageContainer = {
        flex: 0.93,
        textAlign: "center",
        position: "relative",
    };

    media = {
        maxWidth: "100%",
        maxHeight: 600,
    };

    @styleRule
    image = {
        ...this.media,
    };

    @styleRule
    video = {
        ...this.media,
        width: () => (Device.isMobileDevice() ? "calc(100% - 7%)" : "100%"),
        bottom: () => (Device.isMobileDevice() ? "2%" : 14),
        borderRadius: "0 0 7% 7%",
        position: "absolute",
        zIndex: -1,
        transform: "translateX(-50%)",
    };

    @styleRule
    textContainer = {
        flex: 1,
        paddingTop: () => (Device.isMobileDevice() ? 36 : 20),
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 24 : 36),
        marginBottom: () => (Device.isMobileDevice() ? 24 : 36),
        textAlign: () => (Device.isMobileDevice() ? "center" : ""),
    };

    @styleRule
    paragraph = {
        fontSize: () => (Device.isMobileDevice() ? 16 : 21),
        lineHeight: () => (Device.isMobileDevice() ? 24 : 33),
        marginBottom: () => (Device.isMobileDevice() ? 24 : 33),
        fontWeight: "lighter",
    };

    @styleRule
    leftContent = {
        paddingLeft: () => (isDesktopSmall() ? "" : 40),
    };

    @styleRule
    iphoneVideoContainer = {
        margin: "auto",
        width: 364,
        maxWidth: "100%",
        position: "relative",
    }
}

@registerStyle(WalletTextImageSectionStyle)
export class WalletTextImageSection extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            mediaUrl: null,
            title: null,
            description: null,
            imagePositionLeft: false,
            isVideo: false,
            hasShadow: false,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);

        attr.addClass(this.styleSheet.section);
        if (this.options.imagePositionLeft && !isDesktopSmall()) {
            attr.setStyle({flexDirection: "row-reverse"});
        }
    }

    getVideo() {
        const {mediaUrl} = this.options;
        const mediaUrls = Array.isArray(this.options.mediaUrl) ? mediaUrl : [mediaUrl];
        const sourceHtml = mediaUrls.map(({url, type}) => `<source src="${url}" type="${type}">`).join("");

        return (
            <RawHTML
                innerHTML={`<video autoplay="" loop="" muted="" playsinline="" data-object-fit="cover"
                class="${this.styleSheet.video}">${sourceHtml}</video>`}
            />
        );
    }

    render() {
        const {styleSheet} = this;
        const {mediaUrl, title, description, imagePositionLeft, isVideo} = this.options;

        return [
            <div className={styleSheet.imageContainer + (imagePositionLeft ? styleSheet.leftContent : "")}>
                {isVideo ? (
                    <div className={styleSheet.iphoneVideoContainer}>
                        <IphoneFrame />
                        {this.getVideo()}
                    </div>
                ) : (
                    <Image src={mediaUrl} className={styleSheet.image} />
                )}
            </div>,
            <div className={styleSheet.textContainer + (imagePositionLeft ? "" : styleSheet.leftContent)}>
                <div className={styleSheet.title}>{title}</div>
                {description.map(paragraph => (
                    <div className={styleSheet.paragraph}>{paragraph}</div>
                ))}
            </div>,
        ];
    }
}
