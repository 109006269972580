// TODO @cleanup just use from Stem utils
import {WIDGET_KEYBOARD_TOGGLED_THRESHOLD} from "./widget/WidgetConstants";
import {isFirefox, isSafari} from "../stem-core/src/base/Utils.js";

export * from "./UtilsLib"; // TODO: functions from UtilsLib should be imported directly from there.

export function isIframe() {
    return window.parent && window.parent !== window;
}

export function normalize(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function isStorageDisabled() {
    try {
        window.localStorage;
        return false;
    } catch (e) {
        return true;
    }
}

export function isThirdPartyStorageBlocked() {
    return isStorageDisabled();
}

export function isFacebookBrowser() {
    const {userAgent} = navigator;

    return userAgent.indexOf("FBAN") > -1 || userAgent.indexOf("FBAV") > -1;
}

export function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
        return "n/a";
    }
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) {
        return bytes + " " + sizes[i];
    }
    let amount = (bytes / Math.pow(1024, i)).toFixed(1);
    let unit = sizes[i];
    if (!(amount && unit)) {
        return "-";
    }
    return amount + " " + unit;
}

export function closeWindow() {
    try {
        if (window.opener) {
            window.opener.focus();
        }
    } finally {
        window.close();
    }
}

export function isScrolledInViewport(node, percent = 1) {
    if (!node) {
        return false;
    }

    let {top, height} = node.getBoundingClientRect();
    if (!height) {
        return false;
    }
    let windowHeight = window.innerHeight;

    if (window.visualViewport) {
        top -= window.visualViewport.offsetTop;
        windowHeight = window.visualViewport.height;
    }

    return (windowHeight - top) / windowHeight >= percent;
}

export function getOpenerOrigin() {
    // Accessing an opener origin that differs from the current one throws error.
    try {
        return (window.opener === window) ? null : window.opener.origin;
    } catch (e) {
        return null;
    }
}

export function isNetworkError(error) {
    return error && error.detail === "Network error";
}

export function isKeyboardExpanded() {
    return window.innerHeight < WIDGET_KEYBOARD_TOGGLED_THRESHOLD && document.activeElement !== document.body;
}
