import {UI} from "../../../../stem-core/src/ui/UIBase.js";

import {LoadingSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {authService} from "../../../../client/connection/services/AuthService.js";
import {closeWindow, getQueryParam} from "../../../Utils.js";
import {AuthHelper, useRedirectsInsteadOfTabs} from "../../../AuthHelper.js";


export class SetCookiePage extends UI.Element {
    render() {
        return <LoadingSpinner/>
    }

    onMount() {
        const token = getQueryParam("token");
        if (!token) {
            window.close();
            return;
        }
        authService.setAuthToken({
            token: {
                key: token.t,
                expiresAt: token.e,
            },
        });
        // Keeping legacy logic: always honor a redirectUrl, and if there is a merchantRedirectUrl, only redirect on Facebook browser
        // TODO possibly reconsider this logic
        const redirectUrl = getQueryParam("redirectUrl") ||
            (useRedirectsInsteadOfTabs() && getQueryParam("merchantRedirectUrl"));

        if (redirectUrl) {
            AuthHelper.redirectToUrl(redirectUrl);
        } else {
            closeWindow();
        }
    }
}
