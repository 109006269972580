import {StoreObject, Store} from "../../stem-core/src/state/Store";
import {StemDate} from "../../stem-core/src/time/Date";
import {apiClient} from "../connection/BlinkApiClient.js";
import {capitalize} from "../../blinkpay/Utils";
import {field} from "../../stem-core/src/state/StoreField.js";
import {PaymentProcessorStore} from "./PaymentProcessorStore";
import {iFrameMerchantService} from "../../blinkpay/services/IframeMerchantService.js"; // For dependency


export class PaymentMethod extends StoreObject {
    @field("PaymentProcessor") paymentProcessor;
    @field(Date) createdAt;
    @field(Date) deletedAt;
    @field(Boolean) isPrimary;
    @field(Object) details;
    @field(String) scope; // TODO Use enum

    getLastDigits() {
        const {last4} = this.details;
        return last4;
    }

    getBrand() {
        let brand = this.details.brand || "Card";
        if (brand.toLowerCase() === "american express") {
            // American Express is too long in string messages and breaks some UI elements
            brand = "AmEx";
        }
        brand = capitalize(brand);
        return brand;
    }

    getExpiryDate() {
        const {expMonth, expYear} = this.details;
        if (!expMonth || !expYear) {
            return null;
        }
        return new StemDate(expMonth + "/01/" + expYear).getEndOfMonth();
    }

    // The list of payment processors should be the ones that are available for this merchant
    isAvailable(paymentProcessors, merchant = iFrameMerchantService.getMerchant()) {
        if (iFrameMerchantService.isCDSPaymentMethod()) {
            return false;
        }
        if (merchant) {
            const ownPaymentProcessor = this.paymentProcessor;
            if (!ownPaymentProcessor.canServiceMerchant(merchant)) {
                return false;
            }
            for (const otherPaymentProcessor of paymentProcessors) {
                if (ownPaymentProcessor.provider === otherPaymentProcessor.provider
                    && ownPaymentProcessor.isBlinkGlobal()
                    && !otherPaymentProcessor.isBlinkGlobal()) {
                    return false;
                }
            }
        }
        return !this.deletedAt && this.scope === PaymentMethodStore.Scope.PUBLIC;
    }
}

class PaymentMethodStoreClass extends Store("PaymentMethod", PaymentMethod) {
    // TODO @cleanup make this enum
    Scope = {
        PUBLIC: "public",
        PRIVATE: "private",
    };

    getPaymentMethodsForRecurringPayment(recurringPayment) {
        // TODO @payments: This is wrong. What if recurringPayment is a DONATION and the payment
        //  method was used for a SUBSCRIPTION with the same ID?
        return this.all().filter(paymentMethod => {
            return !paymentMethod.deletedAt && paymentMethod.scope === PaymentMethodStore.Scope.PRIVATE
            && (paymentMethod.scopeResourceId === recurringPayment.id || paymentMethod.scopeResourceId == null)
        });
    }

    getPrimary() {
        return this.getAvailablePaymentMethods().find(paymentMethod => paymentMethod.isPrimary);
    }

    getAvailablePaymentMethods(merchant = iFrameMerchantService.getMerchant()) {
        const paymentProcessors = PaymentProcessorStore.filter(paymentProcessor => !merchant || paymentProcessor.canServiceMerchant(merchant));
        const paymentMethods = this.filter(paymentMethod => paymentMethod.isAvailable(paymentProcessors, merchant));

        // First the primary ones, then the rest
        return paymentMethods.sort((a, b) => {
            if (a.isPrimary === b.isPrimary) {
                return 0;
            }
            return a.isPrimary ? -1 : 1;
        });
    }

    getDefault() {
        const availablePaymentMethods = this.getAvailablePaymentMethods();
        return availablePaymentMethods.find(paymentMethod => paymentMethod.isPrimary) || availablePaymentMethods[0]
    }

    applyDeleteEvent(event) {
        const deletedObj = this.getObjectForEvent(event);
        if (!deletedObj) {
            return;
        }
        if (!deletedObj.deletedAt) {
            deletedObj.deletedAt = StemDate.now();
        }
        deletedObj.dispatch("delete", event, deletedObj);
        this.dispatch("delete", deletedObj, event);
    }
}

export const PaymentMethodStore = new PaymentMethodStoreClass();

export async function apiAddPaymentMethodToken(token, scope, paymentProcessor) {
    const response = await apiClient.post("/payment_methods/add_payment_method", {
        tokenId: token.id,
        scope,
        rawToken: JSON.stringify(token),
        paymentProcessorId: paymentProcessor.id,
    });
    return PaymentMethodStore.loadObject(response);
}

export async function apiSetPrimaryPaymentMethod(paymentMethodId) {
    const response = await apiClient.post("/payment_methods/set_primary_payment_method", {paymentMethodId});
    return PaymentMethodStore.loadObject(response);
}

export async function apiDeletePaymentMethod(paymentMethodId) {
    return apiClient.post("/payment_methods/delete_payment_method", {paymentMethodId});
}
