import {StoreObject, MakeStore} from "../../stem-core/src/state/Store";
import {SubscriptionOfferStore} from "./SubscriptionOfferStore";
import {field} from "../../stem-core/src/state/StoreField.js";
import {apiClient} from "../connection/BlinkApiClient.js";
import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";

@makeEnum
export class CoverageChangeType extends BaseEnum {
    static UPGRADE;
    static DOWNGRADE;
    static EQUIVALENT;
    static OTHER;

    // For downgrades there's no reason to apply the conversion now, since you're not getting any money back
    canAllowInstantConversion() {
        return this === this.constructor.UPGRADE || this === this.constructor.OTHER;
    }
}

export class SubscriptionCoverage extends StoreObject {
    @field("Merchant") merchant;
    @field(String) name;
    @field(Object) description; // An array of description items
    @field(Boolean) requiresAddress;
    @field(Boolean) addressRequiresPostalCode;
    @field(Boolean) addressRequiresPhoneNumber;
    @field(Boolean) billableProRata;
    @field(String) blinkCoverage;
    @field(String) extraCoverage;
    @field("SubscriptionCoverage") includesCoverage;
    @field(Array) benefitIds;

    getName() {
        return this.name;
    }

    getDescription() {
        return this.description;
    }

    getAvailableOffers() {
        return SubscriptionOfferStore.filter(offer => offer.coverageId === this.id && offer.isAvailable());
    }

    // TODO @branch rename to isActive?
    isAvailable() {
        return !this.deletedAt && this.getAvailableOffers().length;
    }

    toString() {
        return this.getName();
    }
}

export const SubscriptionCoverageStore = MakeStore("subscriptionCoverage", SubscriptionCoverage);

export async function apiMerchantCreateSubscriptionCoverage(request) {
    const response = await apiClient.post("/merchant/create_subscription_coverage", request);
    return SubscriptionCoverageStore.loadObject(response);
}

export async function apiMerchantEditSubscriptionCoverage(request) {
    return apiClient.post("/merchant/edit_subscription_coverage", request, {allowNulls: true});
}
