import {Store} from "../../../stem-core/src/state/Store";
import {MerchantSDKSettingsStore} from "../merchant/MerchantSDKSettingsStore";

/* TODO @branch @cleanup Remove this in the future.
    this is required because the ws server does not guarantee the order of
    subscription status updates - ACTIVE status may be received before
    PENDING
*/
export const PaymentStatusDependentStore = (...args) =>
    class PaymestStatusStore extends Store(...args) {
        importState(objects) {
            objects = objects || [];
            for (const obj of objects) {
                const subscription = this.get(obj.id);
                if (!this.shouldSkipUpdate(subscription, obj)) {
                    super.importState([obj]);
                }
            }
        }

        applyEvent(event) {
            const obj = this.getObjectForEvent(event);

            if (this.shouldSkipUpdate(obj, event.data)) {
                event.data = obj;
            }
            return super.applyEvent(event);
        }

        shouldSkipUpdate(obj, updateObj) {
            return !updateObj;
        }
    };


// Since multiple objects might have been created on a version fork, we'll find the one we edited by the parent id
export function loadVersionedObjectFromResponse(store, response, expectedParentId) {
    const objects = store.load(response);
    if (objects.length <= 1) {
        return objects[0];
    }
    // We forked the published version of merchant settings with this request
    const sdkSettingsVersion = MerchantSDKSettingsStore.loadObject(response);
    // Find the object that is part of the forked settings and has the expected
    // version parent.
    return objects.find(obj => obj.settingsVersion === sdkSettingsVersion && obj.parentId == expectedParentId);
}
