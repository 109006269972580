import {UI, styleRule, registerStyle, StyleSheet} from "../../../../stem-core/src/ui/UI.js";

import {authService} from "../../../../client/connection/services/AuthService.js";
import {PaymentMethodStore, UserPreferencesStore, UserProfileStore, UserAddressStore} from "../../../State.js";
import {Messages} from "../../../Messages.js";

import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {PersonalDetails} from "./sections/PersonalDetails.jsx";
import {MyAddresses} from "./sections/MyAddresses.jsx";
import {PaymentMethods} from "./sections/PaymentMethods.jsx";
import {UserSettings} from "./sections/UserSettings.jsx";
import {SecurityCard} from "./sections/SecurityCard.jsx";


class AccountPageStyle extends StyleSheet {
    @styleRule
    logout = {
        fontSize: 16,
        color: this.themeProps.LINK_COLOR,
        padding: "0.3em",
        opacity: 1,
        cursor: "pointer",
        textAlign: "center",
        width: "fit-content",
        margin: "0 auto",
        ":hover": {
            opacity: 0.8,
        },
    };
}

@registerStyle(AccountPageStyle)
export class AccountPage extends UI.Element {
    render() {
        console.assert(this.getUserProfile() && this.getUserPreferences() &&
            this.getUserPaymentMethods() && this.getUserAddresses());

        const userProfile = this.getUserProfile();

        return [
            <PersonalDetails testId="accountPersonalDetails"/>,
            <MyAddresses userAddresses={this.getUserAddresses()} testId="accountAddNewAddress"/>,
            <PaymentMethods userPaymentMethods={this.getUserPaymentMethods()} testId="accountPaymentMethods"/>,
            <UserSettings userPreferences={this.getUserPreferences()} testId="accountUserSettings"/>,
            <SecurityCard userProfile={userProfile} testId="accountSecuritySettings"/>,
            <div testId="accountLogoutButton" className={this.styleSheet.logout} onClick={() => this.handleLogout()}>
                {Messages.logOut}
            </div>,
        ];
    }

    getUserProfile() {
        return UserProfileStore.getUserProfile();
    }

    getUserPreferences() {
        return UserPreferencesStore.getPreferences();
    }

    getUserPaymentMethods() {
        return PaymentMethodStore.getAvailablePaymentMethods();
    }

    getUserAddresses() {
        return UserAddressStore.all();
    }

    @wrapInSpinner
    async handleLogout() {
        await authService.logout();
        window.location.href = "/";
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserProfileStore, () => this.redraw());
        this.attachChangeListener(UserPreferencesStore, () => this.redraw());
        this.attachChangeListener(PaymentMethodStore, () => this.redraw());
        this.attachChangeListener(UserAddressStore, () => this.redraw());
    }
}
