import {MakeStore, StoreObject} from "../../stem-core/src/state/Store.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {apiClient} from "../connection/BlinkApiClient.js";

class SubscriptionBenefit extends StoreObject {
    @field(String) name;
    @field(String) description;
    @field(String) alias;

    toString() {
        return this.name;
    }

    serializeForSDK() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            alias: this.alias,
        }
    }
}

export const SubscriptionBenefitStore = MakeStore("SubscriptionBenefit", SubscriptionBenefit);

export async function apiMerchantCreateSubscriptionBenefit(request) {
    const response = await apiClient.post("/merchant/create_subscription_benefit/", request);
    return SubscriptionBenefitStore.loadObject(response);
}

export async function apiMerchantEditSubscriptionBenefit(request) {
    return apiClient.post("/merchant/edit_subscription_benefit/", request);
}
