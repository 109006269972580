import {AjaxHandler} from "../../stem-core/src/base/Ajax";

export const RequestCache = {
    ajaxHandler: new AjaxHandler(null),
    cache: new Map(),

    async loadJSON(path) {
        return this.load(path, "json");
    },

    // A promise resolves to the same value if re-evaluated
    async load(path, dataType) {
        let resource = this.cache.get(path);
        if (!resource) {
            resource = this.ajaxHandler.fetch(path, {dataType, data: {}, method: "GET"});
            this.cache.set(path, resource);
        }

        return resource;
    }
}
