import {registerStyle, styleRule, UI} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {PaymentStore} from "../../../../State.js";
import {BaseCard, BaseCardStyle, UIPaginationManager} from "../../../../ui/BaseCard.jsx";
import {PaymentDescription} from "../../../../common/PaymentDescription.jsx";
import {Link} from "../../../../../stem-core/src/ui/UIPrimitives.jsx";
import {Dispatcher} from "../../../../../stem-core/src/base/Dispatcher.js";
import {EndpointPaginator} from "../../../../../client/state/EndpointPaginator.js";
import {autoredraw} from "../../../../../stem-core/src/decorators/AutoRedraw.js";
import {Table} from "../../../../../stem-core/src/ui/table/Table.jsx";
import {RealTableStyle} from "../../../components/BaseRecurringPaymentList.jsx";


export class SubscriptionPaymentsStyle extends BaseCardStyle {
    @styleRule
    paymentTitle = {
        fontSize: 14,
        fontWeight: "initial",
    };
}

@registerStyle(SubscriptionPaymentsStyle)
@autoredraw(PaymentStore)
export class SubscriptionPayments extends BaseCard {
    paginator = new EndpointPaginator(
        PaymentStore,
        PaymentStore.API_LIST_ENDPOINT,
        {resourceTypes: [PaymentStore.ResourceType.SUBSCRIPTION]},
        {resourceType: PaymentStore.ResourceType.SUBSCRIPTION},
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.subscriptionPayments,
            description: () => (this.getPayments().length || !this.paginator.lastPageLoaded) ? "" : Messages.emptySubscriptionPayments,
        };
    }

    getPayments() {
        // TODO @Mihai seems to be a lot of sorting
        return this.paginator.all().sort((a, b) => b.createdAt - a.createdAt);
    }

    getPaymentDescription(payment) {
        const subscription = payment.getResource();
        return {title: subscription ? subscription.getMerchantTitle() : "",};
    }

    getDescriptionColumnValue(payment) {
        const {styleSheet} = this;
        return <PaymentDescription payment={payment}>
            <Link className={styleSheet.paymentTitle}
                  onClick={() => Dispatcher.Global.dispatch("highlightEntry", payment.getResource())}>
                {this.getPaymentDescription(payment)?.title}
            </Link>
        </PaymentDescription>;
    }

    render() {
        const payments = this.getPayments();

        if (!payments.length) {
            return [];
        }

        const columns = [
            {
                headerName: Messages.description,
                value: payment => this.getDescriptionColumnValue(payment),
                headerStyle: {
                    textAlign: "left",
                    width: "50%",
                }
            },
            {
                headerName: Messages.amount,
                value: payment => `${payment.status === PaymentStore.Status.REVERTED ? "-" : ""}${payment.getDebitedAmount()}`,
                headerStyle: {
                    textAlign: "right",
                    width: "20%",
                },
                cellStyle: {
                    textAlign: "right"
                }
            },
            {
                headerName: Messages.date,
                value: payment => payment.createdAt,
                headerStyle: {
                    textAlign: "right",
                    width: "30%",
                },
                cellStyle: {
                    textAlign: "right",
                    color: this.themeProps.MERCHANT_2,
                }
            },
        ];

        return <Table
            styleSheet={RealTableStyle}
            columns={columns}
            entries={payments}
        />
    }

    onMount() {
        super.onMount();
        this.paginationHandler.attach();
    }
}
