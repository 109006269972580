import {registerStyle, styleRule, UI} from "../../../../../stem-core/src/ui/UI.js";
import {SubscriptionStore, RecurringPaymentStoreObject} from "../../../../State.js";
import {Messages} from "../../../../Messages.js";
import {BaseRecurringPaymentList, BaseRecurringPaymentStyle} from "../../../components/BaseRecurringPaymentList.jsx";
import {CancelSubscriptionModal} from "../components/CancelSubscriptionModal.jsx";
import {ReactivateSubscriptionModal} from "../components/ReactivateSubscriptionModal.jsx";
import {ChangeSubscriptionAddressModal} from "../components/ChangeSubscriptionAddressModal.jsx";
import {PaymentMethodDescription} from "../../../../common/PaymentMethodDescription.jsx";
import {ChoosePaymentMethodModal} from "../../../components/ChoosePaymentMethodModal.jsx";
import {ExclamationTriangleIcon} from "../../../../../core/ui/SVGElements.jsx";
import {BlinkButton} from "../../../../../core/ui/Button.jsx";
import {AddCardModal} from "../../wallet/components/cards/AddCardModal.jsx";
import {getPaymentMethodEmptySubscriptionMessage} from "../../../../common/MessagingUtils.js";
import {LinkButton} from "../../../../../core/ui/LinkButton.jsx";


class ActiveSubscriptionsStyle extends BaseRecurringPaymentStyle {
    @styleRule
    changeButton = {
        marginRight: 20,
        display: "inline-block",
    };

    @styleRule
    flexContainer = {
        display: "flex",
        justifyContent: "space-between",
    };
}

@registerStyle(ActiveSubscriptionsStyle)
export class ActiveSubscriptions extends BaseRecurringPaymentList {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.activeSubscriptions,
            description: () => this.getEntries().length ? "" : Messages.emptyActiveSubscriptions,
            expandedRows: true,
        };
    }

    getEntries() {
        return [...SubscriptionStore.getActive(), ...SubscriptionStore.getUnpaid()];
    }

    getExpandedRowContent(entry) {
        const {styleSheet} = this;
        const contentRows = [];
        const shippingAddress = entry.getShippingAddress();
        if (shippingAddress) {
            contentRows.push(this.makeRowEntry(
                Messages.shippingAddress,
                <div className={styleSheet.flexContainer}>
                    <div style={{overflow: "hidden", textOverflow: "ellipsis", flex: 1}}>
                        {shippingAddress}
                    </div>
                    <LinkButton
                        testId="changeAddress"
                        className={styleSheet.changeButton}
                        style={{position: "initial"}}
                        onClick={() => ChangeSubscriptionAddressModal.show({subscription: entry})}
                    >
                        {Messages.change}
                    </LinkButton>
                </div>
            ));
        }
        if (entry.status !== SubscriptionStore.Status.CANCELED) {
            const paymentMethod = entry.getPaymentMethod();
            contentRows.push(this.makeRowEntry(
                Messages.paymentMethod,
                <div className={styleSheet.flexContainer}>
                    {
                        paymentMethod ? <PaymentMethodDescription paymentMethod={paymentMethod}/> :
                            <div>{getPaymentMethodEmptySubscriptionMessage(entry)}</div>
                    }
                    <LinkButton
                        testId="changeCard"
                        className={styleSheet.changeButton}
                        onClick={() => paymentMethod ? ChoosePaymentMethodModal.show({entry}) : AddCardModal.show()}
                    >
                        {paymentMethod ? Messages.change : Messages.addCard}
                    </LinkButton>
                </div>
            ));
        }
        if (entry.isUnpaid()) {
            contentRows.push(this.makeRowEntry(
                Messages.lastPayment,
                [
                    <div className={this.styleSheet.failedPayment}>
                        <ExclamationTriangleIcon
                            fill={this.themeProps.ERROR_COLOR}
                            size={20}
                            className={this.styleSheet.exclamationIcon}
                        />
                        <span>{Messages.subscribePaymentFailed}</span>
                    </div>,
                    <div className={styleSheet.center}>
                        <BlinkButton
                            label={Messages.retryPayment}
                            className={styleSheet.retryPaymentButton}
                            onClick={() => this.retryPayment(entry)}
                        />
                    </div>
                ]
            ));
        } else {
            contentRows.push(this.makeRowEntry(
                Messages.lastPayment,
                this.renderLastPayment(entry),
            ));
        }

        const subscriptionButton =
            entry.status === RecurringPaymentStoreObject.Status.ACTIVE ?
                <div className={styleSheet.button + styleSheet.buttonCancel}
                     onClick={() => CancelSubscriptionModal.show({subscription: entry})}>
                    {Messages.cancelSubscription}
                </div> : (entry.status === RecurringPaymentStoreObject.Status.CANCELED &&
                    <div className={styleSheet.button + styleSheet.buttonRenew}
                         onClick={() => ReactivateSubscriptionModal.show({subscription: entry})}>
                        {Messages.resubscribe}
                    </div>);

        if (subscriptionButton) {
            contentRows.push(this.makeRowEntry(
                null,
                subscriptionButton,
            ));
        }

        return <div style={{paddingLeft: 30}}>{contentRows}</div>;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            value: entry => `${entry.getMerchantTitle()} - ${entry.coverage.name}`,
            headerStyle: {
                textAlign: "left",
                width: "50%"
            },
            cellStyle: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }
        }, {
            headerName: Messages.amount,
            value: entry => entry.formatPriceShortened(),
            headerStyle: {
                textAlign: "right",
                width: "20%",
            },
            cellStyle: {
                textAlign: "right"
            }
        }, {
            headerName: Messages.nextPayment,
            value: entry => this.renderLastPayment(entry),
            headerStyle: {
                textAlign: "right",
                width: "30%",
            },
            cellStyle: {
                textAlign: "right",
                color: this.themeProps.MERCHANT_2,
            }
        }];
    }
}
