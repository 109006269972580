import {UI} from "../../stem-core/src/ui/UIBase.js";
import {styleRule, styleRuleInherit, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {DashboardAnchoredPopup, DashboardAnchoredPopupStyle} from "../ui/input/PopupInput";
import {InfoIcon} from "../../core/ui/SVGElements.jsx";

export class InfoElementStyle extends StyleSheet {
    @styleRule
    container = {
        display: "inline-block",
        cursor: "help",
        lineHeight: 0,
        fontSize: 0,
        verticalAlign: "text-bottom",
    }
}

class InfoPopupStyle extends DashboardAnchoredPopupStyle {
    @styleRuleInherit
    popup = {
        fontSize: 15,
        pointerEvents: "none",
    }
}

@registerStyle(InfoPopupStyle)
export class InfoContentPopup extends DashboardAnchoredPopup {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            toggleOnSameAnchor: true,
            hideOnMouseLeave: true,
        };
    }
}

@registerStyle(InfoElementStyle)
class InfoElement extends UI.Primitive("span") {
    render() {
        return <InfoIcon size={21}/>;
    }

    showPopup() {
        const {content} = this.options;
        InfoContentPopup.show({
            anchor: this,
            content,
            theme: this.getTheme(),
        });
    }

    onMount() {
        for (const eventType of ["mouseenter", "tap"]) {
            this.addNodeListener(eventType, () => this.showPopup());
        }
    }

    static optionally(content) {
        const Class = this;
        return content ? <Class content={content} /> : null;
    }
}

// TODO @Mihai see why this fails if content is a UIElement
export function MakeInfoElement(content) {
    return InfoElement.optionally(content);
}
