import {UI, registerStyle, styleRule, StyleSheet} from "../../../stem-core/src/ui/UI.js";

import {Messages} from "../../Messages.js";
import {PopupMenu} from "../../ui/PopupMenu.jsx";
import {SubscriptionStore} from "../../../client/state/SubscriptionStore.js";
import {DonationStore} from "../../../client/state/DonationStore.js";
import {apiSetPrimaryPaymentMethod} from "../../../client/state/PaymentMethodStore.js";
import {Toast} from "../../ui/Toast.jsx";
import {unwrapArray} from "../../../stem-core/src/base/Utils.js";
import {PaymentMethodDescription} from "../PaymentMethodDescription.jsx";


class PaymentMethodInlineMenuStyle extends StyleSheet {
    @styleRule
    container = {
        position: "relative",
        padding: "10px 0",
        fontSize: 15,
        display: "flex",
        alignItems: "center",
        color: this.themeProps.MERCHANT_4,
    };

    @styleRule
    cardDetails = {
        marginRight: 6,
    };
}


// TODO @flow this should be moved into core
@registerStyle(PaymentMethodInlineMenuStyle)
export class PaymentMethodInlineMenu extends UI.Element {
    getDefaultOptions() {
        return {
            paymentMethod: null,
            handleDelete: null,
        };
    }

    removeAction() {
        const {paymentMethod, handleDelete} = this.options;
        const entries = [
            ...DonationStore.getActiveRecurring().filter(donation => !donation.isCanceled()),
            ...SubscriptionStore.getActiveAndNotCanceled()
        ].filter(recurringPayment => recurringPayment.getPaymentMethod() === paymentMethod);

        if (entries.length) {
            // If there's an active recurring payment using this
            Toast.showError(Messages.paymentMethodInUseError);
        } else {
            handleDelete(paymentMethod);
        }
    }

    async makeDefault() {
        const {paymentMethod} = this.options;
        await apiSetPrimaryPaymentMethod(paymentMethod.id);
        Toast.show(Messages.changesSaved);
    }

    getMenuItems() {
        const {paymentMethod} = this.options;

        return unwrapArray([{
                content: Messages.removeCard,
                onClick: () => this.removeAction(),
            },
            !paymentMethod.isPrimary && {
                content: Messages.makeDefault,
                onClick: () => this.makeDefault(),
            }]);
    }

    render() {
        const {paymentMethod} = this.options;

        return [
            <PaymentMethodDescription
                paymentMethod={paymentMethod}
                showIfDefault
                showExpiryDate
            />,
            <PopupMenu items={this.getMenuItems()}/>
        ];
    }
}
