import {UI, registerStyle, styleRuleInherit} from "../../../../../stem-core/src/ui/UI.js";
import {Messages} from "../../../../Messages.js";

import {Toast} from "../../../../ui/Toast.jsx";
import {BlinkInput} from "../../../../ui/Input.jsx";
import {EditableBaseCard, EditableBaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {EmailChangeConfirmationModal} from "../components/EmailChangeConfirmationModal.jsx";
import {isSmallScreen} from "../../../../UtilsLib.js";
import {userService} from "../../../../../client/connection/services/UserService.js";
import {BlinkInputField} from "../../../../../dashboard/common/Input.jsx";

class PersonalDetailsStyle extends EditableBaseCardStyle {
    @styleRuleInherit
    cardFooter = {
        "> *": {
            marginTop: 36,
        },
    };

    @styleRuleInherit
    cardHeader = {
        "> *": {
            marginBottom: 18,
        }
    };
}

@registerStyle(PersonalDetailsStyle)
export class PersonalDetails extends EditableBaseCard {
    insertedName = ""; // TODO @cleanup this should be deleted
    insertedEmail = "";

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.personalDetails,
        };
    }

    setOptions(options) {
        super.setOptions(options);
        const {fullName, email} = this.getUserProfileData();
        this.insertedName = this.insertedName || fullName;
        this.insertedEmail = this.insertedEmail || email;
    }

    render() {
        const {fullName, email} = this.getUserProfileData();

        const onInputChange = () => {
            this.insertedName = this.nameInput.getValue().trim();
            this.insertedEmail = this.emailInput.getValue().trim();
            this.setConfirmButtonDisabled(!this.canUpdateUserDetails());
        }

        return this.isEditingEnabled() ? [
            <BlinkInput
                ref="nameInput"
                label={Messages.name}
                initialValue={fullName}
                inputAttributes={{testId: "accountNameInput"}}
                onChange={onInputChange}
                onBlur={() => this.handleInputBlur()}
                onEnter={() => this.updateUserDetails()}
            />,

            <BlinkInput
                ref="emailInput"
                label={Messages.email}
                initialValue={email}
                inputAttributes={{type: "email", testId: "accountEmailInput"}}
                onChange={onInputChange}
                onBlur={() => this.handleInputBlur()}
                onEnter={() => this.updateUserDetails()}
            />
        ] : [
            <div>
                <BlinkInputField label={Messages.name}>
                    {fullName}
                </BlinkInputField>
            </div>,

            <div>
                <BlinkInputField label={Messages.email}>
                    {email}
                </BlinkInputField>
            </div>
        ];
    }

    handleConfirmButtonClick() {
        this.updateUserDetails();
    }

    executeEditToggleActions() {
        const {fullName, email} = this.getUserProfileData();
        if (!this.editing) {
            this.insertedName = "";
            this.insertedEmail = "";
        } else {
            this.insertedName = fullName;
            this.insertedEmail = email;
        }
        this.setConfirmButtonDisabled(!this.canUpdateUserDetails());
    }

    handleInputBlur() {
        if (!isSmallScreen()) {
            return;
        }
        window.scrollTo(0, 0)
    }

    getUserProfileData() {
        return {
            fullName: userService.getUserName(),
            email: userService.getUserEmail()
        };
    }

    canUpdateUserDetails() {
        return this.canUpdateName() || this.canUpdateEmail();
    }

    canUpdateEmail() {
        const currentEmail = this.getUserProfileData().email;
        const newEmail = this.insertedEmail.trim();

        return newEmail.length > 0 && newEmail !== currentEmail;
    }

    canUpdateName() {
        const currentName = this.getUserProfileData().fullName;
        const newName = this.insertedName.trim();

        return newName.length > 0 && newName !== currentName;
    }

    async updateUserDetails() {
        const requestedEmailChange = await userService.requestUpdateUserData(this.insertedName, this.insertedEmail);
        if (requestedEmailChange) {
            EmailChangeConfirmationModal.show({
                userProfile: userService.getUser(),
                newEmail: this.insertedEmail,
                onConfirmSuccess: () => {
                    this.toggleEditing();
                    Toast.show(Messages.emailSuccessfullyChanged);
                },
            });
        } else {
            this.toggleEditing();
        }
    }
}
