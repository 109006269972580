import {UI, keyframesRule, styleRule, StyleSheet, registerStyle, Theme} from "../../stem-core/src/ui/UI.js";
import {Router} from "../../stem-core/src/ui/Router.jsx";
import {SVG} from "../../stem-core/src/ui/svg/SVGBase.js";
import "../../stem-core/src/ui/svg/SVGPrimitives.js";

import {BlinkLightningIcon} from "./SVGElements.jsx";
import {isIframe} from "../../blinkpay/Utils.js";


export class SpinnerAnimation extends StyleSheet {
    @keyframesRule
    dash = {
        "0%": {
            strokeDasharray: "10, 110",
            strokeDashoffset: "0",
        },
        "50%": {
            strokeDasharray: "90, 110",
            strokeDashoffset: "-60",
        },
        "100%": {
            strokeDasharray: "90, 110",
            strokeDashoffset: "-120",
        },
    };

    @keyframesRule
    rotate = {
        "100%": {
            transform: "rotate(360deg)",
        },
    };
}

export class SpinnerStyle extends StyleSheet {
    size = 46;

    @styleRule
    container = {
        display: "inline-block",
        position: "relative",
        margin: `-${this.size}px 0 0 -${this.size}px`,
        width: this.size * 2,
        height: this.size * 2,
        animation: `${SpinnerAnimation.getInstance().rotate.toString()} 1.6s linear infinite`,
        " circle": {
            fill: "none",
            strokeLinecap: "round",
            animation: `${SpinnerAnimation.getInstance().dash.toString()} 1.6s linear infinite`,
        },
    };
}

@registerStyle(SpinnerStyle)
export class Spinner extends SVG.SVGRoot {
     getDefaultOptions() {
        return {
            strokeWidth: 3,
            strokeColor: Theme.props.SPINNER_COLOR,
            radius: 20,
            viewBox: "0 0 50 50",
        }
    }

    render() {
        const {strokeWidth, strokeColor, radius} = this.options;
        return <SVG.Circle center={{x: 25, y: 25}} radius={radius} strokeWidth={strokeWidth} stroke={strokeColor} />
    }
}

export class BlinkLogoSpinnerStyle extends StyleSheet {
    @styleRule
    container = {
        position: "relative",
        width: "100%",
        height: 70,
        zIndex: 100,
        ">*": {
            left: "50%",
            top: "50%",
            position: "absolute",
        },
        ">:last-child": {
            transform: "translate(-50%,-50%)",
        },
        ">:last-child g": {
            fill: this.themeProps.LOADING_SPINNER_COLOR,
        },
    };
}


@registerStyle(BlinkLogoSpinnerStyle)
export class BlinkLogoSpinner extends UI.Element {
    getDefaultOptions() {
        return {
            size: 25,
            strokeWidth: 2,
            strokeColor: Theme.props.LOADING_SPINNER_COLOR,
            radius: 15,
        };
    }

    render() {
        const {size, strokeColor, strokeWidth, radius} = this.options;
        return [
            <Spinner strokeWidth={strokeWidth} strokeColor={strokeColor} radius={radius} />,
            <BlinkLightningIcon size={size} color={strokeColor} />
        ];
    }
}


class LoadingSpinnerStyle extends StyleSheet {
    @styleRule
    container = {
        background: this.themeProps.LOADING_SPINNER_BACKGROUND,
        position: isIframe() ? "absolute" : "fixed",
        width: "100%",
        height: "100%",
        marginTop: 0,
        top: 0,
        left: 0,
        zIndex: 11000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        ">:last-child g": {
            fill: this.themeProps.LOADING_SPINNER_COLOR,
        },

        // These are for Safari border bugs during animations.
        "-webkit-backface-visibility": "hidden",
        "-moz-backface-visibility": "hidden",
        "-webkit-transform": "translate3d(0, 0, 0)",
        "-moz-transform": "translate3d(0, 0, 0)",
    };
}

// TODO: This is identical to the MerchantLoadingSpinner
@registerStyle(LoadingSpinnerStyle)
export class LoadingSpinner extends UI.Element {
    static instance = null;
    static counter = 0; // how deep we are in the show/hide bracket

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "loadingSpinner",
        };
    }

    static show() {
        if (LoadingSpinner.instance) {
            this.counter += 1; // Just bump it up
            return;
        }
        LoadingSpinner.instance = this.create(Router.Global?.node || document.body);
        this.counter = 1;
    }

    static hide(force = false) {
        this.counter -= 1;
        if (force || this.counter <= 0) {
            this.instance?.destroyNode();
            this.instance = null;
            this.counter = 0;
        }
    }

    // Wrap a function call with a spinner
    static async call(func) {
        try {
            this.show();
            return await func();
        } finally {
            this.hide();
        }
    }

    render() {
        return <BlinkLogoSpinner size={48} strokeWidth={3} radius={20}/>;
    }

    onMount() {
        super.onMount();
        if (Router.Global) {
            this.attachListener(Router.Global, "change", () => this.constructor.hide());
        }
    }
}


// Decorator for methods to be wrapped in a spinner
export function wrapInSpinner(target, keyName, descriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = async function () {
        const callback = () => originalMethod.apply(this, arguments);
        return LoadingSpinner.call(callback);
    }

    return descriptor;
}
