import {UI, registerStyle, StyleSheet, styleRule} from "../../../stem-core/src/ui/UI.js";
import {LinkButton} from "../../../core/ui/LinkButton.jsx";
import {Nav} from "./Nav.jsx";
import {PAGES} from "./Nav.jsx";
import {BlinkLogo} from "../../../core/ui/SVGElements.jsx";

class NavBarStyle extends StyleSheet {
    itemPadding = 18;

    @styleRule
    navBarContainer = {
        background: this.themeProps.NAVBAR_BACKGROUND + "!important",
        boxShadow: this.themeProps.NAVBAR_BOX_SHADOW,
        position: "fixed",
        display: "flex",
        justifyContent: "space-between",
        zIndex: 9999,
        transition: `box-shadow ${this.themeProps.DEFAULT_TRANSITION_DURATION_MS}ms`,
        width: "100vw",
        height: 72,
        padding: "0 24px",
    };

    @styleRule
    sideItemContainer = {
        height: "100%",
        width: 96,
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    centerItemsContainer = {
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    centerItem = {
        color: this.themeProps.NAVBAR_CENTER_ITEM_INACTIVE,
        padding: this.itemPadding,
        fontSize: 16,
        fontWeight: "normal",
        "-webkit-user-select": "none",
        ":hover": {
            color: this.themeProps.NAVBAR_CENTER_ITEM_ACTIVE,
        }
    };

    @styleRule
    centerItemActive = {
        color: this.themeProps.NAVBAR_CENTER_ITEM_ACTIVE,
    };

    @styleRule
    alignRight = {
        display: "flex",
        justifyContent: "flex-end",
    };

    @styleRule
    activeLine = {
        height: 2,
        opacity: this.themeProps.BASE_DISABLED_OPACITY,
        background: this.themeProps.NAVBAR_CENTER_ITEM_ACTIVE,
        position: "absolute",
        transform: "translateY(16px)",
        pointerEvents: "none",
    }
}

@registerStyle(NavBarStyle)
export class NavBar extends Nav {
    activeLineStyle = {};

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.navBarContainer);
    }

    render() {
        const {styleSheet} = this;
        const centerItems = PAGES.filter(page => !page.Icon);
        const iconItems = PAGES.filter(page => page.Icon);

        requestAnimationFrame(() => this.repositionUnderline());

        return [
            <div className={styleSheet.sideItemContainer}>
                <BlinkLogo size={16} color={this.themeProps.NAVBAR_CENTER_ITEM_ACTIVE}/>
            </div>,
            <div className={styleSheet.centerItemsContainer} ref="container">
                {centerItems.map(page => {
                    const active = page === this.options.currentActivePage;
                    return <LinkButton {...active ? {ref: "active"} : {}}
                        className={styleSheet.centerItem + (active ? styleSheet.centerItemActive : "")}
                        href={page.href}>
                        {page.title.toString()}
                    </LinkButton>;
                })}
                <div className={styleSheet.activeLine} style={this.activeLineStyle} ref="activeLine"/>
            </div>,
            <div className={styleSheet.sideItemContainer + styleSheet.alignRight}>
                {iconItems.map(page => {
                    const active = page === this.options.currentActivePage;
                    const {Icon, IconActive} = page;
                    return <LinkButton href={page.href}>{active ? <IconActive/> : <Icon/>}</LinkButton>;
                })}
            </div>,
        ];
    }

    repositionUnderline(skipTransition=false) {
        if (this.active) {
            const extraWidth = 4;
            const {itemPadding} = this.styleSheet;
            const {width, left} = this.active.node.getBoundingClientRect();
            this.activeLineStyle = {
                width: width - 2 * itemPadding + extraWidth * 2,
                left: left + itemPadding - extraWidth,
                transition: !skipTransition ? this.themeProps.DEFAULT_TRANSITION : "",
            };
            this.activeLine.setStyle(this.activeLineStyle);
        }
    }

    onMount() {
        super.onMount();
        this.attachEventListener(window, "resize", () => this.repositionUnderline(true));
    }
}
