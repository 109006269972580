import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {INLINE_GIFT_SUBSCRIPTION_URLS} from "../../PanelConstants.js";

export class GiftSubscriptionDetailsFlowStep extends BaseFlowStep {
    beneficiaryEmailAddress = "";
    name = "";
    message = "";

    showPanel() {
        Router.changeURL(INLINE_GIFT_SUBSCRIPTION_URLS.giftDetails);
    }
}