import {GenericObjectStore, StoreObject} from "../../../stem-core/src/state/Store.js";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../connection/BlinkApiClient.js";
import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {BlinkGlobal} from "../../../blinkpay/UtilsLib.js";
import {filterStoreByMerchantAndLooseIdArray} from "../misc/Filter.js";
import {Batcher} from "../../../stem-core/src/base/Batcher.js";
import {VisibilityStatus} from "../misc/GenericEnums.js";

@makeEnum
export class AudienceNewsletterFrequency extends BaseEnum {
    static DAILY;
    static WEEKLY;
    static MONTHLY;
    static CUSTOM;
}

class MerchantAudience extends StoreObject {
    @field("Merchant") merchant;
    @field(String) name;
    @field(VisibilityStatus) visibility;
    @field(Boolean) isPublic;
    @field(AudienceNewsletterFrequency) defaultFrequency;
    @field(Array) defaultFrequencyOffset;
    @field(Date) defaultSendTime;
    @field(Boolean) autosubscribeNewUsers;
    @field(Array) autosubscribeOnBenefitIds;

    toString() {
        return this.name;
    }

    getSize() {
        if (this.size >= 0) {
            // To protect from the -1 used as sentinel
            return this.size;
        }
        return null;
    }
}

class MerchantAudienceStoreClass extends GenericObjectStore {
    statsBatcher = new Batcher(async (audiences) => {
        const response = await apiMerchantGetAudienceStats({audienceIds: audiences.map(audience => audience.id)});
        audiences.forEach((audience) => {
            Object.assign(audience, response[audience.id]);
            audience.dispatchChange();
        })
    });

    constructor() {
        super("MerchantAudience", MerchantAudience);
    }

    allUnfiltered() {
        return super.all();
    }

    // TODO @branch Fuuuuuuuuck, fuck you, fucking wasting my time
    all() {
        return filterStoreByMerchantAndLooseIdArray(this.allUnfiltered(), BlinkGlobal.iFrameState?.newsletter,
            (audience, idOrName) => audience.id == idOrName || audience.name == idOrName);
    }

    enqueueLoadStats(audience) {
        if (audience.size != null) {
            return;
        }
        audience.size = -1; // Mark that we've enqueued this audience
        this.statsBatcher.process(audience);
    }
}

export const MerchantAudienceStore = new MerchantAudienceStoreClass();

export async function apiMerchantCreateAudience(request) {
    return apiClient.post("/merchant/create_audience", request);
}

export async function apiMerchantEditAudience(request) {
    return apiClient.post("/merchant/edit_audience", request, {allowNulls: true});
}

export async function apiMerchantDeleteAudience(request) {
    return apiClient.post("/merchant/delete_audience", request);
}

export async function apiMerchantGetAudienceMembers(request) {
    return apiClient.get("/merchant/get_audience_members", request);
}

export async function apiMerchantGetAudienceStats(request) {
    return apiClient.get("/merchant/get_audience_stats", request);
}
