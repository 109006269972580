import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {apiClient} from "../../connection/BlinkApiClient.js";
import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {AuthService, authService} from "../../connection/services/AuthService.js";


@makeEnum
export class MerchantAudienceMemberStatus extends BaseEnum {
    static SELF_SUBSCRIBED;
    static MANUALLY_SUBSCRIBED;
    static AUTO_SUBSCRIBED;
    static SELF_UNSUBSCRIBED;
    static MANUALLY_UNSUBSCRIBED;
    static AUTO_UNSUBSCRIBED;
    static RULE_SUBSCRIBED;
}

export const ProperSubscribedStatuses = [MerchantAudienceMemberStatus.SELF_SUBSCRIBED, MerchantAudienceMemberStatus.MANUALLY_SUBSCRIBED, MerchantAudienceMemberStatus.AUTO_SUBSCRIBED];
export const SubscribedStatuses = [...ProperSubscribedStatuses, MerchantAudienceMemberStatus.RULE_SUBSCRIBED];
export const ProperUnsubscribedStatuses = [MerchantAudienceMemberStatus.SELF_UNSUBSCRIBED, MerchantAudienceMemberStatus.MANUALLY_UNSUBSCRIBED, MerchantAudienceMemberStatus.AUTO_UNSUBSCRIBED];
export const UnsubscribedStatuses = ProperUnsubscribedStatuses;
export const ProperStatuses = [...ProperSubscribedStatuses, ...ProperUnsubscribedStatuses];

export class MerchantAudienceMember extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantAudience") audience;
    @field("UserProfile") user;
    @field(MerchantAudienceMemberStatus) status;
    @field(Date) unsubscribedAt;
    @field(Date) subscribedAt;
    @field(Date) createdAt;
    @field("MerchantConversion") conversion;

    isSubscribed() {
        return SubscribedStatuses.indexOf(this.status) >= 0;
    }

    serializeForSDK() {
        const {audienceId, status, subscribedAt, unsubscribedAt, conversionId} = this;
        return {
            status: status.valueOf(),
            audienceId,
            subscribedAt,
            unsubscribedAt,
            conversionId,
            name: this.audience?.name,
        }
    }
}

export const MerchantAudienceMemberStore = MakeStore("MerchantAudienceMember", MerchantAudienceMember);

export async function apiGetNewsletterSubscriptions(request, noAuthToken=false) {
    return apiClient.get("/users/get_newsletter_subscriptions", request, {noAuthToken});
}

export const NewsletterSubscriptionUpdateType = {
    SUBSCRIBE: "subscribe",
    UNSUBSCRIBE: "unsubscribe",
    REPLACE: "replace",
}

// TODO @branch make this subscribe the user to a non-public newsletter
export async function apiUserUpdateNewsletterSubscriptions(request, noAuthToken=false) {
    const response = await apiClient.post("/users/update_newsletter_subscriptions", request, {noAuthToken});
    // We may have been automatically logged in here.
    if (authService.hasAuthToken(response)) {
        // We've been auto-authenticated via registration, since this merchant doesn't care about confirmation codes on new visits
        authService.setAuthToken(response);
        authService.dispatch(AuthService.EventTypes.USER_DATA_AVAILABLE, response);
    }
    return response;
}

export async function apiMerchantCreateAudienceMembers(request) {
    return apiMerchantEditAudienceMembers(request);
}

export async function apiMerchantEditAudienceMembers(request) {
    return apiClient.post("/merchant/edit_audience_members", request);
}
