import {UI, styleRule, registerStyle} from "../../../../../stem-core/src/ui/UI.js";

import {BaseCard, BaseCardStyle, UIPaginationManager} from "../../../../ui/BaseCard.jsx";
import {Messages} from "../../../../Messages.js";
import {Link} from "../../../../../stem-core/src/ui/UIPrimitives.jsx";
import {PurchasePriceElement} from "../components/PurchasePriceElement.jsx";
import {EndpointPaginator} from "../../../../../client/state/EndpointPaginator.js";
import {PurchaseStore} from "../../../../../client/state/PurchaseStore.js";
import {TopUpStatus} from "../../../../../client/state/TopUpPaymentStore.js";
import {RealTableStyle} from "../../../components/BaseRecurringPaymentList.jsx";
import {Table} from "../../../../../stem-core/src/ui/table/Table.jsx";


export class ArticlePaymentsStyle extends BaseCardStyle {
    @styleRule
    purchaseTitleContainer = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }

    @styleRule
    purchaseTitle = {
        fontWeight: "normal",
        ":hover": {
            textDecoration: "underline",
        },
    };
}

// TODO @branch @Mihai reimplement and rename to PurchasesList
@registerStyle(ArticlePaymentsStyle)
export class ArticlePayments extends BaseCard {
    paginator = new EndpointPaginator(
        PurchaseStore,
        "/payments/list_purchases",
        {status: TopUpStatus.SUCCEEDED},
        {status: TopUpStatus.SUCCEEDED},
    );
    paginationHandler = new UIPaginationManager(this);

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "purchaseHistory",
            title: Messages.articlePayments,
            description: () => {
                if (!this.paginator.lastPageLoaded) {
                    return "";
                }

                // TODO @branch Use the paginator Luke
                return this.getPurchases().length ? Messages.articlePaymentsDescription : Messages.emptyArticlePayments
            },
        };
    }

    getPurchases() {
        return this.paginator.all().sort((a, b) => b.createdAt - a.createdAt);
    }

    render() {
        const {styleSheet} = this;
        const purchases = this.getPurchases();

        if (!purchases.length) {
            return null;
        }

        const columns = [
            {
                headerName: "Title",
                value: purchase => {
                    let title = [
                        purchase.merchant + " - ",
                        <span style={{fontStyle: "italic"}}>{purchase.getTitle()}</span>
                    ];
                    if (purchase.url) {
                        title = <Link className={styleSheet.purchaseTitle} href={purchase.url} newTab>
                            {title}
                        </Link>
                    }

                    return <div className={styleSheet.purchaseTitleContainer}>
                        {title}
                    </div>;
                },
                headerStyle: {
                    textAlign: "left",
                    width: "55%"
                }
            },
            {
                headerName: "Date",
                value: payment => payment.createdAt,
                headerStyle: {
                    textAlign: "left",
                    width: "30%"
                },
                cellStyle: {
                    color: this.themeProps.MERCHANT_2
                }
            },
            {
                headerName: "Price",
                value: purchase => <PurchasePriceElement purchase={purchase}/>,
                headerStyle: {
                    textAlign: "right",
                    width: "15%",
                }
            }
        ];

        return <Table
            styleSheet={RealTableStyle}
            columns={columns}
            entries={purchases}
        />;
    }

    onMount() {
        super.onMount();

        this.paginationHandler.attach();
    }
}
