import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {iFrameConnection} from "../../services/IFrameConnection.js";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages.js";
import {Link} from "../../../stem-core/src/ui/UIPrimitives.jsx";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient.js";

export function redirectOnClickLink(event, element) {
    if (event.shiftKey || event.ctrlKey || event.metaKey || element.options.newTab || element.options.href == null ||
        (element.options.target && element.options.target !== "_self")) {
        // Leave it to the browser
        return;
    }
    event.preventDefault();
    event.stopPropagation();
    iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url: element.options.href});
}


export class PanelLink extends Link {
    onMount() {
        if (!this.options.href) {
            // Not a link
            return;
        }
        this.addClickListener(event => {
            dispatchAnalyticsEvent(AnalyticsEventType.ELEMENT_CLICK);
            redirectOnClickLink(event, this);
        });
    }
}
