import {UI, styleRule, registerStyle, Theme} from "../../../../../stem-core/src/ui/UI.js";
import {Messages} from "../../../../Messages.js";

import {BaseCard, BaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {BlinkButton} from "../../../../../core/ui/Button.jsx";
import {List} from "../../../../ui/List.jsx";
import {PaymentMethodInlineMenu} from "../../../../common/inline-menu/PaymentMethodInlineMenu.jsx";
import {AddCardModal} from "../../wallet/components/cards/AddCardModal.jsx";
import {ConfirmationModal} from "../../../../ui/ConfirmationModal.jsx";
import {apiDeletePaymentMethod, apiSetPrimaryPaymentMethod} from "../../../../../client/state/PaymentMethodStore.js";
import {FormatPaymentMethodDescription} from "../../../../common/PaymentMethodDescription.jsx";
import {wrapInSpinner} from "../../../../../core/ui/LoadingSpinner.jsx";
import {Toast} from "../../../../ui/Toast.jsx";


class DeletePaymentMethodModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "removeCardModal",
            title: Messages.removeCard,
            confirmLabel: Messages.remove,
            confirmAction: () => this.removeCard(),
            description: Messages.removeCardEndingIn(this.options.card.getBrand(), this.options.card.getLastDigits()),
        };
    }

    @wrapInSpinner
    async removeCard() {
        await apiDeletePaymentMethod(this.options.card.id);
        Toast.show(Messages.cardRemoved);
    }
}


class PaymentMethodsStyle extends BaseCardStyle {
    @styleRule
    addCardButton = {
        width: "100%",
    };

    @styleRule
    listElement = {
        ">:first-child": {
            marginTop: 12,
            borderTop: `1px solid ${Theme.props.DIVIDER_LINE_COLOR} !important`,
        },
        ">:last-child": {
            marginBottom: 12,
        },
    };
}

@registerStyle(PaymentMethodsStyle)
export class PaymentMethods extends BaseCard {
    async handleAddPaymentMethod() {
        const card = await AddCardModal.prompt();
        if (!card) {
            return;
        }

        if (!card.isPrimary) {
            const setAddedCardAsPrimary = await ConfirmationModal.prompt({
                testId: "setPrimaryModal",
                title: Messages.promptSetCardAsDefault,
                description: FormatPaymentMethodDescription(card),
                confirmLabel: Messages.yes,
                cancelLabel: Messages.no,
            });

            if (setAddedCardAsPrimary) {
                await apiSetPrimaryPaymentMethod(card.id);
            }
        }
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.paymentMethods,
            userPaymentMethods: [],
        };
    }

    getDescription() {
        return this.options.userPaymentMethods?.length ? "" : Messages.emptyPaymentMethodsListDescription;
    }

    renderPaymentMethodMenu(paymentMethod) {
        return <PaymentMethodInlineMenu
            paymentMethod={paymentMethod}
            handleDelete={(paymentMethod) => DeletePaymentMethodModal.show({paymentMethod})}
        />
    }

    render() {
        const {userPaymentMethods} = this.options;
        return <List testId="accountPaymentMethods" className={this.styleSheet.listElement}>
            {userPaymentMethods?.map(paymentMethod => this.renderPaymentMethodMenu(paymentMethod))}
        </List>;
    }

    renderFooter() {
        return <BlinkButton
            testId="accountAddCardButton"
            label={Messages.addCard}
            className={this.styleSheet.addCardButton}
            onClick={() => this.handleAddPaymentMethod()}
        />;
    }
}
