import {registerStyle, styleRule, StyleSheet, UI} from "../../../stem-core/src/ui/UI.js";
import {Route, Router} from "../../../stem-core/src/ui/Router.jsx";
import {NavBar} from "../nav/NavBar.jsx";
import {Footer} from "../components/Footer.jsx";
import {NavMobile} from "../nav/NavMobile.jsx";
import {isSmallScreen} from "../../Utils.js";
import {userService} from "../../../client/connection/services/UserService.js";
import {PAGES} from "../nav/Nav.jsx";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";


class HomePageStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        flexDirection: "column",
        minHeight: ["100vh !important", "-webkit-fill-available"],
        width: "100%",
        alignItems: "center",
        background: "rgba(232,240,242,0.6)",
    };

    @styleRule
    mainFrame = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        paddingTop: () => isSmallScreen() ? 56 + 18 : 72 + 36,
        paddingBottom: () => isSmallScreen() ? "0" : 12,
    };
}

@registerStyle(HomePageStyle)
export class HomePage extends UI.Element {
    urlPartsToSet = null;

    render() {
        if (!userService.isUserFetched()) {
            LoadingSpinner.show();
            return [];
        }
        const NavbarClass = isSmallScreen() ? NavMobile : NavBar;
        const dashboardRoutes = PAGES.map(
            page => new Route(page.href, page.Component, [], page.title.toString())
        );

        return [
            <NavbarClass ref="navBar" />,
            <div className={this.styleSheet.mainFrame}>
                <Router ref="router" routes={new Route(null, null, dashboardRoutes)} />
            </div>,
            <Footer />,
        ];
    }

    setURL(urlParts) {
        if (!userService.isUserFetched()) {
            // Save the params to re-call this function once
            // the user is fetched and the component will render the router and the navbar
            this.urlPartsToSet = urlParts;
            return;
        }

        let cleanUrlParts = urlParts;
        const hrefs = PAGES.map(page => page.href);

        if (hrefs.indexOf(urlParts[0]) === -1) {
            cleanUrlParts = [hrefs[0]];
            Router.changeURL("/" + hrefs[0], {replaceHistory: true});
        }

        this.router.setURL(cleanUrlParts);
        this.navBar.setURL(cleanUrlParts);
    }

    onMount() {
        userService.ensureUserDataRequested(() => {
            LoadingSpinner.hide();
            this.redraw();

            if (this.urlPartsToSet) {
                this.setURL(this.urlPartsToSet);
                this.urlPartsToSet = null;
            }
        });
    }
}
