import {UI} from "../../../../../stem-core/src/ui/UI.js";

import {BlinkButton} from "../../../../../core/ui/Button.jsx";
import {Messages} from "../../../../Messages.js";
import {PaymentMethodStore} from "../../../../../client/state/PaymentMethodStore.js";
import {WalletStore} from "../../../../../client/state/WalletStore.js";
import {ADD_FUNDS_DISABLE_TIMEOUT} from "../../../../Constants.js";
import {Toast} from "../../../../ui/Toast.jsx";
import {wrapInSpinner} from "../../../../../core/ui/LoadingSpinner.jsx";
import {Money} from "../../../../../stem-core/src/localization/Money.js";
import {apiUserTopUp, TopUpStatus} from "../../../../../client/state/TopUpPaymentStore.js";


export class AddFundsButton extends BlinkButton {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            amount: 0,
            userWallet: WalletStore.getWallet(),
            onClick: () => this.addFunds(),
            onNoPaymentMethod: null, // Required to be passed in
        };
    }

    render() {
        const {amount, userWallet} = this.options;
        const moneyAmount = new Money(amount, userWallet.getCurrency());
        return Messages.addFundsAmount(moneyAmount.toMainUnitString({decimalsDisplayed: 0}));
    }

    @wrapInSpinner
    async addFunds() {
        const {amount, userWallet, onNoPaymentMethod} = this.options;
        const primaryCard = PaymentMethodStore.getPrimary();
        if (!primaryCard) {
            onNoPaymentMethod();
            return;
        }
        Toast.hide();
        this.tempDisable(ADD_FUNDS_DISABLE_TIMEOUT);

        try {
            const transaction = await apiUserTopUp(amount, primaryCard, userWallet);
            if (transaction.status !== TopUpStatus.SUCCEEDED) {
                throw null;
            }
        } catch (error) {
            Toast.showError(error, Messages.failedAddFunds);
        }
    }
}
