import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";
import {styleRule, StyleSheet} from "../../../../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme.js";
import {PaymentMethodStore} from "../../../../../../client/state/PaymentMethodStore.js";
import {NOOP_FUNCTION} from "../../../../../../stem-core/src/base/Utils.js";
import {LinkButton} from "../../../../../../core/ui/LinkButton.jsx";
import {PlusIcon} from "../../../../../../core/ui/SVGElements.jsx";
import {Messages} from "../../../../../Messages.js";
import {iFrameUserDataService} from "../../../../../services/IFrameUserDataService.js";
import {MakeMerchantUserResourcesAccessLink} from "../../../../../common/MerchantUserResourcesAccessLink.jsx";
import {RadioListInput} from "../../../../../../core/ui/input/radio/RadioListInput.jsx";
import {PaymentMethodDescription} from "../../../../../common/PaymentMethodDescription.jsx";
import {BaseInputElement} from "../../../../../../stem-core/src/ui/input/BaseInputElement.js";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw.js";


class PaymentMethodSelectInputStyle extends StyleSheet {
    @styleRule
    addNewCard = {
        cursor: "pointer",
        fontWeight: this.themeProps.FONT_WEIGHT_REGULAR,
        color: this.themeProps.LINK_COLOR,
        whiteSpace: "nowrap",
        marginTop: 14,
        display: "inline-block",
        ">*": {
            display: "inline-block",
        },
        ">:last-child": {
            marginLeft: 14,
        },
    };

    @styleRule
    description = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        marginBottom: 14,
    };

    @styleRule
    header = {
        display: "flex",
        justifyContent: "space-between",
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        paddingBottom: 4,
    };

    @styleRule
    list = {
        marginRight: 0,
        width: "100%",
    };

    @styleRule
    activeCardInfo = {
        marginBottom: 18,
    };
}

@registerStyle(PaymentMethodSelectInputStyle)
@autoredraw(PaymentMethodStore)
export class PaymentMethodSelectInput extends BaseInputElement {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            initialValue: PaymentMethodStore.getPrimary(),
            onAddCard: NOOP_FUNCTION,
            // TODO @branch Rename recurringPayment and make it default to PaymentMethodStore
            recurringPayment: null,
            showDescription: false,
            showHeader: false,
        };
    }

    getEntries() {
        if (this.options.recurringPayment) {
            return this.options.recurringPayment.getAvailablePaymentMethods();
        }

        return PaymentMethodStore.getAvailablePaymentMethods();
    }

    renderDescription() {
        const {styleSheet} = this;
        const {showDescription} = this.options;

        return showDescription && <div className={styleSheet.description}>{Messages.chooseCardDescription}</div>;
    }

    renderHeader() {
        const {styleSheet} = this;
        const {showHeader} = this.options;

        return (showHeader && !!this.getEntries().length) && <div className={styleSheet.header}>
                <div>{Messages.creditCardsListHeader}</div>
                <div>{Messages.expires}</div>
            </div>;
    }

    renderEntries() {
        return <RadioListInput
            initialValue={this.getValue()}
            entries={this.getEntries()}
            formatter={value => <PaymentMethodDescription paymentMethod={value} showExpiryDate />}
            onChange={(card) => this.setValue(card)}
        />;
    }

    renderAddNewEntry() {
        const {styleSheet} = this;
        const {onAddCard} = this.options;
        const extraPaymentMethodCount = iFrameUserDataService.getExtraPaymentMethodCount();

        return [
            <LinkButton className={styleSheet.addNewCard} onClick={() => onAddCard()}>
                <PlusIcon size={12}/>
                <span>{Messages.addNewPaymentMethod}</span>
            </LinkButton>,
            MakeMerchantUserResourcesAccessLink(extraPaymentMethodCount),
        ]
    }

    render() {
        return [
            this.renderDescription(),
            this.renderHeader(),
            this.renderEntries(),
            this.renderAddNewEntry()
        ];
    }
}
