import {Dispatcher} from "../../../stem-core/src/base/Dispatcher.js";

// This will get populated in FlowState.js
export const Flow = {};


export class FlowPlan extends Dispatcher {
    steps = [];
    currentStep = 0;

    constructor(steps = [], finalize = () => Flow.activeFlowHandler.finalize()) {
        super();

        this.steps = steps.filter(x => x); // Skip null or false steps

        this.finalize = finalize;
    }

    getCurrentStep() {
        return this.steps[this.currentStep];
    }

    isBeforeFirstStep() {
        return this.currentStep === -1;
    }

    goToNextStep() {
        this.currentStep += 1;
    }

    goToPreviousStep() {
        this.currentStep -= 1;
        while (this.currentStep >= 0 && !(this.getCurrentStep().shouldPrompt())) {
            this.currentStep -= 1;
        }
    }

    goToStep(step) {
        for (let index = 0; index < this.steps.length; index++) {
            if (this.steps[index] === step) {
                this.currentStep = index;
                return;
            }
        }
    }

    async prompt(forcePrompt=false) {
        // Skip any invalid steps
        while (this.isBeforeFirstStep() || (this.currentStep < this.steps.length && !forcePrompt && !this.getCurrentStep().shouldPrompt())) {
            this.currentStep += 1;
        }

        if (this.currentStep >= this.steps.length) {
            if (Flow.flowPlan === this) {
                await this.finalize();
                Flow.flowPlan = null; // We're done if the finalization was ok
                Flow.activeFlowHandler = null;
            }
            return;
        }

        await this.getCurrentStep().prompt();

        this.promptNextStep().then(); // TODO @flow2 hmmmm
    }

    async promptNextStep() {
        this.goToNextStep();
        await this.prompt();
    }

    async promptPreviousStep() {
        this.goToPreviousStep();

        if (this.currentStep >= 0) {
            await this.prompt();
        } else {
            // Explicitly marking as outside of the flow
            this.currentStep = -1;
        }
    }
}