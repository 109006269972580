import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme.js";
import {styleRule, StyleSheet} from "../../../../../../stem-core/src/ui/Style.js";


class AddressDescriptionStyle extends StyleSheet {
    @styleRule
    container = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
    };

    @styleRule
    nameOnAddress = {
        marginBottom: 4,
    };

    @styleRule
    addressDetails = {
        display: "flex",
        flexDirection: "column",
    };
}

@registerStyle(AddressDescriptionStyle)
export class AddressDescription extends UI.Element {
    render() {
        const {styleSheet} = this;
        const {userAddress} = this.options;

        return [
            <div className={styleSheet.nameOnAddress}>{userAddress.getRecipientName()}</div>,
            <div className={styleSheet.addressDetails}>{userAddress}</div>,
        ]
    }
}
