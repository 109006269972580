import {UI} from "../../../stem-core/src/ui/UIBase";

import {PaySVG} from "../../SVGElements";
import {ProductPage} from "../primitives/ProductPage";
import {Messages} from "../../../blinkpay/Messages";
import {MEDIA_FOLDER} from "../../Constants";
import {Device} from "../../../stem-core/src/base/Device";
import {isFirefox} from "../../../stem-core/src/base/Utils.js";

export const getLandingPageMediaUrls = mediaBaseUrl => {
    const mediaUrls = [
        {
            url: `${mediaBaseUrl}.mp4`,
            type: "video/mp4",
        },
    ];

    if (!(Device.isMobileDevice() && isFirefox())) {
        mediaUrls.push({
            url: `${mediaBaseUrl}.webm`,
            type: "video/webm",
        });
    }

    if (!Device.isMobileDevice()) {
        mediaUrls.push({
            url: `${mediaBaseUrl}.ogv`,
            type: "video/ogv",
        });
    }

    return mediaUrls;
}

export class PayPage extends ProductPage {
    getDefaultOptions() {
        const mediaBaseUrl = `${MEDIA_FOLDER}pay${Device.isMobileDevice() ? "-mobile" : ""}`;

        return {
            ...super.getDefaultOptions(),
            icon: <PaySVG size="100%"/>,
            summary: Messages.payLongSummary,
            description: Messages.payLongText,
            mediaUrl: getLandingPageMediaUrls(mediaBaseUrl),
            isVideo: true,
        };
    }
}
