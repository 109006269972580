import {UI} from "../../stem-core/src/ui/UIBase";
import {CheckmarkIcon, ChevronDownIcon, MinusIcon} from "./SVGElements.jsx";

const ICON_TO_CLASS = {
    "chevron-down": ChevronDownIcon,
    "minus": MinusIcon,
    "checkmark": CheckmarkIcon,
}

export const BlinkMakeIcon = (icon, options) => {
    // TODO: this is common with the Stem implementation, reuse?
    if (!icon) {
        return null;
    }

    if (icon instanceof UI.Element) {
        return icon;
    }

    if (icon.prototype instanceof UI.Element) {
        return new icon(options);
    }

    if (typeof icon === "function") {
        return icon(options);
    }

    // TODO: handle string mappings here
    const IconClass = ICON_TO_CLASS[icon];
    if (IconClass) {
        return <IconClass {...options} />;
    }

    console.warn("Invalid icon:", icon);

    return null;
}