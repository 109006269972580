import {StoreObject, GenericObjectStore} from "stem-core/src/state/Store";

export class Language extends StoreObject {
    buildTranslation(callback) {
        callback(this.translationMap);
    }
}

class LanguageStoreClass extends GenericObjectStore {
    getLocale() {
        return this.locale || this.all()[0];
    }

    setLocale(locale) {
        this.locale = locale;
        this.dispatch("localeChange", locale);
    }
}

export const LanguageStore = new LanguageStoreClass("languageStore", Language);
