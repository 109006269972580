import {registerStyle, styleRule, styleRuleInherit, UI} from "../../stem-core/src/ui/UI";

import {Section, SectionStyle} from "../primitives/Section";
import {Device} from "../../stem-core/src/base/Device";
import {Messages} from "../../blinkpay/Messages";
import {isDesktopSmall} from "../Utils";
import {MEDIA_FOLDER, SCREENSHOTS_FOLDER} from "../Constants";
import {WalletTextImageSection} from "./primitives/WalletTextImageSection";
import {getLandingPageMediaUrls} from "../sections-publisher/product-pages/PayPage";
import {FeaturesBackgroundSVG} from "../SVGElements";

class FeaturesStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        paddingTop: "",
        paddingBottom: "",
        padding: () => (isDesktopSmall() ? "0 24px 36px" : "30px 0 0"),
        position: "relative",
        zIndex: 9,
    };

    @styleRuleInherit
    content = {
        padding: isDesktopSmall() ? "0" : "0 24px",
    };

    @styleRule
    curve = {
        position: "absolute",
        bottom: 2,
        transform: "translateY(100%)",
        left: 0,
        width: "100%",
        pointerEvents: "none",
    };
}

const walletTextImageSections = [
    {
        title: Messages.walletTextImageSectionTitle1,
        description: [Messages.walletTextImageSectionDescription1a, Messages.walletTextImageSectionDescription1b],
        mediaUrl: `${SCREENSHOTS_FOLDER}reader-wallet-2${Device.isMobileDevice() ? "-mobile" : ""}.png`,
        imagePositionLeft: true,
    },
    {
        title: Messages.walletTextImageSectionTitle2,
        description: [
            Messages.walletTextImageSectionDescription2a,
            Messages.walletTextImageSectionDescription2b,
            Messages.walletTextImageSectionDescription2c,
        ],
        mediaUrl: getLandingPageMediaUrls(`${MEDIA_FOLDER}pay-mobile`),
        isVideo: true,
    },
];

@registerStyle(FeaturesStyle)
export class Features extends Section {
    render() {
        return [
            walletTextImageSections.map(itemOptions => <WalletTextImageSection {...itemOptions} />),
            <FeaturesBackgroundSVG className={this.styleSheet.curve} />,
        ];
    }
}
