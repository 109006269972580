import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils.js";
import {BlinkButton} from "../../../core/ui/Button.jsx";
import {Messages} from "../../Messages.js";
import {BlinkInput} from "../../ui/Input.jsx";
import {isNetworkError} from "../../Utils.js";
import {DefaultCodeConfirmationFormStyle} from "./DefaultCodeConfirmationForm.jsx";


@registerStyle(DefaultCodeConfirmationFormStyle)
export class LongCodeEmailConfirmationForm extends UI.Element {
    hasPastedValue = false;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSubmit: NOOP_FUNCTION,
            testId: "emailCodeInput"
        }
    }

    hasValidInput() {
        return this.codeInput && this.codeInput.isValid();
    }

    getInsertedCode() {
        return this.codeInput && this.codeInput.getValue().trim();
    }

    async confirmCode() {
        if (!this.hasValidInput()) {
            return;
        }

        try {
            await this.options.onSubmit(this.getInsertedCode());
            this.clearErrors();
        } catch (error) {
            this.codeInput.updateOptions({error: isNetworkError(error) ? error.message : Messages.incorrectConfirmationCode});
        }
    }

    handleInputChange() {
        this.clearErrors();
        this.redraw();

        if (this.hasPastedValue) {
            this.confirmCode();
            this.hasPastedValue = false;
        }
    }

    render() {
        const {styleSheet} = this;

        return [
            <BlinkInput
                ref="codeInput"
                onFocus={() => this.clearErrors()}
                onChange={() => this.handleInputChange()}
                onEnter={() => this.confirmCode()}
                validators={[{condition: () => !this.codeInput.isEmpty()}]}
            />,
            <BlinkButton label={Messages.confirm} className={styleSheet.button} disabled={!this.hasValidInput()} onClick={() => this.confirmCode()}/>
        ]
    }

    blur() {
        this.codeInput.blur();
    }

    clearErrors() {
        this.codeInput.updateOptions({error: null})
    }

    onMount() {
        this.codeInput.addNodeListener("paste", () => {
            this.hasPastedValue = true;
        });
    }
}
