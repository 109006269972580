import {keyframesRule, styleRule, styleRuleInherit, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {isNetworkError} from "../../Utils.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Messages} from "../../Messages.js";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils.js";
import {ShortCodeInput} from "./ShortCodeInput.jsx";
import {canWriteCookies} from "../../AuthHelper.js";
import {authService} from "../../../client/connection/services/AuthService.js";
import {SpinnerIcon} from "../../../core/ui/SVGElements.jsx";
import {DefaultCodeConfirmationFormStyle, DefaultCodeConfirmationForm} from "./DefaultCodeConfirmationForm.jsx";
import {authFormService} from "../../services/AuthFormService.js";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {BlinkButton} from "../../../core/ui/Button.jsx";


class SpinnerAnimation extends StyleSheet {
    @keyframesRule
    animation = {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    };
}

class BlockedCookiesCodeConfirmationFormStyle extends DefaultCodeConfirmationFormStyle {
    @styleRuleInherit
    button = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    @styleRule
    buttonSpinner = {
        animation: `${SpinnerAnimation.getInstance().animation.toString()} .6s linear infinite`,
        marginLeft: 4,
        verticalAlign: "middle",
        color: "inherit",
        ">:last-child g > path": {
            stroke: "currentColor",
        }
    };
}

@autoredraw(authFormService)
@registerStyle(BlockedCookiesCodeConfirmationFormStyle)
class BlockedCookiesCodeConfirmationForm extends UI.Element {
    verifyCodeTimeout = null;
    lastSentCode = null;
    verifiedCode = false;
    loading = false;
    error = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onSubmit: NOOP_FUNCTION,
            onCodeAutomaticallyVerified: NOOP_FUNCTION,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.formComponent);
    }

    render() {
        const {styleSheet} = this;

        return [
            <ShortCodeInput
                ref={"codeInput"}
                success={this.verifiedCode}
                onChange={() => this.redraw()}
                error={this.error}
                onClearError={() => {
                    this.error = null;
                    this.redraw();
                }}
                onCodeInserted={() => this.handleCodeInserted()}
            />,
            !this.verifiedCode ?
                <BlinkButton className={styleSheet.button} disabled={true} onClick={() => this.verifyCode()}>
                    {Messages.confirm}
                    {this.loading ? <SpinnerIcon className={styleSheet.buttonSpinner}/>: null}
                </BlinkButton>
                : <BlinkButton className={styleSheet.button} onClick={() => this.handleContinueClick()}>
                    {Messages.continue}
                </BlinkButton>,
        ];
    }

    async verifyCode() {
        const code = this.codeInput.getCode();
        const setLoading = (isDisplayed) => {
            this.loading = isDisplayed;
            this.redraw();
        };

        setLoading(true);
        try {
            await authService.loginWithEmailCode({code});

            this.options.onCodeAutomaticallyVerified();
            this.codeInput.blur();
            this.verifiedCode = true;
        } catch (error) {
            this.setCodeInputError(error);
        }
        setLoading(false);
    }

    async handleContinueClick() {
        try {
            // Careful with the order, the "onSubmit" might delete the node.
            this.handleConfirmationSuccess();
            await this.options.onSubmit(this.codeInput.getCode());
        } catch (error) {
            if (this.node) {
                this.setCodeInputError(error);
            }
        }
    }

    handleConfirmationSuccess() {
        this.codeInput.blur();
        this.codeInput.updateOptions({success: true});
        this.error = null;
        this.redraw();
    }

    setCodeInputError(error) {
        this.codeInput.blur();
        this.error = isNetworkError(error) ? error.message : Messages.incorrectConfirmationCode;
        this.redraw();
    }

    getVerifyRequestThrottle() {
        return this.lastSentCode != null ? 2000 : 300;
    }

    handleCodeInserted() {
        const code = this.codeInput.getCode();
        if (this.lastSentCode === code) {
            return;
        }

        if (this.verifyCodeTimeout) {
            clearTimeout(this.verifyCodeTimeout)
        }

        this.verifyCodeTimeout = this.attachTimeout(() => {
            this.verifyCode();
        }, this.getVerifyRequestThrottle());
    }
}

export const ShortCodeEmailConfirmationForm = canWriteCookies() ? DefaultCodeConfirmationForm : BlockedCookiesCodeConfirmationForm;
