import {SOUTHERN_THING_ALIAS} from "../../Constants.js"
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {
    apiSubscribe,
    apiSubscribeWithCds
} from "../../../client/state/SubscriptionStore.js";
import {Messages} from "../../Messages.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {
    INLINE_SUBSCRIPTION_URLS,
    PAYMENT_FAILED_PANEL_URL,
} from "../PanelConstants.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";
import {iFrameUserDataService} from "../../services/IFrameUserDataService.js";
import {FLOW_CHECKPOINT, FLOW_TYPE, PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore.js";
import {SubscriptionFlowType} from "./steps/SubscriptionPlanFlowStep.js";
import {BaseFlowHandler, sendFlowCheckpoint} from "./BaseFlowHandler.js";
import {Flow} from "./Flow.js";
import {iFrameState} from "../../services/IFrameState.js";
import {SubscriptionOfferStore} from "../../../client/state/SubscriptionOfferStore.js";


export class SubscriptionFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.subscribe;

    static subscriptionToCancel = null;

    getFlowPlan() {
        const existingActiveSubscription = iFrameUserDataService.getActiveSubscription();

        if (existingActiveSubscription) {
            // TODO @flow2 not very nice
            Router.changeURL(INLINE_SUBSCRIPTION_URLS.subscriptionManageInline);
            return null;
        }

        Flow.subscriptionPlan.init({
            subscriptionFlowType: SubscriptionFlowType.SUBSCRIBE,
            discountCode: iFrameState.iframeParams.selectedDiscountCode,
        });

        // TODO @flow move inside the init?
        if (Flow.subscriptionPlan.discountCode) {
            Flow.subscriptionPlan.fetchPrices().then();
        }

        if (iFrameState.iframeParams.selectedSubscriptionOfferId) {
            const subscriptionOffer = SubscriptionOfferStore.get(iFrameState.iframeParams.selectedSubscriptionOfferId);
            if (subscriptionOffer) {
                Flow.subscriptionPlan.selectedValue = subscriptionOffer;
                Flow.subscriptionPlan.shouldPrompt = () => false; // Won't be prompted anymore, but can still jump to it manually
            } else {
                console.warn("Invalid subscription offerId passed in", iFrameState.iframeParams.selectedSubscriptionOfferId);
            }
        }


        return [
            Flow.subscriptionPlan,

            Flow.auth,

            Flow.address.init({
                shouldPrompt: () => Flow.subscriptionPlan.selectedValue.shouldCollectAddress(),
                continueLabel: Messages.reviewOrder,
            }),

            Flow.subscriptionReview,

            Flow.paymentMethod.init({
                savePaymentMethod: true,
                shouldPrompt: () => Flow.paymentMethod.selectedValue == null,
                scope: PaymentMethodStore.Scope.PRIVATE,
            }),
        ];
    }

    @wrapInSpinner
    async finalize() {
        const payload = await this.getPayload();
        dispatchAnalyticsEvent(AnalyticsEventType.ATTEMPT_SUBSCRIBE, {recurringOfferId: payload.recurringOfferId});

        let subscription = null;
        try {
            const apiSubscribeCallback = iFrameMerchantService.isCDSPaymentMethod() ? apiSubscribeWithCds : apiSubscribe;
            subscription = await apiSubscribeCallback(payload);
        } catch (error) {
            Flow.paymentMethod.update({
                paymentError: error,
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
            return;
        }

        sendFlowCheckpoint(FLOW_TYPE.subscription, FLOW_CHECKPOINT.complete, {
            subscriptionId: subscription.id,
            offerId: subscription.recurringOfferId,
            coverageId: subscription.coverageId,
            amount: subscription.getPrice().toMainUnitString({includeSymbol: false}),
            currency: subscription.currency.isoCode,
        });

        Router.changeURL(INLINE_SUBSCRIPTION_URLS.subscribeThankYou);
    }

    async getPayload() {
        const shippingAddress = Flow.address.selectedValue;
        const {discountCode} = Flow.subscriptionPlan;
        const selectedOffer = Flow.subscriptionPlan.selectedValue;
        const discountInfo = Flow.subscriptionPlan.getDiscountInfo(selectedOffer);
        const subscriptionDetails = {
            recurringOfferId: selectedOffer.id,
            discountCode: discountInfo?.discountValue ? discountCode : null,
            metadata: {},
            ...(await Flow.paymentMethod.getPayload()),
        };

        if (selectedOffer.shouldCollectAddress()) {
            subscriptionDetails.deliveryAddressId = shippingAddress.id;
        }

        if (iFrameMerchantService.isMerchant(SOUTHERN_THING_ALIAS)) {
            subscriptionDetails.metadata.shareName = Flow.subscriptionPlan.shareName;
        }
        return subscriptionDetails;
    }
}


