import {BlinkGlobal} from "../../../blinkpay/UtilsLib";

export function filterStoreByMerchantAndLooseIdArray(unfilteredObjects, looseIdArray, looseIdMatches) {
    const merchantId = BlinkGlobal.iFrameMerchantService?.merchantId;
    if (merchantId) {
        unfilteredObjects = unfilteredObjects.filter(obj => obj.merchantId === merchantId);
    }
    if (looseIdArray) {
        unfilteredObjects = looseIdArray.map(id => unfilteredObjects.find(obj => looseIdMatches(obj, id)))
                                        .filter(Boolean);
    }
    return unfilteredObjects;
}
