import {UI} from "../../stem-core/src/ui/UI";
import {Link} from "../../stem-core/src/ui/UIPrimitives";

import {Messages} from "../../blinkpay/Messages";
import {Section, SectionStyle} from "../primitives/Section";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {styleRule, styleRuleInherit} from "../../stem-core/src/decorators/Style";
import {COMPONENT_MAX_WIDTH, IMAGES_FOLDER} from "../Constants";
import {Image} from "../../stem-core/src/ui/UIPrimitives";
import {isDesktopMedium, isDesktopSmall} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";
import {TeamData} from "../sections-company/TeamData";
import {MessageElement} from "../../blinkpay/widget/ui/MessageElement";
import {ArrowRightIcon} from "../../core/ui/SVGElements.jsx";

class OurCompanyStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        color: this.themeProps.LANDING_PAGE_DARK_FONT_COLOR,
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: () => (isDesktopSmall() ?  "8px 0 16px" : "58px 0 93px"),
        position: "relative",
    };

    @styleRule
    textSection = {
        flex: 1,
    };

    @styleRule
    flexContainer = {
        display: "flex",
        flexDirection: () => (isDesktopSmall() ? "column-reverse" : "row"),
        margin: () => (isDesktopMedium() ? "" : "0 -42px"),
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 24 : 32),
        marginBottom: () => (Device.isMobileDevice() ? 24 : 85),
        textAlign: () => (Device.isMobileDevice() ? "left" : "center"),
    };

    @styleRule
    imageSection = {
        flex: () => (isDesktopSmall() ? "" : 1),
        position: "relative",
        marginLeft: () => (isDesktopSmall() ? 0 : 40),
        height: () => (Device.isMobileDevice() ? 290 : 394),
    };

    @styleRule
    image = {
        height: () => (isDesktopSmall() ? "100%" : "calc(100% + 70px)"),
        position: "absolute",
        left: () => (isDesktopSmall() ? -24 : 0),
        top: () => (isDesktopSmall() ? -24 : -35),
    };

    @styleRule
    numericValue = {
        marginBottom: 4,
        fontSize: () => (Device.isMobileDevice() ? 18 : 22),
    };

    @styleRule
    label = {
        fontWeight: "lighter",
        fontSize: () => (Device.isMobileDevice() ? 16 : 22),
    };

    @styleRule
    verticalLine = {
        height: "calc(100% - 9px)",
        marginTop: "0.45rem",
        width: 1,
        background: this.themeProps.LANDING_PAGE_VERTICAL_LINE_COLOR,
        marginLeft: 17,
        marginRight: () => (isDesktopMedium() ? 17 : "3.45rem"),
        display: "inline-block",
    };

    @styleRule
    statsContainer = {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 48,
        whiteSpace: "nowrap",
        paddingRight: () => (Device.isMobileDevice() ? "" : 48),
    };

    @styleRule
    stat = {
        display: "inline-block",
    };

    @styleRule
    description = {
        marginBottom: 18,
        lineHeight: () => (Device.isMobileDevice() ? 24 : 33),
        fontSize: () => (Device.isMobileDevice() ? 16 : 21),
        fontWeight: "lighter",
        " b": {
            fontWeight: "normal",
        },
    };

    horizontalLine = {
        width: () => (isDesktopSmall() ? "calc(100% - 48px)" : COMPONENT_MAX_WIDTH),
        height: 2,
        background: this.themeProps.LANDING_PAGE_HORIZONTAL_LINE_COLOR,
        position: "absolute",
    };

    @styleRule
    topLine = {
        ...this.horizontalLine,
        top: 0,
        display: () => (isDesktopSmall() ? "none" : ""),
    };

    @styleRule
    bottomLine = {
        ...this.horizontalLine,
        bottom: 0,
    };

    @styleRule
    hidden = {
        display: "none",
    };

    @styleRule
    arrowIcon = {
        marginLeft: 8,
    };
}

const stats = [
    {
        numericValue: 2018,
        label: Messages.companyStats1,
    },
    {
        numericValue: 3,
        label: Messages.companyStats2,
    },
    {
        numericValue: TeamData.length,
        label: Messages.companyStats3,
    },
];

@registerStyle(OurCompanyStyle)
export class OurCompany extends Section {
    render() {
        const {styleSheet} = this;
        const statElements = <div className={styleSheet.statsContainer}>
            {stats.map(({numericValue, label}, index) => (
                <div>
                    {index ? <div className={styleSheet.verticalLine} /> : null}
                    <div className={styleSheet.stat}>
                        <div className={styleSheet.numericValue}>{numericValue}</div>
                        <div className={styleSheet.label}>{label}</div>
                    </div>
                </div>
            ))}</div>;

        return [
            <div className={styleSheet.topLine} />,
            <div className={styleSheet.title}>{Messages.ourCompanyTitle}</div>,
            isDesktopSmall() ? statElements : null,
            <div className={styleSheet.flexContainer}>
                <div className={styleSheet.textSection}>
                    {isDesktopSmall() ? null : statElements}
                    <MessageElement className={styleSheet.description} message={Messages.ourCompanyDescription1} />
                    <div className={styleSheet.description}>{Messages.ourCompanyDescription2}</div>
                    <Link href={"/company"}>
                        {Messages.findOutMore}
                        <ArrowRightIcon className={styleSheet.arrowIcon} />
                    </Link>
                </div>
                <div className={styleSheet.imageSection}>
                    <Image src={IMAGES_FOLDER + "PaloAltoLight.png"} className={styleSheet.image} />
                </div>
            </div>,
            <div className={styleSheet.bottomLine} />,
        ];
    }
}
