import {UI, styleRule, StyleSheet, registerStyle} from "../../stem-core/src/ui/UI";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {Device} from "../../stem-core/src/base/Device";
import {isDesktopSmall} from "../Utils";
import {Messages} from "../../blinkpay/Messages";
import {Theme} from "../../stem-core/src/ui/style/Theme";
import {BlinkLogo} from "../../core/ui/SVGElements.jsx";
import {AuthenticationModal} from "../components/AuthenticationModal";

export class MobileNavbarAnimatedStyle extends StyleSheet {
    transitionTime = 0.2;

    @styleRule
    scrim = {
        position: "fixed",
        zIndex: 10000,
        width: "100%",
        height: "100%",
        background: this.themeProps.MODAL_BACKGROUND_COLOR,
        top: 0,
        left: 0,
        opacity: 0,
        transition: this.transitionTime + "s ease",
    };

    @styleRule
    links = {
        ">*": {
            fontSize: () => (Device.isMobileDevice() ? 18 : 22),
            color: this.themeProps.WHITE + " !important",
            fontWeight: "lighter",
            display: "block",
            width: "100%",
            padding: () => (Device.isMobileDevice() ? "12px 0 12px 24px" : "1.92rem 0 1.92rem 23px"),
        },
        opacity: 0,
        transition: this.transitionTime + "s ease",
        transitionDelay: this.transitionTime + "s",
        flex: 1,
    };

    @styleRule
    nav = {
        padding: () => (Device.isMobileDevice() ?  "4px 0 8px" : "1.32rem 0 1.28rem"),
        display: "flex",
        width: "100%",
        position: "fixed",
        background: () =>
            isDesktopSmall()
                ? this.themeProps.LANDING_PAGE_MOBILE_HEADER_BACKGROUND
                : this.themeProps.LANDING_PAGE_HEADER_BACKGROUND,
        top: 0,
        zIndex: 10002,
        transform: () => (Device.isMobileDevice() ? "translateY(-190px)" : "translateY(- 304px)"),
        opacity: 1,
        transition: this.transitionTime + "s ease",
        boxShadow: this.themeProps.CARD_SHADOW_1 + ", " + this.themeProps.CARD_SHADOW_2,
    };

    @styleRule
    visible = {
        ">*": {
            opacity: 1,
        },
        ">*>*": {
            opacity: 1,
        },
        ">:first-child": {
            transform: "translateY(0) scale(1)",
        },
    };

    @styleRule
    close = {
        padding: () =>
            Device.isMobileDevice()
                ?  "9px 24px 20px"
                : isDesktopSmall()
                ? "1.44rem 1.84rem 32px"
                : "1.44rem 3.84rem 32px",
        color: this.themeProps.WHITE,
        alignSelf: "start",
        position: "absolute",
        top: () => (Device.isMobileDevice() ? 10 : 16),
        right: () => (Device.isMobileDevice() ? 2 : 22),
        zIndex: 10003,
        cursor: "pointer",
        ">*>svg>g>g": {
            fill: this.themeProps.WHITE,
        },
    };

    @styleRule
    animatedSelected = {
        position: "fixed",
        top: () => (Device.isMobileDevice() ? 16 : "2.56rem"),
        left: () => (Device.isMobileDevice() ? 24 : 23),
        zIndex: 10003,
        color: this.themeProps.WHITE,
        fontSize: () => (Device.isMobileDevice() ? 18 : 22),
        fontWeight: "normal",
        transition: this.transitionTime + "s ease",
    };

    @styleRule
    navbarItem = {
        fontWeight: "normal",
    };
}

@registerStyle(MobileNavbarAnimatedStyle)
export class MobileNavbarAnimated extends UI.Element {
    static show(options) {
        this.create(document.body, options);
    }

    render() {
        const {styleSheet, themeProps} = this;
        const {selectedSection} = this.options;
        const {items} = this.options.navManager;

        return [
            <div className={styleSheet.nav}>
                <div className={styleSheet.links} ref="links">
                    {items.map((item, index) => (
                        <Link
                            value={
                                index ? item.label : <BlinkLogo color={themeProps.LIGHT_BACKGROUND} size={21} />
                            }
                            onClick={() => this.hide(item)}
                            style={{fontWeight: item.id === this.options.active.id ? "bold" : "normal"}}
                        />
                    ))}
                    {Device.isMobileDevice() && selectedSection === "navPublisher" ? null : (
                        <Link className={styleSheet.navbarItem}
                              onClick={() => this.onLoginButtonClick()}>
                            {Messages.logIn}
                        </Link>
                    )}
                </div>
                <CloseIcon color="white" className={styleSheet.close} onClick={() => this.hide()}
                                size={Device.isMobileDevice() && isDesktopSmall() ? 17 : 27}/>
            </div>,
            <div className={styleSheet.scrim} onClick={() => this.hide()} />
        ];
    }

    onLoginButtonClick() {
        this.removeElementWithAnimation();
        AuthenticationModal.show();
    }

    removeElementWithAnimation() {
        setTimeout(() => {
            this.links.setStyle("transitionDelay", "0s");
            this.removeClass(this.styleSheet.visible);
        }, 0);

        setTimeout(() => this.removeElement(), this.styleSheet.transitionTime * 1000);
    }

    hide(page) {
        const {navManager} = this.options;
        this.removeElementWithAnimation();

        if (page) {
            navManager.scrollTo(page.id);
        }
    }

    removeElement() {
        this.node.remove();
        delete this;
    }

    onMount() {
        setTimeout(() => {
            this.addClass(this.styleSheet.visible);
        }, 50);
    }
}
