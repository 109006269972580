import {registerStyle, styleRule, StyleSheet, UI} from "stem-core/src/ui/UI";
import {isSmallScreen} from "../../Utils";
import {ExclamationTriangleIcon} from "../../../core/ui/SVGElements.jsx";


class ErrorPanelStyle extends StyleSheet {
    @styleRule
    container = {
        textAlign: "center",
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
    };

    @styleRule
    icon = {
        margin: () => isSmallScreen() ? "70px auto" : "0px auto 12px",
    };
}

@registerStyle(ErrorPanelStyle)
export class ErrorPanel extends UI.Element {
    render() {
        const {styleSheet} = this;
        return [
            <ExclamationTriangleIcon className={styleSheet.icon}/>,
            <div>{this.options.errorMessage}</div>
        ];
    }
}

