import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {
    INLINE_DONATION_URLS,
    INLINE_EDIT_DONATION_URLS,
    PAYMENT_FAILED_PANEL_URL
} from "../PanelConstants.js";
import {LoadingSpinner, wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {
    apiDonate,
    apiDonateWithCds,
    apiCancelDonation,
    apiEditDonationWithCds,
    apiUpdateRecurringDonation
} from "../../../client/state/DonationStore";
import {iFrameUserDataService} from "../../services/IFrameUserDataService.js";
import {iFrameState} from "../../services/IFrameState.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore.js";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient.js";
import {Messages} from "../../Messages.js";
import {FLOW_CHECKPOINT, FLOW_TYPE, PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {UserAddressStore} from "../../../client/state/UserAddressStore.js";
import {BaseFlowHandler, sendFlowCheckpoint} from "./BaseFlowHandler.js";
import {Flow} from "./Flow.js";
import {userService} from "../../../client/connection/services/UserService.js";
import {authFormService} from "../../services/AuthFormService.js";
import {authService} from "../../../client/connection/services/AuthService.js";
import {AuthHelper} from "../../AuthHelper.js";


export class DonationFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.donation;
    previouslyCanceledDonation = null;

    getPaymentAndDonationSteps() {
        const shouldSplitFlow = false; // TODO @flow2 this is not enabled for now

        Flow.paymentMethod.init({
            continueLabel: Messages.finalizePayment,
            scope: PaymentMethodStore.Scope.PRIVATE,
        });

        if (shouldSplitFlow) {
            return [
                Flow.donationDetails,
                Flow.paymentMethod,
            ]
        }

        return [
            Flow.paymentAndDonationDetails,
        ]
    }

    getFlowPlan() {
        const {editRecurring, skipCTA, skipAuth} = iFrameState;

        if (!skipCTA) {
            Router.changeToCustomPanel();
            return null;
        }

        const activeDonation = Flow.donation.getActiveDonation();

        if (editRecurring && activeDonation) {
            Flow.paymentMethod.init({
                entry: activeDonation,
                savePaymentMethod: true,
                selectedValue: activeDonation.getPaymentMethod(),
                continueLabel: Messages.updatePaymentMethod,
                scope: PaymentMethodStore.Scope.PRIVATE,
            });

            Flow.donationDetails.updateFromDonation(activeDonation);

            Router.changeURL(INLINE_EDIT_DONATION_URLS.donate);
            return null;
        }

        // If we have an active donation and editing wasn't explicitly requested, we'll only be allowed to make a one-time donation
        return [
            !skipAuth && Flow.auth,

            ...this.getPaymentAndDonationSteps(),

            Flow.address.init({
                shouldPrompt: () => this.shouldCollectAddress(),
                continueLabel: Messages.finalizeDonation,
            }),
        ]
    }

    getActiveDonation() {
        return iFrameUserDataService.getActiveRecurringDonation();
    }

    shouldCollectAddress() {
        const donationOffer = Flow.donationDetails.getDonationOffer();
        return donationOffer.shouldCollectAddress();
    }

    async callDonateEndpoint(payload) {
        const apiDonateEndpoint = iFrameMerchantService.isCDSPaymentMethod() ? apiDonateWithCds : apiDonate;

        const donation = await apiDonateEndpoint(payload);

        if (donation.isActive() || donation.isFinished()) {
            sendFlowCheckpoint(FLOW_TYPE.donation, FLOW_CHECKPOINT.complete, {
                donationId: donation.id,
                offerId: donation.recurringOfferId,
                // TODO @Mihai: Change this
                amount: donation.getPrice().toMainUnitString({includeSymbol: false}),
                currency: donation.currency.isoCode,
                recurring: donation.isRecurring(),
                frequency: donation.frequency.toString()
            });
        }

        return donation;
    }

    async getPayload() {
        const payload = {
            ...Flow.donationDetails.getPayload(),
            ...(await Flow.paymentMethod.getPayload()),
        };

        if (this.shouldCollectAddress()) {
            // This must have been already collected
            payload.userAddressId = Flow.address.selectedValue.id;
        }

        return payload;
    }

    @wrapInSpinner
    async finalize() {
        const payload = await this.getPayload();
        payload.recurringOfferId = Flow.donationDetails.getDonationOffer().id; // TODO @branch take from Flow.donationDetails

        dispatchAnalyticsEvent(AnalyticsEventType.ATTEMPT_DONATE, {
            ...Flow.paymentMethod.maskPayload(payload),
            isCustomAmount: Flow.donationDetails.isDonationAmountCustom(),
        });

        // We need to re-enter the spinner state, since the previous steps might have removed it
        // in need of other inputs
        LoadingSpinner.show();
        try {
            await this.callDonateEndpoint(payload);
        } catch (error) {
            Flow.paymentMethod.update({
                paymentError: error,
                retryPayment: () => this.finalize(),
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
            return;
        }

        Router.changeURL(INLINE_DONATION_URLS.thankYou);
    }

    @wrapInSpinner
    async cancelActiveDonation() {
        const donation = this.getActiveDonation();

        await apiCancelDonation(donation);

        this.previouslyCanceledDonation = donation;
        Router.changeURL(INLINE_EDIT_DONATION_URLS.cancelSuccess);
    }

    @wrapInSpinner
    async updateDonation() {
        const activeDonation = this.getActiveDonation();
        const payload = await this.getPayload();

        if (iFrameMerchantService.isCDSPaymentMethod()) {
            // Payment data needs to be entered again
            if (!payload.paymentInfo) {
                await Flow.paymentMethod.prompt({
                    scope: PaymentMethodStore.Scope.PRIVATE,
                });
                const paymentPayload = await Flow.paymentMethod.getPayload();
                Object.assign(payload, paymentPayload);
            }

            // TODO @flow not sure if we need this
            Object.assign(payload, {
                userAddressId: activeDonation.getShippingAddress()?.id || UserAddressStore.all()[0]?.id,
                frequency: activeDonation.frequency, // TODO @flow this is because we can't edit the frequency
            });

            await apiEditDonationWithCds(payload);
        } else {
            await apiUpdateRecurringDonation(activeDonation, payload);
        }

        Router.changeURL(INLINE_EDIT_DONATION_URLS.thankYou);
    }
}
