export class KeyEvent {
    constructor(event) {
        this.event = event;
    }

    is(key) {
        return this.event.code === key || this.event.key === key;
    }

    isArrowUp() {
        return this.is("ArrowUp");
    }

    isArrowDown() {
        return this.is("ArrowDown");
    }

    isArrowLeft() {
        return this.is("ArrowLeft");
    }

    isArrowRight() {
        return this.is("ArrowRight");
    }

    isEnter() {
        return this.is("Enter");
    }

    isSpace() {
        return this.is("Space");
    }

    isBackspace() {
        return this.is("Backspace");
    }

    isDelete() {
        return this.is("Delete");
    }

    isEscape() {
        return this.is("Escape");
    }

    isTab() {
        return this.is("Tab");
    }

    isDigit() {
        return this.event.key.length === 1 && this.event.key.match(/[0-9]/);
    }

    toString() {
        return this.event.key || this.event.code;
    }
}
