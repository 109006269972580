import {Theme} from "../../stem-core/src/ui/style/Theme.js";
import {
    BoolType,
    BorderType, BoxShadowType,
    ColorType, FloatType,
    FontFamilyType,
    FontWeightType, ObjectType,
    SizeType, TextShadowType
} from "../../stem-core/src/ui/style/ThemeTypes.js";
import {Color, enhance} from "../../stem-core/src/ui/Color.js";
import {Link} from "../../stem-core/src/ui/primitives/Link.jsx";
import {BlinkLinkStyle} from "../../core/ui/LinkButton.jsx";
import {DEFAULT_TRANSITION_DURATION_MS} from "../../blink-sdk/Constants.js";
import {SetMakeIcon, SetMakeText} from "../../stem-core/src/ui/SimpleElements.jsx";
import {BlinkMakeIcon} from "../../core/ui/MakeIcon.jsx";
import {BlinkMakeText} from "../../core/ui/MakeText.jsx";


export function autoenhance(color){
    const enhanceToDark = Color.isLight(color) || Color.isBlack(color);
    const gammaEnhance = enhanceToDark ? 0.03 : -0.15;
    const saturationEnhance = enhanceToDark ? 0.1 : 0.2;
    return enhance(color, gammaEnhance, saturationEnhance);
}


// TODO deprecate merchantStyleRule but use the logic on this
// This is both a whitelist of what the merchant can overwrite, and a type for them to generate a better input
const MERCHANT_STYLE_WHITELIST = {
    // --------------- TYPOGRAPHY ---------------------
    FONT_FAMILY_DEFAULT: FontFamilyType(props => props.FONT_FAMILY_SANS_SERIF),

    FONT_SIZE_SMALL: SizeType(12),
    FONT_SIZE_NORMAL: SizeType(14),
    FONT_SIZE_LARGE: SizeType(16),
    FONT_SIZE_TITLE: SizeType(21),

    FONT_WEIGHT_LIGHT: FontWeightType("lighter"),
    FONT_WEIGHT_REGULAR: FontWeightType("normal"),
    FONT_WEIGHT_BOLD: FontWeightType("bold"),

    TEXT_PRIMARY_COLOR: ColorType("#232529"),
    TEXT_SECONDARY_COLOR: ColorType("#545F69"),

    LINK_COLOR: ColorType("#0080FF"),
    LINK_COLOR_HOVER: ColorType(props => autoenhance(props.LINK_COLOR)),

    BUTTON_BASE_STYLE: ObjectType({}), // TODO @cleanup @rename to MERCHANT_BUTTON_STYLE

    BUTTON_PRIMARY_COLOR: ColorType(props => props.WHITE),
    BUTTON_PRIMARY_BACKGROUND: ColorType("linear-gradient(180deg, #125C67 0%, #0F4851 100%)"),
    BUTTON_PRIMARY_BORDER_WIDTH: SizeType(1),
    BUTTON_PRIMARY_BORDER_COLOR: ColorType("#0F4851"),
    BUTTON_PRIMARY_TEXT_SHADOW: TextShadowType("none"),
    BUTTON_PRIMARY_SHADOW: BoxShadowType("none"),
    BUTTON_PRIMARY_HOVER_COLOR: ColorType(props => props.BUTTON_PRIMARY_COLOR),
    BUTTON_PRIMARY_HOVER_BACKGROUND: ColorType(props => autoenhance(props.BUTTON_PRIMARY_BACKGROUND)),
    BUTTON_PRIMARY_HOVER_BORDER_COLOR: ColorType(props => autoenhance(props.BUTTON_PRIMARY_BORDER_COLOR)),
    BUTTON_PRIMARY_HOVER_SHADOW: BoxShadowType("none"),

    BUTTON_SECONDARY_COLOR: ColorType("#535558"),
    BUTTON_SECONDARY_BACKGROUND: ColorType("transparent"),
    BUTTON_SECONDARY_BORDER_WIDTH: SizeType(1),
    BUTTON_SECONDARY_BORDER_COLOR: ColorType(props => props.BUTTON_SECONDARY_COLOR),
    BUTTON_SECONDARY_TEXT_SHADOW: TextShadowType("none"),
    BUTTON_SECONDARY_SHADOW: BoxShadowType("none"),
    BUTTON_SECONDARY_HOVER_COLOR: ColorType(props => props.BUTTON_SECONDARY_COLOR),
    BUTTON_SECONDARY_HOVER_BACKGROUND: ColorType(props => autoenhance(props.BUTTON_SECONDARY_BACKGROUND)),
    BUTTON_SECONDARY_HOVER_BORDER_COLOR: ColorType(props => autoenhance(props.BUTTON_SECONDARY_BORDER_COLOR)),
    BUTTON_SECONDARY_HOVER_SHADOW: BoxShadowType("none"),

    // --------------- INPUTS -------------------------
    BASE_BORDER_RADIUS: SizeType(6, "The default border radius used in buttons, cards, input, etc."),

    INPUT_DEFAULT_HEIGHT: SizeType(42),
    INPUT_BACKGROUND: ColorType("#FDFFFE"),
    INPUT_BACKGROUND_HOVER: ColorType(null),
    INPUT_BORDER_RADIUS: SizeType(props => props.BASE_BORDER_RADIUS),
    INPUT_BORDER_COLOR: ColorType(Color.addOpacity("#545F69", 0.24).toString()),
    INPUT_BORDER_ACTIVE_COLOR: ColorType(Color.addOpacity("#545F69", 0.5).toString()),
    INPUT_TEXT_COLOR: ColorType("#444F59"),
    INPUT_PLACEHOLDER_COLOR: ColorType("rgba(102,102,102,.72)"),
    INPUT_NOT_EDITABLE_LABEL_COLOR: ColorType(props => props.MERCHANT_2),

    CHECKBOX_SIZE: SizeType("1.14em"),
    CHECKBOX_BORDER_RADIUS: SizeType(2),
    CHECKBOX_BORDER_COLOR: ColorType(props => props.WALLET_11),
    CHECKBOX_ENABLED_BACKGROUND_COLOR: ColorType(props => props.WALLET_11),
    CHECKBOX_CHECKMARK_COLOR: ColorType(props => props.WHITE),

    RADIO_BUTTON_BORDER_RADIUS: SizeType(4),
    RADIO_BUTTON_PADDING: SizeType(12),
    RADIO_BUTTON_FLEX: SizeType(1), // TODO @theme this shouldn't be a constant

    POPUP_BACKGROUND: ColorType(props => props.WHITE),
    POPUP_BORDER: BorderType(null),
    POPUP_SHADOW: BoxShadowType("0 2px 8px 5px rgba(0,0,0,.1), 0 5px 27px -5px rgba(0,0,0,.37)"),
    POPUP_MAX_WIDTH: SizeType(360),
    POPUP_MAX_HEIGHT: SizeType(480),

    LOADING_SPINNER_BACKGROUND: ColorType("rgba(255,255,255,.84)"),
    LOADING_SPINNER_COLOR: ColorType("#125C67"),

    SUCCESS_COLOR: ColorType("#11BA87"),
    WARNING_COLOR: ColorType("#F0AD4E"),
    ERROR_COLOR: ColorType("#FF4848"),

    BASE_DISABLED_OPACITY: FloatType(0.6),

    INPUT_USE_PLACEHOLDER_AS_LABEL: BoolType(false),
};


export function GetCleanMerchantThemeProps(themeProps) {
    // TODO This conversion table will be pruned after we update the merchant in prod
    const NORMALIZED_KEY_NAMES = {
        MERCHANT_BUTTON_INPUT_TOAST_BORDER_RADIUS: "BASE_BORDER_RADIUS",
        MERCHANT_USE_INPUT_PLACEHOLDER_INSTEAD_OF_LABEL: "INPUT_USE_PLACEHOLDER_AS_LABEL",
        MERCHANT_SUCCESS: "SUCCESS_COLOR",
        MERCHANT_ERROR: "ERROR_COLOR",
        MERCHANT_FONT_FAMILY: "FONT_FAMILY_DEFAULT",
        MERCHANT_SPINNER_BACKGROUND: "LOADING_SPINNER_BACKGROUND",
        MERCHANT_SPINNER_COLOR: "LOADING_SPINNER_COLOR",
        MERCHANT_BUTTON_PRIMARY_TEXT_COLOR: "MERCHANT_BUTTON_PRIMARY_COLOR",
        MERCHANT_BUTTON_PRIMARY_TEXT_COLOR_HOVER: "MERCHANT_BUTTON_PRIMARY_HOVER_COLOR",
        MERCHANT_BUTTON_PRIMARY_BACKGROUND_HOVER: "MERCHANT_BUTTON_PRIMARY_HOVER_BACKGROUND",
        MERCHANT_BUTTON_PRIMARY_BORDER_COLOR_HOVER: "MERCHANT_BUTTON_PRIMARY_HOVER_BORDER_COLOR",
        MERCHANT_BUTTON_SECONDARY_TEXT_COLOR: "MERCHANT_BUTTON_SECONDARY_COLOR",
        MERCHANT_BUTTON_SECONDARY_BACKGROUND_HOVER: "MERCHANT_BUTTON_SECONDARY_HOVER_BACKGROUND",
        MERCHANT_BUTTON_SECONDARY_BORDER_COLOR_HOVER: "MERCHANT_BUTTON_SECONDARY_HOVER_BORDER_COLOR",
    }

    // First we'll rename some fields that were poorly named before
    const result = {};
    for (const [key, value] of Object.entries(themeProps)) {
        const newKey = NORMALIZED_KEY_NAMES[key] ?? key;
        if (themeProps.hasOwnProperty(newKey) && newKey === NORMALIZED_KEY_NAMES[key]) {
            continue;
        }
        result[newKey] = value;
    }

    const MERCHANT_PREFIX = "MERCHANT_";

    for (const [key, value] of Object.entries(result)) {
        if (key.startsWith(MERCHANT_PREFIX)) {
            const newKey = key.substring(MERCHANT_PREFIX.length);
            if (themeProps.hasOwnProperty(newKey)) {
                continue;
            }
            if (MERCHANT_STYLE_WHITELIST[newKey]) {
                result[newKey] = value;
            }
        }
    }

    return result;
}

export function initializeTheme(merchantThemeConstants={}) {
    Theme.setProperties({
        WALLET_0: "#F8FBFB",
        WALLET_1: "#E8F0F2",
        WALLET_2: "#D2E2E5",
        WALLET_3: "#BBD4D8",
        WALLET_4: "#A5C6CB",
        WALLET_5: "#8EB8BE",
        WALLET_6: "#78AAB2",
        WALLET_7: "#619CA5",
        WALLET_8: "#4B8E98",
        WALLET_9: "#34808B",
        WALLET_10: "#1E727E",
        WALLET_11: "#086472",
        WALLET_12: "#085B68",
        WALLET_13: "#07525E",
        WALLET_14: "#064953",
        WALLET_15: "#064049",
        WALLET_16: "#05373F",
        WALLET_17: "#042E34",
        WALLET_18: "#03252A",
        WALLET_19: "#031C20",
        WALLET_20: "#021315",
        WALLET_21: "#010506",

        MERCHANT_1: "#A0A0A0",
        MERCHANT_2: "#6C6C6C",
        MERCHANT_3: "#3F3F3F",
        MERCHANT_4: "#232323",
        MERCHANT_5: "#151515",

        WHITE: "#FFFFFF", // TODO @theme nobody means props.WHITE

        // ------------------- INPUTS & BUTTONS --------------------
        SOCIAL_BUTTON_SHADOW: "0 5px 8px 0 rgba(50, 50, 93, 0.11), 0 2px 4px 0 rgba(0, 0, 0, 0.08)",
        SOCIAL_BUTTON_HOVER_SHADOW: "0 4px 7px 0 rgba(50, 50, 93, 0.22), 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        SOCIAL_BUTTON_FACEBOOK_COLOR: props => props.WHITE,
        SOCIAL_BUTTON_FACEBOOK_BACKGROUND: "#3B5A9A",
        SOCIAL_BUTTON_GOOGLE_COLOR: "rgba(0, 0, 0, 0.72)",
        SOCIAL_BUTTON_GOOGLE_BACKGROUND: props => props.WHITE,
        SOCIAL_BUTTON_LINKEDIN_COLOR: props => props.WHITE,
        SOCIAL_BUTTON_LINKEDIN_BACKGROUND: "#0077B5",
        SOCIAL_BUTTON_TWITTER_COLOR: props => props.WHITE,
        SOCIAL_BUTTON_TWITTER_BACKGROUND: "#1da1f2",

        TOGGLE_BACKGROUND: props => props.WALLET_2,
        TOGGLE_COLOR: props => props.WALLET_11,
        TOGGLE_SHADOW: "0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12), 0 1px 3px 0 rgba(0,0,0,.2)",
        TOGGLE_DISABLED_BACKGROUND: props => props.WALLET_6,
        TOGGLE_PILL_SIZE: 20,

        DROPDOWN_ITEM_BORDER: "rgba(27,126,140,.24)",
        DROPDOWN_ITEM_HOVER_BACKGROUND: "rgba(2, 92, 150, 0.06)",

        RADIO_SIZE: 16,
        RADIO_COLOR: "#545F69",

        // ---------------------- GENERAL ------------------------
        DEFAULT_TRANSITION_DURATION_MS: DEFAULT_TRANSITION_DURATION_MS,
        DEFAULT_TRANSITION: props => props.DEFAULT_TRANSITION_DURATION_MS + "ms ease",

        APP_BACKGROUND: "#FAFDFD",
        NAV_MOBILE_COLOR: props => props.WHITE,
        NAV_MOBILE_SHADOW: "0 8px 16px -8px rgba(0, 0, 0, 0.15), 0 13px 27px -5px rgba(50, 50, 93, 0.12)",
        NAVBAR_BOX_SHADOW: "0 12px 12px -6px rgba(0, 0, 0, 0.12)",
        NAVBAR_BACKGROUND: props => props.WALLET_11,
        NAVBAR_CENTER_ITEM_ACTIVE: props => props.WHITE,
        NAVBAR_CENTER_ITEM_INACTIVE: props => props.WALLET_3,

        MODAL_BACKGROUND: props => props.WALLET_0,
        MODAL_SHADOW: "0 0 6px 0 rgba(0,0,0,.12), 0 8px 16px -8px rgba(0,0,0,.15), 0 13px 27px -5px rgba(50,50,93,.12)",
        MODAL_TRANSITION_TIME_MS: 150,
        MODAL_TRANSITION: props => `${props.MODAL_TRANSITION_TIME_MS}ms ease`,

        SPINNER_COLOR: props => props.WALLET_8, // TODO Move this?
        DASHBOARD_SCRIM_BACKGROUND: "rgba(255,255,255,.84)", // For all dashboards

        BASE_CARD_SHADOW: "0 6px 12px -6px rgba(0,0,0,.12), 0 10px 20px -4px rgba(0,0,0,0.12)",
        BASE_CARD_BACKGROUND: props => props.WALLET_0,
        BASE_CARD_TITLE_COLOR: props => props.WALLET_11,
        BASE_CARD_WIDTH: 480,
        BASE_CARD_MAX_WIDTH: 480,

        CARD_HEADER_TEXT_COLOR: "#005A64", // TODO merge with BUTTON_COLOR into PRIMARY
        CARD_HEADER_BACKGROUND_COLOR: "#F3F8F7",
        LOGO_CARD_SHADOW: "0 6px 10px -6px rgba(0, 0, 0, 0.3), 0 9px 18px -4px rgba(50, 50, 93, 0.25)",
        LOGO_CARD_CONTENT_BACKGROUND: props => props.WHITE,

        DIVIDER_TEXT_COLOR: "rgba(0, 0, 0, 0.48)",
        DIVIDER_LINE_COLOR: "rgba(84, 95, 105, 0.12)",

        TOAST_TEXT_COLOR: props => props.WHITE,
        TOAST_BACKGROUND: "rgba(0,0,0,.84)",

        // ----------------------- TABLE & FAKE TABLE -------------------
        TABLE_HEADER_TEXT_COLOR: props => props.CARD_HEADER_TEXT_COLOR,
        TABLE_HEADER_BACKGROUND_COLOR: props => props.CARD_HEADER_BACKGROUND_COLOR,
        TABLE_HEADER_BACKGROUND_ALTERNATE: "#F5F5F5",

        // ------------------------- MISC ----------------------------
        SETTINGS_LABEL_COLOR: props => props.MERCHANT_4,
        DASHBOARD_DESCRIPTION_COLOR: props => props.MERCHANT_2,
        GENERAL_LINE_HEIGHT: "1.5",

        // TODO Wallet specific, do not polute globally
        WALLET_BALANCE_COLOR: props => props.WHITE,
        WALLET_GRADIENT_BACKGROUND: "linear-gradient(60deg, #085B68, #064953)",
    });

    Theme.setProperties(MERCHANT_STYLE_WHITELIST);

    // TODO move this somewhere else
    Theme.register(Link, BlinkLinkStyle);

    // TODO remove the MERCHANT_ in the setting file
    const MerchantDefaultConstants = {
        // -------------- INPUTS & BUTTONS ---------------
        MERCHANT_INPUT_STYLE: {},

        // -------------- GENERAL ----------------------
        MERCHANT_SCRIM_BACKGROUND: "rgba(0,0,0,.5)",

        MERCHANT_FRAME_BACKGROUND: "#FBFDFD",
        MERCHANT_FRAME_BORDER: null,
        MERCHANT_FRAME_HEADER_BACKGROUND: "#F3F7F8",
        MERCHANT_FRAME_CLOSE_BUTTON_COLOR: "#242424",
        MERCHANT_FRAME_CLOSE_BUTTON_SIZE: 28,
        MERCHANT_FRAME_BORDER_COLOR: "rgba(84, 95, 105, 0.18)",
        MERCHANT_FRAME_BORDER_RADIUS: "10px",
        MERCHANT_FRAME_HEADER_BORDER_COLOR: Color.addOpacity("#545F69", 0.12).toString(),

        MERCHANT_CARD_BACKGROUND: props => props.WHITE,
        MERCHANT_CARD_BOX_SHADOW: "0 12px 12px 0px rgba(0, 0, 0, 0.12)",
        MERCHANT_CARD_BORDER_COLOR: "#E3E3E3",

        MERCHANT_FOOTER_TEXT: "#545F69",
        MERCHANT_FOOTER_LOGO: "#545F69",

        MERCHANT_USER_DASHBOARD_TAB_STYLE: {
            background: "#E0E0E0",
            textDecoration: "none !important",
        },
        MERCHANT_USER_DASHBOARD_ACTIVE_TAB_STYLE: props => ({
            background: props.WHITE,
        }),

        MERCHANT_TITLE_STYLE: {},
    };

    const cleanMerchantThemeConstants = GetCleanMerchantThemeProps(merchantThemeConstants);

    Theme.setProperties({
        ...MerchantDefaultConstants,
        ...cleanMerchantThemeConstants
    });

    SetMakeIcon(BlinkMakeIcon);
    SetMakeText(BlinkMakeText);
}
