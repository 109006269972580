import {UI, styleRule, StyleSheet, registerStyle} from "../../../stem-core/src/ui/UI.js";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils.js";
import {ToggleInput} from "../../../stem-core/src/ui/input/checkbox/ToggleInput.jsx";

class LabeledToggleStyle extends StyleSheet {
    @styleRule
    container = {
        padding: "12px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 6,
    };

    @styleRule
    label = {
        fontSize: this.themeProps.FONT_SIZE_LARGE,
        color: this.themeProps.SETTINGS_LABEL_COLOR,
    }
}

@registerStyle(LabeledToggleStyle)
export class LabeledToggle extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onChange: NOOP_FUNCTION,
            initialValue: false,
            label: ""
        }
    }

    render() {
        const {styleSheet} = this;
        const {label, initialValue, onChange} = this.options;

        return [
            <div className={styleSheet.label}>
                {label}
            </div>,
            <ToggleInput initialValue={initialValue} onChange={onChange}/>
        ]
    }
}
