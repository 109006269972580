import {apiClient, apiStreamQuery} from "../connection/BlinkApiClient.js";
import {Money} from "../../stem-core/src/localization/Money.js";
import {PaymentStatusDependentStore} from "./misc/StoreUtils";
import {RecurringPaymentStoreObject} from "./RecurringPaymentStoreObject";
import {SubscriptionDiscountStore} from "./SubscriptionDiscountStore";
import {field} from "../../stem-core/src/state/StoreField.js";
import {MerchantUserStore} from "./merchant/MerchantUserStore";
import {SubscriptionBenefitStore} from "./SubscriptionBenefitStore.js";


export const SubscriptionApplyPolicy = {
    INSTANTLY: "instantly",
    NEXT_BILLING_CYCLE: "next_billing_cycle",
};

export class Subscription extends RecurringPaymentStoreObject {
    @field("BillingPlan") billingPlan;
    @field("SubscriptionCoverage") coverage;
    @field("SubscriptionOffer") recurringOffer;
    @field("SubscriptionDiscount") discount;
    @field("Subscription") fromGroupSubscription;
    @field("UserProfile") user;
    @field("UserProfile") billedUser;
    // TODO @Mihai Only for group subscriptions, disabled for now
    @field(Boolean) isGroupSubscription;
    @field(Array) groupEmailDomains;
    @field(Array) groupIpV4Ranges;
    @field(Number) groupNumCustomBeneficiaries;
    @field(Boolean) ownerCanEditIpRanges;
    @field(Boolean) ownerCanEditDomains;

    async reactivate() {
       return apiReactivateSubscription(this.id);
    }

    async updatePaymentMethod(paymentMethodId) {
        return apiUpdateSubscription({
            subscriptionId: this.id,
            preferredPaymentMethodId: paymentMethodId,
        });
    }

    getMerchantUser() {
        return MerchantUserStore.getByUserAndMerchant(this.userId, this.merchantId);
    }

    // TODO Deprecate this field
    getDiscount() {
        return SubscriptionDiscountStore.get(this.discountId);
    }

    getPrice() {
        return Money.optionally(this.lastCyclePrice?.finalPrice, this.getCurrency());
    }

    serializeForSDK() {
        const {id, deliveryAddressId, blinkSignature, nextPaymentAttempt, userId, amount, createdAt, canceledAt, activeUntil, billingPlanId} = this;
        let obj = {id, deliveryAddressId, blinkSignature, nextPaymentAttempt, userId, amount, createdAt, canceledAt, activeUntil, billingPlanId};
        obj.currencyCode = this.getCurrency().getIsoCode();
        obj.name = this.coverage.getName();
        obj.billingPlan = this.billingPlan.serializeForSDK();

        const benefitIds = this.recurringOffer.getAllBenefitIds();
        obj.benefits = benefitIds.map((benefitId) => {
            const benefit = SubscriptionBenefitStore.get(benefitId);
            if (!benefit) {
                return {
                    id: benefitId,
                }
            }
            return benefit.serializeForSDK();
        });
        return obj;
    }

    formatPriceShortened() {
        return `${this.getPrice()}/${this.billingPlan.getAbbreviatedCycleDuration()}`;
    }

    formatPrice() {
        return `${this.getPrice()}/${this.billingPlan.getCycleDuration(true)}`;
    }
}

export class SubscriptionStoreClass extends PaymentStatusDependentStore("subscription", Subscription, {dependencies: ["Currency", "Merchant", "SubscriptionCoverage", "SubscriptionOffer", "BillingPlan"]}) {
    Status = Subscription.Status;

    getActive() {
        return this.filter(subscription => subscription.isActive());
    }

    getUnpaid() {
        return this.filter(subscription => subscription.isUnpaid());
    }

    getActiveAndNotCanceled() {
        return this.filter(subscription => subscription.status === this.Status.ACTIVE);
    }

    getPast() {
        return this.filter(subscription => !subscription.isActive() && !subscription.isUnpaid());
    }

    getActiveWithAddress(address) {
        return this.getActive().filter(sub => sub.getShippingAddress() === address);
    }
}

export const SubscriptionStore = new SubscriptionStoreClass();

export async function apiSubscribe(payload) {
    const response = await apiClient.post("/subscriptions/subscribe", payload);
    return SubscriptionStore.load(response).find(subscription => subscription.isActive());
}

export async function apiSubscribeWithCds(payload) {
    const response = await apiClient.post("/external_providers/subscribe_with_cds", payload);
    return SubscriptionStore.load(response).find(subscription => subscription.isActive());
}

export async function apiUpdateSubscription(payload) {
    const response = await apiClient.post("/subscriptions/update_subscription", payload);
    return SubscriptionStore.load(response).find(subscription => !subscription.isFinished());
}

export async function apiRequestSubscriptionPriceOnAddressChange(subscriptionId, merchantId, deliveryAddressId) {
    return apiClient.get("/subscriptions/calculate_update_subscription_price", {
        subscriptionId,
        merchantId,
        deliveryAddressId,
    });
}

export async function apiCancelSubscription(subscriptionId) {
    return apiClient.post("/subscriptions/cancel", {subscriptionId});
}

export async function apiReactivateSubscription(subscriptionId) {
    return apiClient.post("/subscriptions/reactivate", {subscriptionId});
}

export async function apiMerchantUpdateSubscriptionGracePeriod(request) {
    return apiClient.post("/merchant/update_subscription_grace_period", request);
}

export async function apiMerchantCancelSubscription(request) {
    return apiClient.post("/merchant/cancel_subscription", request);
}

export async function apiMerchantCreateGroupSubscription(request) {
    const response = await apiClient.post("/merchant/create_group_subscription", request);
    return SubscriptionStore.loadObject(response);
}

export async function apiMerchantEditGroupSubscription(request) {
    return apiClient.post("/merchant/edit_group_subscription", request);
}

export async function apiMerchantProcessSubscriptionSentToOffline(request) {
    return apiClient.post("/external_providers/process_subscription_sent_to_offline", request);
}

export function apiStreamSubscriptions(filters) {
    return apiStreamQuery("/merchant/get_subscriptions", filters);
}
