import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {BlinkLogo, BlinkPoweredByIcon} from "../../../core/ui/SVGElements.jsx";
import {accessDashboardURLFromIFrame} from "../../widget/misc/WidgetUtils.js";
import {Link} from "../../../stem-core/src/ui/primitives/Link.jsx";


class PoweredByBlinkStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 0",
    };

    @styleRule
    link = {
        lineHeight: 0,
        fontSize: 0,
    }

    @styleRule
    logo = {
        paddingLeft: 5,
    };
}

@registerStyle(PoweredByBlinkStyle)
export class PoweredByBlink extends UI.Element {
    getDefaultOptions() {
        return {
            poweredByColor: this.themeProps.MERCHANT_FOOTER_TEXT,
            blinkLogoColor: this.themeProps.MERCHANT_FOOTER_LOGO,
        };
    }

    render() {
        const {styleSheet} = this;
        return [
            // Fixes Safari overflow. Maybe use this on all SVG Elements?
            <BlinkPoweredByIcon size={16} color={this.options.poweredByColor} />,
            <Link href="/" newTab onClick={accessDashboardURLFromIFrame} className={styleSheet.link}>
                <BlinkLogo size={13} color={this.options.blinkLogoColor} className={styleSheet.logo}/>
            </Link>
        ];
    }
}
