import {Button, registerStyle, styleRule, StyleSheet} from "../../stem-core/src/ui/UI.js";

import {BUTTON_TYPE} from "../../blinkpay/Constants.js";
import {deepCopy} from "../../stem-core/src/base/Utils.js";


export class BlinkButtonStyle extends StyleSheet {
    @styleRule
    container = deepCopy({
        padding: 12,
        borderStyle: "solid !important", // Voodoo: Seems strange that important is needed in some cases
        borderRadius: this.themeProps.BASE_BORDER_RADIUS,
        outline: 0,
        fontSize: this.themeProps.FONT_SIZE_LARGE,
        cursor: "pointer",
        transition: this.themeProps.DEFAULT_TRANSITION,
        userSelect: "none",
        display: "inline-block",
        textAlign: "center",
        "[disabled]": {
            cursor: "not-allowed", // TODO: this is not working because pointerEvents is none
            opacity: this.themeProps.BASE_DISABLED_OPACITY,
            pointerEvents: "none"
        },
    }, this.themeProps.BUTTON_BASE_STYLE);

    @styleRule
    primaryButton = {
        color: this.themeProps.BUTTON_PRIMARY_COLOR,
        background: this.themeProps.BUTTON_PRIMARY_BACKGROUND,
        borderWidth: this.themeProps.BUTTON_PRIMARY_BORDER_WIDTH,
        borderColor: this.themeProps.BUTTON_PRIMARY_BORDER_COLOR,
        boxShadow: this.themeProps.BUTTON_PRIMARY_SHADOW,
        textShadow: this.themeProps.BUTTON_PRIMARY_TEXT_SHADOW,
        ":hover:not([disabled])": {
            color: this.themeProps.BUTTON_PRIMARY_HOVER_COLOR,
            background: this.themeProps.BUTTON_PRIMARY_HOVER_BACKGROUND,
            borderColor: this.themeProps.BUTTON_PRIMARY_HOVER_BORDER_COLOR,
            boxShadow: this.themeProps.BUTTON_PRIMARY_HOVER_SHADOW,
        },
    };

    @styleRule
    secondaryButton = {
        color: this.themeProps.BUTTON_SECONDARY_COLOR,
        background: this.themeProps.BUTTON_SECONDARY_BACKGROUND,
        borderWidth: this.themeProps.BUTTON_SECONDARY_BORDER_WIDTH,
        borderColor: this.themeProps.BUTTON_SECONDARY_BORDER_COLOR,
        boxShadow: this.themeProps.BUTTON_SECONDARY_SHADOW,
        textShadow: this.themeProps.BUTTON_SECONDARY_TEXT_SHADOW,
        ":hover:not([disabled])": {
            color: this.themeProps.BUTTON_SECONDARY_HOVER_COLOR,
            background: this.themeProps.BUTTON_SECONDARY_HOVER_BACKGROUND,
            boxShadow: this.themeProps.BUTTON_SECONDARY_HOVER_SHADOW,
            borderColor: this.themeProps.BUTTON_SECONDARY_HOVER_BORDER_COLOR,
        },
    };

    Size() {}
    Level() {}
}

@registerStyle(BlinkButtonStyle)
export class BlinkButton extends Button {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            type: BUTTON_TYPE.PRIMARY,
        };
    }

    extraNodeAttributes(attr) {
        const {styleSheet} = this;
        if (this.options.type === BUTTON_TYPE.PRIMARY) {
            attr.addClass(styleSheet.primaryButton);
        } else {
            attr.addClass(styleSheet.secondaryButton);
        }
    }

    // TODO Fully correct, a manual call to disable would also clear the timeout
    tempDisable(durationMs) {
        this.disable();
        this.attachTimeout(() => this.enable(), durationMs);
    }
}
