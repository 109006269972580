import {UI, StyleSheet, registerStyle, styleRule} from "../../stem-core/src/ui/UI";
import {VerticalDotsIcon} from "../../core/ui/SVGElements.jsx";
import {AnchoredPopup} from "../../stem-core/src/ui/AnchoredPopup.jsx";


class PopupMenuStyle extends StyleSheet {
    @styleRule
    container = {
        cursor: "pointer",
    }

    @styleRule
    menuItem = {
        padding: "6px 12px",
        cursor: "pointer",
        transition: this.themeProps.DEFAULT_TRANSITION,
        userSelect: "none",
        ":hover": {
            background: this.themeProps.DROPDOWN_ITEM_HOVER_BACKGROUND,
        },
    };

    @styleRule
    menuItemDisabled = {
        opacity: this.themeProps.BASE_DISABLED_OPACITY,
        pointerEvents: "none",
        cursor: "not-allowed",
    }
}

// The container that has all the menu options
// TODO @cleanup max height 200px
class AnchoredMenuPopup extends AnchoredPopup {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            toggleOnSameAnchor: true,
            testId: "menuPopup"
        }
    }

    renderMenuItem(item) {
        const styleSheet = PopupMenuStyle.getInstance(this.getTheme());

        return <div
            onClick={() => {
                this.hide();
                item.onClick(); // TODO @cleanup should be maybe ".callback()
            }}
            className={styleSheet.menuItem + (item.disabled ? styleSheet.menuItemDisabled : "")}
        >
            {item.content}
        </div>
    }

    render() {
        return this.options.items.map(item => this.renderMenuItem(item));
    }
}


@registerStyle(PopupMenuStyle)
export class PopupMenu extends UI.Element {
    getDefaultOptions() {
        return {
            testId: "popupMenu",
        }
    }

    render() {
        return <VerticalDotsIcon size={16}/>
    }

    onMount() {
        this.addClickListener(() => AnchoredMenuPopup.show({
            anchor: this,
            items: this.options.items
        }));
    }
}