import {UI} from "../../../stem-core/src/ui/UI.js";
import {RawHTML} from "../../../stem-core/src/ui/RawHTML.jsx";
import {LoadingSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {apiClient} from "../../../client/connection/BlinkApiClient.js";
import {PolicyType} from "../../../client/state/PolicyTermsStore.js";

export function RenderPolicyHTML(htmlTemplate) {
    return <RawHTML style={{padding: 20, maxWidth: 800, margin: "auto", fontFamily: "serif", color: "#1E1C29"}} __innerHTML={htmlTemplate}/>
}


const PolicyPage = (policyType) => class extends UI.Element {
    async fetchHTML() {
        try {
            const response = await apiClient.get("/compliance/policy", {type: policyType});
            Object.assign(this, {
                loaded: true,
                innerHTML: response.htmlTemplate,
            });
        } catch (error) {
            Object.assign(this, {
                loaded: true,
                error
            });
        }
        this.redraw();
    }

    render() {
        if (!this.loaded) {
            return <LoadingSpinner/>;
        }

        if (this.error) {
            return "Error loading terms, please try again later";
        }

        return RenderPolicyHTML(this.innerHTML);
    }

    onMount() {
        this.fetchHTML();
    }
}

export const CookiePolicyPage = PolicyPage(PolicyType.COOKIE);
export const TermsPolicyPage = PolicyPage(PolicyType.TOS);
export const PrivacyPolicyPage = PolicyPage(PolicyType.PRIVACY);
