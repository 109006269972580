// TODO @branch move out of here
import {AuthStartPanel} from "./AuthStartPanel.jsx";
import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {Route, Router} from "../../../stem-core/src/ui/Router.jsx";
import {LoginWithEmailCode} from "./LoginWithEmailCodeForm.jsx";
import {authFormService} from "../../services/AuthFormService.js";


const LOGIN_WITH_EMAIL_CODE_URL = "enter-login-code"; // Different than the global route on purpose

// This panel wraps all the states in which login can be in a single element
// TODO @branch should this just extend Router?
export class AuthFlowPanel extends UI.Element {
    getDefaultOptions() {
        return {
            AuthStartPanelClass: AuthStartPanel,
        }
    }

    onEmailCodeRequested() {
        if (this.options.onEmailCodeRequested) {
            this.options.onEmailCodeRequested()
            return;
        }
        this.registrationMail = authFormService.registrationMail; // TODO @branch I don't think I need this
        this.confirmationCodeType = authFormService.confirmationCodeType;
        this.router.setURL([LOGIN_WITH_EMAIL_CODE_URL]);
    }

    // TODO @branch @auth this should also be called on login via new accounts
    onAuthentication() {
        if (this.options.onAuth) {
            this.options.onAuth();
        }
    }

    getRoutes() {
        const {AuthStartPanelClass} = this.options;

        return new Route(null, () => <AuthStartPanelClass
            {...this.getCleanedOptions()}
            onEmailCodeRequested={() => this.onEmailCodeRequested()}
        />, [
            new Route(LOGIN_WITH_EMAIL_CODE_URL, () => <LoginWithEmailCode
                onWrongEmailButtonClick={() => this.router.setURL([])}
                onSuccess={() => this.onAuthentication()}
            />),
            // Add later - email & password, should the user want that
        ]);
    }

    render() {
        return <Router ref="router" routes={this.getRoutes()}/>
    }

    onMount() {
        this.router.setURL([]);
    }
}
