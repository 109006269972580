import {StoreObject, MakeStore} from "../../stem-core/src/state/Store";
import {apiClient} from "../connection/BlinkApiClient.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {Money} from "../../stem-core/src/localization/Money.js";
import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";
import {formatPercent} from "../../stem-core/src/base/Formatting.js";
import {StemDate} from "../../stem-core/src/time/Date.js";
import {EditableObjectStatus} from "./misc/GenericEnums.js";


@makeEnum
export class SubscriptionDiscountScope extends BaseEnum {
    static ALL_OFFERS = {name: "All subscription"};
    static TARGETED_COVERAGES = {name: "Selected subscription tiers"};
    static TARGETED_OFFERS = {name: "Selected subscription plans"};
}

@makeEnum
export class SubscriptionDiscountApplyPolicy extends BaseEnum {
    static BEFORE_SHIPPING;
    static AFTER_SHIPPING;
}

@makeEnum
export class SubscriptionDiscountType extends BaseEnum {
    static DISCOUNT_PERCENT;
    static DISCOUNT_AMOUNT;
    static FIXED_PRICE;
}


class SubscriptionDiscount extends StoreObject {
    // TODO: missing some fields here
    @field("Merchant") merchant;
    @field(Date) deletedAt;
    @field(SubscriptionDiscountScope) scope;
    @field(Array) targetOfferIds;
    @field(Array) targetCoverageIds;
    @field(String) name;
    @field(String) code;
    @field(Date) startDate;
    @field(Date) expireDate;
    @field(SubscriptionDiscountApplyPolicy) applyPolicy;
    @field(Number) numCyclesDiscounted;
    @field(String) discountPercent; // TODO Need to support Decimal
    @field(Number) discountAmount;
    @field(Number) discountFixedPrice;

    // TODO @branch deprecate
    getCode() {
        return this.code;
    }

    getDiscountValue() {
        if (this.discountPercent != null) {
            const numericalPercent = parseFloat(this.discountPercent);
            return `${formatPercent(numericalPercent)} off`;
        }
        const currency = this.merchant.getCurrency();
        if (this.discountAmount != null) {
            return `${new Money(this.discountAmount, currency)} off`;
        }
        return `Reduced to ${new Money(this.discountFixedPrice, currency)}`;
    }

    // TODO @branch deprecate
    getMerchant() {
        return this.merchant;
    }

    getStatus() {
        if (this.deletedAt) {
            return EditableObjectStatus.INACTIVE;
        }
        const now = StemDate.now();
        if (this.startDate?.isAfter(now)) {
            return EditableObjectStatus.DRAFT;
        }
        if (this.endDate?.isBefore(now)) {
            return EditableObjectStatus.EXPIRED;
        }
        return EditableObjectStatus.ACTIVE;
    }

    isActive() {
        return this.getStatus() === EditableObjectStatus.ACTIVE;
    }
}

export const SubscriptionDiscountStore = MakeStore("SubscriptionDiscount", SubscriptionDiscount);

export async function apiMerchantCreateSubscriptionDiscount(request) {
    const response = await apiClient.post("/merchant/create_subscription_discount", request);
    return SubscriptionDiscountStore.loadObject(response);
}

export async function apiMerchantEditSubscriptionDiscount(request) {
    return apiClient.post("/merchant/edit_subscription_discount", request);
}

export async function apiMerchantArchiveSubscriptionDiscount(request) {
    return apiClient.post("/merchant/archive_subscription_discount", request);
}
