import {UI, styleRule, styleRuleInherit, registerStyle} from "../../../../../stem-core/src/ui/UI.js";

import {ConfirmationModal, ConfirmationModalStyle} from "../../../../ui/ConfirmationModal.jsx";
import {Messages} from "../../../../Messages.js";
import {wrapInSpinner} from "../../../../../core/ui/LoadingSpinner.jsx";
import {Toast} from "../../../../ui/Toast.jsx";
import {DropdownInput} from "../../../../../core/ui/input/dropdown/DropdownInput.jsx";
import {apiUserRequestPurchaseRefund} from "../../../../../client/state/PurchaseStore.js";


class RefundModalStyle extends ConfirmationModalStyle {
    @styleRuleInherit
    modal = {
        overflow: "visible",
    };

    @styleRule
    dropdown = {
        marginTop: 18,
        marginBottom: 36,
    };
}

// TODO @branch this now takes in a purchase
@registerStyle(RefundModalStyle)
export class RefundModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "refundModal",
            title: Messages.refund,
            confirmAction: () => this.refund(),
        }
    }

    @wrapInSpinner
    async refund() {
        const {purchase} = this.options;
        this.hide();
        await apiUserRequestPurchaseRefund({
            purchaseId: purchase.id,
            refundReason: this.dropdown.getValue(),
        })
        Toast.show(Messages.refundTransactionSuccess);
    }

    render() {
        const {styleSheet} = this;
        const refundReasons = [
            "I accidentally opened the article",
            "Title is misleading, clickbait",
            "Article is fake news",
            "Poorly written",
            "Article is overpriced",
            "Other reason"
        ];

        return (
            <DropdownInput
                ref="dropdown"
                className={styleSheet.dropdown}
                options={refundReasons}
                label={Messages.selectRefundReason}
            />
        );
    }

    onMount() {
        super.onMount();
        this.modalConfirmButton.setAttribute("disabled", true);
        this.dropdown.addListener("change", () => {
            this.modalConfirmButton.setAttribute("disabled", false);
        });
    }
}
