import {registerStyle, styleRule, styleRuleInherit, UI} from "../../stem-core/src/ui/UI";

import {Section, SectionStyle} from "../primitives/Section";
import {FunnelSVG, IdentitySVG, PaySVG} from "../SVGElements";
import {Device} from "../../stem-core/src/base/Device";
import {Messages} from "../../blinkpay/Messages";
import {ProductCard} from "./primitives/ProductCard";
import {isDesktopMedium, isDesktopSmall} from "../Utils";

class OurProductsStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: () => (Device.isMobileDevice() ? "36px 0 0" : "120px 0 0"),
        position: "relative",
        zIndex: 9,
        marginBottom: () => (Device.isMobileDevice() ? -36 : ""),
    };

    @styleRuleInherit
    content = {
        padding: isDesktopSmall() ? "0" : "0 24px",
    };

    @styleRule
    title = {
        fontSize: () => (isDesktopSmall() ? 24 : 32),
        paddingBottom: () => (Device.isMobileDevice() ? 36 : ""),
        color: this.themeProps.LANDING_PAGE_DARK_FONT_COLOR,
        textAlign: "center",
    };

    @styleRule
    cards = {
        display: "flex",
        alignItems: "center",
        flexDirection: () => (isDesktopMedium() ? "column" : "row"),
        justifyContent: () => (isDesktopMedium() ? "space-around" : "space-between"),
        marginBottom: () => (Device.isMobileDevice() ? 56 : 72),
        marginTop: () => (Device.isMobileDevice() ? "" : 48),
    };
}

const productCards = [
    {
        title: Messages.blinkIdentity,
        description: Messages.identityShortText,
        icon: <IdentitySVG />,
        sectionId: "identity",
    },
    {
        title: Messages.blinkPay,
        description: Messages.payShortText,
        icon: <PaySVG />,
        sectionId: "pay",
    },
    {
        title: Messages.blinkFunnel,
        description: Messages.funnelShortText,
        icon: <FunnelSVG />,
        sectionId: "funnel",
    },
];

@registerStyle(OurProductsStyle)
export class OurProducts extends Section {
    render() {
        const {styleSheet} = this;

        productCards.forEach(productCard => {
           productCard.icon.options.size = "100%";
        });

        return [
            <div className={styleSheet.title}>{Messages.ourProducts}</div>,
            <div className={styleSheet.cards}>
                {productCards.map(itemOptions => (
                    <ProductCard
                        {...itemOptions}
                        onClick={() => this.options.navManager.scrollTo(itemOptions.sectionId)}
                    />
                ))}
            </div>,
            <div className={styleSheet.title}>{Messages.howItWorks}</div>,
        ];
    }
}
