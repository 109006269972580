import {UI} from "../../../stem-core/src/ui/UIBase.js";

import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {PopupMenu} from "../../ui/PopupMenu.jsx";
import {Messages} from "../../Messages.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {SubscriptionStore} from "../../../client/state/SubscriptionStore.js";
import {unwrapArray} from "../../../stem-core/src/base/Utils.js";
import {Toast} from "../../ui/Toast.jsx";


class AddressInlineMenuStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "space-between",
        overflow: "initial !important",
    };

    @styleRule
    addressLine = {
        flex: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}

@registerStyle(AddressInlineMenuStyle)
export class AddressInlineMenu extends UI.Element {
    getDefaultOptions() {
        return {
            address: null,
            handleEdit: null,
            handleDelete: null,
        }
    }

    // Returns the address only if it's not being used anywhere else
    selectAddressForChange() {
        const {address} = this.options;
        if (SubscriptionStore.getActiveWithAddress(address).length) {
            // TODO this is not a great error, but not really terrible
            Toast.showError(Messages.addressInUseError);
            return null;
        }

        return address;
    }

    getMenuItems() {
        const {handleEdit, handleDelete} = this.options;

        return unwrapArray([
            handleEdit && {
                content: Messages.edit,
                onClick: () => {
                    const address = this.selectAddressForChange();
                    if (address) {
                        handleEdit(address);
                    }
                },
            },
            handleDelete && {
                content: Messages.deleteMessage,
                onClick: () => {
                    const address = this.selectAddressForChange();
                    if (address) {
                        handleDelete(address);
                    }
                },
            }]);
    }

    render() {
        const {address} = this.options;

        return [
            <span className={this.styleSheet.addressLine}>{address}</span>,
            <PopupMenu items={this.getMenuItems()}/>
        ]
    }
}
