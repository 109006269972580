import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../stem-core/src/ui/Style";

import {styleRule} from "../../stem-core/src/decorators/Style";
import {removeScrollOnScrim} from "../../blink-sdk/utils/ScrimHelpers.js";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";


class ScrimStyle extends StyleSheet {
    @styleRule
    container = {
        position: "fixed",
        cursor: "default",
        zIndex: 10005,
        width: "100%",
        height: "100%",
        background: this.themeProps.DASHBOARD_SCRIM_BACKGROUND,
        top: 0,
        left: 0,
        opacity: 0,
        outline: "none",
        transition: this.themeProps.MODAL_TRANSITION,
    };
}

@registerStyle(ScrimStyle)
export class Scrim extends UI.Element {
    restoreScrollbar = NOOP_FUNCTION;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            hideOnClick: true,
            onHide: NOOP_FUNCTION,
        }
    }

    destroyNode() {
        this.restoreScrollbar();
        this.restoreScrollbar = NOOP_FUNCTION;

        super.destroyNode();
    }

    onMount() {
        super.onMount();

        const {restore} = removeScrollOnScrim();
        this.restoreScrollbar = restore;

        if (this.options.hideOnClick) {
            this.addClickListener(() => this.options.onHide());
        }
    }
}
