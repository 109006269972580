import {CleanupJobs, Dispatcher} from "../stem-core/src/base/Dispatcher";


// TODO @flow merge with the autoredraw stuff, and maybe add a function enqueueRedraw(uiElement) that is global
export class AsyncAggregateDispatcher extends Dispatcher {
    constructor({listener, changeDispatchers}) {
        super();
        this.cleanupJobs = new CleanupJobs();
        this.willDispatchEvent = false;
        for (const changeDispatcher of (changeDispatchers || [])) {
            this.attachChangeDispatcher(changeDispatcher);
        }
        if (listener) {
            this.addListener(listener);
        }
    }

    attachChangeDispatcher(dispatcher) {
        const cleanupJob = dispatcher.addChangeListener(() => this.dispatch());
        this.cleanupJobs.add(cleanupJob);
    }

    attachDispatcher(dispatcher, channel) {
        const cleanupJob = dispatcher.addListener(channel, () => this.dispatch());
        this.cleanupJobs.add(cleanupJob);
    }

    dispatch() {
        if (this.willDispatchEvent) {
            return;
        }
        this.willDispatchEvent = true;
        queueMicrotask(() => {
            super.dispatch();
            this.willDispatchEvent = false;
        });
    }

    cleanup() {
        this.removeAllListeners();
        this.cleanupJobs.cleanup();
    }
}
