import {UI} from "../stem-core/src/ui/UIBase.js";
import {StemApp} from "../stem-core/src/app/StemApp.jsx";
import {ViewportMeta} from "../stem-core/src/ui/ViewportMeta.jsx";
import {Device} from "../stem-core/src/base/Device.js";
import {GlobalStyleSheet} from "./theme/GlobalStyleSheet.jsx";
import {apiClient} from "../client/connection/BlinkApiClient.js";
import {ApiErrors} from "../client/connection/ApiErrors.js";
import {authService} from "../client/connection/services/AuthService.js";
import {PageTitleManager} from "../stem-core/src/base/PageTitleManager.js";
import {initializeTheme} from "./theme/StyleConstants.js";
import {APP_NAME, WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED} from "./Constants.js";
import {AuthToken} from "../client/connection/AuthToken.js";
import {isString} from "../stem-core/src/base/Utils.js";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../blink-sdk/utils/AnalyticsClient.js";
import {LanguageStore} from "../client/state/LanguageStore.js";
import {englishTranslationMap} from "./Messages.js";
import {setLanguageStore} from "../stem-core/src/ui/Translation.js";

// TODO Everywhere this is duplicated, merge the code
LanguageStore.importState([
    {
        id: 1,
        translationMap: englishTranslationMap,
    },
]);

setLanguageStore(LanguageStore);


class BlinkViewportMeta extends ViewportMeta {
    getContent() {
        const {scale} = this.options;
        const windowWidth = (Device.isMobileDevice() && window.screen?.width) || window.innerWidth;
        
        // Squash everything if the window is too small
        const initialScale = (windowWidth < WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED) ? windowWidth / WEBSITE_MOBILE_SCREEN_MIN_WIDTH_SUPPORTED : scale;

        return `width=device-width,initial-scale=${initialScale},maximum-scale=${scale},user-scalable=no`;
    }
}

export class BaseBlinkApp extends StemApp {
    static init() {
        this.initializeTheme();
        this.initializeGlobalStyle();
        this.initializeViewportMeta();
        this.addAjaxProcessors();
        this.initializeAuthentication();
        return super.init();
    }

    static initializeViewportMeta() {
        BlinkViewportMeta.create(document.head);
    }

    static initializeAuthentication() {
        authService.setLoginAppName(APP_NAME);

        const attemptWSConnection = () => {
            apiClient.initWebsocketConnection(authService.token);
        }

        attemptWSConnection();

        authService.addListener(AuthToken.EventTypes.REFRESH, attemptWSConnection);
        authService.addListener(AuthToken.EventTypes.LOGIN, attemptWSConnection);

        let wsListenerSet = false;

        authService.onAuthentication(() => {
            if (!wsListenerSet) {
                this.addWsListener("user-" + authService.token.getUserId() + "-events");
                wsListenerSet = true;
            }
        });
        authService.addListener(AuthToken.EventTypes.LOGOUT, () => {
            apiClient.dropWebsocketConnection();
            wsListenerSet = false;
        });
    }

    static addWsListener() {}

    static initializeTheme() {
        initializeTheme();
    }

    static initializeGlobalStyle() {
        GlobalStyleSheet.initialize();
    }

    getContainer() {
        return this.getRouter();
    }

    static addAjaxProcessors() {
        const ajaxHandler = apiClient.getAjaxHandler();
        ajaxHandler.addPostprocessor(response => {
            const {error} = response;
            if (!error) {
                return response;
            }
            if (error.code === ApiErrors.AUTHENTICATION_FAILED || error.code === ApiErrors.NOT_AUTHENTICATED) {
                // If token is invalid, log out the user.
                // TODO all BLINK API errors should be available via an enum
                // TODO we should not redirect to home page inside this low level service
                authService.clearCurrentInfo();
                return null;
            }
            return response;
        });
        ajaxHandler.addPreprocessor((options) => {
            options.analytics = {requestStartTime: performance.now()};
        });
        ajaxHandler.addPostprocessor((response, xhrPromise) => {
            const {error} = response;
            const {options, request} = xhrPromise;
            let eventType = AnalyticsEventType.AJAX_REQUEST_SUCCEEDED;
            const eventProperties = {
                method: request.method,
                httpStatusCode: xhrPromise.getXHR().status,
                endpoint: (new URL(request.url)).pathname,
                duration: performance.now() - options.analytics.requestStartTime,
            };

            if (error) {
                eventType = AnalyticsEventType.AJAX_REQUEST_FAILED;
                eventProperties.errorCode = error.code;
                eventProperties.errorMessage = error.message;
            }

            dispatchAnalyticsEvent(eventType, eventProperties);
        });
    }

    onMount() {
        super.onMount();
        // This is so that the elements on ios get unfocused when tapping on the body.
        document.body.tabIndex = 1;
    }
}

PageTitleManager.setDefaultTitle("Blink");

// Add some polyfills
if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(pattern, replacement) {
        if (isString(pattern)) {
            pattern = new RegExp(pattern, "g");
        }
        if (this?.replace) {
            return this.replace(pattern, replacement);
        }
        return this;
	};
}

if (typeof self.queueMicrotask !== "function") {
    self.queueMicrotask = function (callback) {
        Promise.resolve()
            .then(callback)
            .catch(e => setTimeout(() => {
                throw e;
            })); // report exceptions
    };
}
