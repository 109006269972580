import {Button, registerStyle, styleRule, styleRuleInherit, StyleSheet, UI} from "../../stem-core/src/ui/UI.js";
import {FacebookIcon, GoogleIcon, LinkedInIcon, TwitterIcon} from "../ui/SVGElements.jsx";
import {SOCIAL_APPS} from "../../blink-sdk/Constants.js";
import {GetExternalAuthClient} from "./ExternalAuthConfigs.js";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../blink-sdk/utils/AnalyticsClient.js";
import {Device} from "../../stem-core/src/base/Device.js";


class ExternalAuthButtonStyle extends StyleSheet {
    @styleRule
    container = {
        border: 0,
        outline: 0,
        fontSize: 20,
        cursor: "pointer",
        userSelect: "none",
        position: "relative",
        boxShadow: this.themeProps.SOCIAL_BUTTON_SHADOW,
        display: "flex",
        alignItems: "center",
        fontWeight: () => this.themeProps.FONT_WEIGHT_BOLD + " !important",
        padding: 0,
        width: 44,
        height: 44,
        borderRadius: 2,
        transition: this.themeProps.DEFAULT_TRANSITION,
        ":hover": {
            boxShadow: this.themeProps.SOCIAL_BUTTON_HOVER_SHADOW,
        },
        ":disabled": {
            opacity: this.themeProps.BASE_DISABLED_OPACITY,
            cursor: "not-allowed",
            pointerEvents: () => (Device.isMobileDevice() ? "none" : ""),
        },
    };

    @styleRule
    iconContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        ">*": {
            transform: "scale(2)",
        },
        ">*>*": {
            display: "flex",
            alignItems: "center",
        },
    };

    Size() {}
    Level() {}
}

// TODO extend BlinkButton?
@registerStyle(ExternalAuthButtonStyle)
class ExternalAuthButton extends Button {
    render() {
        const {description, IconClass} = this.options;
        return [
            <div className={this.styleSheet.iconContainer}>
                <IconClass size={16}/>
            </div>,
            description,
        ];
    }

    startExternalAuth() {
        const {clientName} = this.options;

        dispatchAnalyticsEvent(AnalyticsEventType.SOCIAL_LOGIN_BUTTON_CLICKED, {provider: clientName});

        const externalAuthClient = GetExternalAuthClient(this.options.clientName);
        externalAuthClient.setLoginCallback(this.options.onLoginCallback);
        externalAuthClient.login();
    }

    onMount() {
        this.addClickListener(() => {
            this.startExternalAuth();
        });
    }
}

class FacebookButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    container = {
        color: this.themeProps.SOCIAL_BUTTON_FACEBOOK_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_FACEBOOK_BACKGROUND,
    };
}

class GoogleButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    container = {
        color: this.themeProps.SOCIAL_BUTTON_GOOGLE_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_GOOGLE_BACKGROUND,
    };
}

class LinkedInButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    container = {
        color: this.themeProps.SOCIAL_BUTTON_LINKEDIN_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_LINKEDIN_BACKGROUND,
        ">*": {
            transform: "translateY(-2px)",
        },
    };
}

class TwitterButtonStyle extends ExternalAuthButtonStyle {
    @styleRuleInherit
    container = {
        color: this.themeProps.SOCIAL_BUTTON_TWITTER_COLOR,
        backgroundColor: this.themeProps.SOCIAL_BUTTON_TWITTER_BACKGROUND,
    };
}


const ExternalAuthDescriptors = {
    [SOCIAL_APPS.Google]: {
        icon: GoogleIcon,
        styleSheet: GoogleButtonStyle,
    },
    [SOCIAL_APPS.Facebook]: {
        icon: FacebookIcon,
        styleSheet: FacebookButtonStyle,
    },
    [SOCIAL_APPS.Twitter]: {
        icon: TwitterIcon,
        styleSheet: TwitterButtonStyle,
    },
    [SOCIAL_APPS.LinkedIn]: {
        icon: LinkedInIcon,
        styleSheet: LinkedInButtonStyle,
    },
};

class ExternalAuthButtonsStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "space-around",
        maxWidth: 300,
        margin: "auto",
    };
}

@registerStyle(ExternalAuthButtonsStyle)
export class ExternalAuthButtons extends UI.Element {
    getDefaultOptions() {
        return {
            externalAuthProviders: [],
        };
    }

    render() {
        let {externalAuthProviders} = this.options;

        externalAuthProviders = externalAuthProviders.map(provider => provider.toLowerCase()).filter(provider => provider !== SOCIAL_APPS.CDS);

        return externalAuthProviders.map(authProviderName => {
            const {icon, styleSheet} = ExternalAuthDescriptors[authProviderName];
            return (
                <ExternalAuthButton
                    testId={authProviderName + "Button"}
                    style={{margin: 0}}
                    clientName={authProviderName}
                    styleSheet={styleSheet}
                    IconClass={icon}
                />
            );
        })

    }
}
