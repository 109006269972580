import {authService} from "../client/connection/services/AuthService.js";
import {
    closeWindow,
    getOpenerOrigin,
    isFacebookBrowser, isIframe, openWindowWithOpener,
} from "./Utils";
import {iFrameConnection} from "./services/IFrameConnection";
import {IFrameMessages} from "../blink-sdk/messaging/IFrameMessages";
import {Router} from "../stem-core/src/ui/Router";
import {
    BLINK_PAY_URL,
    LOGIN_URL
} from "./Constants";
import {wrapInSpinner} from "../core/ui/LoadingSpinner.jsx";
import {NOOP_FUNCTION, getCookie, setCookie} from "../stem-core/src/base/Utils";
import {AuthToken} from "../client/connection/AuthToken";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../blink-sdk/utils/AnalyticsClient";
import {iFrameState} from "./services/IFrameState";


export function sendTokenAndCloseTab(loginResponse) {
    if (window.opener && getOpenerOrigin() === window.origin) {
        iFrameConnection.sendToOpener(IFrameMessages.USER_TOKEN_UPDATE, {
            merchantToken: authService.merchantToken.isAuthenticated() ? authService.merchantToken.getTokenObjectStringified() : null,
            token: authService.token.getTokenObjectStringified(),
            userData: loginResponse,
        });
    }

    closeWindow();
}

export function useRedirectsInsteadOfTabs() {
    return isFacebookBrowser();
}

export function canWriteCookies() {
    try {
        const cookieValue = +Date.now();
        // Set a cookie that expires in a second.
        setCookie("blinkCookieTest", cookieValue, 1);
        return parseInt(getCookie("blinkCookieTest"), 10) === cookieValue;
    } catch (e) {
        return false;
    }
}

// TODO: Check and improve path for third party storage blocked.
export class AuthHelper {
    static loginListenerHandlers = []; // refences to listeners on login, to be able to cancel them
    static oauth1NewTab = null;
    static oauth2NewTab = null;

    static getMerchantRedirectPage() {
        if (this.savedRedirectUrl) {
            return this.savedRedirectUrl;
        }

        try {
            return sessionStorage.getItem("merchantPageUrl");
        } catch (e) {
            return null;
        }
    }

    static redirectToUrl(redirectUrl) {
        if (!redirectUrl) {
            this.confirmedAuthenticationAction();
            return;
        }

        if (isIframe()) {
            iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL, {url: redirectUrl});
        } else {
            // TODO @auth why do we redirect to this?
            openWindowWithOpener(redirectUrl, "_self");
        }
    }

    static redirectToMerchantPage() {
        this.redirectToUrl(this.getMerchantRedirectPage());
    }

    static saveMerchantPageUrlForRedirect(url) {
        this.savedRedirectUrl = url;
        try {
            sessionStorage.setItem("merchantPageUrl", url);
        } catch (e) {
            console.warn("Failed to set redirect page in session storage");
        }
    }

    // TODO @flow shit callback
    static addAuthenticationCallback(callback = NOOP_FUNCTION) {
        const handler = authService.addListener(AuthToken.EventTypes.LOGIN, callback);
        this.loginListenerHandlers.push(handler);
    }

    static clearAuthenticationCallbacks() {
        for (const callback of this.loginListenerHandlers) {
            callback.cleanup();
        }
        this.loginListenerHandlers = [];
    }

    static redirectToOAuthLogin(url) {
        // We can't redirect inside an iframe, so we need to redirect the parent page.
        if (!useRedirectsInsteadOfTabs() && this.oauth2NewTab) {
            this.oauth2NewTab.location.href = url;
        } else if (isIframe()) {
            if (useRedirectsInsteadOfTabs()) {
                this.saveMerchantPageUrlForRedirect(iFrameState.merchantPageUrl);
            }
            iFrameConnection.sendToSDK(IFrameMessages.REDIRECT_TO_URL,
                {url, newTab: !useRedirectsInsteadOfTabs()});
        } else {
            // On facebook browser we can't have multiple tabs.
            openWindowWithOpener(url, useRedirectsInsteadOfTabs() ? "_self" : "_blank");
        }
    }

    // User clicked on a 3rd party oauth2 client login button; Open the oauth login url for that client.
    static oauth2Login(url) {
        // We can open a new tab only when we are top level and not in the facebook browser.
        if (!useRedirectsInsteadOfTabs()) {
            this.oauth2NewTab = openWindowWithOpener();
        }
        this.redirectToOAuthLogin(url);
    }

    // Same as above, but for a oauth1 client.
    static oauth1Login() {
        // In oauth1 we need to do a call to our backend first, so the entire flow will be async. However, some browsers
        // block tabs open through async flows, so we open the tab synchronously before the call is finished. We won't
        // be able to open the new tab on Facebook browser (because it allows for only one active tab at a time) and in
        // an iframe; for these cases we will redirect once we get the url from our backend.
        if (!useRedirectsInsteadOfTabs()) {
            this.oauth1NewTab = openWindowWithOpener();
        }
    }

    // We received the client url from our backend, now we can redirect / set the url on the previously open tab
    static oauth1TokenReceivedCallback(url) {
        // Set the url on the previously opened tab.
        if (!useRedirectsInsteadOfTabs() && this.oauth1NewTab) {
            this.oauth1NewTab.location.href = url;
        } else {
            this.redirectToOAuthLogin(url);
        }
    }

    // TODO @auth @flow cleanup
    // The 3rd party oauth client redirects to one of our pages with the oauth token. Now, we can attempt to login the
    // user. Note: At this point you surely aren't inside an iframe.
    static oauthHandleLoginRedirect(oauthClient, urlParams) {
        let loginCallback;
        if (getOpenerOrigin() === window.origin) {
            // This tab was opened externally, so we should close it after the login is finished.
            loginCallback = sendTokenAndCloseTab;
        } else {
            // We logged in via redirects. Now handle things normally.
            loginCallback = (loginResponse) => this.confirmedAuthenticationAction(loginResponse);
        }

        // We have the oauth token, now let's login the user and redirect.
        oauthClient.setLoginCallback(loginCallback);
        oauthClient.handleLoginResponse(urlParams);
    }

    // User has interrupted the oauth login or an error has occurred.
    static oauthHandleError() {
        if (!useRedirectsInsteadOfTabs()) {
            closeWindow();
            return;
        }

        if (isIframe() || !this.getMerchantRedirectPage()) {
            Router.changeURL(LOGIN_URL);
        } else {
            this.redirectToMerchantPage();
        }
    }

    @wrapInSpinner
    static async ensureTokenSetOnMainWebsiteAfterBackendCall(backendCall) {
        await backendCall();

        if (canWriteCookies()) {
            // We're all done here
            return;
        }

        // This is used when the user gets signed in the iframe but the token isn't set on the main blink website. The current
        // cases when this needs to be done is on Safari (because you can't change the main domain's storage inside an
        // iframe) and when the third party storage is blocked (because you don't even have access to any kind of
        // storage from the iframe).
        // The tab needs to be opened before the first await, to register as coming from a user action.

        const setCookieUrl = `${BLINK_PAY_URL}/set-cookie?token=${authService.token.usingMerchantToken ? "null" : encodeURI(authService.token.getTokenObjectStringified())}`;
        if (!useRedirectsInsteadOfTabs()) {
            const setCookieTab = openWindowWithOpener();
            // Once the url is set, the page loads, the cookie is set on our domain and then the page closes itself.
            setCookieTab.location.href = setCookieUrl;
        } else {
            // TODO: Are we sure we are in an iframe here? Why? At least write a comment about it
            this.redirectToUrl(setCookieUrl + "&merchantRedirectUrl=" + encodeURI(iFrameState.merchantPageUrl));
        }
    }

    static async requestLoginCode(payload) {
        if (payload == null) {
            payload = this.previousLoginCodeRequest;
        }
        this.previousLoginCodeRequest = payload;

        dispatchAnalyticsEvent(AnalyticsEventType.REQUEST_EMAIL_ACCESS_CODE);
        return await authService.requestLoginCode(payload);
    }

    static async loginWithEmailCode(code) {
        return this.ensureTokenSetOnMainWebsiteAfterBackendCall(() => authService.loginWithEmailCode({code}))
    }

    // This gets called after user authenticates by clicking the login link (received in the login code email)
    static completedLoginByEmailKeyAction(redirectUrl = "") {
        if (redirectUrl && redirectUrl.trim().length > 0) {
            // TODO @auth why not just redirect there inline?
            openWindowWithOpener(redirectUrl, "_self");
        } else {
            this.confirmedAuthenticationAction();
        }
    }

    // TODO we probably want to bring you where you were before logging in
    static redirectAfterLogin() {
        Router.changeURL("/");
    }

    // This gets called when we display a confirmation message (we display it after either accepting Terms for oauth
    // account creation, or after confirming the email via normal account creation); this also gets called in the email
    // code confirmation page when the user completed the account creation in another tab.
    static confirmedAuthenticationAction() {
        if (this.getMerchantRedirectPage()) {
            this.redirectToMerchantPage();
        } else if (isIframe()) {
            // We are in an iframe; the correct redirect is being made in previous code after we are authenticated, so
            // now nothing is left to do.
        } else {
            // The authentication was not made externally, so just redirect to the homepage.
            this.redirectAfterLogin();
        }
    }
}
