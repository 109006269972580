import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {styleRule, styleRuleInherit, StyleSheet} from "../../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {BaseInputElement} from "../../../../stem-core/src/ui/input/BaseInputElement.js";


class RadioListInputStyle extends StyleSheet {
    @styleRule
    container = {
        // TODO @cleanup anything with "first-child" is like the work of Cain, cursed for eternity
        ">:first-child": {
            borderTop: "1px solid " + this.themeProps.DIVIDER_LINE_COLOR,
        }
    }

    @styleRule
    entry = {
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
        color: this.themeProps.RADIO_COLOR,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "12px 0",
        cursor: "pointer",
        borderBottom: "1px solid " + this.themeProps.DIVIDER_LINE_COLOR,
    };

    @styleRule
    radioIcon = {
        border: () => "2px solid " + this.themeProps.RADIO_COLOR,
        borderRadius: "100%",
        position: "relative",
        width: this.themeProps.RADIO_SIZE,
        height: this.themeProps.RADIO_SIZE,
        marginRight: 12,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    @styleRule
    selectedRadioIcon = {
        "::after": {
            content: "\"\"",
            height: this.themeProps.RADIO_SIZE / 2,
            width: this.themeProps.RADIO_SIZE / 2,
            background: this.themeProps.RADIO_COLOR,
            borderRadius: "100%",
        },
    };

    @styleRule
    label = {
        userSelect: "none",
        width: "100%"
    };
}

@registerStyle(RadioListInputStyle)
export class RadioListInput extends BaseInputElement {
    renderEntry(entry) {
        const {styleSheet} = this;
        const {formatter} = this.options;
        const label = formatter ? formatter(entry) : entry;

        return <div className={styleSheet.entry} onClick={() => {
                this.setValue(entry);
                this.redraw();
            }}>
            <div className={styleSheet.radioIcon + (entry === this.getValue() ? styleSheet.selectedRadioIcon : "")} />
            <div className={styleSheet.label}>{label}</div>
        </div>

    }

    render() {
        return this.options.entries.map(entry => this.renderEntry(entry));
    }
}

// TODO @cleanup merge with class above
export class DashboardRadioStyle extends RadioListInputStyle {
    @styleRuleInherit
    radioIcon = {
        cursor: "pointer"
    }

    @styleRule
    disabled = {
        opacity: this.themeProps.DEFAULT_DISABLED_OPACITY,
        cursor: "default"
    }
}

@registerStyle(DashboardRadioStyle)
export class DashboardRadio extends UI.Element {
    extraNodeAttributes(attr) {
        const {styleSheet} = this;
        attr.addClass(styleSheet.radioIcon);
        if (this.options.selected) {
            attr.addClass(styleSheet.selectedRadioIcon);
        }
        if (this.options.disabled) {
            attr.addClass(styleSheet.disabled);
        }
    }
}
