import {UI, styleRule, registerStyle} from "stem-core/src/ui/UI";
import {StyleSheet} from "stem-core/src/ui/Style";
import {TextInput} from "../../stem-core/src/ui/input/Input";
import {MakeInfoElement} from "./InfoTooltip";

export class BlinkInputFieldStyle extends StyleSheet {
    @styleRule
    container = {
        display: "inline-block",
        margin: 8,
        " input": {
            height: 36,
        },
        " input[disabled]": {
            color: "#AAA",
        },
        " select": {
            height: 36,
        },
    }

    @styleRule
    inputFieldLabel = {
        display: "inline-block",
        fontSize: 12,
        fontWeight: 700,
        padding: 4,
    }

    @styleRule
    inputFieldHelp = {
        display: "inline-block",
    }
}

@registerStyle(BlinkInputFieldStyle)
export class BlinkInputField extends UI.Element {
    render() {
        const {styleSheet} = this;
        const {label, helpInfo} = this.options;

        return [
            <div>
                <span className={styleSheet.inputFieldLabel}>{label}</span>
                {MakeInfoElement(helpInfo)}
            </div>,
            ...this.getGivenChildren(),
        ];
    }
}

// TODO implement like GMail and move to another file
export class MultiEmailAddressInput extends TextInput {
    getValue() {
        const str = super.getValue();
        return str.split(",").map(emailAddress => emailAddress.trim()).filter(emailAddress => emailAddress.length > 0);
    }

    setValue(emails) {
        const str = (emails || []).join(",");
        super.setValue(str);
    }
}
