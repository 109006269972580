import {Store} from "../../stem-core/src/state/Store";
import {Money, MoneyObject} from "../../stem-core/src/localization/Money.js";


export class Wallet extends MoneyObject {
    getBalance() {
        // TODO: maybe rename for consistency for all MoneyObjects?
        return new Money(this.balance, this.getCurrency());
    }

    getAmount() {
        return this.getBalance();
    }
}

class WalletStoreClass extends Store("Wallet", Wallet) {
    // TODO: this function should be deprecated
    getWallet() {
        return this.all()[0];
    }
}

export const WalletStore = new WalletStoreClass();
