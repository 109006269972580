import {UI} from "../../stem-core/src/ui/UIBase";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";

import {BlinkInput, BlinkInputStyle} from "../ui/Input";
import {styleRule, styleRuleInherit} from "../../stem-core/src/decorators/Style";


export class MerchantCurrencyInputStyle extends BlinkInputStyle {
    invisibleArrows = {
        "-webkit-appearance": "none",
        margin: 0,
    };

    @styleRuleInherit
    inputContainer = {
      display: "flex",
      alignItems: "center",
    };

    @styleRuleInherit
    input = {
        paddingLeft: 0,
        fontSize: this.themeProps.FONT_SIZE_LARGE,
        "-moz-appearance": "textfield",
        "::-webkit-inner-spin-button": this.invisibleArrows,
        "::-webkit-outer-spin-button": this.invisibleArrows,
        ":focus": {
            outline: "none",
            borderColor: "none",
            boxShadow: "none",
        },
    };

    @styleRule
    currency = {
        lineHeight: 1,
    };

    @styleRuleInherit
    errorMessage = {
        top: "100%",
    }
}

@registerStyle(MerchantCurrencyInputStyle)
export class MerchantCurrencyInput extends BlinkInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            currency: null,
            inputPattern: /^$|^[1-9][0-9]*$/, // This allows for empty string, or any number without leading 0s.
            inputAttributes: {
                type: "tel",
            }
        }
    }

    render() {
        const {currency} = this.options;

        return [
            <div className={this.styleSheet.currency}>{currency.getMainUnitSymbol()}</div>
        ];
    }
}
