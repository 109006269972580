// TODO @flow rename stuff here explicitly to purchase
import {iFrameConnection} from "./IFrameConnection.js";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages.js";
import {TopUpPaymentStore} from "../../client/state/TopUpPaymentStore.js";
import {Dispatchable, Dispatcher} from "../../stem-core/src/base/Dispatcher.js";
import {CurrencyStore} from "../../stem-core/src/localization/CurrencyStore.js";
import {iFrameMerchantService} from "./IframeMerchantService.js";
import {ProductStore} from "../../client/state/ProductStore.js";
import {apiUserPurchaseProduct} from "../../client/state/PurchaseStore.js";
import {Money} from "../../stem-core/src/localization/Money.js";


// TODO @flow should this be in Flow.purchaseSomething?
class IFramePurchaseHandler extends Dispatchable {
    autopayAfterReloadHandler = null;
    manualPaymentOfferId = null;
    lastRequest = null;
    lastResponse = null;

    init() {
        // TODO @flow what reason is there for this?
        iFrameConnection.addIframesListener(IFrameMessages.MAKE_NEXT_PAYMENT_MANUALLY, ({offerId}) => {
            this.manualPaymentOfferId = offerId;
        });

        iFrameConnection.addListener(IFrameMessages.PAYMENT_REQUEST, request => {
            if (request.offerId !== this.manualPaymentOfferId) {
                this.requestPayment(request, false).then();
                return;
            }
            this.manualPaymentOfferId = null;
            this.requestPayment(request, true).then();
        });
    }

    getProduct(paymentRequest) {
        if (paymentRequest.productId) {
            return ProductStore.get(paymentRequest.productId);
        }
        const allowedProducts = ProductStore.filterBy({allowArbitrarySubproducts: true});
        if (allowedProducts.length !== 1) {
            throw `Can't automatically fill in product, number of products with arbitrary subproduct: ${allowedProducts.length}`;
        }
        return allowedProducts[0];
    }

    // Helper method to allow the underlying request to be more flexible
    normalizeRequest(paymentRequest) {
        const {
            amount,
            currencyIsoCode,
            currencyId,
            currency,
            resourceId,
            comment,
        } = paymentRequest;

        const merchant = iFrameMerchantService.getMerchant();
        const requestCurrency = CurrencyStore.get(currency || currencyId || currencyIsoCode) || merchant.getCurrency();
        const product = this.getProduct(paymentRequest);

        return {
            comment,
            resourceId,
            product: product,
            amount: new Money(amount, requestCurrency) || product.getAmount(), // Handles from String or Number in millionth of subunits
        }
    }

    async makePaymentRequest(paymentRequest, isAutomatic) {
        const {
            amount,
            product,
            resourceId,
            comment,
        } = this.normalizeRequest(paymentRequest);

        const merchant = iFrameMerchantService.getMerchant();

        const request = {
            merchantId: merchant.id,
            ...amount.toJSON(),
            useWalletFunds: "yes",
            isAutomatic,
            products: {
                entries: [{
                    productId: product.id,
                    subproductId: resourceId,
                }],
            },
            ...comment, // TODO @flow why is this spread?
        }

        return await apiUserPurchaseProduct(request);
    }

    async requestPayment(request, isAutomatic = false) {
        this.lastRequest = request;

        try {
            const response = await this.makePaymentRequest(request, isAutomatic);
            this.lastResponse = {request, response};
        } catch (error) {
            this.lastResponse = {request, error};
        }

        iFrameConnection.sendToSDK(IFrameMessages.PAYMENT_RESPONSE, this.lastResponse);
    }

    remakeLastPaymentAfterFundsAdded() {
        if (this.autopayAfterReloadHandler) {
            this.autopayAfterReloadHandler.remove();
        }
        // TODO @flow send the request payload to the widget and have them resend it
        this.autopayAfterReloadHandler = this.attachListenerOnce(TopUpPaymentStore, "create", () => {
            this.attachListenerOnce(Dispatcher.Global, "balanceAnimationFinished", () => {
                this.autopayAfterReloadHandler = null;
                this.requestPayment(this.lastRequest, false);
            });
        });
    }
}

export const iFramePurchaseHandler = new IFramePurchaseHandler();
