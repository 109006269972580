import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {getQueryParam} from "../../../UtilsLib.js";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {authService} from "../../../../client/connection/services/AuthService.js";
import {apiUndoCloseAccount} from "../../../../client/state/UserProfileStore.js";


export class UndoCloseAccountPage extends UI.Element {
    @wrapInSpinner
    async undoCloseAccount(undoToken) {
        const response = await apiUndoCloseAccount(undoToken);
        authService.setAuthToken(response);
        Router.changeURL("/");
    }

    onMount() {
        super.onMount();
        const undoToken = getQueryParam("undoToken");
        if (!undoToken) {
            Router.changeURL("/");
            return;
        }

        requestAnimationFrame(() => this.undoCloseAccount(undoToken));
    }
}
