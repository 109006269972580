import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI.js";
import {isSmallScreen} from "../Utils.js";
import {BlinkLogo} from "../../core/ui/SVGElements.jsx";


export class FormContainerPageStyle extends StyleSheet {
    @styleRule
    container = {
        width: "100%",
        minHeight: "fit-content",
        height: () => (isSmallScreen() ? "" : "100vh"),
    };

    @styleRule
    formContainer = {
        boxShadow: this.themeProps.LOGO_CARD_SHADOW,
        background: this.themeProps.LOGO_CARD_CONTENT_BACKGROUND,
        width: 416,
        margin: "auto !important",
        borderRadius: 12,
        overflow: "hidden",
        position: "relative",
        padding: 48,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    };

    @styleRule
    center = {
        height: "100%",
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    logo = {
        textAlign: "center",
        marginBottom: 24,
    };
}

export const FormContainerPage = (FormClass, formOptions) => {
    @registerStyle(FormContainerPageStyle)
    class FormContainer extends UI.Element {
        render() {
            const {styleSheet} = this;

            return [
                <div className={styleSheet.center}>
                    <div className={styleSheet.formContainer}>
                        <BlinkLogo size={24} color={styleSheet.themeProps.WALLET_12} className={styleSheet.logo}/>
                        <FormClass ref="form" {...formOptions} />
                    </div>
                </div>,
            ];
        }

        onMount() {
            this.addListener("urlEnter", () => this.form.dispatch("urlEnter"));
            this.addListener("urlExit", () => this.form.dispatch("urlExit"));
        }
    }

    return FormContainer;
};
