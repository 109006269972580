import {Store, StoreObject} from "../../stem-core/src/state/Store";
import {apiClient} from "../connection/BlinkApiClient.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {CycleUnit} from "./RecurringPaymentStoreObject";


export function getFeeRawPercentage(merchantId) {
    // TODO Fucking Denis and Jean, thanks guys
    return 0.079;
}

export class DonationOffer extends StoreObject {
    @field("Merchant") merchant;
    @field("Currency") currency;
    @field(Number) maxPaymentAttemptsCount;
    @field(Number) paymentAttemptDelayHours;
    @field(Number) attemptsBeforeNextCycle;
    @field(Number) useCalendarPeriod;
    @field(Array) frequencyOptions;
    @field(CycleUnit) defaultFrequency;
    //@field(Array) predefinedAmounts; // TODO @Mihai fix when that proxy obj works
    @field(Number) defaultPredefinedAmountIndex;
    @field(Number) maxPredefinedAmountsSize;
    @field(Boolean) allowCustomAmount;
    @field(Boolean) collectAddress;

    shouldCollectAddress() {
        return this.collectAddress;
    }

    // TODO deprecate
    getCurrency() {
        return this.currency;
    }

    hasAmountChoice() {
        return this.allowCustomAmount || this.predefinedAmounts.length > 1;
    }

    hasFrequencyChoice() {
        return this.getFrequencyOptions().length > 1;
    }

    getFrequencyOptions() {
        return this.frequencyOptions.map(option => CycleUnit.fromValue(option));
    }

    getName() {
        return "";
    }

    computeFeeFromAmount(amount) {
        if (!amount) {
            return 0;
        }
        return Math.ceil((getFeeRawPercentage(this.merchantId) * amount / 1e6 + 0.3) * 2) * 5e5;
    }
}

class DonationOfferStoreClass extends Store("donationOffer", DonationOffer) {
    getAvailable() {
        return this.all();
    }
}

export const DonationOfferStore = new DonationOfferStoreClass();

export async function apiMerchantEditDonationOffer(data) {
    return apiClient.post("/merchant/edit_donation_offer", data);
}
