import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../stem-core/src/ui/style/Theme.js";
import {UI} from "../../stem-core/src/ui/UIBase.js";
import {UserPreferencesStore, WalletStore} from "../State.js";
import {Messages} from "../Messages.js";
import {Money} from "../../stem-core/src/localization/Money.js";
import {wrapInSpinner} from "../../core/ui/LoadingSpinner.jsx";
import {apiUpdatePreferences} from "../../client/state/UserPreferencesStore.js";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw.js";
import {ToggleInput} from "../../stem-core/src/ui/input/checkbox/ToggleInput.jsx";
import {MakeInfoElement} from "../../dashboard/common/InfoTooltip.jsx";


class AutoReloadStyle extends StyleSheet {
    @styleRule
    flexContainer = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };
}

@registerStyle(AutoReloadStyle)
@autoredraw(UserPreferencesStore, WalletStore)
export class AutoReloadToggle extends UI.Element {
    render() {
        const {styleSheet} = this;
        const userPreferences = UserPreferencesStore.getPreferences();

        const {autoTopUpEnabled} = userPreferences;
        const currency = WalletStore.getWallet().getCurrency();

        // TODO these should have been extracted with @field
        const autoTopUpAmount = new Money(userPreferences.autoTopUpAmount, currency);
        const autoTopUpThreshold = new Money(userPreferences.autoTopUpThreshold, currency);

        const infoContent = () => <div className={styleSheet.message}>
            {Messages.autoReloadWallet(autoTopUpAmount, autoTopUpThreshold, currency)}
        </div>;

        return [
            <div className={styleSheet.flexContainer}>
                <div>
                    {Messages.autoReload} {MakeInfoElement(infoContent)}
                </div>
                <ToggleInput
                    testId="walletAutoReloadToggle"
                    ref="autoReloadToggle"
                    initialValue={autoTopUpEnabled}
                    onChange={(active) => this.updateAutoReload(active)}
                />
            </div>
        ];
    }

    @wrapInSpinner
    async updateAutoReload(autoTopUpEnabled) {
        await apiUpdatePreferences({autoTopUpEnabled});
    }
}
