import {StoreObject, MakeStore} from "../../stem-core/src/state/Store";
import {TaskStatus} from "./misc/GenericEnums";
import {field} from "../../stem-core/src/state/StoreField.js";


export class SubscriptionChange extends StoreObject {
    @field(TaskStatus) status;
}

export const SubscriptionChangeStore = MakeStore("subscriptionChange", SubscriptionChange);
