export const ApiErrors = {
    API_EXCEPTION: 1000,
    VALIDATION_ERRORS: 1001,
    PARSE_ERROR: 1002,
    AUTHENTICATION_FAILED: 1003,
    NOT_AUTHENTICATED: 1004,
    PERMISSION_DENIED: 1005,
    NOT_FOUND: 1006,
    METHOD_NOT_ALLOWED: 1007,
    NOT_ACCEPTABLE: 1008,
    UNSUPPORTED_MEDIA_TYPE: 1009,
    THROTTLED: 1010,
    PAYMENT_METHOD_NOT_FOUND: 1209,
    PRIMARY_PAYMENT_METHOD_NOT_FOUND: 1800,
    CDS_NEW_ORDER_ERROR: 9002,
};
