import {UI} from "../../stem-core/src/ui/UIBase";

import {Modal, ModalStyle} from "../../blinkpay/ui/Modal";
import {BlinkLogo} from "../../core/ui/SVGElements.jsx";
import {authFormService} from "../../blinkpay/services/AuthFormService";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {AuthFlowPanel} from "../../blinkpay/common/auth/AuthFlowPanel.jsx";
import {styleRuleInherit} from "../../stem-core/src/decorators/Style.js";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw.js";


class AuthenticationModalStyle extends ModalStyle {
    @styleRuleInherit
    modal = {
        background: this.themeProps.WHITE,
        padding: "36px 24px",
        maxWidth: 375,
        fontSize: 14,
        display: "flex",
        flexDirection: "column",
    };
}


@autoredraw(authFormService)
@registerStyle(AuthenticationModalStyle)
export class AuthenticationModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            autoWidth: false,
            onHide: () => authFormService.setRegistrationMail(null),
        };
    }

    render() {
        const {themeProps} = this;
        return [
            <BlinkLogo size={24} color={themeProps.WALLET_12} style={{
                textAlign: "center",
                marginBottom: 24,
            }}/>,
            <AuthFlowPanel
                externalAuthProviders={["facebook", "google", "twitter", "linkedin"]}
            />
        ];
    }
}
