import {UI, registerStyle, styleRule} from "../../../../../stem-core/src/ui/UI.js";
import {Modal, ModalStyle} from "../../../../ui/Modal.jsx";
import {Messages} from "../../../../Messages.js";
import {NOOP_FUNCTION} from "../../../../../stem-core/src/base/Utils.js";
import {wrapInSpinner} from "../../../../../core/ui/LoadingSpinner.jsx";
import {DefaultCodeConfirmationForm} from "../../../../common/auth/DefaultCodeConfirmationForm.jsx";
import {apiConfirmUserEmailChange} from "../../../../../client/state/UserProfileStore.js";
import {MessageElement} from "../../../../widget/ui/MessageElement.jsx";


export class EmailChangeConfirmationModalStyle extends ModalStyle {
    @styleRule
    cancel = {
        cursor: "pointer",
        padding: 6,
        width: "fit-content",
        color: this.themeProps.MERCHANT_2,
        margin: "6px auto 0",
    };

    @styleRule
    textLine = {
        lineHeight: 21,
    };

    @styleRule
    textContainer = {
        ">:not(:last-child)": {
            marginBottom: 12,
        }
    };
}

@registerStyle(EmailChangeConfirmationModalStyle)
export class EmailChangeConfirmationModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            hideOnScrimClick: false,
            title: Messages.confirmEmailChangeModalTitle,
            onConfirmSuccess: NOOP_FUNCTION,
            userProfile: null,
            newEmail: "",
            closeTestId: "confirmationModalClose",
        };
    }

    render() {
        const {styleSheet} = this;
        const {userProfile, newEmail} = this.options;

        return [
            <div className={styleSheet.textContainer}>
                <MessageElement className={styleSheet.textLine} message={Messages.confirmEmailChangeLine1(newEmail)}/>
                <MessageElement className={styleSheet.textLine}
                                message={Messages.confirmEmailChangeLine2(newEmail, userProfile.getEmail())}/>
            </div>,
            <DefaultCodeConfirmationForm onSubmit={code => this.handleConfirmationCodeSubmit(code)}/>,
            <div className={styleSheet.cancel} onClick={() => this.hide()}>{Messages.cancel}</div>
        ]
    }

    @wrapInSpinner
    async handleConfirmationCodeSubmit(code) {
        const {onConfirmSuccess} = this.options;
        const response = await apiConfirmUserEmailChange({code});
        this.hide();
        onConfirmSuccess();
        return response;
    }
}
