import {MakeStore, StoreObject} from "../../stem-core/src/state/Store.js";
import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";
import {field} from "../../stem-core/src/state/StoreField.js";
import {apiClient} from "../connection/BlinkApiClient.js";


@makeEnum
export class PaymentProcessorType extends BaseEnum {
    static BLINK_CREDIT;
    static STRIPE;
    static CDS;
    static AMAZON;
    static PAYPAL;
    static CDS_LEGACY;

    toString() {
        if (this === this.constructor.AMAZON) {
            return "Amazon Pay"
        }
        if (this === this.constructor.PAYPAL) {
            return "PayPal"
        }
        return super.toString();
    }
}

class PaymentProcessor extends StoreObject {
    @field(PaymentProcessorType) provider;
    @field("Merchant") merchant;
    @field("LegalEntity") legalEntity;
    @field(String) publicKey;
    @field(Object) publicConfig;
    @field(Boolean) isPublic;
    @field(Boolean) isActive;
    @field(Boolean) isEditable;
    @field(Boolean) supportsDonations;
    @field(Boolean) supportsRecurring;

    toString() {
        return `${this.provider} ${this.isPublic ? "public" : "private"} ${this.merchant || "Blink"}`;
    }

    // Convert this object to be safe for the SDK to handle it
    toSDK() {
        const {
            provider,
            merchantId,
            legalEntityId,
            publicKey,
            publicConfig,
            isPublic,
            isActive,
            supportsDonations,
            supportsRecurring
        } = this;

        return {
            provider,
            merchantId,
            legalEntityId,
            publicKey,
            publicConfig,
            isPublic,
            isActive,
            supportsDonations,
            supportsRecurring
        }
    }

    isBlinkGlobal() {
        return this.merchantId == null && this.legalEntityId == null;
    }

    canServiceMerchant(merchant) {
        if (this.merchantId === merchant?.id || this.legalEntityId === merchant?.legalEntityId) {
            return true;
        }
        return !this.merchantId && !this.legalEntityId;
    }
}

export const PaymentProcessorStore = MakeStore("PaymentProcessor", PaymentProcessor);

export async function apiMerchantEditPaymentProcessor(request) {
    return apiClient.post("/merchant/edit_payment_processor", request);
}
