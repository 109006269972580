import {apiClient} from "../connection/BlinkApiClient.js";
import {MoneyObject} from "../../stem-core/src/localization/Money.js";
import {PaymentStatusDependentStore} from "./misc/StoreUtils";
import {PaymentMethodStore} from "./PaymentMethodStore";
import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";
import {field} from "../../stem-core/src/state/StoreField.js";

@makeEnum
export class TopUpStatus extends BaseEnum {
    static NOT_PROCESSED;
    static FAILED;
    static SUCCEEDED;
    static REVERTED;
}

export class TopUpPayment extends MoneyObject {
    @field("Merchant") merchant;
    @field("UserProfile") user;
    @field("PaymentMethod") paymentMethod;
    @field(Date) createdAt;
    @field(TopUpStatus) status;
    @field(Boolean) isAutomatic;
    // TODO @branch other fields are still required

    // TODO @cleanup deprecate
    getPaymentMethod() {
        return PaymentMethodStore.get(this.paymentMethodId);
    }
}

class TopUpPaymentStoreClass extends PaymentStatusDependentStore("TopUpPayment", TopUpPayment) {
    Source = {
        PAYMENT_METHOD: "payment_method",
        CREDIT: "blink_credit",
    };
}

export const TopUpPaymentStore = new TopUpPaymentStoreClass();

export async function apiUserTopUp(amount, paymentMethod, userWallet) {
    const response = await apiClient.post("/top_up_payments/top_up", {
        amount,
        paymentMethodId: paymentMethod.id,
        walletId: userWallet.id,
    });
    return TopUpPaymentStore.loadObject(response);
}

export async function apiAdminMarkChargeFailed(request) {
    return apiClient.post("/admin/top_up_payments/edit_status_failed", request);
}
