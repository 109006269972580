import {UI} from "../../../../stem-core/src/ui/UIBase";
import {styleRuleInherit} from "../../../../stem-core/src/ui/Style";

import {DropdownItem} from "./DropdownItem";
import {styleRule} from "../../../../stem-core/src/decorators/Style";
import {isSmallScreen} from "../../../../blinkpay/Utils";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {isDeepEqual} from "../../../../blinkpay/UtilsLib";
import {KeyEvent} from "../../../../blinkpay/KeyEvent";
import {AnchoredPopup, AnchoredPopupStyle} from "../../../../stem-core/src/ui/AnchoredPopup.jsx";


export class DropdownListStyle extends AnchoredPopupStyle {
    @styleRuleInherit
    popup = {
        maxHeight: 200,
        overflow: "auto",
        width: "100%",
        minWidth: "fit-content",
        padding: "6px 0",
        ">:first-child": {
            borderTop: "none",
        },
        ">*": {
            borderTop: () => "1px solid " + this.themeProps.DROPDOWN_ITEM_BORDER,
        },
    };

    @styleRule
    item = {
        width: "100%",
        color: this.themeProps.INPUT_TEXT_COLOR,
        whiteSpace: "nowrap",
        fontSize: () => (isSmallScreen() ? this.themeProps.FONT_SIZE_SMALL : this.themeProps.FONT_SIZE_NORMAL),
        ">*": {
            padding: () => (isSmallScreen() ? 7 : 13),
        },
    };

    @styleRule
    hovered = {
        background: this.themeProps.DROPDOWN_ITEM_HOVER_BACKGROUND,
    };
}


@registerStyle(DropdownListStyle)
export class DropdownList extends AnchoredPopup {
    lastKeyUpTimestamp = 0;
    currentSelectedElementIndex = -1;
    prefix = "";
    maxHeight = 200;

    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            testId: "dropdownList",
            offset: -options?.anchor?.getHeight() - 6,
        };
    }

    onKeyUp(event) {
        event.stopPropagation();
        event.preventDefault();

        const key = new KeyEvent(event);

        if (key.isEnter() || key.isTab()) {
            this.children[this.currentSelectedElementIndex].children[0].setActive();
            this.hide();
            return;
        }
        if (key.isBackspace()) {
            this.prefix = "";
            return;
        }
        if (key.isEscape()) {
            this.hide();
            return;
        }
        // TODO: This doesn't work when scrolling is involved.
        if (key.isArrowUp()) {
            this.updateHoverElement(this.currentSelectedElementIndex - 1);
            return;
        }
        if (key.isArrowDown()) {
            this.updateHoverElement(this.currentSelectedElementIndex + 1);
            return;
        }

        const currentTimestamp = Date.now();

        // 1.5s delay, after that it resets.
        if (currentTimestamp - this.lastKeyUpTimestamp > 1000) {
            this.prefix = "";
        }
        this.lastKeyUpTimestamp = currentTimestamp;
        this.prefix += key.toString();

        this.updateCurrentHighlightedOption();
    }

    updateCurrentHighlightedOption() {
        let index = 0;
        for (const option of this.children) {
            const optionName = option.children[0].children[0].node.innerHTML;
            if (optionName.toLowerCase().startsWith(this.prefix.toLowerCase())) {
                this.updateHoverElement(index);
                return;
            }
            index += 1;
        }
    }

    updateHoverElement(index) {
        if (index < 0) {
            index = this.children.length - 1;
        } else if (index >= this.children.length) {
            index = 0;
        }
        if (this.currentSelectedElementIndex >= 0) {
            this.children[this.currentSelectedElementIndex].removeClass(this.styleSheet.hovered);
        }
        this.currentSelectedElementIndex = index;
        this.children[this.currentSelectedElementIndex].addClass(this.styleSheet.hovered);
        this.children[this.currentSelectedElementIndex].node.scrollIntoView();
    }

    render() {
        const {styleSheet} = this;
        const {options, active, formatter} = this.options;

        return options.map(option => {
                return <div className={styleSheet.item}>
                    <DropdownItem active={active && isDeepEqual(active, option)} list={this} item={option}>
                        {formatter(option)}
                    </DropdownItem>
                </div>
            }
        );
    }

    // options = {dropdown, anchor, options, active, formatter}
    static expand(options) {
        return this.create(document.body, {
            ...options,
            style: {
                width: options.dropdown.getWidth()
            }
        });
    }

    hide() {
        super.hide();
        this.options.dropdown.dispatch("collapse");
    }

    addAnchorListeners() {
        super.addAnchorListeners();
        const {dropdown} = this.options;

        this.addListener("changeActive", child => {
            dropdown.dispatch("changeActive", child);
            this.updateOptions({active: child.value});
            this.hide();
        });
    }

    scrollToActiveItem() {
        if (!this.children) {
            return;
        }

        const activeItem = this.children.find(itemWrapperNode => itemWrapperNode.children[0].options.active)

        if (activeItem) {
            activeItem.node.scrollIntoView({block: "center"})
        }
    }

    showPopup() {
        // While the window size changes, delay the apparition of the dropdown list. If there is a 50ms interval where
        // no resizes occured, then show the lis. After a second, if we weren't able to show the list due to these
        // window size changes, close the list.

        const deltaTimeout = 50;

        this.attachTimeout(() => {
            if (this.hidden) {
                this.hide();
            }
        }, 1000);

        let lastChangeTimestamp = Date.now();
        this.attachTimeout(() => tryToShowDropdownList(lastChangeTimestamp), deltaTimeout);

        const tryToShowDropdownList = inputChangeTimestamp => {
            if (lastChangeTimestamp === inputChangeTimestamp) {
                super.showPopup();
                this.scrollToActiveItem();
            }
        };
        this.attachEventListener(window, "resize", () => {
            lastChangeTimestamp = Date.now();
            this.attachTimeout(() => tryToShowDropdownList(lastChangeTimestamp), deltaTimeout);
        });
    }

    onMount() {
        super.onMount();

        this.attachEventListener(document.body, "keyup", event => this.onKeyUp(event));
    }
}
