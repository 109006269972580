const imagePrefix = "https://d3ki0vovb6k3h1.cloudfront.net/images/team/";

export const TeamData = [{
    imageURL: imagePrefix + "MihaiCiucu.jpeg",
    name: "Mihai Ciucu",
    position: "Chief Executive Officer",
    locations: ["Palo Alto, CA", "Bucharest, RO"],
}, {
    imageURL: imagePrefix + "AndreiGrigorean.jpeg",
    name: "Andrei Grigorean",
    position: "Chief Operating Officer",
    locations: ["Palo Alto, CA", "Bucharest, RO"],
}, {
    imageURL: imagePrefix + "MonicaSomandroiu-256px.jpg",
    name: "Monica Somandroiu",
    position: "Operations Manager",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "NiallFerguson.jpeg",
    name: "Niall Ferguson",
    position: "Advisor",
    locations: ["Stanford, CA"],
}, {
    imageURL: imagePrefix + "KatharinaSpence.png",
    name: "Katharine Spence",
    position: "Communications",
    locations: ["London, UK"],
},  {
    imageURL: imagePrefix + "MihaitaLeoveanu.jpeg",
    name: "Mihaita Leoveanu",
    position: "Engineering Manager",
    locations: ["Bucharest, RO"],
},  {
    imageURL: imagePrefix + "JonnyMack-256px.jpg",
    name: "Jonny Mack",
    position: "Designer",
    locations: ["Seattle, WA"],
}, {
    imageURL: imagePrefix + "DenisMita.jpeg",
    name: "Denis Mita",
    position: "Front-End Developer",
    locations: ["Bucharest, RO"],
},  {
    imageURL: imagePrefix + "MariusCirstean.jpeg",
    name: "Marius Cirstean",
    position: "QA Engineer",
    locations: ["Bucharest, RO"],
}, {
    imageURL: imagePrefix + "VladTarniceru-256px.jpg",
    name: "Vlad Tarniceru",
    position: "UI/UX Engineer",
    locations: ["Bucharest, RO"],
}];

