import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";
import {Link} from "../../stem-core/src/ui/primitives/Link.jsx";
import {COMPONENT_MAX_WIDTH, MOBILE_MARGIN, NAVBAR_HOVER_CUT_OFF, NavbarConstants} from "../Constants";
import {isDesktopMedium, isDesktopSmall} from "../Utils";
import {Messages} from "../../blinkpay/Messages";
import {ArrowRightIcon, BlinkLogo} from "../../core/ui/SVGElements.jsx";
import {LandingPageLinkButton} from "../LandingPageTheme";
import {Header} from "../sections-common/Header";
import {switchSection} from "../SectionsTransitionAnimation";
import {AuthenticationModal} from "../components/AuthenticationModal";

class NavBarStyle extends StyleSheet {
    transitionTime = 300;

    @styleRule
    container = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        zIndex: 100,
        height: NavbarConstants.HEIGHT + NavbarConstants.PADDING / 2,
        marginBottom: -NavbarConstants.HEIGHT - NavbarConstants.PADDING / 2,
        position: "relative",
        color: this.themeProps.NAVBAR_ITEM_COLOR,
    };

    @styleRule
    navInner = {
        width: COMPONENT_MAX_WIDTH,
        margin: () => (isDesktopMedium() ? `auto ${MOBILE_MARGIN}px` : "0 auto"),
        display: "flex",
        alignItems: "center",
    };

    @styleRule
    navSection = {
        display: "flex",
        flex: 1,
    };

    @styleRule
    navItem = {
        display: "flex",
        alignItems: "center",
        color: "inherit",
        textDecoration: "none",
        fontWeight: "normal",
        fontSize: 18,
        cursor: "pointer",
        transition: "0.15s ease",
        opacity: 0.66,
        marginRight: 48,
        ":hover": {
            opacity: 1,
        },
    };

    @styleRule
    navLogo = {
        display: "flex",
        alignItems: "center",
        marginRight: 64,
    };

    @styleRule
    menuItemsContainer = {
        display: "flex",
    };

    @styleRule
    clickable = {
        cursor: "pointer",
    };

    @styleRule
    loginSignupItemsContainer = {
        display: "flex",
        ">*": {
            fontWeight: "normal",
        },
    };

    @styleRule
    rightArrowIcon = {
        marginLeft: 10,
    };

    @styleRule
    loginButton = {
        whiteSpace: "nowrap",
        padding: "10px 18px !important",
        borderRadius: "21px !important",
        transition: ".3s box-shadow ease !important",
        background: this.themeProps.LANDING_PAGE_LOGIN_BUTTON_BACKGROUND + "!important",
        boxShadow: () => this.themeProps.LANDING_PAGE_BUTTON_SHADOW + "!important",
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_DARK_BUTTON_SHADOW_ON_DARK_BACKGROUND + "!important",
            background: this.themeProps.LANDING_PAGE_LOGIN_BUTTON_BACKGROUND + "!important",
        },
    };

    @styleRule
    fillSpace = {
        flex: 1,
    };

    @styleRule
    darkLogo = {
        " g": {
            fill: this.themeProps.LANDING_PAGE_NAV_FLOATING_COLOR,
        },
    };

    @styleRule
    darkLoginButton = {
        background: () => this.themeProps.LANDING_PAGE_NAV_FLOATING_BUTTON_BACKGROUND + "!important",
        ":hover": {
            background: () => this.themeProps.LANDING_PAGE_NAV_FLOATING_BUTTON_BACKGROUND + "!important",
            boxShadow: () => this.themeProps.LANDING_PAGE_LOGIN_BUTTON_SHADOW_ON_LIGHT_BACKGROUND + "!important",
        },
    };

    @styleRule
    hoverableNav = {
        position: "fixed",
        height: NavbarConstants.HEIGHT,
        top: 0,
        transform: "translateY(-100%)",
        background: this.themeProps.LANDING_PAGE_NAV_FLOATING_BACKGROUND,
        color: this.themeProps.LANDING_PAGE_NAV_FLOATING_COLOR,
        boxShadow: this.themeProps.LANDING_PAGE_CARD_SHADOW,
    };

    @styleRule
    hoverableNavTransition = {
        transition: "transform " + this.transitionTime / 1000 + "s ease",
    };

    @styleRule
    hoverableNavVisible = {
        transform: "translateY(0) !important",
    };
}

@registerStyle(NavBarStyle)
export class NavBar extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            pageInstance: null,
            selectedSection: null,
        };
    }

    constructor(...args) {
        super(...args);
        this.constructor.instance = this;
    }

    static getInstance() {
        return this.instance;
    }

    extraNodeAttributes(attr) {
        if (this.hoverableNav) {
            attr.addClass(this.styleSheet.hoverableNav);
            attr.setAttribute("id", "white-nav");
        }
    }

    getSelectedNavbarItemStyle() {
        return {
            fontWeight: "bold",
            opacity: 1,
            cursor: "initial",
            pointerEvents: "none",
        };
    }

    getNavbarItemStyle(itemId) {
        if (itemId === this.options.navManager.getActiveTab()) {
            return this.getSelectedNavbarItemStyle();
        }
        return {};
    }

    render() {
        const {styleSheet, hoverableNav, themeProps} = this;
        const {selectedSection, navManager} = this.options;
        const {sections, items} = navManager;

        return [
            <div className={styleSheet.navInner}>
                <div className={styleSheet.navSection}>
                    <Link
                        className={styleSheet.clickable + styleSheet.navLogo}
                        onClick={() => navManager.scrollTo(items[0].id)}>
                        <BlinkLogo
                            color={themeProps.NAVBAR_ITEM_COLOR}
                            size={26}
                            className={hoverableNav ? styleSheet.darkLogo : ""}
                        />
                    </Link>
                    <div className={styleSheet.menuItemsContainer}>
                        {sections.map(({id, href, label}) => (
                            <Link
                                className={styleSheet.navItem}
                                value={label}
                                onClick={() => switchSection(href)}
                                style={id === selectedSection ? this.getSelectedNavbarItemStyle() : {}}
                            />
                        ))}
                    </div>
                    <div className={styleSheet.fillSpace}/>
                    <div className={styleSheet.menuItemsContainer} id="navbar-items">
                        {items.slice(1).map(navItem => <Link
                                className={styleSheet.navItem}
                                style={this.getNavbarItemStyle(navItem.id)}
                                onClick={() => navManager.scrollTo(navItem.id)}
                                label={navItem.label}
                            />
                        )}
                    </div>
                    <div className={styleSheet.loginSignupItemsContainer}>
                        <LandingPageLinkButton style={{display: "flex"}}
                                               onClick={() => AuthenticationModal.show()}
                                               className={styleSheet.loginButton + (hoverableNav ? styleSheet.darkLoginButton : "")}>
                            {Messages.logIn}
                            <ArrowRightIcon size={21} className={styleSheet.rightArrowIcon} />
                        </LandingPageLinkButton>
                    </div>
                </div>
            </div>,
        ];
    }

    refreshFloatingStatus() {
        const {styleSheet} = this;

        if (isDesktopSmall() || !window.pageYOffset) {
            if (this.hoverableNav) {
                this.hoverableNav = false;
                this.removeClass(styleSheet.hoverableNavVisible);
                this.removeClass(styleSheet.hoverableNavTransition);
                this.redraw();
            }
            return;
        }

        const headerSection = Header.getInstance();
        if (!headerSection) {
            return;
        }
        const headerNode = headerSection.node;
        const {bottom} = headerNode.getBoundingClientRect();

        if (bottom < NAVBAR_HOVER_CUT_OFF && !this.hoverableNav) {
            this.hoverableNav = true;
            this.redraw();
            setTimeout(() => {
                this.addClass(styleSheet.hoverableNavTransition);
                this.addClass(styleSheet.hoverableNavVisible);
            });
        } else if (bottom >= NAVBAR_HOVER_CUT_OFF && this.hoverableNav) {
            this.hoverableNav = false;
            this.redraw();
            this.removeClass(styleSheet.hoverableNavVisible);
            this.removeClass(styleSheet.hoverableNavTransition);
        }
    }

    onMount() {
        super.onMount();

        this.attachListener(this.options.navManager, "activeTabUpdate", () => this.redraw());

        this.attachEventListener(window, "scroll", () => this.refreshFloatingStatus());
    }
}
