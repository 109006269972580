import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Messages} from "../../../Messages.js";
import {Flow} from "../Flow.js";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {INLINE_SUBSCRIPTION_URLS} from "../../PanelConstants.js";

export class SubscriptionReviewFlowStep extends BaseFlowStep {
    continueLabel = Messages.continueToPayment;

    async showPanel() {
        await Flow.subscriptionPlan.fetchPrices(Flow.address.selectedValue); // TODO @flow2 this is here only as a temp solution, should happen automatically after fetching the address
        Router.changeURL(INLINE_SUBSCRIPTION_URLS.subscriptionCheckout);
    }
}
