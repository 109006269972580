import {UI, styleRule, StyleSheet} from "../../stem-core/src/ui/UI";

import {HamburgerIcon} from "../../core/ui/SVGElements.jsx";
import {registerStyle, Theme} from "../../stem-core/src/ui/style/Theme";
import {MobileNavbarAnimated} from "./MobileNavbarAnimated";
import {isDesktopSmall} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";
import {BlinkLogo} from "../../core/ui/SVGElements.jsx";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {switchSection} from "../SectionsTransitionAnimation";


class MobileNavbarStyle extends StyleSheet {
    @styleRule
    container = {
        position: "fixed",
        width: "100vw",
        zIndex: 10000,
        display: "flex",
        flexDirection: "column",
        color: this.themeProps.NAVBAR_ITEM_COLOR,
    };

    @styleRule
    nav = {
        padding: () => (Device.isMobileDevice() ?  "8px 0" : "1.28rem 0"),
        display: "flex",
        alignItems: "center",
        background: () =>
            isDesktopSmall()
                ? this.themeProps.LANDING_PAGE_MOBILE_HEADER_BACKGROUND
                : this.themeProps.LANDING_PAGE_HEADER_BACKGROUND,
        transition: "box-shadow .3s",
        justifyContent: "space-between",
    };

    @styleRule
    activeHeaderElement = {
        marginRight: 4,
        fontWeight: "regular",
        fontSize: () => (Device.isMobileDevice() ? 18 : 22),
        color: this.themeProps.LIGHT_BACKGROUND,
        paddingLeft: Device.isMobileDevice() ? 24 : 23,
    };

    @styleRule
    bars = {
        padding: () => (Device.isMobileDevice() ? "1px 24px 20px" : ".16rem 3.84rem 32px"),
        fontSize: () => (Device.isMobileDevice() ? 28 : "4.48rem"),
        color: this.themeProps.LIGHT_BACKGROUND,
        alignSelf: "start",
        ">*>svg>g>g": {
            fill: this.themeProps.LIGHT_BACKGROUND,
        },
        cursor: "pointer",
    };

    @styleRule
    navItem = {
        color: "inherit",
        fontSize: 14,
        opacity: 0.66,
        marginLeft: 6,
        marginTop: 4,
        padding:  "4px 8px",
    };

    @styleRule
    fillSpace = {
        flex: 1,
    };
}

@registerStyle(MobileNavbarStyle)
export class MobileNavbar extends UI.Element {
    getSelectedNavbarItemStyle() {
        return {
            fontWeight: "bold",
            opacity: 1,
            cursor: "initial",
        };
    }

    render() {
        const {styleSheet, themeProps} = this;
        const {selectedSection, navManager} = this.options;
        const {sections} = navManager;

        let activeHeaderElementStyle = null;

        return [
            <div className={styleSheet.nav}>
                <div className={styleSheet.activeHeaderElement} style={activeHeaderElementStyle}>
                    <BlinkLogo
                        color={themeProps.LIGHT_BACKGROUND}
                        size={21}
                        style={{marginTop: () => (Device.isMobileDevice() ? "-2px" : "-12px")}}
                    />
                </div>
                {sections.map(({id, href, label}) => (
                    <Link
                        className={styleSheet.navItem}
                        value={label.toString()}
                        onClick={() => switchSection(href)}
                        style={id === selectedSection ? this.getSelectedNavbarItemStyle() : {}}
                    />
                ))}
                <div className={styleSheet.fillSpace} />
                <HamburgerIcon ref="menuButton" className={styleSheet.bars}
                               size={Device.isMobileDevice() && isDesktopSmall() ? 17 : 27} />
            </div>,
        ];
    }

    toggleMenu() {
        const {navManager, selectedSection} = this.options;
        const {items} = navManager;

        const activeChild =
            this.currentActive === items[0].id ? items[0] : items.find(el => el.id === this.currentActive);
        MobileNavbarAnimated.show({active: activeChild, navManager, selectedSection});
    }

    onMount() {
        super.onMount();
        this.menuButton.addClickListener(event => {
            event.stopPropagation();
            event.preventDefault();
            this.toggleMenu();
        });

        this.attachListener(this.options.navManager, "activeTabUpdate", activeTab => {
            this.currentActive = activeTab;
            this.redraw();
        });
    }
}
