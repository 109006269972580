import {OAuth2Client} from "./OAuth2Client.js";
import {OAuth1Client} from "./OAuth1Client.js";
import {
    FACEBOOK_CLIENT_ID,
    GOOGLE_CLIENT_ID,
    LINKEDIN_CLIENT_ID, SOCIAL_APPS,
    TWITTER_CLIENT_ID
} from "../../blink-sdk/Constants.js";

const EXTERNAL_AUTH_CONFIGS = {
    [SOCIAL_APPS.Google]: {
        client: OAuth2Client,
        clientId: GOOGLE_CLIENT_ID,
        redirectUri: "/login-redirect/google/",
        responseType: "code",
        scope: "email profile",
        authServerUrl: "https://accounts.google.com/o/oauth2/v2/auth",
        loginEndpoint: "/socialauth/google/",
    },
    [SOCIAL_APPS.LinkedIn]: {
        client: OAuth2Client,
        clientId: LINKEDIN_CLIENT_ID,
        redirectUri: "/login-redirect/linkedin/",
        responseType: "code",
        scope: "r_liteprofile r_emailaddress",
        authServerUrl: "https://www.linkedin.com/oauth/v2/authorization",
        loginEndpoint: "/socialauth/linkedin/",
    },
    [SOCIAL_APPS.Facebook]: {
        client: OAuth2Client,
        clientId: FACEBOOK_CLIENT_ID,
        redirectUri: "/login-redirect/facebook/",
        scope: "email",
        authServerUrl: "https://www.facebook.com/v6.0/dialog/oauth", // TODO @auth Come back to this on May 5th 2022 (preferably sooner tho).
        loginEndpoint: "/socialauth/facebook/",
    },
    [SOCIAL_APPS.Twitter]: {
        client: OAuth1Client,
        clientId: TWITTER_CLIENT_ID,
        redirectUri: "/login-redirect/twitter/",
        authServerUrl: "https://api.twitter.com/oauth/authorize",
        requestTokenEndpoint: "/socialauth/twitter/request_token/",
        loginEndpoint: "/socialauth/twitter/connect/",
    },
};

const cachedClients = {};

export function GetExternalAuthClient(clientName) {
    clientName = clientName.toLowerCase(); // TODO @cleanup doing this to protect from remenant of the stupid
    if (cachedClients[clientName]) {
        return cachedClients[clientName];
    }
    const config = EXTERNAL_AUTH_CONFIGS[clientName];
    const ClientClass = config.client;
    cachedClients[clientName] = new ClientClass(config);

    return cachedClients[clientName];
}
