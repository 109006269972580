import {iFrameState} from "../../services/IFrameState.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {INLINE_NEWSLETTER_URLS} from "../PanelConstants.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore.js";
import {BaseFlowHandler} from "./BaseFlowHandler.js";
import {toArray} from "../../../stem-core/src/base/Utils.js";
import {MerchantAudienceMemberStore} from "../../../client/state/merchant/MerchantAudienceMemberStore.js";
import {VisibilityStatus} from "../../../client/state/misc/GenericEnums.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";


export class NewsletterFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.newsletter;

    // Only the newsletters for this flow
    getNewsletters() {
        const newsletters = toArray(iFrameState.iframeParams.newsletter);
        // TODO @flow handle everything in the same way
        const allNewsletters = MerchantAudienceStore.allUnfiltered().filter(audience => audience.merchantId === iFrameMerchantService.merchantId);
        if (newsletters) {
            // We want only specific newsletters
            return allNewsletters.filter(audience => newsletters.includes(audience.id) || newsletters.includes(audience.name));
        }
        return allNewsletters.filter(audience => audience.visibility === VisibilityStatus.PUBLIC);
    }

    isUserSubscribedToAllGivenNewsletters() {
        const newsletters = this.getNewsletters();
        const audienceMemberships = MerchantAudienceMemberStore.all();
        const isSubscribed = (newsletter) => audienceMemberships.find(audienceMember => audienceMember.audience === newsletter && audienceMember.isSubscribed())

        for (const newsletter of newsletters) {
            if (!isSubscribed(newsletter)) {
                return false
            }
        }
        return true;
    }

    getFlowPlan() {
        const {skipCTA} = iFrameState;

        if (skipCTA) {
            Router.changeURL(INLINE_NEWSLETTER_URLS.subscribe);
        } else {
            if (this.isUserSubscribedToAllGivenNewsletters()) {
                Router.changeURL(INLINE_NEWSLETTER_URLS.success);
            } else if (this.getNewsletters().length === 1) {
                Router.changeURL(INLINE_NEWSLETTER_URLS.subscribe);
            } else {
                Router.changeToCustomPanel();
            }
        }

        return null;
    }
}
