import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";

import {Modal, ModalStyle} from "../../../../../ui/Modal.jsx";
import {BlinkButton} from "../../../../../../core/ui/Button.jsx";
import {Messages} from "../../../../../Messages.js";
import {authService} from "../../../../../../client/connection/services/AuthService.js";
import {styleRule} from "../../../../../../stem-core/src/decorators/Style.js";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme.js";

class AccountSuspendedModalStyle extends ModalStyle {
    @styleRule
    button = {
        width: "100%",
    };

    @styleRule
    description = {
        fontSize: 16,
        lineHeight: 20,
        color: this.themeProps.MERCHANT_2,
        marginBottom: 24,
    };
}

@registerStyle(AccountSuspendedModalStyle)
export class AccountSuspendedModal extends Modal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.accountClosedModalTitle,
            hasCloseIcon: false,
            hideOnScrimClick: false,
        };
    }

    render() {
        const {styleSheet} = this;

        return [
          <div className={styleSheet.description}>{Messages.accountClosedModalText}</div>,
          <BlinkButton className={styleSheet.button} onClick={async () => {
              await authService.logout();
              window.location.href = "/";
          }}>{Messages.logOut}</BlinkButton>
        ];
    }
}
