import {UI} from "../../../../../stem-core/src/ui/UIBase.js";
import {styleRule, StyleSheet} from "../../../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";
import {ConfirmationModal} from "../../../../ui/ConfirmationModal.jsx";
import {UserAddressStore} from "../../../../../client/state/UserAddressStore.js";
import {LinkButton} from "../../../../../core/ui/LinkButton.jsx";
import {AddAddressModal} from "../../account/components/address/AddAddressModal.jsx";
import {PlusIcon} from "../../../../../core/ui/SVGElements.jsx";
import {Messages} from "../../../../Messages.js";
import {NOOP_FUNCTION} from "../../../../../stem-core/src/base/Utils.js";
import {wrapInSpinner} from "../../../../../core/ui/LoadingSpinner.jsx";
import {Toast} from "../../../../ui/Toast.jsx";
import {
    apiRequestSubscriptionPriceOnAddressChange,
    apiUpdateSubscription,
} from "../../../../../client/state/SubscriptionStore.js";
import {MessageElement} from "../../../../widget/ui/MessageElement.jsx";
import {Money} from "../../../../../stem-core/src/localization/Money.js";
import {RadioListInput} from "../../../../../core/ui/input/radio/RadioListInput.jsx";
import {Flow} from "../../../../panel/flows/Flow.js";
import {AddressDescription} from "../../../../panel/pages/panels/address/components/AddressDescription.jsx";


class ChooseSubscriptionAddressStepStyle extends StyleSheet {
    @styleRule
    addNewAddress = {
        color: this.themeProps.LINK_COLOR,
        cursor: "pointer",
        fontSize: 14,
        fontWeight: "initial",
        whiteSpace: "nowrap",
        marginTop: "1.55rem",
        display: "inline-block",
        ">*": {
            opacity: 0.8,
            display: "inline-block",
        },
        ":hover>*": {
            opacity: 1,
        },
        " g": {
            fill: this.themeProps.LINK_COLOR,
        },
        ">:last-child": {
            marginLeft: 12,
        },
        ">:first-child": {
            transform: "translateY(1px)",
        },
    };

    @styleRule
    listItem = {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    };
}

@registerStyle(ChooseSubscriptionAddressStepStyle)
class ChooseSubscriptionAddressStep extends UI.Element {
    selectedAddress = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "chooseAddressModal",
            onAddressSelected: NOOP_FUNCTION,
            defaultSelectedAddress: null,
        }
    }

    render() {
        const {styleSheet} = this;
        this.selectedAddress = this.selectedAddress || this.options.defaultSelectedAddress;

        return [
            <RadioListInput
                onChange={(value) => this.handleListItemSelect(value)}
                entries={UserAddressStore.all()}
                initialValue={Flow.address.selectedValue}
                formatter={(userAddress) => <AddressDescription userAddress={userAddress} />}
            />,
            <LinkButton className={styleSheet.addNewAddress} onClick={() => AddAddressModal.show()}>
                <PlusIcon size={12.25}/>
                <span>{Messages.addNewAddress}</span>
            </LinkButton>,

        ]
    }

    handleListItemSelect(address) {
        this.onSelectedAddressChange(address);
    }

    onSelectedAddressChange(address) {
        this.selectedAddress = address;
        this.options.onAddressSelected(address);
        this.redraw();
    }

    onMount() {
        super.onMount();

        this.attachChangeListener(UserAddressStore, () => this.redraw());
        this.attachCreateListener(UserAddressStore, address => this.onSelectedAddressChange(address));
    }
}

class ConfirmSubscriptionPriceStepStyle extends StyleSheet {
    @styleRule
    container = {
        fontSize: 16,
        color: this.themeProps.DASHBOARD_DESCRIPTION_COLOR,
        lineHeight: 20,
    };

    @styleRule
    secondDescriptionLine = {
        marginTop: 12,
        ">*": {
            color: "#232323",
            fontWeight: "normal",
        }
    };
}

@registerStyle(ConfirmSubscriptionPriceStepStyle)
class ConfirmSubscriptionPriceStep extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            offer: null,
            currentPriceDetails: null,
            nextPriceDetails: null,
        }
    }

    render() {
        const {billingPlan, currentPriceDetails, nextPriceDetails} = this.options;

        return [
            Messages.updateShippingAddressDescriptionLine1,
            currentPriceDetails.finalPrice === nextPriceDetails.finalPrice ? null
                : <MessageElement className={this.styleSheet.secondDescriptionLine}
                                  message={
                                      Messages.updateShippingAddressDescriptionLine2(
                                          `${new Money(currentPriceDetails.finalPrice, billingPlan.currency)}/${billingPlan.cycleUnit}`,
                                          `${new Money(nextPriceDetails.finalPrice, billingPlan.currency)}/${billingPlan.cycleUnit}`
                                      )} />
        ]
    }
}

// TODO @cleanup fucking Cristi and his boilerplate duplication, merge this with the other address flow
export class ChangeSubscriptionAddressModal extends ConfirmationModal {
    selectedAddress = this.options.subscription?.getShippingAddress();
    confirmButtonDisabled = true;
    newAddressConfirmed = false;
    computedSubscriptionNewPrice = null;
    chooseSubscriptionAddressStepOptions = {
        title: Messages.updateAddressTitle,
        confirmLabel: Messages.updateShippingAddress,
        cancelLabel: Messages.close,
        confirmAction: () => this.handleAddressChangeConfirmation(),
        infirmAction: () => this.hide(),
    };
    confirmNewPriceStepOptions = {
        title: Messages.updateShippingAddress,
        confirmLabel: Messages.confirm,
        cancelLabel: Messages.cancel,
        confirmAction: () => this.updateSubscriptionAddress(),
        infirmAction: () => {
            this.newAddressConfirmed = false;
            this.updateOptions(this.chooseSubscriptionAddressStepOptions)
        },
    };

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            subscription: null,
            title: Messages.updateAddressTitle,
            confirmLabel: Messages.updateShippingAddress,
            cancelLabel: Messages.close,
            confirmAction: () => this.handleAddressChangeConfirmation(),
            infirmAction: () => this.hide(),
        }
    }

    render() {
        if (this.newAddressConfirmed) {
            const {subscription} = this.options;
            // TODO: from @mihai: in the future, we may skip this confirmation step if the subscription price does not change
            return <ConfirmSubscriptionPriceStep billingPlan={subscription.billingPlan}
                                                 currentPriceDetails={subscription.nextCyclePrice}
                                                 nextPriceDetails={this.computedSubscriptionNewPrice} />
        }
        return <ChooseSubscriptionAddressStep
            defaultSelectedAddress={this.selectedAddress}
            onAddressSelected={address => this.onAddressSelected(address)}
        />;
    }

    onAddressSelected(address) {
        this.selectedAddress = address;
        this.confirmButtonDisabled = address && address === this.options.subscription.getShippingAddress();
        this.redraw();
    }

    @wrapInSpinner
    async handleAddressChangeConfirmation() {
        const {subscription} = this.options;
        const response = await apiRequestSubscriptionPriceOnAddressChange(subscription.id,
            subscription.merchantId,
            this.selectedAddress.id
        );

        this.newAddressConfirmed = true;
        this.computedSubscriptionNewPrice = response.recurringOffers.find(offer => offer.recurringOfferId == null);
        // TODO WTF Fucking Cristi
        this.updateOptions(this.confirmNewPriceStepOptions);
    }

    @wrapInSpinner
    async updateSubscriptionAddress() {
        const {subscription} = this.options;
        if (!subscription) {
            return;
        }

        // TODO @flow2 dumb duplication, should do through the same flow logic as the other subscription editing logic
        await apiUpdateSubscription({
            subscriptionId: subscription.id,
            deliveryAddressId: this.selectedAddress.id,
        });

        Toast.show(Messages.updateAddressSuccess);
        this.hide();
    }
}
