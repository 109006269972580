import {UI, styleRule, StyleSheet, registerStyle, styleRuleInherit, Level} from "../../stem-core/src/ui/UI";
import {BlinkGlobal, isSmallScreen, isString} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";
import {TEST_MODE} from "../Constants";
import {CloseIcon} from "../../core/ui/SVGElements.jsx";
import {MessageElement} from "../widget/ui/MessageElement";
import {Router} from "../../stem-core/src/ui/Router.jsx";
import {WALLET_EXPANDED_WIDTH} from "../widget/WidgetConstants.js";


class BaseToastStyle extends StyleSheet {
    @styleRule
    container = {
        zIndex: 12000,
        transition: this.themeProps.DEFAULT_TRANSITION,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
        background: this.themeProps.TOAST_BACKGROUND,
        color: this.themeProps.TOAST_TEXT_COLOR,
        boxShadow: this.themeProps.TOAST_SHADOW,
    }

    // Level colors are referenced via the level name
    @styleRule
    error = {
        background: this.themeProps.ERROR_COLOR,
    }

    @styleRule
    success = {
        background: this.themeProps.SUCCESS_COLOR,
    }

    @styleRule
    visible = {
        opacity: 1,
        transform: "translate(-50%, 0)",
    };

    @styleRule
    message = {
        display: "inline-block",
        paddingRight: 8,
    };

    @styleRule
    closeIcon = {
        marginLeft: "auto", // Align it to the right inside the container
        display: "inline-block",
        cursor: "pointer",
    }
}

class WebsiteToastStyle extends BaseToastStyle {
    @styleRuleInherit
    container = {
        position: "fixed",
        padding: () => Device.isMobileDevice() ? 12 : "12px 18px",
        transform: "translate(-50%, 96px)",
        borderRadius: this.themeProps.BASE_BORDER_RADIUS,
        bottom: () => (isSmallScreen() ? 14 : 48),
        margin: "auto",
        left: "50%",
        opacity: 0,
        minWidth: "fit-content",
        width: "fit-content",
        textAlign: "center",
    };
}

export class PanelToastStyle extends WebsiteToastStyle {
    @styleRuleInherit
    container = {
        bottom: "",
        top: 8,
        transform: "translate(-50%, -96px)",
    };
}

// The style inside the pill widget
export class WidgetToastStyle extends BaseToastStyle {
    @styleRuleInherit
    container = {
        position: "absolute",
        padding: 16,
        transform: "translateY(calc(-100% - 6px))",
        top: 0,
        width: WALLET_EXPANDED_WIDTH,
        overflow: "hidden",
        minWidth: "100%",
    };

    @styleRule
    visible = {
        transform: "translateY(0)", // Shift it down
    };
}

@registerStyle(WebsiteToastStyle)
export class Toast extends UI.Element {
    active = null;
    hideTimeout = null;

    getDefaultOptions() {
        return {
            testId: "toast",
            visibleTimeout: 5000,
            showClose: true,
            level: Level.SUCCESS, // TODO @cleanup by default probably all Toast messages should be success
        };
    }

    static show(message, options) {
        if (Router.getCurrentPath() === "/empty") {
            return;
        }

        Toast.active?.remove();

        Toast.active = this.create(BlinkGlobal.appInstance?.node || document.body, {
            ...options,
            message
        });
    }

    static showError(error, fallbackMessage) {
        if (error.stack) {
            // This is a javascript error, at least print it
            // TODO @cleanup maybe massage the error for the user
            console.error("Error: ", error, error.stack);
        }
        if (!isString(error)) {
            error = error?.message || error?.toString();
        }
        this.show(error || fallbackMessage || "Error in performing action", {
            level: Level.ERROR,
            visibleTimeout: null,
        });
    }

    static showSuccess(message) {
        this.show(message, {
            level: Level.SUCCESS,
        });
    }

    extraNodeAttributes(attr) {
        const {styleSheet} = this;
        const {level} = this.options;

        attr.addClass(styleSheet[level]);
    }

    render() {
        const {styleSheet} = this;
        const {message, showClose} = this.options;

        return [
            <MessageElement message={message} className={styleSheet.message}/>,

            showClose && <CloseIcon
                color={styleSheet.themeProps.TOAST_TEXT_COLOR}
                className={styleSheet.closeIcon}
                onClick={() => this.constructor.hide(this)}
            />,
        ];
    }

    static hide(instance=null) {
        if (Toast.active && Toast.active === instance) {
            Toast.active.removeAnimated();
        }
        return instance;
    }

    remove() {
        clearTimeout(this.hideTimeout);
        this.node.remove();
        delete this;
    }

    removeAnimated() {
        this.removeClass(this.styleSheet.visible);
        setTimeout(() => {
            this.remove();
        }, this.themeProps.DEFAULT_TRANSITION_DURATION_MS);
    }

    onMount() {
        // This needs to be added in a different tick to animate the insertion.
        setTimeout(() => this.addClass(this.styleSheet.visible));

        if (this.options.visibleTimeout) {
            const toastVisibleTime = (TEST_MODE ? 2 : 1) * (this.options.visibleTimeout + this.themeProps.DEFAULT_TRANSITION_DURATION_MS);
            this.hideTimeout = setTimeout(() => this.constructor.hide(this), toastVisibleTime);
        }

        this.attachListenerOnce(Router.Global, "change", () => {
            this.constructor.hide(this);
        });
    }
}

window.Toast = Toast;
