import {MakeStore} from "../../stem-core/src/state/Store";
import {field} from "../../stem-core/src/state/StoreField.js";
import {EditableObjectStatus, VisibilityStatus} from "./misc/GenericEnums.js";
import {MoneyObject} from "../../stem-core/src/localization/Money.js";
import {apiClient} from "../connection/BlinkApiClient.js";
import {ShippingPriceEntry} from "./misc/ShippingPrice";

class Product extends MoneyObject {
    @field("Merchant") merchant;
    @field(EditableObjectStatus) status;
    @field(VisibilityStatus) visibility;
    @field(String) name;
    @field(String) description;
    @field(String) image; // Optional, the URL of the image
    @field(Number) inventorySize;
    @field(Boolean) allowMultiplePurchasesPerUser;
    @field(Boolean) requiresAddress;

    @field(Object) shippingPrices; // An array of entries, of which the first matching one is used

    // Return an array of ShippingPrice objects. Will modify the objects inline.
    getShippingPrices() {
        return ShippingPriceEntry.loadArray(this.shippingPrices, this.currencyId);
    }

    // TODO @branch @Mihai should not be required, MoneyObject should take care of this
    get currency() {
        return this.getCurrency();
    }
}

export const ProductStore = MakeStore("Product", Product);


export async function apiMerchantCreateProduct(request) {
    const response = await apiClient.post("/merchant/create_product", request);
    // TODO @Mihai loadObject without index should probably at least log a warning
    //  if there are multiple objects (or None)
    return ProductStore.loadObject(response);
}

export async function apiMerchantEditProduct(request) {
    return apiClient.post("/merchant/edit_product", request, {allowNulls: true});
}
