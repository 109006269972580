import {UI, styleRule, registerStyle, Theme} from "../../../../../stem-core/src/ui/UI.js";
import {Messages} from "../../../../Messages.js";

import {BaseCard, BaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {BlinkButton} from "../../../../../core/ui/Button.jsx";
import {List} from "../../../../ui/List.jsx";
import {AddressInlineMenu} from "../../../../common/inline-menu/AddressInlineMenu.jsx";
import {AddAddressModal} from "../components/address/AddAddressModal.jsx";
import {EditAddressModal} from "../components/address/EditAddressModal.jsx";
import {DeleteAddressModal} from "../components/address/DeleteAddressModal.jsx";


export class MyAddressesStyle extends BaseCardStyle {
    @styleRule
    addNewAddressButton = {
        width: "100%",
    };

    @styleRule
    listItem = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: "normal",
        fontSize: 16,
        padding: "12px 0",
        color: this.themeProps.MERCHANT_4,
    };

    @styleRule
    listElement = {
        ">:first-child": {
            marginTop: 12,
            borderTop: `1px solid ${Theme.props.DIVIDER_LINE_COLOR} !important`,
        },
        ">:last-child": {
            marginBottom: 12,
        },
    };
}

@registerStyle(MyAddressesStyle)
export class MyAddresses extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.myAddresses,
            userAddresses: []
        };
    }

    getDescription() {
        return this.options.userAddresses?.length ? "" : Messages.emptyAddressListDescription;
    }

    render() {
        return <List testId="accountAddresses" className={this.styleSheet.listElement}>
            {this.options.userAddresses?.map(address => <AddressInlineMenu
                address={address}
                className={this.styleSheet.listItem}
                handleEdit={() => EditAddressModal.show({address})}
                handleDelete={() => DeleteAddressModal.show({address})}
            />)}
        </List>;
    }

    renderFooter() {
        return <BlinkButton
            label={Messages.addNewAddress}
            testId="accountAddAddressButton"
            className={this.styleSheet.addNewAddressButton}
            onClick={() => AddAddressModal.show()}
        />;
    }
}
