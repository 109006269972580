import {StyleSheet} from "../stem-core/src/ui/Style";

import {Router} from "../stem-core/src/ui/Router";
import {styleRule} from "../stem-core/src/decorators/Style";

class LandingPageTransitionStyle extends StyleSheet {
    transitionTime = 200;
    transitionStyle = {
        transition: "opacity " + this.transitionTime / 1000 + "s",
    };
    hideStyle = {
        transitionTimingFunction: "ease-in",
        opacity: 0,
    };
    showStyle = {
        transitionTimingFunction: "ease-out",
        opacity: 1,
    };
    navTransitionStyle = {
        transition: "transform " + this.transitionTime / 1000 + "s ease-out",
    };
    hideNav = {
        transform: "translateY(calc(-100% - 80px)) !important",
    };

    @styleRule
    transition = {
        " #header-text-section": this.transitionStyle,
        " #navbar-items": this.transitionStyle,
        " #product-content": this.transitionStyle,
        " #identity-content": this.transitionStyle,
        " #pay-content": this.transitionStyle,
        " #funnel-content": this.transitionStyle,
        " #value-proposition-content": this.transitionStyle,
        " #ready-content": this.transitionStyle,
        " #company-content": this.transitionStyle,
        " #footer-content": this.transitionStyle,
        " #mission-content": this.transitionStyle,
        " #features-content": this.transitionStyle,
        " #white-nav": this.navTransitionStyle,
    };

    @styleRule
    hide = {
        " #header-text-section": this.hideStyle,
        " #navbar-items": this.hideStyle,
        " #product-content": this.hideStyle,
        " #identity-content": this.hideStyle,
        " #pay-content": this.hideStyle,
        " #funnel-content": this.hideStyle,
        " #value-proposition-content": this.hideStyle,
        " #ready-content": this.hideStyle,
        " #company-content": this.hideStyle,
        " #footer-content": this.hideStyle,
        " #mission-content": this.hideStyle,
        " #features-content": this.hideStyle,
        " #white-nav": this.hideNav,
    };

    @styleRule
    show = {
        " #header-text-section": this.showStyle,
        " #navbar-items": this.showStyle,
        " #product-content": this.showStyle,
        " #identity-content": this.showStyle,
        " #pay-content": this.showStyle,
        " #funnel-content": this.showStyle,
        " #value-proposition-content": this.showStyle,
        " #ready-content": this.showStyle,
        " #company-content": this.showStyle,
        " #footer-content": this.showStyle,
        " #mission-content": this.showStyle,
        " #features-content": this.showStyle,
    };
}

export function switchSection(url) {
    // Router.changeURL(url, {replaceHistory: true});
    const styleSheet = LandingPageTransitionStyle.getInstance();

    document.body.classList.add(styleSheet.transition);
    document.body.classList.add(styleSheet.hide);

    setTimeout(() => {
        Router.changeURL(url, {replaceHistory: true});
        document.body.classList.remove(styleSheet.hide);
        document.body.classList.add(styleSheet.show);
        setTimeout(() => {
            document.body.classList.remove(styleSheet.transition);
            document.body.classList.remove(styleSheet.show);
        }, styleSheet.transitionTime);
    }, styleSheet.transitionTime);
}
