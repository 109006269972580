import {BaseFlowStep} from "./BaseFlowStep.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {INLINE_DONATION_URLS} from "../../PanelConstants.js";
import {Flow} from "../Flow.js";

export class PaymentAndDonateFlowStep extends BaseFlowStep {
    init(options) {
        Flow.paymentMethod.init(options.paymentMethod);
        delete options.paymentMethod;

        Flow.donationDetails.init(options.donationDetails);
        delete options.donationDetails;

        return super.init(options);
    }

    async beforeShowPanel() {
        await Flow.paymentMethod.beforeShowPanel();
        await Flow.donationDetails.beforeShowPanel();
    }

    showPanel() {
        Router.changeURL(INLINE_DONATION_URLS.paymentAndDonate);
    }
}
