import {UI, StyleSheet, styleRule, registerStyle} from "../stem-core/src/ui/UI";

import {isDesktopSmall} from "./Utils";
import {LandingPageTheme} from "./LandingPageTheme";

export class LandingPageIndexStyle extends StyleSheet {
    @styleRule
    container = {
        width: "100%",
        overflow: "hidden",
        position: "relative",
    };

    @styleRule
    navMobile = {
        display: () => (isDesktopSmall() ? "" : "none !important"),
    };

    @styleRule
    nav = {
        display: () => (isDesktopSmall() ? "none !important" : ""),
    };

    @styleRule
    headerSubtext = {
        marginTop: () => (isDesktopSmall() ? 24 : 16),
        fontSize: 17,
        opacity: 0.88,
        fontWeight: "lighter",
    };

    @styleRule
    floatingBackground = {
        display: isDesktopSmall() ? "block" : "none",
        background: this.themeProps.LANDING_PAGE_MOBILE_HEADER_BACKGROUND,
        position: "fixed",
        top: 0,
        zIndex: -1,
        height: "100%",
        width: "100%",
    }
}

@registerStyle(LandingPageIndexStyle)
export class LandingPageIndex extends UI.Element {
    getDefaultOptions() {
        return {
            theme: LandingPageTheme,
        }
    }

    onPageEnter() {
        window.scrollTo(0, 0);
    }

    getChildrenToRender() {
        return [
            <div ref="floatingBackground" className={this.styleSheet.floatingBackground} />,
            this.render()
        ];
    }

    onMount() {
        super.onMount();

        // This class' inheritor will have a nav manager on the instance member
        this.navManager.updateActiveTab("header");
        this.addListener("urlEnter", () => {
            this.onPageEnter();
        });

        let scrolledTop = true;
        this.attachEventListener(window, "scroll", () => {
            if (!this.floatingBackground) {
                return;
            }

            if (window.scrollY <= 0 && !scrolledTop) {
                scrolledTop = true;
                this.floatingBackground.setStyle({background: this.themeProps.LANDING_PAGE_MOBILE_HEADER_BACKGROUND});
            } else if (window.scrollY > 0 && scrolledTop) {
                scrolledTop = false;
                this.floatingBackground.setStyle({background:  this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND});
            }
        });
    }
}
