import {UI} from "../../stem-core/src/ui/UIBase";

import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {Section, SectionStyle} from "../primitives/Section";
import {Messages} from "../../blinkpay/Messages";
import {styleRule, styleRuleInherit} from "../../stem-core/src/decorators/Style";
import {Device} from "../../stem-core/src/base/Device";
import {isDesktopSmall} from "../Utils";

class ReadyToGetStartedStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: () => (isDesktopSmall() ? "36px 0" : "80px 0"),
    };

    @styleRuleInherit
    content = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: () => (isDesktopSmall() ? "left" : "center"),
        flexDirection: () => (isDesktopSmall() ? "column" : "row"),
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 24 : 32),
        color: this.themeProps.LANDING_PAGE_DARK_FONT_COLOR,
        marginBottom: () => (isDesktopSmall() ? 8 : 12),
    };

    @styleRule
    subtitle = {
        fontSize: () => (Device.isMobileDevice() ? 18 : 24),
        fontWeight: "lighter",
        marginBottom: () => (isDesktopSmall() ? 24 : ""),
    };
}

@registerStyle(ReadyToGetStartedStyle)
export class ReadyToGetStarted extends Section {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            underTitle: null,
            ctaSection: null,
        };
    }

    render() {
        const {styleSheet} = this;
        const {underTitle, ctaSection} = this.options;

        return [
            <div>
                <div className={styleSheet.title}>{Messages.readyToGetStartedTitle}</div>
                <div className={styleSheet.subtitle}>{underTitle}</div>
            </div>,
            ctaSection,
        ];
    }
}
