import {UI} from "../../stem-core/src/ui/UI.js";
import {StyleSheet, styleRule} from "../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../stem-core/src/ui/style/Theme.js";
import {PaymentMethodIcon} from "../panel/pages/panels/payment-method/components/PaymentMethodIcon.jsx";
import {FormatPaymentMethodDescription} from "./PaymentMethodDescription.jsx";


class PaymentDescriptionStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        alignItems: "center",
        fontSize: 12,
    };

    @styleRule
    cardIcon = {
        marginRight: 12,
    };

    @styleRule
    cardDescription = {
        fontSize: 12,
        color: this.themeProps.MERCHANT_2,
    };
}

// TODO It's a bit strange that the content is always passed in to this component
@registerStyle(PaymentDescriptionStyle)
export class PaymentDescription extends UI.Element {
    getDefaultOptions() {
        return {
            payment: null,
        }
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {payment} = this.options;
        const paymentMethod = payment?.getPaymentMethod();

        return [
            <PaymentMethodIcon className={styleSheet.cardIcon} brand={paymentMethod?.getBrand()} size={18}/>,
            <div>
                {this.render()}
                <div className={styleSheet.cardDescription}>
                    {FormatPaymentMethodDescription(paymentMethod)}
                </div>
            </div>
        ];
    }
}
