import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";

export class InputLabelStyle extends StyleSheet {
    @styleRule
    container = {
        display: "inline-block",
        marginBottom: 8,
        fontSize: this.themeProps.FONT_SIZE_SMALL,
        fontWeight: this.themeProps.FONT_WEIGHT_BOLD,
        color: this.themeProps.TEXT_SECONDARY_COLOR,
    }
}

@registerStyle(InputLabelStyle)
export class InputLabel extends UI.Element {
    render() {
        return this.options.label;
    }
}
