import {UI} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {ConfirmationModal} from "../../../../ui/ConfirmationModal.jsx";
import {Toast} from "../../../../ui/Toast.jsx";
import {apiCancelSubscription} from "../../../../../client/state/SubscriptionStore.js";


export class CancelSubscriptionModal extends ConfirmationModal {
    getDefaultOptions(options) {
        const {subscription} = options;
        return {
            ...super.getDefaultOptions(),
            title: Messages.cancelSubscriptionTitle,
            description: Messages.cancelSubscriptionDescription(`${subscription.getMerchantTitle()} - ${subscription.coverage.name}`),
            confirmAction: () => this.cancel(),
            cancelLabel: Messages.close,
            confirmLabel: Messages.cancelSubscription,
        };
    }

    async cancel() {
        try {
            await apiCancelSubscription(this.options.subscription.id);
            Toast.show(Messages.cancelSubscriptionSuccess);
            this.hide();
        } catch (error) {
            Toast.showError(error);
        }
    }
}
