import {UI} from "../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../stem-core/src/ui/Style";
import {styleRule} from "../../../stem-core/src/decorators/Style";
import {Device} from "../../../stem-core/src/base/Device";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {isDesktopSmall} from "../../Utils";

class WalletProductCardStyle extends StyleSheet {
    @styleRule
    card = {
        display: "flex",
        flexDirection: () => (Device.isMobileDevice() ? "row" : "column"),
        marginBottom: () => (isDesktopSmall() ? 24 : ""),
        ":last-child": {
            marginBottom: 0,
        },
    };

    @styleRule
    icon = {
        marginBottom: () => (Device.isMobileDevice() ? "" : 24),
        transform: () => (Device.isMobileDevice() ? "scale(.87)" : ""),
        width: () => (Device.isMobileDevice() ? 80 : ""),
        display: () => (Device.isMobileDevice() ? "flex" : ""),
        alignItems: "center",
        ">*": {
            margin: () => (Device.isMobileDevice() ? "auto" : ""),
        },
    };

    @styleRule
    textContainer = {
        flex: () => (Device.isMobileDevice() ? "1" : ""),
        marginLeft: () => (Device.isMobileDevice() ? 10 : ""),
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 18 : 24),
        marginBottom: () => (Device.isMobileDevice() ? 7 : 24),
    };

    @styleRule
    description = {
        fontSize: () => (Device.isMobileDevice() ? 14 : 21),
        fontWeight: "lighter",
        lineHeight: () => (Device.isMobileDevice() ? 24 : 33),
    };
}

@registerStyle(WalletProductCardStyle)
export class WalletProductCard extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            IconClass: null,
            title: null,
            description: null,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);

        attr.addClass(this.styleSheet.card);
    }

    render() {
        const {styleSheet} = this;
        const {IconClass, title, description, size} = this.options;

        return [
            IconClass ? <div className={styleSheet.icon}><IconClass size={size}/></div> : <div/>,
            <div className={styleSheet.textContainer}>
                <div className={styleSheet.title}>{title}</div>
                <div className={styleSheet.description}>{description}</div>
            </div>,
        ];
    }
}
