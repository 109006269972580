import {UI} from "../../stem-core/src/ui/UIBase.js";
import {GetExternalAuthClient} from "./ExternalAuthConfigs.js";
import {AuthHelper} from "../../blinkpay/AuthHelper.js";
import {getQueryParam} from "../../blinkpay/UtilsLib.js";
import {socialAuthService} from "../../blinkpay/services/SocialAuthService.js";


// This is the redirect page with the oauth token from the client url.
// The external provider sends us here.
export class LoginRedirectPage extends UI.Element {
    setURL(urlParts) {
        const state = getQueryParam("state");
        if (state && state.merchantId) {
            socialAuthService.setMerchantId(state.merchantId);
        }

        try {
            const oauthClient = GetExternalAuthClient(urlParts[0]);
            const urlParams = new URL(window.location.href).searchParams;
            AuthHelper.oauthHandleLoginRedirect(oauthClient, urlParams);
        } catch (e) {
            console.error("Login error", e);
            AuthHelper.oauthHandleError();
        }
    }
}
