// TODO @cleanup this file seems to be a piece of shit
import {isElementInView, registerStyle, styleRule, StyleSheet, UI} from "../../stem-core/src/ui/UI";

import {isSmallScreen, isString} from "../Utils";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {Device} from "../../stem-core/src/base/Device";
import {KeyEvent} from "../KeyEvent";
import {ANDROID_KEYBOARD_TOGGLE_TIMEOUT} from "../widget/WidgetConstants";
import {Input} from "../../stem-core/src/ui/input/Input";


export class BlinkInputStyle extends StyleSheet {
    @styleRule
    container = {
        fontSize: () => isSmallScreen() ? 16 : 14,
        marginTop: 18,
        marginBottom: 8,
        color: this.themeProps.INPUT_TEXT_COLOR,
    };

    @styleRule
    error = {
        borderColor: this.themeProps.ERROR_COLOR,
        ":hover": {
            borderColor: this.themeProps.ERROR_COLOR + " !important",
        },
        " input": {
            color: this.themeProps.ERROR_COLOR + " !important",
        },
        " input::placeholder": {
            color: this.themeProps.ERROR_COLOR + " !important",
        }
    };

    @styleRule
    success = {
        borderColor: this.themeProps.SUCCESS_COLOR,
        ":hover": {
            borderColor: this.themeProps.SUCCESS_COLOR + " !important",
        },
    };

    @styleRule
    label = {
        textAlign: "left",
        fontSize: this.themeProps.FONT_SIZE_SMALL,
        fontWeight: this.themeProps.FONT_WEIGHT_BOLD,
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        marginBottom: 8,
    };

    message = {
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translateY(-100%)",
        paddingBottom: 10,
        fontSize: this.themeProps.FONT_SIZE_SMALL,
        color: this.themeProps.SUCCESS_COLOR,
    };

    @styleRule
    errorMessage = [
        this.message,
        {
            color: () => this.themeProps.ERROR_COLOR,
        },
    ];

    @styleRule
    successMessage = [
        this.message,
        {
            color: () => this.themeProps.SUCCESS_COLOR,
        },
    ];

    @styleRule
    inputContainer = {
        width: "100%",
        position: "relative",
        fontSize: "inherit",
        height: this.themeProps.INPUT_DEFAULT_HEIGHT,
        background: this.themeProps.INPUT_BACKGROUND,
        borderRadius: this.themeProps.BASE_BORDER_RADIUS,
        border: () => "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
        ":focus-within": {
            borderColor: this.themeProps.INPUT_BORDER_ACTIVE_COLOR,
        },
        transition: "box-shadow 0.2s ease",
        ...this.themeProps.MERCHANT_INPUT_STYLE,
    };

    @styleRule
    input = {
        caretColor: this.themeProps.INPUT_TEXT_COLOR,
        overflow: "hidden",
        padding: ".25rem 5px",
        paddingLeft: 18,
        background: "transparent",
        border: "none !important",
        margin: 0,
        fontSize: "inherit",
        outline: "none",
        color: () => this.themeProps.INPUT_TEXT_COLOR + "!important",
        borderRadius: this.themeProps.BASE_BORDER_RADIUS,
        height: "100%",
        width: "100%",
        boxShadow: "none",
        backgroundClip: "padding-box !important",
        ":-webkit-autofill": {
            boxShadow: () => "0 0 0px 1000px " + this.themeProps.INPUT_BACKGROUND + " inset !important",
            color: () => this.themeProps.INPUT_TEXT_COLOR + "!important",
            "-webkit-text-fill-color": () => this.themeProps.INPUT_TEXT_COLOR + "!important",
        },
        "::placeholder": {
            color: this.themeProps.INPUT_PLACEHOLDER_COLOR,
        },
    };
}


@registerStyle(BlinkInputStyle)
export class BlinkInput extends UI.Element {
    error = null;
    initialValue = "";

    getDefaultOptions() {
        return {
            onEnter: NOOP_FUNCTION, // TODO @cleanup the name for an event where an input requests components above use it's value should be onSave everywhere
            onBlur: NOOP_FUNCTION,
            onFocus: NOOP_FUNCTION,
            onChange: NOOP_FUNCTION,
            onInvalidInput: NOOP_FUNCTION,
            onValidInput: NOOP_FUNCTION,
            InputClass: Input,
            inputPattern: /\\*/,
            // If you want to pass a value that might get changed by the user's input, pass it in this variable, instead
            // of passing it in the inputAttributes object.
            initialValue: null,
            inputAttributes: {},
            /*
                Validators array is used in determining if an input has a valid value or not.
                This array is composed of 'validator' objects having the following format:
                {
                  condition: <Boolean value / Function that returns boolean value>,
                  error: <String> | Default value: " "
                 }
             */
            validators: [],
        };
    }

    setOptions(options) {
        options.inputAttributes = {
            ...this.getDefaultOptions(options).inputAttributes,
            ...(options.inputAttributes || {})
        };

        return super.setOptions(options);
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {success, label, inputAttributes, InputClass, initialValue} = this.options;
        const error = this.options.error || this.error;
        const usePlaceholderAsLabel = this.themeProps.INPUT_USE_PLACEHOLDER_AS_LABEL;

        const inputExtraAttributes = {};

        if (usePlaceholderAsLabel && label) {
            inputExtraAttributes.placeholder = String(label);
        }

        // TODO @Andrei @input all the input classes should know how to handle initialValue
        // The behaviour is the following: when the class' initial value is changed from options, it means it must be
        // set as the input's value; otherwise, don't modify the current input's value.
        if (initialValue !== this.initialValue) {
            this.initialValue = initialValue;
            inputExtraAttributes.value = initialValue;
        }

        let inputContainerStyleClasses = styleSheet.inputContainer;
        let inputLabelStyleClasses = styleSheet.label;
        let inputComponentStyleClasses = styleSheet.input;


        if (success) {
            inputContainerStyleClasses += styleSheet.success;
        }
        if (error) {
            inputContainerStyleClasses += styleSheet.error;
        }

        const successMessage = success !== true && success;
        const errorMessage = error !== true && error;

        return [
            label && !usePlaceholderAsLabel && <div className={inputLabelStyleClasses}>{label}</div>,
            <div className={inputContainerStyleClasses}>
                {this.render()}
                <InputClass
                    ref="input"
                    className={inputComponentStyleClasses}
                    {...inputAttributes}
                    {...inputExtraAttributes}
                />
                <div className={styleSheet.successMessage}>
                    {successMessage}
                </div>
                <div className={styleSheet.errorMessage}>
                    {errorMessage}
                </div>
            </div>,
        ];
    }

    getValue() {
        let value = this.input?.getValue() || "";
        if (isString(value)) {
            value = value.trim();
        }
        return value;
    }

    setValue(value) {
        this.input.setValue(value);
    }

    focus() {
        this.input.node.focus();
    }

    blur() {
        this.input.node.blur();
    }

    isValid() {
        return this.getValidationErrors().length === 0;
    }

    validate() {
        if (this.isValid()) {
            return;
        }

        this.error = this.getValidationErrors()[0] || " ";
        this.redraw();
    }

    getValidationErrors() {
        return this.options.validators.filter(validator => {
            const {condition} = validator;
            const conditionFulfilled = typeof condition === "function" ? condition() : condition;
            return !conditionFulfilled;
        }).map(failedValidator => failedValidator.error);
    }

    isEmpty() {
        return this.input.getValue().trim().length === 0;
    }

    clearError() {
        this.error = null;
        this.updateOptions({error: null});
    }

    saveSelection() {
        this.selectionStart = this.input.node.selectionStart;
        this.selectionEnd = this.input.node.selectionEnd;
    }

    initFocusListeners() {
        this.input.addNodeListener("blur", () => this.options.onBlur());
        this.input.addNodeListener("focus", (event) => {
            this.saveSelection();
            this.clearError();
            this.options.onFocus(event);
            if (Device.isMobileDevice()) {
                const scrollInputIntoView = () => {
                    if (this.input.node === document.activeElement && !isElementInView(this)) {
                        this.node.scrollIntoView({behavior: "smooth"});
                    }
                };

                for (let timeout = 0; timeout <= ANDROID_KEYBOARD_TOGGLE_TIMEOUT; timeout += 300) {
                    this.attachTimeout(scrollInputIntoView, timeout);
                }
            }
        });
    }

    initValidationListeners() {
        let previousValue = this.getValue();

        const isInputValid = (value) => {
            const {inputAttributes, inputPattern} = this.options;
            const {min, max} = inputAttributes;
            if (!value.match(inputPattern)) {
                return false;
            }
            if (min != null && parseFloat(value) < min) {
                return false;
            }
            return !(max != null && parseFloat(value) > max);
        };

        this.input.addNodeListener("input", () => {
            const newValue = this.getValue().toString();
            if (!isInputValid(newValue)) {
                const shouldAllow = this.options.onInvalidInput(newValue);
                if (!shouldAllow) {
                    this.setValue(previousValue);
                    try {
                        this.input.node.setSelectionRange(this.selectionStart, this.selectionEnd);
                    } catch (e) {
                        // Ignore the error?
                    }
                }

                return;
            }
            this.options.onValidInput(newValue);

            previousValue = this.getValue();

            this.saveSelection();
            this.clearError();
        });
    }

    dispatchChange() {
        const value = this.getValue();
        this.options.onChange(value);
    }

    initKeyListeners() {
        this.input.addNodeListener("keydown", () => {
            this.saveSelection();
        });

        this.input.addNodeListener("keyup", event => {
            this.saveSelection();
            const key = new KeyEvent(event);

            if (key.isEnter()) {
                this.options.onEnter();
            } else {
                this.dispatchChange();
            }
        });

        this.input.addNodeListener("paste", () => {
            this.dispatchChange();
            this.saveSelection();
        });
    }

    initListeners() {
        this.saveSelection();

        this.initFocusListeners();
        this.initValidationListeners();
        this.initKeyListeners();
    }

    onMount() {
        super.onMount();
        this.initListeners();
    }
}
