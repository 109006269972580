import {UI, styleRule, registerStyle, styleRuleInherit} from "../../../../../../stem-core/src/ui/UI.js";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../../ui/ConfirmationModal.jsx";
import {AddressForm} from "../../../../../common/AddressForm.jsx";
import {Messages} from "../../../../../Messages.js";
import {wrapInSpinner} from "../../../../../../core/ui/LoadingSpinner.jsx";
import {apiCreateAddress} from "../../../../../../client/state/UserAddressStore.js";
import {Toast} from "../../../../../ui/Toast.jsx";


class AddressModalStyle extends ConfirmationModalStyle {
    @styleRule
    form = {
        marginBottom: 60,
    };

    @styleRuleInherit
    modal = {
        display: "block !important",
    };
}

@registerStyle(AddressModalStyle)
export class AddAddressModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "addAddressModal",
            confirmLabel: Messages.save,
            title: Messages.addNewAddress,
            confirmAction: () => this.saveAddress()
        };
    }

    render() {
        return <AddressForm ref="form" className={this.styleSheet.form}/>;
    }

    @wrapInSpinner
    async saveAddress() {
        const validInputs = this.form && this.form.validateInputs();
        if (!validInputs) {
            return;
        }

        try {
            this.hide();
            await apiCreateAddress(this.form.getValues());
        } catch (error) {
            this.onErrorResponseReceived(error);
        }
    }

    onErrorResponseReceived(errorResponse) {
        this.form.displayFieldErrors(errorResponse);
        const nonFieldError = errorResponse.find(fieldError => fieldError.field === "non_field_errors");

        if (nonFieldError) {
            Toast.showError(nonFieldError);
        }
    }
}
