import {BaseEnum, makeEnum} from "../../../stem-core/src/state/BaseEnum.js";
import {field} from "../../../stem-core/src/state/StoreField.js";
import {Money} from "../../../stem-core/src/localization/Money.js";
import {setObjectPrototype, unwrapArray} from "../../../stem-core/src/base/Utils.js";

function addressPartMatches(entry, filter) {
    if (filter == null) {
        return true;
    }
    if (Array.isArray(filter)) {
        for (const value of filter) {
            if (String(entry).toLowerCase() === String(value).toLowerCase()) { // String or numbers are the same
                return true;
            }
        }
        return false;
    }

    return String(entry).toLowerCase() === String(filter).toLowerCase();
}

@makeEnum
export class ShippingPriceType extends BaseEnum {
    static SINGLE_COUNTRY;
    static MULTI_COUNTRY;
    static WORLDWIDE;
}


// TODO @branch where to move this?
// Mode can be single country, multi country, worldwide ("*")
// Worldwide has countryCode = "*", matches anything
// Multicountry matches an explicit list of countries (and just that)
// Single country can have further filters (region, postalCode).
// TODO @Mihai support EU as country code
export class ShippingPriceEntry {
    @field("Currency") currency; // From the subscription plan
    amount;
    countryCode; // Array or String
    region; // Optional String
    postalCode; // Optional String or Array

    constructor() {
        this.countryCode = "US";
        this.amount = 0;
    }

    getType() {
        if (this.isWorldwide()) {
            return ShippingPriceType.WORLDWIDE;
        }
        if (this.isMultiCountry()) {
            return ShippingPriceType.MULTI_COUNTRY;
        }
        return ShippingPriceType.SINGLE_COUNTRY;
    }

    isWorldwide() {
        return this.countryCode === "*";
    }

    isMultiCountry() {
        return Array.isArray(this.countryCode);
    }

    isSingleCountry() {
        return !this.isWorldwide() && !this.isMultiCountry();
    }

    matchesAddress(address) {
        if (this.isWorldwide()) {
            return true;
        }

        if (!addressPartMatches(address.countryCode, this.countryCode)) {
            return false;
        }

        if (this.isSingleCountry()) {
            if (!addressPartMatches(address.state, this.region) || !addressPartMatches(address.postalCode, this.postalCode)) {
                return false;
            }
        }

        return true;
    }

    getPrice(defaultCurrency) {
        const currency = this.currency || defaultCurrency;
        return new Money(this.amount, currency);
    }

    formatLocation() {
        if (this.isWorldwide()) {
            return "Worldwide";
        }
        // TODO @branch need longer country names
        if (this.isMultiCountry()) {
            // TODO @branch truncate long lists (and X more countries).
            return this.countryCode.join(", ");
        }
        const regionFormat = this.region && "Region " + (Array.isArray(this.region) ? this.region.join(", ") : this.region);
        const postalCodeFormat = this.postalCode && "Post code " + (Array.isArray(this.postalCode) ? this.postalCode.join(", "): this.postalCode);
        return unwrapArray([this.countryCode, regionFormat, postalCodeFormat]).join("; ");
    }

    // TODO @Mihai @branch make this iterable
    static loadArray(entries, currencyId) {
        if (!Array.isArray(entries)) {
            return [];
        }
        for (let entry of entries) {
            // TODO: @branch kinda voodoo
            setObjectPrototype(entry, this);
            if (currencyId && !entry.currencyId) {
                entry.currencyId = currencyId;
            }
        }
        return entries;
    }

    static clone(entry, inplace=false) {
        const obj = {
            ...entry,
        };
        // TODO @branch support inplace
        return setObjectPrototype(obj, this);
    }
}

// TODO @branch implement as method of shipping prices field
export function CalculateShippingPrice(address, shippingPrices) {
    for (const shippingPrice of shippingPrices) {
        if (shippingPrice.matchesAddress(address)) {
            return shippingPrice.getPrice();
        }
    }
    return new Money(0, shippingPrices[0]?.currency || 1);
}
