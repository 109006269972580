import {UI, keyframesRule, registerStyle, styleRule, StyleSheet} from "../../stem-core/src/ui/UI";
import {Balance} from "../ui/Balance";
import {Dispatcher} from "../../stem-core/src/base/Dispatcher";
import {Money} from "../../stem-core/src/localization/Money.js";


const beginStyle = {
    first: {
        transform: "translateY(0)",
        opacity: 1,
    },
    second: {
        transform: "translateY(100%)",
        opacity: 0,
    },
    third: {
        transform: "translateY(200%))",
        opacity: 0,
    },
};

const holdStyle = {
    first: {
        transform: "translateY(-100%)",
        opacity: 0,
    },
    second: {
        transform: "translateY(0)",
        opacity: 1,
    },
    third: {
        transform: "translateY(100%)",
        opacity: 0,
    },
};

const endStyle = {
    first: {
        transform: "translateY(-200%)",
        opacity: 0,
    },
    second: {
        transform: "translateY(-100%)",
        opacity: 0,
    },
    third: {
        transform: "translateY(0)",
        opacity: 1,
    },
};

const buildAnimation = name => ({
    "0%": beginStyle[name],
    "40%": holdStyle[name],
    "60%": holdStyle[name],
    "100%": endStyle[name],
});

class AnimationKeyframes extends StyleSheet {
    @keyframesRule
    oldBalance = buildAnimation("first");

    @keyframesRule
    amount = buildAnimation("second");

    @keyframesRule
    newBalance = buildAnimation("third");
}

class RollAnimatedBalanceStyle extends StyleSheet {
    transitionDuration = 2000;

    @styleRule
    balance = {
        userSelect: "none",
        position: "relative",
        width: "100%",
        transform: "translateY(-.5em)",
        ">*": {
            position: "absolute",
            width: "100%",
            textAlign: "center",
        },
    };

    getTransitionStyle = name => ({
        animation: AnimationKeyframes.getInstance()[name].toString(),
        animationDuration: this.transitionDuration / 1000 + "s",
        animationFillMode: "forwards",
        animationTimingFunction: "ease",
    });

    @styleRule
    animatedBalance = {
        ">:first-child": this.getTransitionStyle("oldBalance"),
        ">:nth-child(2)": this.getTransitionStyle("amount"),
        ">:nth-child(3)": this.getTransitionStyle("newBalance"),
    };
}

@registerStyle(RollAnimatedBalanceStyle)
export class RollAnimatedBalance extends Balance {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.balance);
        if (this.inAnimation) {
            attr.addClass(this.styleSheet.animatedBalance);
        }
    }

    render() {
        if (this.balance == null) {
            return "";
        }
        if (this.inAnimation) {
            return [
                <div>{this.balance.toMainUnitString()}</div>,
                <div>
                    {this.updateSign}
                    {this.updateValue}
                </div>,
                <div>{this.getBalance().toMainUnitString()}</div>,
            ];
        }
        return [<div>{this.getBalance().toMainUnitString()}</div>];
    }

    redraw() {
        if (this.balance == null || document.hidden) {
            this.balance = this.getBalance();
            return super.redraw();
        }
        if (!this.inAnimation && !this.balance.equals(this.getBalance())) {
            this.animate();
            return null;
        }
        return super.redraw();
    }

    animate() {
        const startBalance = this.balance;
        const endBalance = this.getBalance();
        const balancesDiff = endBalance.subtract(startBalance).getAmount();
        this.updateValue = new Money(Math.abs(balancesDiff), startBalance.getCurrency());
        this.inAnimation = true;
        this.updateSign = endBalance.greaterThan(startBalance) ? "+" : "";
        this.redraw();

        this.attachTimeout(() => {
            this.updateValue = null;
            this.updateSign = null;
            this.balance = endBalance;
            Dispatcher.Global.dispatch("balanceAnimationFinished");
            this.inAnimation = false;
            this.redraw();
        }, this.styleSheet.transitionDuration);
    }
}
