import {MerchantStore} from "../../client/state/MerchantStore";
import {TNR_ALIAS} from "blink-sdk/Constants";
import {BlinkGlobal} from "../UtilsLib";


// TODO @flow this file should be dismantled
class IframeMerchantService {
    init(merchantId) {
        this.merchantId = merchantId;
        BlinkGlobal.iFrameMerchantService = this;
    }

    getMerchant() {
        return MerchantStore.get(this.merchantId);
    }

    // For hardcoded stuff.
    isMerchant(alias) {
        return this.getMerchant()?.alias === alias;
    }

    // TODO @cleanup this method should be called in a lot fewer places
    isCDSPaymentMethod() {
        return this.isMerchant(TNR_ALIAS);
    }
}

export const iFrameMerchantService = new IframeMerchantService();
