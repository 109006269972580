// TODO @cleanup this is shit
// This is a map from error codes from backend responses to error iframe links the content website should display when
// receiving those errors.
import {
    ACCESS_AUTHORIZATION_REJECTED_ERROR_CODE,
    ACCESS_AUTHORIZATION_REJECTED_ERROR_MESSAGE,
} from "../../../blink-sdk/Constants";


export const Errors = {
    errorCodes: {
        VALIDATION_ERROR: 1001,
        NOT_FOUND: 1006,
        USER_NOT_AUTHENTICATED: 1004,
    },
    oauthErrorCodes: {
        INVALID_REDIRECT_URI: 1903,
        INVALID_CLIENT_ID: 1904,
        UNEXPECTED_ERROR: 1910,
        ACCESS_AUTHORIZATION_REJECTED: ACCESS_AUTHORIZATION_REJECTED_ERROR_CODE,
    },
    // TODO @Mihai why do we have these error messages here?
    oauthErrorMessages: {
        INVALID_REDIRECT_URI: "The provided value for the 'redirectUrl' parameter is not valid. The expected value for this parameter is a URI that matches a redirect URI registered for this client application.",
        INVALID_CLIENT_ID: "We're unable to complete your request. The client application with this id does not exist.",
        UNEXPECTED_ERROR: "Sorry, we encountered an unexpected error while signing you in.",
        ACCESS_AUTHORIZATION_REJECTED: ACCESS_AUTHORIZATION_REJECTED_ERROR_MESSAGE,
    },
    confirmEmailCodeErrors: {
        INVALID_CODE: 2300,
        INCORRECT_CODE: 2301,
        EMAIL_ADDRESS_ALREADY_IN_USE: 1502,
    }
};
