export const FUNCTIONALITY_NOT_ENABLED_URL = "functionalityNotEnabled";
export const CTA_URL = "cta";

export const SUBSCRIPTION_PLAN_PANEL_URL = "subscriptionPlan";
export const ADDRESS_PANEL_URL = "address";
export const PAYMENT_DETAILS_PANEL_URL = "paymentDetails";
export const PAYMENT_FAILED_PANEL_URL = "paymentFailed";

export const INLINE_DONATION_URLS = {
    donate: "donationDonate",
    paymentAndDonate: "paymentAndDonate",
    thankYou: "donationThankYou",
};

export const INLINE_EDIT_DONATION_URLS = {
    donate: "editDonationDonate",
    thankYou: "editDonationThankYou",
    cancel: "editDonationCancel",
    cancelSuccess: "donationCanceledConfirmation",
};

export const INLINE_SUBSCRIPTION_URLS = {
    subscriptionManageInline: "subscriptionManageInline",
    subscriptionCheckout: "subscribeCheckout",
    subscribeThankYou: "subscribeThankYou",
};

export const INLINE_EDIT_SUBSCRIPTION_URLS = {
    subscribe: "editSubscriptionSubscribe",
    cancel: "editSubscriptionCancel",
    cancelSuccess: "editSubscriptionCancelSuccess",
    success: "editSubscriptionSuccess",
};

export const INLINE_GIFT_SUBSCRIPTION_URLS = {
    giftDetails: "giftSubscriptionGiftDetails",
    success: "giftSubscriptionSuccess",
};

export const INLINE_SHOP_URLS = {
    products: "shopProducts",
    reviewOrder: "shopReviewOrder",
    orderSuccess: "shopSuccess",
};

export const INLINE_NEWSLETTER_URLS = {
    subscribe: "newsletterSubscribe",
    success: "newsletterSuccess",
};

export const INLINE_USER_DASHBOARD_URLS = {
    home: "dashboardHome",
    removeCard: "dashboardRemoveCard",
    removeAddress: "dashboardRemoveAddress",
    editPersonalDetails: "dashboardEditPersonalDetails",
    confirmEmailChange: "dashboardConfirmEmailChange",
    subscriptionChangeConfirm: "dashboardSubscriptionChangeConfirm",
    subscriptionChangeSuccess: "dashboardSubscriptionChangeSuccess",
    changeEmailConfirmationCode: "dashboardChangeEmailConfirmationCode",
};
