import {SubscriptionFlowType} from "./steps/SubscriptionPlanFlowStep.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {
    INLINE_GIFT_SUBSCRIPTION_URLS,
    PAYMENT_FAILED_PANEL_URL,
} from "../PanelConstants.js";
import {apiSubscribe} from "../../../client/state/SubscriptionStore.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Messages} from "../../Messages.js";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore.js";
import {Flow} from "./Flow.js";
import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {BaseFlowHandler} from "./BaseFlowHandler.js";
import {iFrameState} from "../../services/IFrameState.js";


export class GiftSubscriptionFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.giftSubscription;

    // TODO @flow @cleanup a bit of an overlap with subscription flow
    getFlowPlan() {
        return [
            Flow.subscriptionPlan.init({
                subscriptionFlowType: SubscriptionFlowType.GIFT_SUBSCRIPTION,
                discountCode: iFrameState.iframeParams.selectedDiscountCode,
            }),

            Flow.auth,

            Flow.giftSubscriptionDetails,

            Flow.address.init({
                shouldPrompt: () => Flow.subscriptionPlan.selectedValue.shouldCollectAddress(),
                continueLabel: Messages.reviewOrder,
            }),

            Flow.subscriptionReview,

            Flow.paymentMethod.init({
                savePaymentMethod: true,
                scope: PaymentMethodStore.Scope.PRIVATE,
            }),
        ];
    }

    // TODO @flow2 refactor this function
    @wrapInSpinner
    async finalize() {
        try {
            const subscriptionDetails = await Flow.subscribe.getPayload();

            Object.assign(subscriptionDetails.metadata, {
                gift: true,
                giftFrom: Flow.giftSubscriptionDetails.name,
                giftMessage: Flow.giftSubscriptionDetails.message,
            });

            await apiSubscribe({
                ...subscriptionDetails,
                gift: true,
                giftBeneficiaryEmailAddress: Flow.giftSubscriptionDetails.beneficiaryEmailAddress,
            });
        } catch (error) {
            Flow.paymentMethod.update({
                paymentError: error,
            });
            Router.changeURL(PAYMENT_FAILED_PANEL_URL);
            return false;
        }

        Router.changeURL(INLINE_GIFT_SUBSCRIPTION_URLS.success);
        return true;
    }
}
