import {Route, Router, TerminalRoute} from "../../stem-core/src/ui/Router";
import {BaseBlinkApp} from "../BaseBlinkApp.jsx";
import {AppGuards} from "./AppGuards";

import {LoginRedirectPage} from "../../core/external-auth/LoginRedirectPage.jsx";
import {HomePage} from "./pages/HomePage.jsx";
import {CookiePolicyPage, PrivacyPolicyPage, TermsPolicyPage} from "./pages/PolicyPage.jsx";
import {LoginByEmailCodeUrlPage} from "./pages/pseudo-pages/LoginByEmailCodeUrlPage.jsx";
import {WebsiteIndex} from "./WebsiteIndex";
import {Theme} from "../../stem-core/src/ui/style/Theme";
import {PublisherIndex} from "../../landingpage/PublisherIndex";
import {PUBLISHER_URL, READER_URL, COMPANY_URL} from "../../landingpage/Constants";
import {ReaderIndex} from "../../landingpage/ReaderIndex";
import {CompanyIndex} from "../../landingpage/CompanyIndex";
import {SetCookiePage} from "./pages/pseudo-pages/SetCookiePage.jsx";
import {AuthFlowPanel} from "../common/auth/AuthFlowPanel.jsx";
import {Device} from "../../stem-core/src/base/Device";
import {UndoChangeEmailPage} from "./pages/pseudo-pages/UndoChangeEmailPage.jsx";
import {UndoCloseAccountPage} from "./pages/pseudo-pages/UndoCloseAccountPage.jsx";
import {authService, AuthService} from "../../client/connection/services/AuthService";
import {EmailPreferencesPage} from "./pages/EmailPreferencesPage.jsx";
import {apiClient} from "../../client/connection/BlinkApiClient.js";
import {GlobalState} from "../../stem-core/src/state/State";
import {AuthHelper} from "../AuthHelper";
import {iFrameConnection} from "../services/IFrameConnection";
import {IFrameMessages} from "../../blink-sdk/messaging/IFrameMessages";
import {FormContainerPage} from "../common/FormContainerPage.jsx";
import {DEFAULT_DATE_FORMAT, LOGIN_URL} from "../Constants.js";
import {SetDefaultDateFormat} from "../../stem-core/src/time/Date.js";
import "../panel/flows/FlowLogic.js";


// TODO remove this, it should be Messages
export const appTitles = {
    LoginForm: "Login",
    LoginRedirect: "Login Redirect",
    BlinkServiceLogin: "Login",
    BlinkServiceSignUp: "Sign Up",
    RequestPasswordResetForm: "Request password reset",
    PasswordResetRequested: "Password reset requested",
    ResetPasswordForm: "Reset password",
    PrivacyPolicyPage: "Privacy policy",
    CookiePolicyPage: "Cookie policy",
    TermsOfServicePage: "Terms of service",
    LoadingSpinner: "Blink Pay",
    UnconfirmedEmailPage: "Email unconfirmed",
    HomePage: "Blink",
    EmailConfirmationByCode: "Confirm email",
    LoginByEmailCodeUrl: "Login",
    SetCookie: "Blink",
    GetCookie: "Blink",
    EmailPreferences: "Email preferences",
};

export class WebsiteApp extends BaseBlinkApp {
    static init() {
        super.init();
    }

    static initializeTheme() {
        super.initializeTheme();

        Theme.setProperties({
            FONT_SIZE_DEFAULT: 16,
            NAV_MANAGER_NAVBAR_HEIGHT: 0,
            COLOR_BACKGROUND_BODY: Theme.props.APP_BACKGROUND,

            BASE_BUTTON_STYLE: {
                fontWeight: props => props.FONT_WEIGHT_BOLD,
            },

            BUTTON_PRIMARY_COLOR: props => props.WHITE,
            BUTTON_PRIMARY_BACKGROUND: props => props.WALLET_11,
            BUTTON_PRIMARY_BORDER_COLOR: "transparent",
            BUTTON_PRIMARY_TEXT_SHADOW: "0 1px 2px rgba(6,73,83,.12)",
            BUTTON_PRIMARY_SHADOW: "0 2px 6px 0 rgba(6, 73, 83, 0.24)",
            BUTTON_PRIMARY_HOVER_BACKGROUND: props => props.WALLET_13,
            BUTTON_PRIMARY_HOVER_BORDER_COLOR: null,
            BUTTON_PRIMARY_HOVER_SHADOW: "0 2px 10px 0 rgba(6,73,83,.48)",

            BUTTON_SECONDARY_COLOR: props => props.WALLET_7,
            BUTTON_SECONDARY_BACKGROUND: props => props.WHITE,
            BUTTON_SECONDARY_BORDER_WIDTH: 1,
            BUTTON_SECONDARY_SHADOW: "0 2px 8px rgba(6,73,83,.12)",
            BUTTON_SECONDARY_HOVER_SHADOW: props => "0 2px 8px rgba(6,73,83,.24), inset 0 0 0 1px " + props.WALLET_11,
        });

        SetDefaultDateFormat(DEFAULT_DATE_FORMAT);
    }

    static addWsListener(streamName) {
        apiClient.addWebsocketListener(streamName, event => {
            if (event.type === "logout") {
                authService.clearCurrentInfo();
            } else {
                GlobalState.applyEvent(event);
            }
        });
    }

    getRoutes() {
        return new Route(
            [],
            WebsiteIndex,
            [
                new Route([LOGIN_URL], FormContainerPage(AuthFlowPanel), [], {
                    title: appTitles.LoginForm,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),
                // Redirect of external auth
                new TerminalRoute(["login-redirect"], LoginRedirectPage, [], appTitles.LoginRedirect),
                new Route(["email", "login", "%s"], LoginByEmailCodeUrlPage, [], {
                    title: appTitles.LoginByEmailCodeUrl,
                }),
                new Route(["privacy-policy"], PrivacyPolicyPage, [], appTitles.PrivacyPolicyPage),
                new Route(["cookie-policy"], CookiePolicyPage, [], appTitles.CookiePolicyPage),
                new Route(["terms"], TermsPolicyPage, [], appTitles.TermsOfServicePage),
                // TODO @auth don't set the cookie, use a one-time code that gets converted to a token
                new Route(["set-cookie"], SetCookiePage, [], {title: appTitles.SetCookie}),
                new Route(["email-preferences"], EmailPreferencesPage, [], {title: appTitles.EmailPreferences}),
                new Route(["undo-change-email"], UndoChangeEmailPage, []),
                new Route(["cancel-close-account"], UndoCloseAccountPage, []),
                new Route([PUBLISHER_URL], PublisherIndex, [], {
                    title: appTitles.HomePage,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),
                new Route([READER_URL], ReaderIndex, [], {
                    title: appTitles.HomePage,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),
                new Route([COMPANY_URL], CompanyIndex, [], {
                    title: appTitles.HomePage,
                    beforeEnter: AppGuards.checkNotAuthenticated,
                }),

                new TerminalRoute([], HomePage, {title: appTitles.HomePage, beforeEnter: AppGuards.checkAuthenticated}),
            ],
            appTitles.HomePage
        );
    }

    onMount() {
        super.onMount();
        if (Device.isMobileDevice()) {
            window.scrollTo({top: 0, behavior: "smooth"});
        }

        authService.addListener(AuthService.EventTypes.CLEAR_CURRENT_INFO, () => {
            Router.changeURL("/");
        });

        iFrameConnection.addListener(IFrameMessages.USER_TOKEN_UPDATE, ({token}) => {
            const {t, e} = JSON.parse(token);
            authService.token.setToken(t, e);
            AuthHelper.confirmedAuthenticationAction();
        });
    }
}
