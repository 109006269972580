import {registerStyle, styleRule, UI} from "../../stem-core/src/ui/UI";
import {NavbarConstants} from "../Constants";
import {styleRuleInherit} from "../../stem-core/src/decorators/Style";
import {Section, SectionStyle} from "../primitives/Section";
import {isDesktopSmall} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";
import {HeaderBackgroundSVG} from "../SVGElements";
import {LaptopCoffeeIllustration} from "../SVGElements";

class HeaderStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        paddingBottom: 0,
        paddingTop: 0,
        marginBottom: () => (Device.isMobileDevice() || isDesktopSmall() ? "" : -72),
        color: this.themeProps.WHITE,
        position: "relative",
        zIndex: 10,
    };

    @styleRule
    background = {
        width: "100%",
        background: () =>
            isDesktopSmall()
                ? this.themeProps.LANDING_PAGE_MOBILE_HEADER_BACKGROUND
                : this.themeProps.LANDING_PAGE_HEADER_BACKGROUND,
        backgroundRepeat: "no-repeat",
        padding: () =>
            Device.isMobileDevice()
                ? "52px 0 40px"
                : `${NavbarConstants.HEIGHT + NavbarConstants.PADDING / 2}px 0 50px`,
        position: "relative",
    };

    @styleRule
    textSection = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 2,
    };

    @styleRule
    flex = {
        display: "flex",
        flexDirection: () => (isDesktopSmall() ? "column-reverse" : "row"),
        ">*": {
            flex: 1,
        },
    };

    @styleRule
    text = {
        fontSize: () => (isDesktopSmall() ? 17 : 21),
        lineHeight: () => (isDesktopSmall() ? 23 : 28),
        marginBottom: () => (Device.isMobileDevice() ? 27 : 48),
        opacity: ".88",
        fontWeight: "lighter",
    };

    @styleRule
    title = {
        fontSize: () => (isDesktopSmall() ? 24 : 36),
        marginBottom: () => (isDesktopSmall() ? 27 : 30),
    };

    @styleRule
    imageSection = [
        isDesktopSmall()
            ? {
                  display: "none",
              }
            : {
                  ">*": {
                      width: 460,
                      transform: "translate(80px, 60px)",
                  },
              },
    ];

    @styleRuleInherit
    content = {
        marginTop: isDesktopSmall() ? 16 : 24,
        zIndex: 2,
    };

    @styleRule
    curve = {
        position: "absolute",
        bottom: -6,
        width: "100%",
        pointerEvents: "none",
    };
}

@registerStyle(HeaderStyle)
export class Header extends Section {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: null,
            description: null,
            ctaSection: null,
            hasIllustration: true,
        };
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {title, description, ctaSection, hasIllustration} = this.options;

        return [
            <div className={styleSheet.background}>
                <HeaderBackgroundSVG className={styleSheet.curve} />
                <div className={styleSheet.content}>
                    <div className={styleSheet.flex}>
                        <div className={styleSheet.textSection} id="header-text-section">
                            <div>
                                <div className={styleSheet.title}>{title}</div>
                                <div className={styleSheet.text}>{description}</div>
                            </div>
                            {ctaSection}
                        </div>
                        {
                            hasIllustration ?
                            <div className={styleSheet.imageSection} ref={"illustrationContainer"}>
                                <LaptopCoffeeIllustration animate size={460}/>
                            </div> : null
                        }
                    </div>
                </div>
            </div>,
        ];
    }
}
