import {registerStyle, styleRule, styleRuleInherit, UI} from "../../stem-core/src/ui/UI";

import {Section, SectionStyle} from "../primitives/Section";
import {DevicesSVG, ReadingSVG, UserExperienceSVG} from "../SVGElements";
import {Device} from "../../stem-core/src/base/Device";
import {Messages} from "../../blinkpay/Messages";
import {isDesktopSmall} from "../Utils";
import {SCREENSHOTS_FOLDER} from "../Constants";
import {Image} from "../../stem-core/src/ui/UIPrimitives";
import {WalletProductCard} from "./primitives/WalletProductCard";

class BlinkWalletStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        background: this.themeProps.LANDING_PAGE_LIGHT_BACKGROUND,
        padding: () => (isDesktopSmall() ? "36px 0 0" : "20px 0 60px"),
        position: "relative",
        zIndex: 9,
    };

    @styleRuleInherit
    content = {
        padding: isDesktopSmall() ? "0 24px" : "0 24px",
    };

    @styleRule
    description = {
        fontSize: () => (Device.isMobileDevice() ? 16 : 21),
        lineHeight: () => (Device.isMobileDevice() ? 24 : 33),
        fontWeight: "lighter",
        maxWidth: 680,
        textAlign: "center",
        margin: "auto",
    };

    @styleRule
    image = {
        position: "relative",
        maxWidth: "100%",
        maxHeight: 660,
        left: "50%",
        transform: () => (isDesktopSmall() ? "translate(-50%, 24px)" : "translate(-50%, 48px)"),
    };

    @styleRule
    cardsContainer = {
        width: () => (isDesktopSmall() ? "calc(100% + 48px)" : "calc(100% + 120px)"),
        margin: () => (isDesktopSmall() ? "36px 0 0 -24px" : "90px 0 0 -60px"),
        borderRadius: () => (isDesktopSmall() ? 0 : 12),
        boxShadow: this.themeProps.LANDING_PAGE_CARD_SHADOW,
        background: this.themeProps.LANDING_PAGE_CARD_BACKGROUND,
        display: "flex",
        flexDirection: () => (isDesktopSmall() ? "column" : "row"),
        padding: () => (isDesktopSmall() ? "30px 24px" : "48px 60px 80px"),
        ">:not(:first-child)": {
            marginLeft: () => (isDesktopSmall() ? 0 : 60),
        },
        ">*": {
            flex: () => (isDesktopSmall() ? "" : 1),
        },
    };
}

const walletProductCards = [
    {
        title: Messages.walletProductTitle1,
        description: Messages.walletProductDescription1,
        IconClass: ReadingSVG,
        size: 70,
    },
    {
        title: Messages.walletProductTitle2,
        description: Messages.walletProductDescription2,
        IconClass: DevicesSVG,
        size:70,
    },
    {
        title: Messages.walletProductTitle3,
        description: Messages.walletProductDescription3,
        IconClass: UserExperienceSVG,
        size: 60
    },
];

@registerStyle(BlinkWalletStyle)
export class BlinkWallet extends Section {
    render() {
        const {styleSheet} = this;

        return [
            [Messages.blinkWalletDescription1, Messages.blinkWalletDescription2].map(descrption => (
                <div className={styleSheet.description}>{descrption}</div>
            )),
            <Image
                src={`${SCREENSHOTS_FOLDER}reader-wallet-1${Device.isMobileDevice() ? "-mobile" : ""}.png`}
                className={styleSheet.image}
            />,
            <div className={styleSheet.cardsContainer}>
                {walletProductCards.map(itemOptions => (
                    <WalletProductCard {...itemOptions} />
                ))}
            </div>,
        ];
    }
}
