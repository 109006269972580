import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";

import {Messages} from "../../blinkpay/Messages";
import {LandingPageLinkButton} from "../LandingPageTheme";
import {isDesktopSmall} from "../Utils";

class ChatDemoComponentStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        flexDirection: () => (isDesktopSmall() ? "column" : "row"),
    };

    button = {
        padding: () => (isDesktopSmall() ? "12px !important" : "16px 36px !important"),
        fontSize: () => (isDesktopSmall() ? "16px !important" : "18px !important"),
        fontWeight: "normal !important",
        boxShadow: () => this.themeProps.LANDING_PAGE_BUTTON_SHADOW + "!important",
        width: () => (isDesktopSmall() ? 151 : 200),
        whiteSpace: ":nowrap",
        textAlign: "center",
        borderRadius: () => (isDesktopSmall() ? "6px !important" : ""),
        margin: () => (isDesktopSmall() ? "10px 0" : ""),
        ":hover": {
            boxShadow: this.themeProps.LANDING_PAGE_CHAT_BUTTON_HOVER_SHADOW + " !important",
        },
    };

    chatButton = {
        ...this.button,
        marginRight: () => (isDesktopSmall() ? 24 : 26),
        background: () => this.themeProps.LANDING_PAGE_CHAT_BUTTON_BACKGROUND + "!important",
    };

    @styleRule
    chatButtonLight = {
        ...this.chatButton,
        boxShadow: () => this.themeProps.LANDING_PAGE_BUTTON_SHADOW + "!important",
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_DARK_BUTTON_SHADOW_ON_LIGHT_BACKGROUND + "!important",
        },
    };

    @styleRule
    chatButtonDark = {
        ...this.chatButton,
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_DARK_BUTTON_SHADOW_ON_DARK_BACKGROUND + "!important",
        },
    };

    requestDemo = {
        ...this.button,
        background: () => this.themeProps.LANDING_PAGE_DEMO_BUTTON_BACKGROUND + " !important",
        color: () => this.themeProps.LANDING_PAGE_DEMO_BUTTON_COLOR + " !important",
    };

    @styleRule
    requestDemoLight = {
        ...this.requestDemo,
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_LIGHT_BUTTON_SHADOW_ON_LIGHT_BACKGROUND + "!important",
        },
    };

    @styleRule
    requestDemoDark = {
        ...this.requestDemo,
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_LIGHT_BUTTON_SHADOW_ON_DARK_BACKGROUND + "!important",
        },
    };
}

@registerStyle(ChatDemoComponentStyle)
export class ChatDemoComponent extends UI.Element {
    static themes = {
        DARK: "Dark",
        LIGHT: "Light",
    };

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            sameLine: false,
            colorTheme: this.constructor.themes.DARK, // TODO @cleanup fuck this crap
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        if (this.options.sameLine || isDesktopSmall()) {
            attr.setStyle("flexDirection", "row");
        }
    }

    render() {
        const {styleSheet} = this;
        const {colorTheme} = this.options;

        return [
            <LandingPageLinkButton
                className={styleSheet["requestDemo" + colorTheme]}
                href={"mailto:contact@blink.net?subject=Request a demo"}
                target="_blank">
                {Messages.requestDemo}
            </LandingPageLinkButton>,
        ];
    }
}
