import {UI, registerStyle} from "../stem-core/src/ui/UI";
import {NavBar} from "./nav/NavBar";
import {Header} from "./sections-common/Header";
import {MobileNavbar} from "./nav/MobileNavbar";
import {NavManager} from "./nav/NavManager";
import {ValueProposition} from "./sections-publisher/ValueProposition";
import {Footer} from "./sections-common/Footer";
import {OurCompany} from "./sections-common/OurCompany";
import {OurProducts} from "./sections-publisher/OurProducts";
import {IdentityPage} from "./sections-publisher/product-pages/IdentityPage";
import {PayPage} from "./sections-publisher/product-pages/PayPage";
import {FunnelPage} from "./sections-publisher/product-pages/FunnelPage";
import {ReadyToGetStarted} from "./sections-common/ReadyToGetStarted";
import {Messages} from "../blinkpay/Messages";
import {ChatDemoComponent} from "./components/ChatDemoComponent";
import {PUBLISHER_URL, READER_URL} from "./Constants";
import {LandingPageIndex, LandingPageIndexStyle} from "./LandingPageIndex";


@registerStyle(LandingPageIndexStyle)
export class PublisherIndex extends LandingPageIndex {
    // This ids are tied to the Messages so be careful when changing them.
    navManager = new NavManager(
        [
            {
                id: "navPublisher",
                href: PUBLISHER_URL,
                label: Messages.navPublisher,
            },
            {
                id: "navReader",
                href: READER_URL,
                label: Messages.navReader,
            },
        ],
        [
            {
                element: Header,
                id: "header",
                label: "",
            },
            {
                element: IdentityPage,
                id: "identity",
                label: Messages.identity,
            },
            {
                element: PayPage,
                id: "pay",
                label: Messages.pay,
            },
            {
                element: FunnelPage,
                id: "funnel",
                label: Messages.funnel,
            },
        ]
    );

    onPageEnter() {
        super.onPageEnter();

        this.navBar.refreshFloatingStatus();
        this.identity.redraw();
        this.pay.redraw();
        this.funnel.redraw();
    }

    render() {
        const {styleSheet} = this;

        const headerOptions = {
            title: Messages.publisherHeaderTitle,
            description: Messages.publisherHeaderDescription,
            ctaSection: [
                <ChatDemoComponent sameLine={true} />,
                <div className={styleSheet.headerSubtext}>{Messages.headerSubtext}</div>,
            ],
        };

        const readyToGetStartedOptions = {
            underTitle: Messages.readyToGetStartedSubtitlePublisher,
            ctaSection: <ChatDemoComponent colorTheme={ChatDemoComponent.themes.LIGHT} />,
        };

        return [
            <MobileNavbar
                className={styleSheet.navMobile}
                selectedSection="navPublisher"
                navManager={this.navManager}
            />,
            // Pass the page instance so you can access the right header easily.
            <NavBar
                ref="navBar"
                className={styleSheet.nav}
                selectedSection="navPublisher"
                pageInstance={this}
                navManager={this.navManager}
            />,
            <Header id="header" {...headerOptions} />,
            <OurProducts navManager={this.navManager} containerId="product-content" />,
            <IdentityPage id="identity" ref="identity" containerId="identity-content" />,
            <PayPage id="pay" ref="pay" containerId="pay-content" />,
            <FunnelPage id="funnel" ref="funnel" containerId="funnel-content" />,
            <ValueProposition containerId="value-proposition-content" />,
            <ReadyToGetStarted {...readyToGetStartedOptions} containerId="ready-content" />,
            <OurCompany containerId="company-content" />,
            <Footer id="contact" containerId="footer-content" />,
        ];
    }
}
