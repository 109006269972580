import {UI, styleRule, registerStyle} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";
import {DonationStore, RecurringPaymentStoreObject, SubscriptionStore} from "../../../../State.js";
import {EditDonationModal} from "../components/EditDonationModal.jsx";
import {CancelDonationModal} from "../components/CancelDonationModal.jsx";
import {PaymentMethodDescription} from "../../../../common/PaymentMethodDescription.jsx";
import {ChoosePaymentMethodModal} from "../../../components/ChoosePaymentMethodModal.jsx";
import {ExclamationTriangleIcon} from "../../../../../core/ui/SVGElements.jsx";
import {BlinkButton} from "../../../../../core/ui/Button.jsx";
import {BaseRecurringPaymentList, BaseRecurringPaymentStyle} from "../../../components/BaseRecurringPaymentList.jsx";
import {LinkButton} from "../../../../../core/ui/LinkButton.jsx";


export class RecurringDonationsStyle extends BaseRecurringPaymentStyle {
    @styleRule
    manageDonationButton = {
        color: this.themeProps.LINK_COLOR,
        cursor: "pointer",
        userSelect: "none",
    };

    @styleRule
    changeButton = {
        position: "absolute",
        right: 20,
        display: "inline-block",
    };

    @styleRule
    paymentMethodRow = {
        display: "flex",
        justifyContent: "space-between",
    };
}

@registerStyle(RecurringDonationsStyle)
export class RecurringDonations extends BaseRecurringPaymentList {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.recurringDonations,
            description: this.getEntries().length ? "" : Messages.emptyRecurringDonations,
            expandedRows: true,
        };
    }

    getEntries() {
        return DonationStore.getPresentRecurring();
    }

    getExpandedRowContent(entry) {
        const {styleSheet} = this;
        const contentRows = [];

        if (entry.status === RecurringPaymentStoreObject.Status.ACTIVE) {
            contentRows.push(this.makeRowEntry(
                null,
                <div
                    className={styleSheet.manageDonationButton}
                    onClick={() => EditDonationModal.show({donation: entry})}>
                    {Messages.editRecurringDonation}
                </div>
            ));
        }
        if (entry.getPaymentMethod() && entry.status !== SubscriptionStore.Status.CANCELED) {
            const card = entry.getPaymentMethod();
            contentRows.push(this.makeRowEntry(
                Messages.paymentMethod,
                <div className={styleSheet.paymentMethodRow}>
                    <PaymentMethodDescription paymentMethod={card} />
                    <LinkButton
                        testId="changeCard"
                        className={styleSheet.changeButton}
                        onClick={() => ChoosePaymentMethodModal.show({entry: entry})}
                        label={Messages.change}
                    />
                </div>
            ));
        }

        if (entry.isUnpaid()) {
            contentRows.push(this.makeRowEntry(
                Messages.lastPayment,
                [
                    <div className={this.styleSheet.failedPayment}>
                        <ExclamationTriangleIcon
                            fill={this.themeProps.ERROR_COLOR}
                            size={20}
                            className={this.styleSheet.exclamationIcon}
                        />
                        <span>{Messages.donatePaymentFailedRetry(entry.getMerchantTitle())}</span>
                    </div>,
                    <div className={styleSheet.center}>
                        <BlinkButton
                            label={Messages.retryPayment}
                            className={styleSheet.retryPaymentButton}
                            onClick={() => this.retryPayment(entry)}
                        />
                    </div>
                ]
            ));
        } else {
            contentRows.push(this.makeRowEntry(
                Messages.lastPayment,
                this.renderLastPayment(entry),
            ));
        }

        // TODO the cancel donation button should be a Link button
        if (entry.status === RecurringPaymentStoreObject.Status.ACTIVE) {
            contentRows.push(this.makeRowEntry(
                null,
                <div
                    className={styleSheet.button + styleSheet.buttonCancel}
                    onClick={() => CancelDonationModal.show({donation: entry})}>
                    {Messages.cancelDonation}
                </div>,
            ));
        }

        return <div style={{paddingLeft: 30}}>{contentRows}</div>;
    }

    getColumns() {
        return [{
            headerName: Messages.publicationName,
            value: entry => entry.getMerchantTitle(),
            headerStyle: {
                textAlign: "left",
                width: "55%",
            }
        }, {
            headerName: Messages.amount,
            value: entry => entry.formatPriceShortened(),
            headerStyle: {
                textAlign: "left",
                width: "20%",
            }
        }, {
            headerName: Messages.nextPayment,
            value: entry => this.renderNextPayment(entry),
            headerStyle: {
                textAlign: "right",
                width: "25%",
            },
            cellStyle: {
                color: this.themeProps.MERCHANT_2
            }
        }];
    }
}
