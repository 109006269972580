import {getCookie, setCookie} from "../../stem-core/src/base/Utils";
import {StemDate} from "../../stem-core/src/time/Date";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";


const STORAGE_KEY = "token";

export class AuthToken extends Dispatchable {
    static EventTypes = {
        LOGIN: 1, // From null to having a token
        LOGOUT: 2, // From token to null
        REFRESH: 3, // Token was changed, possibly a refresh or relogin from another browser tab
    };

    usingMerchantToken;
    token;
    expiresAt;

    static getUserIdFromToken(tokenKey) {
        if (!tokenKey) {
            return null;
        }
        const parts = tokenKey.split("_", 2);
        return parts[0];
    }

    getUserId() {
        return this.constructor.getUserIdFromToken(this.token);
    }

    loadFromStorage() {
        const tryLoad = (rawLoader) => {
            let value;
            try {
                value = JSON.parse(rawLoader());
            } catch {}
            if (!value) {
                return false;
            }
            const expiresAt = new StemDate(value.e);
            if (expiresAt < StemDate.now()) {
                this.token = null;
                this.expiresAt = new StemDate();
                return false;
            }

            this.token = value.t;
            this.expiresAt = expiresAt;
            return true;
        }

        if (tryLoad(() => getCookie(STORAGE_KEY))) {
            return;
        }
        if (tryLoad(() => localStorage.getItem(STORAGE_KEY))) {
            return;
        }
        tryLoad(() => sessionStorage.getItem(STORAGE_KEY));
    }

    load() {
        this.loadFromStorage();
        this.checkForTokenChangeEvent(null, this.token);
    }

    checkForTokenChangeEvent(oldToken, newToken) {
        let tokenEvent = null;

        if (!oldToken && newToken) {
            tokenEvent = AuthToken.EventTypes.LOGIN;
        } else if (oldToken && !newToken) {
            tokenEvent = AuthToken.EventTypes.LOGOUT;
        } else if (oldToken && oldToken !== newToken) {
            tokenEvent = AuthToken.EventTypes.REFRESH;
        }

        if (tokenEvent) {
            this.dispatch("change", tokenEvent, oldToken);
        }
    }

    save() {
        const value = this.token ? this.getTokenObjectStringified() : null;

        try {
            const maxCookieAge = 60 * 60 * 24 * 31 * 12 * 5; // 5 years
            setCookie(STORAGE_KEY, value, maxCookieAge);
        } catch {}

        try {
            localStorage.setItem(STORAGE_KEY, value);
        } catch {}

        try {
            sessionStorage.setItem(STORAGE_KEY, value);
        } catch {}
    }

    setToken(token, expiresAt, usingMerchantToken=false) {
        const oldToken = this.token;
        this.token = token;
        this.expiresAt = new StemDate(expiresAt);
        this.usingMerchantToken = usingMerchantToken;
        if (!usingMerchantToken) {
            this.save();
        }
        this.checkForTokenChangeEvent(oldToken, this.token);
    }

    clear() {
        this.setToken(null, null);
    }

    isAuthenticated() {
        return this.token && this.expiresAt > StemDate.now();
    }

    valueOf() {
        return this.token;
    }

    toString() {
        return this.token;
    }

    getTokenObjectStringified() {
        return JSON.stringify({
            t: this.token,
            e: this.expiresAt,
        });
    }
}
