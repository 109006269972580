import {styleRule, UI} from "../../stem-core/src/ui/UI";

import {registerStyle, styleRuleInherit} from "../../stem-core/src/ui/UI";
import {Section, SectionStyle} from "../primitives/Section";
import {Messages} from "../../blinkpay/Messages";
import {
    EasyIntegrationSVG,
    FrictionlessUXSVG,
    ModularitySVG,
    NetworkEffectsSVG,
    ReaderRelationshipSVG,
    SupportSVG,
} from "../SVGElements";
import {ValuePropositionCard} from "./primitives/ValuePropositionCard";
import {Device} from "../../stem-core/src/base/Device";
import {isDesktopMedium} from "../Utils";

export class ValuePropositionStyle extends SectionStyle {
    @styleRuleInherit
    container = {
        background: () =>
            Device.isMobileDevice()
                ? this.themeProps.VALUE_PROPOSITION_MOBILE_BACKGROUND
                : this.themeProps.VALUE_PROPOSITION_BACKGROUND,
        color: this.themeProps.LANDING_PAGE_LIGHT_FONT_COLOR,
        paddingTop: () => (Device.isMobileDevice() ? 70 : 180),
        paddingBottom: () => (Device.isMobileDevice() ? 10 : 30),
    };

    @styleRuleInherit
    content = {
        padding: () => (Device.isMobileDevice() ? 0 : "0 24px"),
    };

    @styleRule
    cardsContainer = {
        display: "flex",
        justifyContent: () => (isDesktopMedium() ? "space-around" : "space-between"),
        maxWidth: 1080,
        flexWrap: "wrap",
    };

    @styleRule
    title = {
        fontSize: () => (Device.isMobileDevice() ? 24 : 32),
        color: this.themeProps.LANDING_PAGE_LIGHT_FONT_COLOR,
        textAlign: "center",
        marginBottom: () => (Device.isMobileDevice() ? 40 : 72),
    };
}

const valuePropositionCards = [
    {
        title: Messages.readerRelationshipTitle,
        description: Messages.readerRelationshipText,
        icon: <ReaderRelationshipSVG />,
        iconStyle: Device.isMobileDevice() ? {transform: "scale(.9)"} : {},
    },
    {
        title: Messages.networkEffectsTitle,
        description: Messages.networkEffectsText,
        icon: <NetworkEffectsSVG />,
        iconStyle: Device.isMobileDevice() ? {transform: "scale(.85)"} : {},
    },
    {
        title: Messages.frictionlessUXTitle,
        description: Messages.frictionlessUXText,
        icon: <FrictionlessUXSVG />,
        iconStyle: Device.isMobileDevice() ? {transform: "scale(.7)"} : {},
    },
    {
        title: Messages.easyIntegrationTitle,
        description: Messages.easyIntegrationText,
        icon: <EasyIntegrationSVG />,
        iconStyle: Device.isMobileDevice() ? {transform: "scale(.65)", transformOrigin: "10% 50%"} : {},
    },
    {
        title: Messages.modularityTitle,
        description: Messages.modularityText,
        icon: <ModularitySVG />,
        iconStyle: Device.isMobileDevice() ? {transform: "scale(.8)", transformOrigin: "10% 50%"} : {},
    },
    {
        title: Messages.supportTitle,
        description: Messages.supportText,
        icon: <SupportSVG />,
        iconStyle: Device.isMobileDevice() ? {transform: "scale(.75)", transformOrigin: "20% 50%"} : {},
    },
];

@registerStyle(ValuePropositionStyle)
export class ValueProposition extends Section {
    render() {
        const {styleSheet} = this;

        valuePropositionCards.forEach(itemOptions => {
            itemOptions.icon.options.size = "100%";
            itemOptions.icon.options.color = "#2AB6BA";
        });

        const cards = valuePropositionCards.map(itemOptions => <ValuePropositionCard {...itemOptions} />);

        return [
            <div className={styleSheet.title}>{Messages.valuePropositionTitle}</div>,
            <div className={styleSheet.cardsContainer}>{cards}</div>,
        ];
    }
}
