import {UI} from "../../stem-core/src/ui/UIBase.js";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style.js";
import {Messages} from "../Messages";
import {isFacebookBrowser} from "../Utils";
import {BLINK_PAY_URL} from "../Constants";
import {PanelLink} from "../panel/panel-elements/PanelLink.jsx";
import {registerStyle} from "../../stem-core/src/ui/style/Theme.js";

class MerchantUserResourcesAccessLinkStyle extends StyleSheet {
    @styleRule
    container = {
        display: "inline-block",
        marginLeft: 8,
        ">:last-child": {
            display: "inline-block",
            marginLeft: 8,
        }
    }
}

@registerStyle(MerchantUserResourcesAccessLinkStyle)
class MerchantUserResourcesAccessLink extends UI.Element {
    getDefaultOptions() {
        return {
            extraResourcesCount: null,
        };
    }

    render() {
        const {extraResourcesCount} = this.options;
        //TODO @auth insanely complex
        return [
            Messages.or,
            <PanelLink
                href={BLINK_PAY_URL + "/login"}
                newTab
                rel="opener"
            >
                {Messages.merchantUserResourcesAccessLink(extraResourcesCount)}
            </PanelLink>
        ];
    }
}

export function MakeMerchantUserResourcesAccessLink(extraResourcesCount) {
    if (isFacebookBrowser()) { // TODO: Maybe support these later.
        return null;
    }

    return (extraResourcesCount > 0) && <MerchantUserResourcesAccessLink extraResourcesCount={extraResourcesCount}/>;
}
