import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";
import {StyleSheet} from "../../../../../../stem-core/src/ui/Style.js";

import {Messages} from "../../../../../Messages.js";
import {PaymentMethodStore} from "../../../../../State.js";
import {styleRule} from "../../../../../../stem-core/src/decorators/Style.js";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme.js";
import {AddCardModal} from "./AddCardModal.jsx";
import {SelectPrimaryCardModal} from "./SelectPrimaryCardModal.jsx";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw.js";
import {MessageElement} from "../../../../../widget/ui/MessageElement.jsx";
import {FormatPaymentMethodDescription} from "../../../../../common/PaymentMethodDescription.jsx";
import {LinkButton} from "../../../../../../core/ui/LinkButton.jsx";


class DefaultPaymentMethodElementStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "space-between",
    };
}

@registerStyle(DefaultPaymentMethodElementStyle)
@autoredraw(PaymentMethodStore)
export class DefaultPaymentMethodElement extends UI.Element {
    render() {
        const defaultPaymentMethod = PaymentMethodStore.getPrimary();

        return [
            <MessageElement 
                testId="walletPrimaryCardDetails"
                message={defaultPaymentMethod ? FormatPaymentMethodDescription(defaultPaymentMethod) : Messages.addCard}
            />,

            <LinkButton
                label={defaultPaymentMethod ? Messages.change : Messages.addCard}
                onClick={() => defaultPaymentMethod ? SelectPrimaryCardModal.show() : AddCardModal.show()}
                testId="walletChangeCardButton"
            />
        ];
    }
}
