import {UI, styleRule, registerStyle, StyleSheet} from "../../../../../stem-core/src/ui/UI.js";
import {Messages} from "../../../../Messages.js";
import {RefundModal} from "./RefundModal.jsx";
import {PopupMenu} from "../../../../ui/PopupMenu.jsx";

class PurchasePriceElementStyle extends StyleSheet {
    @styleRule
    container = {
        display: "flex",
        position: "relative",
        marginRight: -8,
        justifyContent: "flex-end",
    };

    @styleRule
    price = {
        fontSize: 14
    };
}

@registerStyle(PurchasePriceElementStyle)
export class PurchasePriceElement extends UI.Element {
    render() {
        const {purchase} = this.options;

        return [
            <div className={this.styleSheet.price}>{purchase.getAmount().toMainUnitString()}</div>,
            <PopupMenu items={[
                {
                    content: Messages.refund,
                    get disabled() {
                        return !purchase.isRefundable()
                    },
                    onClick: () => RefundModal.show({purchase}),
                }
            ]}/>
        ];
    }
}
