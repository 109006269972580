import {UI} from "../../../stem-core/src/ui/UIBase.js";

import {ConfirmationModal} from "../../ui/ConfirmationModal.jsx";
import {Messages} from "../../Messages.js";
import {AddCardModal} from "../pages/wallet/components/cards/AddCardModal.jsx";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore.js";
import {Toast} from "../../ui/Toast.jsx";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw.js";
import {PaymentMethodSelectInput} from "../../panel/pages/panels/payment-method/components/PaymentMethodSelectInput.jsx";


// TODO who was the retard that put this class in the subscriptions folder, even though it's also used for donations?
// TODO @flow @pay this needs a merchant to be passed in, since the available payment methods differ per merchant
@autoredraw(PaymentMethodStore)
export class ChoosePaymentMethodModal extends ConfirmationModal {
    lastAddedCardId = null;

    getDefaultOptions(options) {
        const entry = options.entry || this.options.entry;
        return {
            ...super.getDefaultOptions(options),
            cancelLabel: Messages.close,
            confirmLabel: entry.isUnpaid() ? (!this.chooseCard || entry.getPaymentMethod().id === this.chooseCard.getValue().id ?
                Messages.retry : Messages.updateAndRetryPayment) : Messages.updatePaymentMethod,
            title: Messages.choosePaymentMethod,
            confirmAction: () => this.updatePaymentMethod(),
            entry: null,
        };
    }

    render() {
        const {entry} = this.options;
        const entityCard = entry.getPaymentMethod();
        const entityCardId = entityCard?.id;
        const selectedCardId = this.chooseCard?.getValue()?.id || this.lastAddedCardId || entityCardId;
        this.confirmButtonDisabled = !this.chooseCard || this.chooseCard?.getValue()?.id === entityCardId;

        if (entityCardId === selectedCardId && entry.isUnpaid()) {
            this.confirmButtonDisabled = false;
        }

        return [
            <PaymentMethodSelectInput
                ref="chooseCard"
                initialValue={entityCard}
                onChange={() => this.redraw()}
                onAddCard={() => this.handleAddCardButtonClick()}
                recurringPayment={entry}
            />,
        ];
    }

    async handleAddCardButtonClick() {
        const paymentMethod = await AddCardModal.prompt();
        this.lastAddedCardId = paymentMethod.id;
        this.chooseCard.setValue(paymentMethod);
        this.redraw();
    }

    async attemptPayEntry(entry) {
        await entry.reactivate();
        Toast.show(Messages.entrySuccessfullyPaid);
    }

    @wrapInSpinner
    async updatePaymentMethod() {
        const {entry} = this.options;

        if (!entry) {
            return;
        }

        if (entry.getPaymentMethod().id === this.chooseCard.getValue().id) {
            await this.attemptPayEntry(entry);
            this.hide();
            return;
        }

        await entry.updatePaymentMethod(this.chooseCard.getValue().id);
        this.hide();

        if (entry.isUnpaid()) {
            await this.attemptPayEntry(entry);
            return;
        }
        Toast.show(Messages.updateEntryPaymentMethodSuccess);
    }
}
