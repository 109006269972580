import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {TelInput} from "../../../stem-core/src/ui/input/Input.jsx";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style.js";
import {isSmallScreen} from "../../UtilsLib.js";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme.js";
import {KeyEvent} from "../../KeyEvent.js";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils.js";

// Search for the first group of 6 consecutive digits. If not found, return the string as-is.
function findConfirmationCodeCandidate(string) {
    if (string == null) {
        return string;
    }
    string = string.replace("-", "");
    const firstDigitMatch = string.match(/[0-9]{6}/);
    if (firstDigitMatch === null) {
        return string; // No digits found. Return unchanged.
    }
    return string.substring(firstDigitMatch.index, firstDigitMatch.index + 6);
}

class ShortCodeInputStyle extends StyleSheet {
    transition = ".3s ease";

    @styleRule
    codeInput = {
        opacity: 1,
        transition: this.transition,
        fontWeight: 300,
        margin: "12px auto 8px",
        textAlign: "center",
        fontSize: 40,
        color: this.themeProps.INPUT_TEXT_COLOR,
        whiteSpace: "nowrap",
        ">*": {
            padding: "0 !important",
            display: "inline-block",
            background: this.themeProps.INPUT_BACKGROUND,
            border: "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
            width: () => isSmallScreen() ? 40 : 44,
            height: 60,
            borderRadius: 0,
            backgroundClip: "padding-box",
            textAlign: "center",
            marginRight: -1,
            fontWeight: "inherit",
            fontSize: "inherit",
            color: this.themeProps.INPUT_TEXT_COLOR,
            ":focus": {
                outline: "none",
                borderColor: this.themeProps.INPUT_BORDER_ACTIVE_COLOR,
                zIndex: 2,
                margin: 0,
            },
        },
        ">:first-child": {
            borderRadius: "8px 0 0 8px",
        },
        ">:last-child": {
            borderRadius: "0 8px 8px 0",
        },
        ">:nth-child(3)": {
            marginRight: 18,
        },
        ">:nth-child(4)": {
            marginLeft: 18,
        },
    };

    @styleRule
    errorCodeInput = {
        ">*": {
            borderColor: () => this.themeProps.ERROR_COLOR + "!important",
            ":focus": {
                borderColor: () => this.themeProps.ERROR_COLOR + "!important",
                boxShadow: "none",
                marginRight: -1,
            },
            ":not(:nth-child(3n+3))": {
                borderRight: "none",
            },
        },

        ">:nth-child(3)": {
            marginRight: () => isSmallScreen() ? 12 : 18,
        },
    };

    @styleRule
    error = {
        position: "absolute",
        color: this.themeProps.ERROR_COLOR,
        whiteSpace: "nowrap",
        left: 0,
        right: 0,
        textAlign: "center",
    };

    @styleRule
    successInput = {
        ">*": {
            pointerEvents: "none",
            borderColor: () => this.themeProps.SUCCESS_COLOR + "!important",
            ":focus": {
                borderColor: () => this.themeProps.SUCCESS_COLOR + "!important",
                boxShadow: "none",
                marginRight: -1,
            },
            ":not(:nth-child(3n+3))": {
                borderRight: "none",
            },
        },

        ">:nth-child(3)": {
            marginRight: () => isSmallScreen() ? 12 : 18,
        },
    };
}

@registerStyle(ShortCodeInputStyle)
export class ShortCodeInput extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            success: null,
            error: null,
            onCodeInserted: NOOP_FUNCTION,
            onEnter: NOOP_FUNCTION,
            onChange: NOOP_FUNCTION,
            onClearError: NOOP_FUNCTION,
        }
    }

    render() {
        const {styleSheet} = this;
        const {error, success} = this.options;
        const inputOptions = {
            maxLength: 1,
            pattern: "[0-9]",
            tabIndex: 0,
        };

        return [
            <div className={styleSheet.codeInput + (success ? styleSheet.successInput
                            : (error ? styleSheet.errorCodeInput : ""))}>
                <TelInput ref="code1" {...inputOptions} autofocus/>
                <TelInput ref="code2" {...inputOptions} />
                <TelInput ref="code3" {...inputOptions} />
                -
                <TelInput ref="code4" {...inputOptions} />
                <TelInput ref="code5" {...inputOptions} />
                <TelInput ref="code6" {...inputOptions} />
            </div>,
            error ? <div className={styleSheet.error}>{error}</div> : null,
        ];
    }

    blur() {
        for (let i = 1; i <= 6; i += 1) {
            this["code" + i].blur();
        }
    }

    getCode() {
        let code = "";
        for (let i = 1; i <= 6; i += 1) {
            code += this["code" + i] ? this["code" + i].getValue() : "";
        }
        return code;
    }

    isValidCode(code) {
        return code.match(/^[0-9]{6}$/);
    }

    hasValidCode() {
        return this.isValidCode(this.getCode());
    }

    onMount() {
        super.onMount();
        const {onCodeInserted, onEnter, onChange} = this.options;
        const setInputValue = (inputElement, value) => {
            inputElement.setValue(value);
            onChange();
        };

        for (let i = 1; i <= 6; i += 1) {
            const previousInput = this["code" + (i - 1)];
            const nextInput = this["code" + (i + 1)];
            const currentInput = this["code" + i];
            currentInput.setAttribute("contenteditable", "true");
            currentInput.addNodeListener("focus", () => this.options.onClearError());
            currentInput.addNodeListener("keydown", event => {
                const prevent = () => {
                    event.stopPropagation();
                    event.preventDefault();
                };
                const key = new KeyEvent(event);
                if (key.isEnter()) {
                    onEnter();
                    return;
                }
                if (key.isBackspace()) {
                    if (currentInput.getValue().length === 0) {
                        if (previousInput) {
                            previousInput.node.focus();
                            previousInput.node.setSelectionRange(1, 1);
                        }
                    } else {
                        setInputValue(currentInput, "");
                    }
                    prevent();
                    return;
                }
                if (key.isDelete()) {
                    setInputValue(currentInput, "");
                    prevent();
                    return;
                }
                if (key.isArrowLeft()) {
                    if (previousInput) {
                        previousInput.node.focus();
                        previousInput.node.setSelectionRange(currentInput.node.selectionStart, currentInput.node.selectionStart);
                    } else {
                        currentInput.node.setSelectionRange(0, 0);
                    }
                    prevent();
                    return;
                }
                if (key.isArrowRight()) {
                    if (nextInput) {
                        nextInput.node.focus();
                        nextInput.node.setSelectionRange(currentInput.node.selectionStart, currentInput.node.selectionStart);
                    } else {
                        currentInput.node.setSelectionRange(1, 1);
                    }
                    prevent();
                    return;
                }
                if (key.isTab()) {
                    if (!nextInput) {
                        currentInput.node.blur();
                    } else {
                        nextInput.node.focus();
                    }
                    prevent();
                    return;
                }
                if (key.isDigit()) {
                    setInputValue(currentInput, event.key);
                    if (nextInput) {
                        nextInput.node.focus();
                        nextInput.node.setSelectionRange(0, 0);
                    }
                    prevent();
                }
                if (!(key.is("v") && (event.metaKey || event.ctrlKey))) {
                    prevent();
                }
            });
            currentInput.addNodeListener("keyup", event => {
                const key = new KeyEvent(event);
                if (!key.isDigit()) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (!currentInput.getValue().match(/[0-9]/)) {
                        setInputValue(currentInput, "");
                    }
                }
                if (key.isDigit() && this.isValidCode(this.getCode())) {
                    onCodeInserted(this.getCode());
                }
            });
            currentInput.addNodeListener("paste", event => {
                const pasteString = findConfirmationCodeCandidate(event.clipboardData.getData("text"));
                event.preventDefault();
                event.stopPropagation();
                if (this.isValidCode(pasteString)) {
                    for (let i = 1; i <= 6; i += 1) {
                        setInputValue(this["code" + i], pasteString[i - 1]);
                    }
                    onCodeInserted(this.getCode());
                }
            });
        }
    }
}
