import {Store, StoreObject} from "../../stem-core/src/state/Store";
import {apiClient} from "../connection/BlinkApiClient.js";
import {StemDate} from "../../stem-core/src/time/Date";
import {field} from "../../stem-core/src/state/StoreField.js";


class UserAddress extends StoreObject {
    @field("UserProfile") user;
    @field(Boolean) isDefault;
    @field(Date) createAt;
    @field(String) recipientName;
    @field(String) streetAddress;
    @field(String) city;
    @field(String) state; // Or region
    @field(String) country;
    @field(String) countryCode;
    @field(String) postalCode; // Only Ireland has non-numerical post codes, for the rest verify extra
    @field(String) phoneNumber;

    // TODO deprecate this method
    getRecipientName() {
        return this.recipientName;
    }

    toString() {
        return this.getAddressLine(this.streetAddress, this.city, this.state, this.postalCode, this.country);
    }

    getAddressLine(...args) {
        return args.filter(addrItem => addrItem?.trim().length > 0).join(", ");
    }
}

// TODO @Mihai maybe allow sort functions to be passed in via the store options
class UserAddressStoreClass extends Store("UserAddress", UserAddress) {
    all() {
        return super.all().sort((a, b) => (new StemDate(a.createdAt)).getTime() - (new StemDate(b.createdAt)).getTime())
    }

    getDefault() {
        const addresses = this.all();

        return addresses.find(address => address.isDefault) || addresses[0];
    }
}

export const UserAddressStore = new UserAddressStoreClass();

export async function apiCreateAddress(newAddressDetails = {}) {
    const response = await apiClient.post("/user_addresses/create", newAddressDetails)
    return UserAddressStore.loadObject(response);
}

export async function apiEditAddress(addressDetails = {}) {
    const response = await apiClient.post("/user_addresses/edit_address", addressDetails);
    return UserAddressStore.loadObject(response);
}

export async function apiDeleteAddress(userAddressId) {
    const response = await apiClient.post("/user_addresses/delete_address", {userAddressId});
    return UserAddressStore.loadObject(response);
}
