import {BLINK_API_URL} from "../../blink-sdk/Constants";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {WebsocketSubscriber} from "./ws-client/WebsocketSubscriber";
import {AjaxHandler} from "../../stem-core/src/base/Ajax";
import {jsonRequestPreprocessor} from "../../stem-core/src/base/RequestProcessors";
import {Messages} from "../../blinkpay/Messages";
import {isPlainObject} from "../../stem-core/src/base/Utils";
import {GlobalState} from "../../stem-core/src/state/State";
import {BaseEnum} from "../../stem-core/src/state/BaseEnum.js";
import {StemDate} from "../../stem-core/src/time/Date";
import {Money} from "../../stem-core/src/localization/Money.js";
import {BlinkGlobal} from "../../blinkpay/UtilsLib";

// This needs to be the very last preprocessor, since other preprocessors
// might add more data to the request.
function avoidCORSPreflightRequestsPreProcessor(options) {
    options.headers.delete("X-Requested-With");
    if (options.headers.get("Content-Type") === "application/json") {
        // This is a hack to mark API calls as "simple HTTP requests" according
        // to the CORS spec: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
        // The browser bypasses CORS preflight requests when sending simple HTTP
        // requests, making the latency much better. Our API implementation supports
        // the "text/plain" content type and treats it the same as "application/json".
        options.headers.set("Content-Type", "text/plain");
        options.body = JSON.stringify(options.data);
    }
}

function serializeRequestData(data, options = {allowNulls: true}) {
    if (data instanceof BaseEnum) {
        return data.valueOf();
    }
    if (data instanceof Money) {
        return data.amount;
    }
    if (data instanceof Date) {
        return new StemDate(data).unix();
    }
    if (Array.isArray(data)) {
        return data.map(element => serializeRequestData(element, options));
    }
    if (isPlainObject(data)) {
        data = {...data}; // Shallow copy the data before modifying.
        for (const [key, value] of Object.entries(data)) {
            if (value == null) {
                // Delete nulls, only if there were not whitelisted
                if (options.allowNulls !== true && !options.allowNulls.has(key)) {
                    delete data[key];
                    continue;
                }
            }
            data[key] = serializeRequestData(data[key], options);
        }
    }
    return data;
}

class BlinkAjaxHandler extends AjaxHandler {
    getPreprocessors() {
        return [
            ...super.getPreprocessors(),
            avoidCORSPreflightRequestsPreProcessor,
        ];
    }
}

export class BlinkApiClient extends Dispatchable {
    websocketSubscriber = new WebsocketSubscriber();
    ajaxHandler = new BlinkAjaxHandler(null, null);

    constructor(httpApiServerAddresses) {
        super();
        if (!Array.isArray(httpApiServerAddresses)) {
            httpApiServerAddresses = [httpApiServerAddresses];
        }
        this.httpApiServerAddresses = httpApiServerAddresses;
        this.ajaxHandler.addPreprocessor(jsonRequestPreprocessor);
    }

    initWebsocketConnection(authToken) {
        this.websocketSubscriber.setAuthToken(authToken);
    }

    dropWebsocketConnection() {
        this.websocketSubscriber.disconnect();
    }

    addWebsocketListener(stream, callback) {
        this.websocketSubscriber.addStreamListener(stream, callback);
    }

    getAjaxHandler() {
        return this.ajaxHandler;
    }

    get(url, request, extraOptions={}) {
        return this.fetch(url, request, "GET", "json", extraOptions);
    }

    post(url, request, extraOptions={}) {
        return this.fetch(url, request, "POST", "json", extraOptions);
    }

    getApiAddress(path) {
        const index = Math.floor(Math.random() * this.httpApiServerAddresses.length);
        return this.httpApiServerAddresses[index] + path;
    }

    fetch(url, data, method, dataType = "json", extraOptions = {allowNulls: false}) {
        if (!url.startsWith("/")) {
            url = "/" + url;
        }

        data = {...data};

        const allowNulls = (extraOptions.allowNulls === true) ? true : new Set(extraOptions.allowNulls);

        data = serializeRequestData(data, {allowNulls});
        if (method === "GET") {
            for (const [key, value] of Object.entries(data)) {
                if (isPlainObject(value)) {
                    data[key] = JSON.stringify(value);
                }
            }
        }

        const xhrPromise = this.ajaxHandler.fetch(this.getApiAddress(url), {
            dataType,
            method,
            data,
            arraySearchParamSuffix: "",
            ...extraOptions,
        });

        return new Promise((resolve, reject) => {
            return xhrPromise.then(
                response => {
                    if (response.error) {
                        reject(this.getErrorFromResponse(response, xhrPromise));
                    }
                    resolve(response)
                },
                error => {
                    reject(this.getPrettifiedApiRuntimeError(error, xhrPromise))
                }
            )
        });
    }

    getPrettifiedApiRuntimeError(error, xhrPromise) {
        if (!(error instanceof Error)) {
            if (error) {
                error.isAPIError = true;
            }
            return error;
        }

        const prettifiedError =  {
            statusCode: xhrPromise.getXHR().status,
            detail: error.message,
            stacktrace: error.stack,
            message: error.message === "Network error" ? Messages.networkError : Messages.unexpectedErrorWithCode(xhrPromise.getXHR().status),
        };
        console.error(prettifiedError);

        return prettifiedError;
    }

    getErrorFromResponse(response, xhrPromise) {
        const statusCode = xhrPromise.getXHR().status;

        // Get the error from the API response
        if (response && response.error) {
            return {
                ...response.error,
                statusCode,
            };
        }
        // The whole response body is an error for HTTP codes >= 400
        if (statusCode >= 400) {
            return {
                ...response,
                statusCode,
            };
        }
        // No error found, return null
        return null;
    }
}

export const apiClient = new BlinkApiClient(BLINK_API_URL);

// Add a state postprocessor
apiClient.getAjaxHandler().addPostprocessor((response, xhrPromise) => {
    const {state, events, userId} = response;

    if (userId) {
        // TODO @cleanup WAT?
        BlinkGlobal.userId = userId;
    }
    if (state && !xhrPromise.options.disableStateImport) {
        GlobalState.importState(state);
    }
    if (events && !xhrPromise.options.disableEventsImport) {
        GlobalState.applyEvent(events);
    }
});


// TODO move as a method in apiClient?
// TODO support resuming?
export async function* apiStreamQuery(apiEndpoint, query, {
    pageSize = 100,
    batchSize = 1,
    disableStateImport = true,
    status = null
} = {}) {

    let currentPage = 1;

    while (!(status?.shouldStop)) {
        const requests = [];

        // First enqueue the requests
        for (let index = 0; index < batchSize; index += 1, currentPage += 1) {
            const reqPromise = apiClient.get(apiEndpoint, {
                pageSize,
                includeSummary: false, // Don't need this or summariezed stats
                ...query,
                page: currentPage,
                disableStateImport,
            });

            requests.push(reqPromise);
        }

        // Now actually await them
        for (const reqPromise of requests) {
            const response = await reqPromise;
            if (!response.state) {
                return;
            }
            yield response;
        }
    }
}


window.apiClient = apiClient;
