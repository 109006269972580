import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI.js";
import {Messages} from "../../Messages.js";
import {ExternalLink} from "../../widget/ui/ExternalLink.jsx";


class TermsMessageStyle extends StyleSheet {
    @styleRule
    container = {
        fontSize: this.themeProps.FONT_SIZE_SMALL,
        color: this.themeProps.TEXT_SECONDARY_COLOR,
    };

    @styleRule
    termsText = {
        display: "inline-block",
        maxWidth: 480,
        paddingTop: 12,
        marginTop: 4,
    };
}

@registerStyle(TermsMessageStyle)
export class TermsMessage extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            preMessage: "",
            postMessage: Messages.termsAndConditions,
            termsLink: "/terms",
            policyLink: "/privacy-policy",
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        if (this.options.showBorder) {
            attr.setStyle("borderTop", "0.5px solid " + this.themeProps.MERCHANT_FRAME_HEADER_BORDER_COLOR);
        }
    }

    render() {
        const {styleSheet} = this;
        const {preMessage, postMessage} = this.options;

        return [
            <div className={styleSheet.termsText} testId="termsText">
                <span>{[preMessage, postMessage]}</span>
                <ExternalLink testId="signupTermsLink" href={this.options.termsLink} newTab noIcon label={Messages.terms} />
                {" and "}
                <ExternalLink testId="signupPolicyLink" href={this.options.policyLink} newTab noIcon label={Messages.policy} />
                .
            </div>,
        ];
    }
}
