import {UI} from "stem-core/src/ui/UIBase.js";
import {ConfirmationModal} from "../../../../../ui/ConfirmationModal.jsx";
import {Toast} from "../../../../../ui/Toast.jsx";
import {Messages} from "../../../../../Messages.js";
import {StripeCardInput} from "../../../../../common/payment-methods/StripeCardInput.jsx";
import {wrapInSpinner} from "../../../../../../core/ui/LoadingSpinner.jsx";


// TODO @cleanup this card needs to be selected in the other input
export class AddCardModal extends ConfirmationModal {
    @wrapInSpinner
    async handleAddCard() {
        const paymentMethod = await this.cardInput.confirmCard();
        Toast.show(Messages.cardAdded);
        this.resolve(paymentMethod);
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "addCardModal",
            title: Messages.addCard,
            confirmAction: () => this.handleAddCard(),
        };
    }

    render() {
        return [
            <StripeCardInput
                onChange={() => this.updateConfirmButton()}
                ref="cardInput"
                style={{marginTop: 36}}
            />,
        ];
    }

    updateConfirmButton() {
        this.modalConfirmButton.updateOptions({disabled: !this.cardInput.hasValidSecureFields()});
    }

    onMount() {
        super.onMount();
        this.updateConfirmButton();
    }
}
