import {UI} from "../../../../../stem-core/src/ui/UIBase.js";
import {Link} from "../../../../../stem-core/src/ui/UIPrimitives.jsx";
import {LabeledToggle} from "../../../components/LabeledToggle.jsx";
import {Toast} from "../../../../ui/Toast.jsx";
import {Messages} from "../../../../Messages.js";
import {userService} from "../../../../../client/connection/services/UserService.js";
import {apiUpdateUserMerchantPreferences} from "../../../../../client/state/merchant/MerchantUserStore.js";


export class AutopaySetting extends UI.Element {
    getDefaultOptions() {
        return {
            merchant: null,
        };
    }

    getSettingLabel() {
        const {merchant} = this.options;

        return <Link href={merchant.getUrl()} newTab>
            {merchant}
        </Link>;
    }

    async handleSettingToggle() {
        try {
            await apiUpdateUserMerchantPreferences({
                userId: userService.getUserId(),
                merchantId: this.options.merchant.id,
                autoPayEnabled: !userService.isAutoPayEnabledForMerchant(this.options.merchant),
            });
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        }
    }

    render() {
        const {merchant} = this.options;

        return <LabeledToggle
            label={this.getSettingLabel()}
            initialValue={userService.isAutoPayEnabledForMerchant(merchant)}
            onChange={() => this.handleSettingToggle()}
        />;
    }
}
