import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {DonationStore} from "../../../../client/state/DonationStore.js";
import {RecurringDonations} from "./sections/RecurringDonations.jsx";
import {PastRecurringDonations} from "./sections/PastRecurringDonations.jsx";
import {DonationPayments} from "./sections/DonationPayments.jsx";
import {Dispatcher} from "../../../../stem-core/src/base/Dispatcher.js";
import {getQueryParam} from "../../../UtilsLib.js";
import {userService} from "../../../../client/connection/services/UserService.js";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";


@autoredraw(DonationStore)
export class DonationsPage extends UI.Element {
    render() {
        const activeRecurringDonations = DonationStore.getPresentRecurring();
        const pastRecurringDonations = DonationStore.getPastRecurring();

        return [
            (activeRecurringDonations.length > 0) && <RecurringDonations testId="recurringDonationsCard"/>,
            (pastRecurringDonations.length > 0) && <PastRecurringDonations testId="pastRecurringDonationsCard"/>,
            <DonationPayments testId="donationPaymentsCard"/>,
        ];
    }

    onMount() {
        const dispatchEntryFocus = () => {
            // TODO @cleanup why is ensureUserDataRequested all over the place?
            userService.ensureUserDataRequested(() => {
                const focusDonationId = getQueryParam("focus");
                if (focusDonationId) {
                    const donation = DonationStore.get(focusDonationId);
                    Dispatcher.Global.dispatch("highlightEntry", donation);
                }
            });
        };
        this.attachTimeout(dispatchEntryFocus);
        this.addListener("urlEnter", dispatchEntryFocus);
    }
}
