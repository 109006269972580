import {Dispatchable} from "../../stem-core/src/base/Dispatcher.js";
import {getQueryParam} from "../UtilsLib.js";


// TODO @flow @auth this should be removed
class SocialAuthService extends Dispatchable {
    constructor() {
        super();
        this.merchantId = getQueryParam("clientId");
    }

    setMerchantId(merchantId) {
        this.merchantId = merchantId;
        this.dispatchChange();
    }
}

export const socialAuthService = new SocialAuthService();
