import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";

import {ConfirmationModal} from "../../../../../ui/ConfirmationModal.jsx";
import {AddressForm} from "../../../../../common/AddressForm.jsx";
import {Messages} from "../../../../../Messages.js";
import {wrapInSpinner} from "../../../../../../core/ui/LoadingSpinner.jsx";
import {SubscriptionStore} from "../../../../../../client/state/SubscriptionStore.js";
import {UserAddressStore} from "../../../../../../client/state/UserAddressStore.js";
import {apiEditAddress} from "../../../../../../client/state/UserAddressStore.js";
import {autoredraw} from "../../../../../../stem-core/src/decorators/AutoRedraw.js";
import {Toast} from "../../../../../ui/Toast.jsx";


@autoredraw(SubscriptionStore, UserAddressStore)
export class EditAddressModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            address: null,
            confirmLabel: Messages.editAddress,
            title: Messages.editAddress,
            confirmAction: () => this.editAddress(),
        };
    }

    @wrapInSpinner
    async editAddress() {
        const validInputs = this.form && this.form.validateInputs();
        if (!validInputs || !this.options.address) {
            return;
        }

        try {
            await apiEditAddress({
                ...this.form.getValues(),
                userAddressId: this.options.address.id
            });
            this.hide();
        } catch (error) {
            this.onErrorResponseReceived(error)
        }
    }

    onErrorResponseReceived(errorResponse) {
        this.form.displayFieldErrors(errorResponse);
        const nonFieldError = errorResponse.find(fieldError => fieldError.field === "non_field_errors");

        if (nonFieldError) {
            Toast.showError(nonFieldError);
        }
    }

    render() {
        return <AddressForm ref="form" address={this.options.address}/>;
    }
}
