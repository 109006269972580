import {Device} from "../stem-core/src/base/Device";

export function isDesktopMedium() {
    return window.screen && Device.isMobileDevice() ? window.screen.width <= 1280 : window.innerWidth <= 1280;
}

// TODO: Should 'isDesktopMedium', 'isSmallScreen' and 'isDesktopSmall' be a single function with a width arg?
export function isDesktopSmall() {
    return window.screen && Device.isMobileDevice() ? window.screen.width <= 880 : window.innerWidth <= 880;
}

