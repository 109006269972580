import {GenericObjectStore, StoreObject} from "../../../stem-core/src/state/Store.js";
import {field} from "../../../stem-core/src/state/StoreField.js";

export class MerchantSDKResource extends StoreObject {
    @field("Merchant") merchant;
    @field(String) alias;
    @field(String) name;
    @field(String) description;
    @field(Date) createdAt;
    @field(Date) lastEditedAt;

    @field("MerchantSDKSettings") settingsVersion; // TODO @cleanup rename to version?
    @field("self") versionParent; // Matching resource from previous version. Named like this to distinguish from inheritance along the same version (from base panel for instance)

    @field(Object) options;
    @field(Object) dashboardOptions;

    toString() {
        return this.name;
    }

    get parent() {
        return this.versionParent;
    }

    get parentId() {
        return this.versionParentId;
    }

    allHistoricVersions(maxLength = 10) {
        let result = [];
        let currentVersion = this.parent;
        while (currentVersion && result.length < maxLength) {
            result.push(currentVersion);
            currentVersion = currentVersion.parent;
        }
        return result;
    }

    // Return an array of all resources with the same version (implicitly with same merchant).
    allWithSameVersion() {
        return this.getStore().filterBy({settingsVersionId: this.settingsVersionId});
    }

    // Return if there is a resource that is based on the current on in settingsVersion
    getFork(settingsVersion) {
        return this.getStore().findBy({settingsVersion, versionParent: this});
    }
}

export class MerchantSDKResourceStoreClass extends GenericObjectStore {
    constructor(objectType, ObjectClass, dependencies = []) {
        super(objectType, ObjectClass, {
            dependencies: ["Merchant", "MerchantSDKSettings", ...dependencies],
        });
    }

    // If settingsVersion is null, it's considered we want the master version of the alias
    getByIdOrAlias(idOrAlias, settingsVersion) {
        const objFromId = this.get(idOrAlias);
        if (objFromId) {
            return objFromId;
        }
        if (settingsVersion) {
            return this.findBy({alias: idOrAlias, settingsVersion});
        }
        return this.find(obj => obj.alias === idOrAlias && obj.settingsVersion?.isMaster);
    }

    all() {
        return super.all().sort((a, b) => b.lastEditedAt - a.lastEditedAt);
    }
}
