import {Dispatchable} from "../../../stem-core/src/base/Dispatcher.js";
import {iFrameConnection} from "../../services/IFrameConnection.js";
import {IFrameMessages} from "../../../blink-sdk/messaging/IFrameMessages.js";
import {AnalyticsEventType, dispatchAnalyticsEvent} from "../../../blink-sdk/utils/AnalyticsClient.js";
import {iFrameState} from "../../services/IFrameState.js";
import {FlowPlan} from "./Flow.js";


export function sendFlowCheckpoint(flowType, flowCheckpoint, payload, extra) {
    dispatchAnalyticsEvent(AnalyticsEventType.FLOW_CHECKPOINT, {flowType, flowCheckpoint, ...payload});
    iFrameConnection.sendToSDK(IFrameMessages.FLOW_CHECKPOINT, {
        event: {
            flowType,
            flowCheckpoint,
            ...payload,
        },
        extra,
    });
}


export class BaseFlowHandler extends Dispatchable {
    // Needed to avoid a reset on an active flow that's already doing this
    sameAsNewOptions(panelType) {
        const {skipCTA, editRecurring} = iFrameState;

        // TODO @flow If we already skipped the CTA, no point in reverting back to a previous state
        const ignoreSkipCTA = this.skipCTA === skipCTA || this.skipCTA;

        return this.panelType === panelType && this.editRecurring === editRecurring && ignoreSkipCTA;
    }

    saveInitialOptions() {
        this.skipCTA = iFrameState.skipCTA;
        this.editRecurring = iFrameState.editRecurring;
    }

    makeFlowPlan(steps) {
        if (!steps) {
            steps = this.getFlowPlan();
        }
        return new FlowPlan(steps, () => this.finalize());
    }
}

