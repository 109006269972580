import {UI} from "../../stem-core/src/ui/UIBase.js";
import {styleRule, styleRuleInherit, StyleSheet} from "../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../stem-core/src/ui/style/Theme.js";
import {Link} from "../../stem-core/src/ui/primitives/Link.jsx";
import {Messages} from "../../blinkpay/Messages.js";
import {Flow} from "../../blinkpay/panel/flows/Flow.js";

export class BlinkLinkStyle extends StyleSheet {
    @styleRule
    container = {
        cursor: "pointer",
        color: this.themeProps.LINK_COLOR,
        fontWeight: this.themeProps.FONT_WEIGHT_REGULAR,
        fontSize: "inherit",
        ":hover": {
            color: this.themeProps.LINK_COLOR_HOVER,
            textDecoration: "underline",
        },
    };
}

class LinkButtonStyle extends BlinkLinkStyle {
    @styleRuleInherit
    container = {
        userSelect: "none",
        fontSize: this.themeProps.FONT_SIZE_NORMAL,
        transition: this.themeProps.DEFAULT_TRANSITION,
        fontWeight: "initial",
        ":hover": {
            textDecoration: null,
        },
    }

    @styleRule
    centered = {
        margin: "12px auto",
        width: "fit-content",
        display: "block",
    }

    @styleRule
    disabled = {
        pointerEvents: "none",
        cursor: "not-allowed",
    };

    @styleRule
    invisible = {
        opacity: 0,
    };
}

// TODO temp class to just collect all random divs that have a link button style attached to them.
//  Should it be called TextButton?
//  Have two modes - inline or centered per line
@registerStyle(LinkButtonStyle)
export class LinkButton extends Link {
    extraNodeAttributes(attr) {
        const {styleSheet} = this;
        const {disabled, invisible, centered} = this.options;

        if (disabled || invisible) {
            attr.addClass(styleSheet.disabled);
        }

        if (invisible) {
            attr.addClass(styleSheet.invisible);
        }

        if (centered) {
            attr.addClass(styleSheet.centered);
        }
    }
}


class BackButtonStyle extends LinkButtonStyle {
    @styleRuleInherit
    container = {
        marginTop: 18,
        display: "block",
        textAlign: "center",
        color: "inherit",
        ":hover": {
            color: "inherit",
        }
    }
}

@registerStyle(BackButtonStyle)
export class BackButton extends LinkButton {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            onClick: () => Flow.back(),
            label: Messages.back,
        }
    }
}