import {UI} from "../../../../../../stem-core/src/ui/UIBase.js";

import {Messages} from "../../../../../Messages.js";
import {ConfirmationModal} from "../../../../../ui/ConfirmationModal.jsx";
import {apiSetPrimaryPaymentMethod, PaymentMethodStore} from "../../../../../../client/state/PaymentMethodStore.js";
import {Toast} from "../../../../../ui/Toast.jsx";
import {PaymentMethodDescription} from "../../../../../common/PaymentMethodDescription.jsx";
import {DropdownInput} from "../../../../../../core/ui/input/dropdown/DropdownInput.jsx";


export class SelectPrimaryCardModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            testId: "selectPrimaryCardModal",
            title: Messages.choosePaymentMethod,
            confirmLabel: "Select",
            confirmAction: async () => {
                let paymentMethod = this.cardsDropdown.getValue();
                if (paymentMethod === PaymentMethodStore.getPrimary()) {
                    this.hide();
                    return;
                }
                paymentMethod.isPrimary = true;
                try {
                    await apiSetPrimaryPaymentMethod(paymentMethod.id);
                    Toast.show(Messages.changesSaved);
                    this.hide();
                } catch (err) {
                    // TODO: handle this case
                    console.error(err);
                }
            },
        };
    }

    render() {
        return <DropdownInput
            ref="cardsDropdown"
            initialValue={PaymentMethodStore.getPrimary()}
            formatter={value => <PaymentMethodDescription paymentMethod={value} showExpiryDate />}
            options={PaymentMethodStore.getAvailablePaymentMethods()}
        />;
    }
}
