import {UI, styleRule, registerStyle} from "../../../../../stem-core/src/ui/UI.js";

import {Messages} from "../../../../Messages.js";

import {BaseCard, BaseCardStyle} from "../../../../ui/BaseCard.jsx";
import {Toast} from "../../../../ui/Toast.jsx";
import {DividerPositions, List} from "../../../../ui/List.jsx";
import {LabeledToggle} from "../../../components/LabeledToggle.jsx";
import {apiUpdatePreferences} from "../../../../../client/state/UserPreferencesStore.js";


class UserSettingsStyle extends BaseCardStyle {
    @styleRule
    settingEntry = {
        paddingBottom: 6
    }
}

@registerStyle(UserSettingsStyle)
export class UserSettings extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.settings,
            userPreferences: null,
        };
    }

    render() {
        const {styleSheet} = this;
        const {userPreferences} = this.options;

        return (
            <List dividerOptions={{position: DividerPositions.TOP}}>
                <div>
                    <LabeledToggle
                        label={Messages.alwaysShowBalance}
                        initialValue={userPreferences.shouldShowBalanceInline()}
                        onChange={(value) => this.updateUserPreferences({alwaysShowBalance: value})}
                        testId="accountShowBalanceToggle"
                    />
                    <div className={styleSheet.settingDescription}>
                        {Messages.alwaysShowBalanceDescription}
                    </div>
                </div>

                <div>
                    <LabeledToggle
                        label={Messages.showPaymentAnimation}
                        initialValue={userPreferences.shouldShowPaymentAnimation()}
                        onChange={(value) => this.updateUserPreferences({showPaymentAnimation: value})}
                        testId="accountPaymentAnimationToggle"
                    />
                    <div className={styleSheet.settingDescription}>
                        {Messages.showPaymentAnimationDescription}
                    </div>
                </div>
            </List>
        );
    }

    async updateUserPreferences(request) {
        try {
            await apiUpdatePreferences(request);
            Toast.show(Messages.changesSaved);
        } catch (error) {
            Toast.showError(error, Messages.errorWhileSaving);
        }
    }
}
