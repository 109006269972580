import {Flow} from "./Flow.js";
import {PaymentMethodStore} from "../../../client/state/PaymentMethodStore.js";
import {iFrameUserDataService} from "../../services/IFrameUserDataService.js";
import {SubscriptionFlowType} from "./steps/SubscriptionPlanFlowStep.js";
import {apiUpdateSubscription} from "../../../client/state/SubscriptionStore.js";
import {BaseFlowHandler} from "./BaseFlowHandler.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {INLINE_USER_DASHBOARD_URLS} from "../PanelConstants.js";
import {cleanObject} from "../../../stem-core/src/base/Utils.js";
import {Messages} from "../../Messages.js";

export class EditSubscriptionFlowHandler extends BaseFlowHandler {
    getFlowPlan() {
        const activeSubscription = iFrameUserDataService.getActiveSubscription();

        return [
            Flow.subscriptionPlan.init({
                subscriptionFlowType: SubscriptionFlowType.EDIT_SUBSCRIPTION,
                selectedValue: activeSubscription.recurringOffer,
                discountCode: activeSubscription.getDiscount()?.getCode() || null,
            }),

            // Remove nulls so that a default is only considered if there's an existing address
            Flow.address.init(cleanObject({
                shouldPrompt: () => Flow.subscriptionPlan.selectedValue.shouldCollectAddress(),
                selectedValue: activeSubscription.getShippingAddress(),
            })),

            Flow.paymentMethod.init({
                continueLabel: Messages.reviewOrder,
                selectedValue: activeSubscription.getPaymentMethod(),
                savePaymentMethod: true,
                scope: PaymentMethodStore.Scope.PRIVATE,
            }),

            Flow.editSubscriptionConfirmation,
        ]
    }

    async getPayload() {
        const activeSubscription = iFrameUserDataService.getActiveSubscription();

        const payload = {};

        if (Flow.paymentMethod.selectedValue && Flow.paymentMethod.selectedValue !== activeSubscription.getPaymentMethod()) {
            const paymentMethodPayload = await Flow.paymentMethod.getPayload();
            Object.assign(payload, paymentMethodPayload);
        }

        if (Flow.address.selectedValue && Flow.address.selectedValue !== activeSubscription.deliveryAddress) {
            payload.deliveryAddressId = Flow.address.selectedValue.id;
        }

        if (Flow.subscriptionPlan.selectedValue && Flow.subscriptionPlan.selectedValue !== activeSubscription.recurringOfferId) {
            payload.recurringOfferId = Flow.subscriptionPlan.selectedValue.id;
        }

        if (Object.keys(payload).length === 0) {
            // Nothing has changed, no point in calling the endpoint
            return null;
        }

        payload.subscriptionId = activeSubscription.id;
        payload.applyPolicy = Flow.subscriptionPlan.applyPolicy;

        return payload;
    }

    @wrapInSpinner
    async finalize() {
        const payload = await this.getPayload();

        if (!payload) {
            return;
        }

        await apiUpdateSubscription(payload);

        Router.changeURL(INLINE_USER_DASHBOARD_URLS.subscriptionChangeSuccess);
    }
}
