import {BaseEnum, makeEnum} from "../../stem-core/src/state/BaseEnum.js";
import {MakeStore, StoreObject} from "../../stem-core/src/state/Store";
import {field} from "../../stem-core/src/state/StoreField.js";
import {apiClient} from "../connection/BlinkApiClient.js";

@makeEnum
export class PolicyType extends BaseEnum {
    static COOKIE;
    static TOS;
    static PRIVACY;
}

class PolicyTerms extends StoreObject {
    @field(Date) createdAt;
    @field(PolicyType) type;
    @field(String) htmlTemplate;
}

export const PolicyTermsStore = MakeStore("PolicyTerms", PolicyTerms);

export async function apiAdminCreatePolicy(request) {
    return apiClient.post("/compliance/create_policy/", request);
}
