import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {ADDRESS_PANEL_URL} from "../../PanelConstants.js";
import {UserAddressStore} from "../../../../client/state/UserAddressStore.js";
import {iFrameUserDataService} from "../../../services/IFrameUserDataService.js";
import {BaseFlowStep} from "./BaseFlowStep.jsx";


export class AddressFlowStep extends BaseFlowStep {
    haveOptionsAvailable() {
        return this.selectedValue || iFrameUserDataService.getExtraUserAddressCount();
    }

    getDefaultFields() {
        this.selectedValue = UserAddressStore.getDefault();
    }

    showPanel() {
        Router.changeURL(ADDRESS_PANEL_URL);
    }
}
