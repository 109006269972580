import {UI} from "../../../../stem-core/src/ui/UI.js";
import {ArticlePayments} from "./sections/ArticlePayments.jsx";
import {AutopayCard} from "./sections/AutopayCard.jsx";


export class ArticlesPage extends UI.Element {
    render() {
        return [
            <AutopayCard testId="autopayCard"/>,
            <ArticlePayments testId="articlePaymentsCard"/>
        ];
    }
}
