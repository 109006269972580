import {PANEL_TYPE} from "../../../blink-sdk/Constants.js";
import {Router} from "../../../stem-core/src/ui/Router.jsx";
import {BaseFlowHandler} from "./BaseFlowHandler.js";


export class CustomPanelFlowHandler extends BaseFlowHandler {
    panelType = PANEL_TYPE.custom;

    getFlowPlan() {
        Router.changeToCustomPanel();

        return null;
    }
}
