import {UI, StyleSheet, styleRule, registerStyle} from "../../stem-core/src/ui/UI";
import {Input} from "../../stem-core/src/ui/input/Input";

import {Messages} from "../../blinkpay/Messages";
import {BlinkButton} from "../../core/ui/Button.jsx";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {isDesktopSmall} from "../Utils";
import {Device} from "../../stem-core/src/base/Device";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {AuthenticationModal} from "./AuthenticationModal";
import {authFormService} from "../../blinkpay/services/AuthFormService";


class AuthenticationInputStyle extends StyleSheet {
    @styleRule
    input = {
        padding: () => (Device.isMobileDevice() ? "10px 12px" : "13px 18px"),
        color: this.themeProps.LANDING_PAGE_INPUT_COLOR,
        fontSize: () => (Device.isMobileDevice() ? 12 : 16),
        borderRadius: 6,
        outline: "none",
        border: "none",
        boxShadow: this.themeProps.LANDING_PAGE_INPUT_SHADOW,
        width: "100%",
    };
}

@registerStyle(AuthenticationInputStyle)
class AuthenticationInput extends UI.Element {
    getDefaultOptions() {
        return Object.assign({}, super.getDefaultOptions(), {
            onEnter: NOOP_FUNCTION,
            inputAttributes: {},
        });
    }

    render() {
        const {styleSheet} = this;
        const {inputAttributes} = this.options;

        return [<Input className={styleSheet.input} ref="input" {...inputAttributes} />];
    }

    getValue() {
        return (this.input && this.input.getValue()) || "";
    }

    addListeners() {
        this.input.addKeyUpListener(event => {
            if (event.code === "Enter" || event.key === "Enter") {
                this.options.onEnter();
            }
        });
    }

    onMount() {
        super.onMount();
        this.addListeners();
    }
}

class AuthenticationComponentStyle extends StyleSheet {
    loginMessage = {
        margin: isDesktopSmall() ? "18px 0 0" : "18px 0 0 18px",
        fontSize: Device.isMobileDevice() ? 12 : 16,
    };

    // TODO @cleanup this shit is dependent on adding a magical "theme" suffix
    @styleRule
    loginMessageDark = {
        ...this.loginMessage,
        color: this.themeProps.WHITE,
    };

    @styleRule
    loginMessageLight = {
        ...this.loginMessage,
        color: this.themeProps.LANDING_1,
    };

    button = {
        padding: () => (Device.isMobileDevice() ? "10px 12px !important" : "13px 18px !important"),
        fontSize: () => (Device.isMobileDevice() ? "12px !important" : ""),
        marginLeft: 12,
        borderRadius:  "6px !important",
        boxShadow: () => this.themeProps.LANDING_PAGE_BUTTON_SHADOW + "!important",
    };

    @styleRule
    buttonDark = {
        ...this.button,
        background: () => this.themeProps.LANDING_PAGE_BUTTON_DARK_THEME_BACKGROUND + "!important",
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_DARK_BUTTON_SHADOW_ON_DARK_BACKGROUND + "!important",
        },
    };

    @styleRule
    buttonLight = {
        ...this.button,
        background: () => this.themeProps.LANDING_PAGE_BUTTON_LIGHT_THEME_BACKGROUND + "!important",
        ":hover": {
            boxShadow: () => this.themeProps.LANDING_PAGE_DARK_BUTTON_SHADOW_ON_LIGHT_BACKGROUND + "!important",
        },
    };

    link = {
        marginLeft: 5,
        borderBottom: () => "1px solid " + this.themeProps.LINK_COLOR,
        fontWeight: "normal",
    };

    @styleRule
    flex = {
        display: "flex",
        ">:first-child": {
            flex: 1,
            maxWidth:  404,
        },
        width: isDesktopSmall() ? "100%" : 559,
    };

    @styleRule
    inputDark = {
        " input::placeholder": {
            color: this.themeProps.LANDING_PAGE_INPUT_DARK_THEME_PLACEHOLDER_COLOR,
        },
    };

    @styleRule
    inputLight = {
        " input::placeholder": {
            color: this.themeProps.LANDING_PAGE_INPUT_LIGHT_THEME_PLACEHOLDER_COLOR,
        },
    };

    @styleRule
    fullHeightInput = {
        ">input": {
            height: "100%",
        }
    }
}

@registerStyle(AuthenticationComponentStyle)
export class AuthenticationComponent extends UI.Element {
    static themes = {
        DARK: "Dark",
        LIGHT: "Light",
    };

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            colorTheme: this.constructor.themes.DARK,
        };
    }

    render() {
        const {styleSheet} = this;
        const {colorTheme} = this.options;

        return [
            <div className={styleSheet.flex}>
                <AuthenticationInput
                    ref="input"
                    inputAttributes={{placeholder: Messages.enterEmailAddress, type: "email"}}
                    className={styleSheet.fullHeightInput + styleSheet["input" + colorTheme]}
                    onEnter={() => this.onSignupConfirmation()}
                />
                <BlinkButton className={styleSheet["button" + colorTheme]} onClick={() => this.onSignupConfirmation()}>
                    {Messages.createAccount}
                </BlinkButton>
            </div>,
            <div className={styleSheet["loginMessage" + colorTheme]}>
                {Messages.loginMessage}{" "}
                <Link onClick={() => AuthenticationModal.show()}>
                    {Messages.logIn}
                </Link>
            </div>,
        ];
    }

    onSignupConfirmation() {
        authFormService.setRegistrationMail(this.input.getValue());
        AuthenticationModal.show();
    }
}
