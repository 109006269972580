import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {apiRequestUserEmailChange, apiUpdateUserProfile, UserProfileStore} from "../../state/UserProfileStore";
import {NOOP_FUNCTION, unwrapArray} from "../../../stem-core/src/base/Utils";
import {apiClient} from "../BlinkApiClient.js";
import {executeWhenDocumentVisible, isFacebookBrowser} from "../../../blinkpay/Utils";
import {authService, AuthService} from "./AuthService";
import {ApiErrors} from "../ApiErrors";
import {Messages} from "../../../blinkpay/Messages";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {MerchantUserStore} from "../../state/merchant/MerchantUserStore";
import {Toast} from "../../../blinkpay/ui/Toast.jsx";


class UserService extends Dispatchable {
    finishedUserDataRequest = false;
    initiatedUserDataRequest = false;
    userData = null;

    constructor() {
        super();
        authService.addListener(AuthService.EventTypes.CLEAR_CURRENT_INFO, () => this.clearUserInfo());
        authService.addListener(AuthService.EventTypes.USER_DATA_AVAILABLE, (response) => {
            this.setUserData(response);
        });
    }

    getUser() {
        return UserProfileStore.getUserProfile();
    }

    getUserId() {
        return this.getUser()?.id;
    }

    getUserName() {
        const user = this.getUser();
        if (!user) {
            return "";
        }
        const {firstName, lastName} = user;
        return unwrapArray([firstName, lastName]).join(" ").trim();
    }

    getUserEmail() {
        return this.getUser()?.getEmail() || "";
    }

    isUserFetched() {
        return this.getUser() != null;
    }

    getMerchantUser(merchant) {
        return MerchantUserStore.getByUserAndMerchant(this.getUserId(), merchant.id);
    }

    isAutoPayEnabledForMerchant(merchant) {
        const merchantUser = this.getMerchantUser(merchant);
        const userPreferences = this.getUser()?.getPreferences();
        if (!userPreferences?.autoPayEnabled) {
            return false;
        }
        return merchantUser?.autoPayEnabled ?? true;
    }

    setUserData(userData) {
        this.initiatedUserDataRequest = true;
        this.userData = userData;
        this.dispatch("userDataFetched", this.userData);
        this.finishedUserDataRequest = true;
        this.dispatch("userDataRequestFinished");
    }

    async forceFetchUserData() {
        this.initiatedUserDataRequest = true;
        try {
            this.userData = await apiClient.get("/user_data");
            this.dispatch("userDataFetched", this.userData);
        } catch(e) {
            this.userData = null;
            if (e.code === ApiErrors.AUTHENTICATION_FAILED || e.code === ApiErrors.NOT_AUTHENTICATED) {
                authService.clearCurrentInfo();
            } else {
                throw e;
            }
        } finally {
            this.finishedUserDataRequest = true;
            this.dispatch("userDataRequestFinished");
        }
    }

    // TODO @cleanup make all this stuff async, possible reuse ResourceCache
    fetchUserData() {
        // Sometimes a request is blocked if the tab isn't the active one, so wait until we're visible
        executeWhenDocumentVisible(() => this.forceFetchUserData());

        // TODO: Facebook browser caches all first requests for a given URL. That is why we need here to make another
        //  request so that the real data gets fetched; perhaps we can avoid this by using a POST instead of a GET?
        if (isFacebookBrowser()) {
            setTimeout(() => this.forceFetchUserData(), 1000);
        }
    }

    onUserDataRequestFinished(callback) {
        return this.addListenerOnce("userDataRequestFinished", callback);
    }

    isUserDataRequestFinished() {
        return this.isUserFetched() || this.finishedUserDataRequest;
    }

    // TODO: all the different iframes need to have some caching mechanism on local storage so they don't all make the same request 4 times
    // TODO this needs to be a promise
    ensureUserDataRequested(callback = NOOP_FUNCTION) {
        if (this.isUserDataRequestFinished()) {
            queueMicrotask(callback);
            return;
        }
        this.onUserDataRequestFinished(() => queueMicrotask(callback));

        if (!this.initiatedUserDataRequest) {
            this.fetchUserData();
        }
    }

    clearUserInfo() {
        this.finishedUserDataRequest = false;
        this.initiatedUserDataRequest = false;
    }

    @wrapInSpinner
    async requestUpdateUserData(newName, newEmail) {
        const currentName = this.getUserName();
        newName = (newName || "").trim();

        if (currentName !== newName) {
            const [firstName, ...lastName] = (newName || "").split(" ");
            try {
                await apiUpdateUserProfile({firstName, lastName: lastName.join(" ")});
            } catch (error) {
                Toast.showError(error, Messages.errorWhileSaving);
                return false;
            }
        }
        const currentEmail = this.getUserEmail();
        newEmail = (newEmail || "").trim();
        if (currentEmail !== newEmail) {
            try {
                await apiRequestUserEmailChange({newEmail});
                return true;
            } catch (error) {
                Toast.showError(error, Messages.errorWhileSaving);
            }
        }
        return false;
    }
}

export const userService = new UserService();
